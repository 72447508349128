import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Box } from '@mediahuis/chameleon-react';
import ItemTypes from './ItemTypes';

const Grid = styled(Box)`
  && {
    margin-bottom: 10px;
  }
  cursor: move;
  text-align: left;
`;

const Card = ({
  card,
  index,
  moveCard,
  availableOptions,
  setAvailableOptions,
  chosenOptions,
  setChosenOptions,
}) => {
  // Based on: https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const { CARD, BOX, RIGHT_SIDE } = ItemTypes;
  const ref = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop({
    accept: CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items
      // height When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { ...card, id: card.id, text: card.text, type: CARD, index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      // Custom logic for setting local state of left and right side.
      if (dropResult && dropResult.name === RIGHT_SIDE) {
        setAvailableOptions([...availableOptions, { ...item, type: BOX }]);
        const optionIds = chosenOptions.map(chosenOption => chosenOption.id);
        const indexId = optionIds.indexOf(item.id);
        const spreadedArray = [...chosenOptions];
        if (indexId > -1) {
          spreadedArray.splice(indexId, 1);
        }
        setChosenOptions(spreadedArray);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid ref={ref} style={{ opacity }}>
      {index + 1}. {card.text}
    </Grid>
  );
};
export default Card;
