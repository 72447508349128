const convertRequirementParams = ({ newRequirement, keysToConvert }) =>
  JSON.parse(JSON.stringify(newRequirement.parameters), (key, value) => {
    if (Object.prototype.hasOwnProperty.call(keysToConvert, key)) {
      return parseFloat(value);
    }
    if (key === 'choices') {
      return value.split(',');
    }
    return value;
  });

export default convertRequirementParams;
