import {
  Box,
  Button,
  Caption,
  Flex,
  Paper,
  Paragraph,
} from '@mediahuis/chameleon-react';
import React from 'react';

const PickupCard = ({
  location,
  hoverable = true,
  handlePickupLocationSelect,
  isSelected,
}) => (
  <Paper hoverable={hoverable}>
    <Box key={`${location.shopId}${location.deliveryStore}`} p={4}>
      <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
        <Box>
          <Paragraph fontWeight="medium">{location.deliveryStore}</Paragraph>
          <Caption>
            {`${location.street} ${location.houseNumber}${
              location.busNumber === undefined ? '' : `/${location.busNumber}`
            }`}
            <br />
            {`${location.postalCode} ${location.city}`}
            <br />
            {location.countryCode === 'NL' ? 'Nederland' : 'België'}
          </Caption>
        </Box>
      </Flex>
      {isSelected ? (
        <Button
          onClick={() => handlePickupLocationSelect()}
          size="small"
          appearance="secondary"
        >
          Remove
        </Button>
      ) : (
        <Button
          onClick={() => handlePickupLocationSelect(location)}
          size="small"
          appearance="secondary"
        >
          Add
        </Button>
      )}
    </Box>
  </Paper>
);

export default PickupCard;
