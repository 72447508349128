import { useMutation, useQueryClient } from 'react-query';

import { OrderService, ORDER_KEY } from '~/services/order';

export const usePutChoiceValidation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      OrderService.putChoiceValidation(body).then(response => response.data),
    {
      onSuccess: responseData => {
        queryClient.setQueryData(
          [ORDER_KEY, responseData.orderId],
          currentOrder => ({
            ...currentOrder,
            choiceValidations: currentOrder.choiceValidations.map(
              choiceValidation => {
                if (choiceValidation.id === responseData.id) {
                  return responseData;
                }

                return choiceValidation;
              },
            ),
          }),
        );
      },
    },
  );
};
