import { createGlobalStyle } from 'styled-components';

export const theme = {
  palette: {
    type: 'light',
    primary: '#1D3557',
    secondary: '#E50000',
    error: '#E50000',
    grey: {
      900: '#fafafa',
      800: '#f5f5f5',
      700: '#eeeeee',
      600: '#e0e0e0',
      500: '#bdbdbd',
      400: '#9e9e9e',
      300: '#757575',
      200: '#616161',
      100: '#424242',
      50: '#212121',
    },
  },
  colors: {
    ds: {
      primary: '#d90000',
      secondary: '#5a4848',
      tertiary: '#025a8a',
      graph: '#b3b3b3',
    },
    gva: {
      primary: '#e20714',
      secondary: '#c1e8e3',
      tertiary: '#ffa600',
      graph: '#fb7a82',
    },
    hbvl: {
      primary: '#ffed00',
      secondary: '#009fe3',
      tertiary: '#e91c1f',
      graph: '#fff876',
    },
    nb: {
      primary: '#0b88e6',
      secondary: '#fdc300',
      tertiary: '#e30614',
      graph: '#8cc1ed',
    },
    dl: {
      primary: '#0075bf',
      secondary: '#ffe600',
      tertiary: '#ffe600',
      graph: '#74c2f1',
    },
    dvn: {
      primary: '#0063af',
      secondary: '#e6007e',
      tertiary: '#bbbbbb',
      graph: '#9bc5e4',
    },
    frd: {
      primary: '#1c738d',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#6cd1ec',
    },
    hco: {
      primary: '#2d4c9e',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#8094cc',
    },
    lcd: {
      primary: '#5059b3',
      secondary: '#d71625',
      tertiary: '#414141',
      graph: '#a7abce',
    },
    mco: {
      primary: '#1c738d',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#6cd1ec',
    },
    nof: {
      primary: '#1c738d',
      secondary: '#549504',
      tertiary: '#1370c2',
      graph: '#00aeda',
    },
    nos: {
      primary: '#2d4c9e',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#8094cc',
    },
    sco: {
      primary: '#0079b6',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#9fc8e2',
    },
    stw: {
      primary: '#d71625',
      secondary: '#c54300',
      tertiary: '#1370c2',
      graph: '#fda6ae',
    },
    lw: {
      primary: '#007cbf',
      secondary: '#c20114',
      tertiary: '#bfad65',
      graph: '#99cde9',
    },
    lt: {
      primary: '#ffbf04',
      secondary: '#0082c9',
      tertiary: '#bfad65',
      graph: '#ffe59b',
    },
    co: {
      primary: '#d1000e',
      secondary: '#0082c9',
      tertiary: '#bfad65',
      graph: '#f3646e',
    },
    tc: {
      primary: '#e30513',
      secondary: '#009fe3',
      tertiary: '#ffed00',
      graph: '#f49ba1',
    },
    black: '#000000',
    white: '#FFFFFF',
    success: '#A8DADC',
    error: '#F25F5C',
    gray: '#BDBDBD',
    grayLight: '#E5E5E5',
  },
  spacing: {
    base: '16px',
    quarter: '4px',
    half: '8px',
    andHalf: '24px',
    double: '32px',
    triple: '48px',
    quad: '64px',
  },
  grid: {
    maxWidth: '1200px',
  },
};

export const GlobalStyle = createGlobalStyle`
  /* General */

  img {
    max-width: 100%;
  }
  
  figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`;
