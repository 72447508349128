const PAYMENT_OPTION = Object.freeze({
  Bancontact: 'Bancontact',
  Belfius: 'Belfius',
  CreditCard: 'CreditCard',
  DirectDebit: 'DirectDebit',
  Ideal: 'Ideal',
  KBC: 'KBC',
  PayPal: 'PayPal',
  WireTransfer: 'WireTransfer',
});

export default PAYMENT_OPTION;
