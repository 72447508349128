import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';

Amplify.configure({
  Auth: {
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
    oauth: {
      domain: COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  },
  Storage: {
    bucket: 'mh-subscriber-settings',
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: COGNITO_REGION,
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
