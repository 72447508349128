import { isBefore } from 'date-fns';

const validateVoucher = ({ usedDate, expirationDate, generatedDate }) => {
  const errors = {};
  if (!expirationDate) {
    errors.startDate = 'Start Date is required';
  }
  if (
    expirationDate &&
    generatedDate &&
    isBefore(new Date(expirationDate), new Date(generatedDate))
  ) {
    errors.expirationDate = `Expiration date must be later than ${new Date(
      generatedDate,
    ).toDateString()}`;
  }
  if (
    usedDate &&
    generatedDate &&
    isBefore(new Date(usedDate), new Date(generatedDate))
  ) {
    errors.usedDate = `Used date must be later than ${new Date(
      generatedDate,
    ).toDateString()}`;
  }
  if (
    usedDate &&
    generatedDate &&
    isBefore(new Date(expirationDate), new Date(usedDate))
  ) {
    errors.usedDate = `Used date must be before ${new Date(
      expirationDate,
    ).toDateString()}`;
  }

  return errors;
};

export default validateVoucher;
