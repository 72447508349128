import React, { useEffect } from 'react';
import { Select, Box } from '@mediahuis/chameleon-react';
import { useTranslation } from 'react-i18next';

const LanguageSelect = ({ options = [], brand = null, isEditing }) => {
  const { i18n } = useTranslation();

  const changeLanguage = e => {
    const lang = e.target.value;
    i18n?.changeLanguage(lang);
  };

  useEffect(() => {
    if (isEditing) {
      const languages = POSSIBLE_LANGUAGES?.[brand];
      languages &&
        !languages.some(lang => lang.value === i18n.language) &&
        i18n?.changeLanguage(languages[0].value);
    } else {
      brand && i18n?.changeLanguage(POSSIBLE_LANGUAGES?.[brand]?.[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  return (
    <Box mb={5}>
      <Select
        id="language"
        value={i18n?.languages[0]}
        onChange={changeLanguage}
        label="Language"
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelect;
