import styled from 'styled-components';

const StatusCircle = styled.span`
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  ${props =>
    props.variant === 'success' &&
    `
    background-color: ${props.theme.colors.success};
  `}
  ${props =>
    props.variant === 'error' &&
    `
   background-color: ${props.theme.colors.error};
  `}
  ${props =>
    props.variant === 'pending' &&
    `
    background-color: ${props.theme.palette.grey[500]};
  `}
`;
export default StatusCircle;
