import {
  Checkmark,
  Clock,
  Gallery,
  Home,
  ListFill,
  LockClosed,
  Mail,
  Menu,
  RecentlyViewedFill,
  ShoppingBag,
  Tag,
  Textsize,
} from '@mediahuis/chameleon-theme-wl/icons';

import { REQUIREMENT_TYPE } from '../enums';

const getRequirementIcon = requirementType => {
  const icons = {
    [REQUIREMENT_TYPE.AddressArea]: Home,
    [REQUIREMENT_TYPE.Boolean]: Checkmark,
    [REQUIREMENT_TYPE.Choice]: Menu,
    [REQUIREMENT_TYPE.Date]: Clock,
    [REQUIREMENT_TYPE.Email]: Mail,
    [REQUIREMENT_TYPE.File]: Gallery,
    [REQUIREMENT_TYPE.InvoiceDetails]: ListFill,
    [REQUIREMENT_TYPE.Login]: LockClosed,
    [REQUIREMENT_TYPE.Payment]: ShoppingBag,
    [REQUIREMENT_TYPE.Preconditions]: RecentlyViewedFill,
    [REQUIREMENT_TYPE.Textfield]: Textsize,
    [REQUIREMENT_TYPE.Voucher]: Tag,
  };

  return icons[requirementType] ?? null;
};

export default getRequirementIcon;
