import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

const useConfiguration = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const getConfiguration = async () => {
    try {
      setIsLoading(true);
      const storageBlob = await Storage.get(
        `${MH_ENV}/aboshop/configuration.json`,
        {
          cacheControl: 'no-cache',
          download: true,
        },
      );
      const bodyText = await storageBlob.Body.text();

      setData(JSON.parse(bodyText));
      setIsError(false);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (err) {
      setError(err);
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  const updateConfiguration = async update => {
    try {
      setIsLoading(true);
      await Storage.put(`${MH_ENV}/aboshop/configuration.json`, update, {
        acl: 'public-read',
        contentType: 'application/json',
        level: 'public',
      });
      getConfiguration();
    } catch (err) {
      setError(err);
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    update: updateConfiguration,
  };
};

export default useConfiguration;
