import { Auth } from 'aws-amplify';
import Axios from 'axios';
import queryString from 'query-string';
import { getI18n } from 'react-i18next';

const preconditionServiceInstance = Axios.create({
  baseURL: API_PRECONDITION_RULES,
  headers: {
    'Content-Type': 'application/json',
  },
});

preconditionServiceInstance.interceptors.request.use(
  async config => {
    const { language } = getI18n();
    const session = await Auth.currentSession();

    if (language) {
      config.params.lang = language;
    }
    if (session.idToken?.jwtToken) {
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    }

    return config;
  },
  err => Promise.reject(err),
);

class PreconditionService {
  static getPreconditions(filters) {
    const filterParams = queryString.stringify(filters);

    return preconditionServiceInstance.get(`?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }
}

export default PreconditionService;
