import { Button, Flex, Text } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';

import LanguageDropdown from '~/components/LanguageDropdown';
import { useQueryParams } from '~/hooks';
import { VouchersQuery, useVouchersCsv } from '~/services/voucher';

import VouchersFilters from './VouchersFilters';
import VouchersTable from './VouchersTable';

const defaultOffset = 0;
const defaultOffsetSize = 20;

const VouchersPage = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [params, setParams] = useQueryParams();

  const vouchersCsvExport = useVouchersCsv();

  function resetFilters() {
    setParams({
      offset: defaultOffset,
    });
  }

  if (!params.offset) {
    return <Redirect to={`${pathname}?offset=${defaultOffset}`} />;
  }

  return (
    <VouchersQuery
      filters={params}
      render={(vouchers, isFetchingVouchers) => (
        <Flex
          justifyContent="space-between"
          px={5}
          style={{ gap: '2.5rem' }}
          width="full"
        >
          <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Total: {vouchers.totalCount}</Text>

              <Pagination
                current={params.offset / defaultOffsetSize + 1}
                pageSize={defaultOffsetSize}
                showSizeChanger={false}
                total={vouchers.totalCount}
                onChange={page =>
                  setParams({
                    ...params,
                    offset: (page - 1) * defaultOffsetSize,
                  })
                }
              />

              <Flex alignItems="center" style={{ gap: '5px' }}>
                <Button
                  appearance="primary"
                  data-testid="vouchers-download-btn"
                  loading={vouchersCsvExport.isLoading}
                  size="small"
                  onClick={() => vouchersCsvExport.mutate(params)}
                >
                  Download
                </Button>
                <LanguageDropdown
                  onChange={() => queryClient.invalidateQueries()}
                />
              </Flex>
            </Flex>

            <VouchersTable isLoading={isFetchingVouchers} vouchers={vouchers} />
          </Flex>

          <VouchersFilters onReset={resetFilters} />
        </Flex>
      )}
    />
  );
};

export default VouchersPage;
