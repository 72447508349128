import React from 'react';
import { TextField, Box, Checkbox } from '@mediahuis/chameleon-react';
import styled from 'styled-components';

const Collapse = styled(Box)`
  display: ${props => (props.$expanded ? 'block' : 'none')};
  transition: display 0.5s;
`;

const ProductCardFields = ({ product, handleItemsChange, index, expanded }) => (
  <Collapse $expanded={expanded}>
    <Box>
      <Box>
        <TextField
          id="label"
          data-testid="offerLabel"
          label="Label"
          name={`items[${index}].label`}
          maxLength="50"
          value={product.label}
          onChange={e => handleItemsChange(index, e.target.id, e.target.value)}
        />
      </Box>
      <Box mt={4}>
        <TextField
          id="buttonLabel"
          datatestid="buttonLabel"
          label="Button"
          value={product.buttonLabel}
          name={`items[${index}].buttonLabel`}
          onChange={e => handleItemsChange(index, e.target.id, e.target.value)}
        />
      </Box>
      <Box mt={4}>
        <TextField
          id="nextStep"
          datatestid="nextStep"
          label="Goto"
          value={product.nextStep}
          name={`items[${index}].nextStep`}
          onChange={e => handleItemsChange(index, e.target.id, e.target.value)}
        />
      </Box>
    </Box>
    {(MH_BRAND === 'hub' || MH_BRAND === 'nl') && (
      <Box>
        <Box mt={5}>
          <Checkbox
            id={`isMultiBrand${index}`}
            name="isMultiBrand"
            checked={product?.isMultiBrand}
            label={
              MH_BRAND === 'hub'
                ? 'feature: MultiBrand'
                : 'feature: Show bottom info'
            }
            onChange={e =>
              handleItemsChange(index, e.target.name, e.target.checked)
            }
          />
        </Box>
      </Box>
    )}
    {(MH_BRAND === 'hub' || MH_BRAND === 'nl') && (
      <Box>
        <Box mt={5}>
          <Checkbox
            id={`hasRouteYou${index}`}
            name="hasRouteYou"
            checked={product?.hasRouteYou}
            label="feature: RouteYou"
            onChange={e =>
              handleItemsChange(index, e.target.name, e.target.checked)
            }
          />
        </Box>
      </Box>
    )}
    <Box>
      <Box mt={5}>
        <Checkbox
          id={`isHighlighted${index}`}
          name="isHighlighted"
          checked={product?.isHighlighted}
          label="design: Highlight"
          onChange={e =>
            handleItemsChange(index, e.target.name, e.target.checked)
          }
        />
      </Box>
    </Box>
  </Collapse>
);

export default ProductCardFields;
