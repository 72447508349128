import {
  Box,
  Button,
  Flex,
  Select,
  TextField,
} from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { GENDER } from '~/constants/gender';
import { usePutLoginValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Login = ({ validation }) => {
  const [login, setLogin] = useState({
    account: validation.data?.account || '',
    brand: validation.data?.brand || '',
    firstName: validation.data?.firstName || '',
    gender: validation.data?.gender || GENDER.UNKNOWN,
    lastName: validation.data?.lastName || '',
  });
  const [state, setState] = React.useState(validation.state);

  const loginValidationMutation = usePutLoginValidation();

  useEffect(() => {
    if (loginValidationMutation.isError) {
      notifyError({
        status:
          loginValidationMutation.error.response?.request?.status &&
          loginValidationMutation.error.response?.request?.status,
        message: loginValidationMutation.error.response?.data?.errors
          ? loginValidationMutation.error.response?.data?.errors[0]
          : loginValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginValidationMutation.isError]);

  useEffect(() => {
    if (loginValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the login',
      });
    }
  }, [loginValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Login
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box>
          <TextField
            label="AccountGuid"
            id="AccountGuid"
            name="AccountGuid"
            data-testid="input-AccountGuid"
            defaultValue={login.account}
            onChange={event =>
              setLogin({ ...login, account: event.target.value })
            }
          />
        </Box>

        <Flex justifyContent="space-between" mt={5}>
          <Box width="49%">
            <TextField
              label="First Name"
              id="firstName"
              name="firstName"
              data-testid="input-firstName"
              defaultValue={login.firstName}
              onChange={event =>
                setLogin({ ...login, firstName: event.target.value })
              }
            />
          </Box>
          <Box width="49%">
            <TextField
              label="Last Name"
              id="lastName"
              name="lastName"
              data-testid="input-lastName"
              defaultValue={login.lastName}
              onChange={event =>
                setLogin({ ...login, lastName: event.target.value })
              }
            />
          </Box>
        </Flex>

        <Box mt={5}>
          <Select
            label="Gender"
            id="Gender"
            name="Gender"
            data-testid="input-Gender"
            defaultValue={login.gender}
            onChange={event =>
              setLogin({ ...login, gender: event.target.value })
            }
          >
            {Object.values(GENDER).map(genderValue => (
              <option key={genderValue} value={genderValue}>
                {genderValue}
              </option>
            ))}
          </Select>
        </Box>

        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              loginValidationMutation.mutate({
                ...validation,
                data: {
                  account: login.account,
                  brand: login.brand,
                  firstName: login.firstName,
                  gender: login.gender,
                  lastName: login.lastName,
                },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Login;
