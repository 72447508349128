import { Box, Flex, Text } from '@mediahuis/chameleon-react';
import React from 'react';

const NavigationItem = ({ active, isChild, testId, title, onClick }) => {
  return (
    <Flex
      bgColor={active && 'colorSecondary10'}
      className="navigation-item"
      data-testid={testId}
      justifyContent="space-between"
      onClick={onClick}
    >
      <Text
        size="Caption1"
        style={{ margin: isChild ? '1rem 2rem 1rem 3rem' : '1rem 2rem' }}
      >
        {title}
      </Text>
      <Box
        bgColor={active ? 'colorSecondaryDark' : 'transparent'}
        width="0.25rem"
      />
    </Flex>
  );
};

export default NavigationItem;
