import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(RouterLink)`
    text-decoration: none;
    ${props =>
      props.underline &&
      `
        text-decoration: underline;
    `}
    color: ${props => props.theme.palette.primary}
`;

export default Link;
