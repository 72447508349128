import { Flex, Textarea, TextField, Checkbox } from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { array, number, object, string, boolean } from 'yup';

import { PAYMENT_INTERVAL } from '../../enums';
import { getPaymentOptions } from '../../utils';

import { PaymentOptionsSelect } from '../../PaymentOptionsSelect';

const PaymentRequirementFormSchema = object().shape({
  amount: number().required(),
  description: string(),
  shouldCaptureIban: boolean(),
  name: string().required(),
  oneShotOptions: array().of(string()),
  recurringOptions: array().of(string()),
});

const PaymentRequirementForm = ({
  disabled,
  formRef,
  initialValues,
  onSubmit,
}) => {
  const oneShotPaymentOptions = getPaymentOptions(PAYMENT_INTERVAL.ONESHOT);
  const recurringPaymentOptions = getPaymentOptions(PAYMENT_INTERVAL.RECURRING);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={PaymentRequirementFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NameInput"
                label="Name"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="amount">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Amount"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                type="number"
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="oneShotOptions">
            {({ field, form }) => (
              <PaymentOptionsSelect
                disabled={disabled}
                label="OneShot"
                options={oneShotPaymentOptions}
                selectedOptions={field.value}
                onChange={selectedOptions =>
                  form.setFieldValue('oneShotOptions', selectedOptions)
                }
              />
            )}
          </Field>

          <Field name="recurringOptions">
            {({ field, form }) => (
              <PaymentOptionsSelect
                disabled={disabled}
                label="Recurring"
                options={recurringPaymentOptions}
                selectedOptions={field.value}
                onChange={selectedOptions =>
                  form.setFieldValue('recurringOptions', selectedOptions)
                }
              />
            )}
          </Field>
          <Field name="shouldCaptureIban">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="ShouldCaptureIban"
                label="Use iDeal/Bancontact to capture Bank Account Number (without charging full amount directly)"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>
        </Flex>
      </Form>
    </Formik>
  );
};

export default PaymentRequirementForm;
