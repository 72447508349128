export const textValidation = ({ name, segment, priority, period }) => {
  let errors = {};

  if (name != null && !name) {
    errors = { ...errors, name: 'Name is a required field.' };
  }
  if (segment != null && !segment) {
    errors = { ...errors, segment: 'Segments is a required field.' };
  }
  if (priority != null && priority < 1) {
    errors = {
      ...errors,
      priority: 'Priority must have a value greater than 0.',
    };
  }
  if (period != null && period < 1) {
    errors = {
      ...errors,
      period: 'Period must have a value greater than 0.',
    };
  }

  return errors;
};
