import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENTS_KEY } from './useContents';

const useCreateContent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body => ContentService.postContent(body).then(response => response.data),
    {
      onSuccess: () => {
        queryClient.removeQueries([CONTENTS_KEY]);
      },
    },
  );
};

export default useCreateContent;
