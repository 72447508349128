import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {
  Text,
  TextField,
  Flex,
  Select,
  Paper,
  Box,
  Heading,
  DatePicker,
  Button,
} from '@mediahuis/chameleon-react';
import {
  PageLoader,
  FormButtons,
  Sidebar,
  StickyTop,
  notifyError,
  notifySuccess,
  notifyWarning,
} from '~/components';
import { secureApiCall, getInProgressMessage, noop } from '~/utils';
import { colorRedBase } from '@mediahuis/chameleon-theme-wl';
import { Popconfirm } from 'antd';
import LanguageSelect from '../../../components/LanguageSelect';
import { stateOptions } from '../data/options';
import validateVoucher from '../validation/ValidateVoucher';

const VoucherForm = ({ apiUrl, initialValues }) => {
  const defaultValues = {
    code: '',
    redeemOrderId: 0,
    purchaseOrderId: 0,
    redeemFormulaId: 0,
    purchaseFormulaId: 0,
    brand: '',
    state: 'New',
    ...initialValues,
    generatedDate: initialValues.generatedDate
      ? new Date(initialValues.generatedDate)
      : null,
    expirationDate: initialValues.expirationDate
      ? new Date(initialValues.expirationDate)
      : null,
    usedDate: initialValues.usedDate ? new Date(initialValues.usedDate) : null,
  };

  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [inProgress, setInProgress] = useState([]);
  const [voucher, setVoucher] = useState(defaultValues);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButtons, setIsLoadingButtons] = useState(false);

  // Overwrite state if parent gives new data.
  // E.g. when fetching data in new language
  useEffect(() => {
    setVoucher(defaultValues);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const clearError = event => {
    const spreadedErrors = {
      ...errors,
    };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const onBlur = event => {
    event.persist();
    setVoucher(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    if (!isEmpty(inProgress)) {
      return notifyWarning({
        message: getInProgressMessage(inProgress),
      });
    }

    setInProgress([]);

    const voucherErrors = validateVoucher({ ...voucher });
    if (!isEmpty(voucherErrors)) return setErrors(voucherErrors);

    setErrors({});
    setIsLoadingButtons(true);

    const formData = {
      ...voucher,
    };

    secureApiCall(apiUrl, {
      method: 'PATCH',
      data: JSON.stringify({
        ...formData,
      }),
      onSuccess: response => {
        setIsLoadingButtons(false);

        /**
         * Save response data to local state because ID's of these objects needs to be saved in case of new items
         */
        setVoucher({
          ...response.data,
          expirationDate: response.data.expirationDate
            ? new Date(response.data.expirationDate)
            : defaultValues.expirationDate,
          generatedDate: response.data.generatedDate
            ? new Date(response.data.generatedDate)
            : defaultValues.generatedDate,
          usedDate: response.data.usedDate
            ? new Date(response.data.usedDate)
            : defaultValues.usedDate,
        });

        notifySuccess({
          message: 'Successfully edited a voucher',
        });
      },
      onError: errorMessage => {
        setIsLoadingButtons(false);
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const onRedeemOrderIdClick = () => {
    history.push(`/orders/edit/${voucher.redeemOrderId}`);
  };

  const onPurchaseOrderIdClick = () => {
    history.push(`/orders/edit/${voucher.purchaseOrderId}`);
  };

  const onRedeemFormulaIdClick = () => {
    history.push(`/subscriptionformula/edit/${voucher.redeemFormulaId}`);
  };

  const onPurchaseFormulaIdClick = () => {
    history.push(`/subscriptionformula/edit/${voucher.purchaseFormulaId}`);
  };

  const revokeOrder = () => {
    const formData = {
      state: 'Revoked',
    };

    secureApiCall(apiUrl, {
      method: 'PATCH',
      data: JSON.stringify({
        ...formData,
      }),
      onSuccess: response => {
        setVoucher(response.data);
        notifySuccess({
          message: 'Successfully revoked voucher',
        });
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Flex justifyContent="center">
      <Box width="600px">
        <Paper p={6} mb={8}>
          <Heading fontFamily="primary" level={4}>
            Voucher
          </Heading>
          <Flex mb={4}>
            <TextField
              id="code"
              datatest-id="voucher-code"
              label="Code"
              name="code"
              error={!!errors.code}
              message={errors.code}
              onBlur={onBlur}
              defaultValue={voucher.code}
              width="full"
              onFocus={clearError}
              key={voucher.code}
              disabled={true}
            />
          </Flex>
          <Flex mb={4}>
            <Box width="full">
              <DatePicker
                id="generatedDate"
                data-testid="generatedDate-input"
                label="Generated Date"
                name="generatedDate"
                error={!!errors.generatedDate}
                message={errors.generatedDate}
                value={voucher.generatedDate}
                disabled={true}
              />
            </Box>
          </Flex>
          <Flex mb={4}>
            <Box width="full">
              <DatePicker
                data-testid="usedDate-input"
                disabled
                error={!!errors.usedDate}
                id="usedDate"
                label="Used Date"
                message={errors.usedDate}
                mr={2}
                name="usedDate"
                value={voucher.usedDate}
              />
            </Box>
            <Box width="full">
              <DatePicker
                id="expirationDate"
                data-testid="expirationDate-input"
                label="Expiration Date"
                name="expirationDate"
                error={!!errors.expirationDate}
                message={errors.expirationDate}
                value={voucher.expirationDate}
                ml={2}
                onChange={date =>
                  setVoucher(prevState => ({
                    ...prevState,
                    expirationDate: date,
                  }))
                }
              />
            </Box>
          </Flex>
          <Flex mb={4}>
            <Box width="full">
              <Select
                id="state"
                data-testid="state-input"
                name="state"
                label="State"
                value={voucher.state}
                width="full"
                disabled={true}
              >
                {stateOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </Paper>

        <Paper p={6} mb={8}>
          <Flex mb={4} alignItems="flex-end">
            <Box width="full">
              <TextField
                id="redeemOrderId"
                datatest-id="voucher-redeemOrderId"
                label="Redeem Order ID"
                name="redeemOrderId"
                error={!!errors.redeemOrderId}
                message={errors.redeemOrderId}
                onBlur={onBlur}
                defaultValue={
                  voucher.redeemOrderId ? voucher.redeemOrderId : '-'
                }
                width="full"
                onFocus={clearError}
                key={voucher.redeemOrderId}
                type="text"
                disabled={true}
              />
            </Box>
            <Box width="full">
              <Button
                data-testid="button-redeemOrderId"
                loading={isLoading}
                onClick={onRedeemOrderIdClick}
                width="full"
                disabled={!voucher.redeemOrderId}
              >
                View Redeem Order
              </Button>
            </Box>
          </Flex>

          <Flex mb={4} alignItems="flex-end">
            <Box width="full">
              <TextField
                id="redeemFormulaId"
                datatest-id="voucher-redeemFormulaId"
                label="Redeem Formula ID"
                name="redeemFormulaId"
                error={!!errors.redeemFormulaId}
                message={errors.redeemFormulaId}
                onBlur={onBlur}
                defaultValue={
                  voucher.redeemFormulaId ? voucher.redeemFormulaId : '-'
                }
                width="full"
                onFocus={clearError}
                key={voucher.redeemFormulaId}
                disabled={true}
              />
            </Box>
            <Box width="full">
              <Button
                data-testid="button-redeemFormulaId"
                loading={isLoading}
                onClick={onRedeemFormulaIdClick}
                width="full"
                disabled={!voucher.redeemFormulaId}
              >
                View Redeem Formula
              </Button>
            </Box>
          </Flex>

          <Flex mb={4} alignItems="flex-end">
            <Box width="full">
              <TextField
                id="purchaseOrderId"
                datatest-id="voucher-purchaseOrderId"
                label="Purchase Order ID"
                name="purchaseOrderId"
                error={!!errors.purchaseOrderId}
                message={errors.purchaseOrderId}
                onBlur={onBlur}
                defaultValue={
                  voucher.purchaseOrderId ? voucher.purchaseOrderId : '-'
                }
                width="full"
                onFocus={clearError}
                key={voucher.purchaseOrderId}
                disabled={true}
              />
            </Box>
            <Box width="full">
              <Button
                data-testid="button-purchaseOrderId"
                loading={isLoading}
                onClick={onPurchaseOrderIdClick}
                width="full"
                disabled={!voucher.purchaseOrderId}
              >
                View Purchase Order
              </Button>
            </Box>
          </Flex>

          <Flex mb={4} alignItems="flex-end">
            <Box width="full">
              <TextField
                id="purchaseFormulaId"
                datatest-id="voucher-purchaseFormulaId"
                label="Purchase Formula ID"
                name="purchaseFormulaId"
                error={!!errors.purchaseFormulaId}
                message={errors.purchaseFormulaId}
                onBlur={onBlur}
                defaultValue={
                  voucher.purchaseFormulaId ? voucher.purchaseFormulaId : '-'
                }
                width="full"
                onFocus={clearError}
                key={voucher.purchaseFormulaId}
                disabled={true}
              />
            </Box>
            <Box width="full">
              <Button
                data-testid="button-purchaseFormulaId"
                loading={isLoading}
                onClick={onPurchaseFormulaIdClick}
                width="full"
                disabled={!voucher.purchaseFormulaId}
              >
                View Purchase Formula
              </Button>
            </Box>
          </Flex>
        </Paper>
      </Box>
      <Sidebar>
        <StickyTop>
          <LanguageSelect
            options={POSSIBLE_LANGUAGES[voucher.brand]}
            brand={voucher?.brand}
          />
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Id</strong>: {initialValues.id}
            </Text>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Brand</strong>: {initialValues.brand}
            </Text>
          </Paper>
          <FormButtons isLoading={isLoadingButtons} onSave={onSubmit} />
          {voucher.state !== 'Revoked' && (
            <Popconfirm
              title="Are you sure you want to revoke this voucher?"
              onConfirm={revokeOrder}
              onCancel={noop}
              okText={<span data-testid="button-revoke-confirm">Confirm</span>}
              cancelText={
                <span data-testid="button-revoke-cancel">Cancel</span>
              }
            >
              <Button
                data-testid="button-revoke-voucher"
                appearance="plain"
                width="full"
                size="small"
                style={{
                  color: colorRedBase,
                }}
              >
                Revoke
              </Button>
            </Popconfirm>
          )}
        </StickyTop>
      </Sidebar>
    </Flex>
  );
};

export default VoucherForm;
