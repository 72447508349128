import React from 'react';
import styled from 'styled-components';
import { Checkmark, Close, Clock } from '@mediahuis/chameleon-theme-wl/icons';
import { STATUS_CONFIRMED, STATUS_PENDING } from './enums';

const PendingIcon = styled(Clock)`
  margin-right: ${props => props.theme.spacing.half};
  && {
    fill: ${props => props.theme.colors.white};
    width: 25px;
    height: 25px;
    padding: 3px;
  }
  background-color: ${props => props.theme.colors.gray};
  border-radius: 50px;
`;

const ValidIcon = styled(Checkmark)`
  margin-right: ${props => props.theme.spacing.half};
  && {
    fill: ${props => props.theme.colors.white};
    width: 25px;
    height: 25px;
    padding: 3px;
  }
  background-color: ${props => props.theme.colors.success};
  border-radius: 50px;
`;

const InvalidIcon = styled(Close)`
  margin-right: ${props => props.theme.spacing.half};
  && {
    fill: ${props => props.theme.colors.white};
    width: 25px;
    height: 25px;
    padding: 3px;
  }
  background-color: ${props => props.theme.colors.error};
  border-radius: 50px;
`;

const ValidationIcon = ({ status }) => {
  if (status === STATUS_PENDING) {
    return <PendingIcon />;
  } else if (status === STATUS_CONFIRMED) {
    return <ValidIcon />;
  }
  return <InvalidIcon />;
};
export default ValidationIcon;
