import isEmpty from 'lodash.isempty';
import { textValidation } from './textValidation';
import { variantsValidations } from './variantsValidation';

const validateOnSubmit = ({ proposalData, variantsData, variantsErrors }) => {
  const newProposalErrors = textValidation({
    name: proposalData.name,
    segment: proposalData.segment,
    period: proposalData.period,
    priority: proposalData.priority,
  });

  let newVariantsErrors = {};

  variantsData.forEach(variant => {
    const variantErrors = variantsValidations({
      name: variant.name,
      path: variant.path,
      error: variantsErrors[variant.id],
    });
    if (!isEmpty(variantErrors)) {
      newVariantsErrors = {
        ...newVariantsErrors,
        [variant.id]: {
          ...variantErrors,
        },
      };
    }
  });

  return { newVariantsErrors, newProposalErrors };
};

export default validateOnSubmit;
