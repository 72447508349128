import apiCall from './apiCall';

const getPickupLocationByPostalCode = ({ postalCode, onSuccess, onError }) =>
  apiCall(`${API_PICKUP_LOCATION}/shop?postalCode=${postalCode}`, {
    method: 'GET',
    onSuccess: response => {
      if (onSuccess) {
        return onSuccess(response);
      }
      return response;
    },
    onError: error => {
      if (onError) {
        onError(error);
      }
      throw error;
    },
  });

export default getPickupLocationByPostalCode;
