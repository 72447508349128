import { Button, Flex, Heading } from '@mediahuis/chameleon-react';
import React, { useState } from 'react';

import { HeaderWrapper } from '~/components';

import { REQUIREMENT_LABEL, REQUIREMENT_TYPE } from './enums';
import { getSubscriptionTypeRequirements } from './utils';

import { RequirementCreate } from './RequirementCreate';
import { RequirementsCollapse } from './RequirementsCollapse';

const getRequirementTypeOptions = typeRequirements => {
  return Object.values(REQUIREMENT_TYPE).map(requirementTypeValue => {
    const activeTypeRequirement = typeRequirements[requirementTypeValue];

    return {
      disabled: Array.isArray(activeTypeRequirement)
        ? false
        : Boolean(activeTypeRequirement),
      label: REQUIREMENT_LABEL[requirementTypeValue],
      value: requirementTypeValue,
    };
  });
};

const TypeRequirements = ({ subscriptionType, onChange }) => {
  const [isCreating, setIsCreating] = useState(false);

  const subscriptionTypeRequirements =
    getSubscriptionTypeRequirements(subscriptionType);

  function handleCollapseChange(requirements) {
    onChange(
      Object.entries(requirements).reduce(
        (acc, [requirementKey, requirementValue]) => {
          acc[requirementKey] = requirementValue ? requirementValue : null;

          return acc;
        },
        {},
      ),
    );
  }

  function handleRequirementCreate(values, type) {
    const currentRequirement = subscriptionType[type];
    const valuesWithBrand = { brand: subscriptionType.brand, ...values };

    onChange({
      ...subscriptionType,
      [type]: Array.isArray(currentRequirement)
        ? [...currentRequirement, valuesWithBrand]
        : valuesWithBrand,
    });
    setIsCreating(false);
  }

  return (
    <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
      <HeaderWrapper>
        <Heading fontFamily="primary" level={4}>
          Requirements
        </Heading>

        <Button
          appearance="secondary"
          data-testid="add-button"
          disabled={isCreating}
          size="small"
          onClick={() => setIsCreating(true)}
        >
          Add
        </Button>
      </HeaderWrapper>

      {isCreating && (
        <RequirementCreate
          requirementTypeOptions={getRequirementTypeOptions(
            subscriptionTypeRequirements,
          )}
          onCancel={() => setIsCreating(false)}
          onSubmit={handleRequirementCreate}
        />
      )}

      <RequirementsCollapse
        isEditable
        requirements={subscriptionTypeRequirements}
        onChange={handleCollapseChange}
      />
    </Flex>
  );
};

export default TypeRequirements;
