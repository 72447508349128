import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Step, Steps, Wizard } from 'react-albus/lib';
import { BrandWizard } from '../../components';
import OfferForm from './components/OfferForm';

const AddOffer = ({ history, url }) => {
  const { state = {} } = useLocation();

  const [initialValues, setInitialValues] = useState({
    ...state.initialValues,
    id: 0,
    brand: '',
    reference: '',
  });

  const handleSelectBrand = ({ brandCode, next }) => {
    if (state.initialValues && state.initialValues.brand === brandCode) {
      setInitialValues({ ...initialValues, brand: brandCode });
    } else {
      setInitialValues({
        ...initialValues,
        htmlContentBlocks: [],
        image: '',
        items: [],
        brand: brandCode,
      });
    }
    next();
  };

  return (
    <Wizard history={history} basename={url}>
      <Steps>
        <Step
          id="brand"
          render={({ next }) => (
            <BrandWizard
              handleSelectBrand={brandCode =>
                handleSelectBrand({ brandCode, next })
              }
            />
          )}
        />
        <Step
          id="offer"
          render={() => <OfferForm initialValues={initialValues} />}
        />
      </Steps>
    </Wizard>
  );
};

export default AddOffer;
