import {
  Box,
  Button,
  DatePicker,
  Flex,
  Heading,
  Paper,
  Switch,
  Text,
  TextField,
} from '@mediahuis/chameleon-react';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  FormButtons,
  FormConfig,
  HeaderWrapper,
  notifyError,
  notifySuccess,
  Sidebar,
  StatusCircle,
  StickyBottom,
  StickyTop,
  TextFieldWithMarkDown,
} from '~/components';
import { HtmlContentTransfer } from '~/components/HtmlContentTransfer';
import {
  CONTENT_LINKS_KEY,
  useAllContents,
  useCreateContentLink,
  useDeleteContentLink,
  usePatchContentLink,
} from '~/services/content';
import { secureApiCall, updateSequenceIndex } from '~/utils';

import LanguageSelect from '../../../components/LanguageSelect';
import { ACQUISITION } from '../constants';
import validateOffer from '../validation/validateOffer';
import ProductCardDragOrder from './ProductCardDragOrder';
import ProductsTable from './ProductsTable';
import OfferGroups from './OfferGroups/OfferGroups';
import OfferGroupCreate from './OfferGroups/OfferGroupCreate';

const OfferForm = ({
  deleteMethod,
  duplicateMethod,
  editing = false,
  initialValues,
}) => {
  const defaultValues = {
    items: [],
    published: true,
    active: true,
    type: ACQUISITION,
    ...initialValues,
    startDate: initialValues.startDate
      ? new Date(initialValues.startDate)
      : new Date(),
    endDate: initialValues.endDate
      ? new Date(initialValues.endDate)
      : new Date('2999-12-31'),
  };

  const history = useHistory();
  const queryClient = useQueryClient();

  const [offer, setOffer] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingProducts, setIsAddingProducts] = useState(false);
  const [htmlContentKeys, setHtmlContentKeys] = useState({});
  const [isCreatingOfferGroup, setIsCreatingOfferGroup] = useState(false);

  const allContentsQuery = useAllContents(
    {
      brand: offer.brand,
      excludedKeys: HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE,
    },
    !!offer,
  );
  const createContentLink = useCreateContentLink();
  const deleteContentLink = useDeleteContentLink();
  const patchContentLink = usePatchContentLink();

  const contentKey = offer.id && `${HTML_CONTENT_LOCATIONS.OFFER}_${offer.id}`;
  const transferDataSource = allContentsQuery.isSuccess
    ? allContentsQuery.data.list.map(htmlContent => ({
        brand: htmlContent.brand,
        id: htmlContent.id,
        key: htmlContent.id,
        name: htmlContent.name,
      }))
    : [];

  // Overwrite state if parent gives new data.
  // E.g. when fetching data in new language
  useEffect(() => {
    setOffer(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // In case user tries to go second step in creating offer wizard
  // without having chosen a brand.
  useEffect(() => {
    if (!offer?.brand) {
      history.push('/offers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      createContentLink.isError ||
      deleteContentLink.isError ||
      patchContentLink.isError
    ) {
      notifyError({
        message:
          'An error was encountered while updating the locations. Please refresh the page to see the current state and try again.',
      });
    }
  }, [
    createContentLink.isError,
    deleteContentLink.isError,
    patchContentLink.isError,
  ]);

  const handleAddProduct = (product, e) => {
    e.preventDefault();
    const items = [...(offer.items || []), product];
    const itemsWithIndex = updateSequenceIndex(items);
    setOffer({
      ...offer,
      items: itemsWithIndex,
    });
  };

  const onDeleteProduct = product => {
    const filteredItems = offer.items.filter(item => item !== product);
    const filteredItemsWithIndex = updateSequenceIndex(filteredItems);
    setOffer({
      ...offer,
      items: filteredItemsWithIndex,
    });
  };

  const toggleShowProductTable = e => {
    e.preventDefault();
    if (isAddingProducts) {
      setIsAddingProducts(false);
    } else {
      setIsAddingProducts(true);
    }
  };

  const handleTogglePublished = event => {
    const checked = event.target.checked;
    setOffer({
      ...offer,
      published: checked,
    });
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (
      isEmpty(
        validateOffer({
          offer,
          setErrors,
        }),
      )
    ) {
      // Update offer
      secureApiCall(
        `${API_OFFER_SERVICE}/offers/${
          initialValues.id ? initialValues.id : ''
        }`,
        {
          method: editing ? 'PUT' : 'POST',
          data: JSON.stringify({
            ...offer,
          }),
          params: {
            'api-version': '2',
          },
          onSuccess: response => {
            setIsLoading(false);
            if (!editing) {
              history.push(`/offers/edit/${response.data.id}`);
            }

            /**
             * Save response data to local state because ID's of these objects needs to be saved in case of new items
             */
            setOffer({
              ...response.data,
              endDate: response.data.endDate
                ? new Date(response.data.endDate)
                : defaultValues.endDate,
              startDate: response.data.startDate
                ? new Date(response.data.startDate)
                : defaultValues.startDate,
            });

            notifySuccess({
              message: `Successfully ${
                editing ? 'edited' : 'created '
              } an offer.`,
            });
          },
          onError: errorMessage => {
            setIsLoading(false);
            notifyError({
              status:
                errorMessage.response.request.status &&
                errorMessage.response.request.status,
              message: errorMessage.response.data.errors
                ? errorMessage.response.data.errors[0]
                : errorMessage.message,
            });
          },
        },
      );

      // Update HTML Content
      const queryData = queryClient.getQueryData([
        CONTENT_LINKS_KEY,
        offer.brand,
        `${HTML_CONTENT_LOCATIONS.OFFER}_${offer.id}`,
      ]);
      const contentLinks = queryData.contentLinks || {};

      Object.keys(htmlContentKeys).forEach(location => {
        const currentKeys = htmlContentKeys[location];
        const locationContentLinks = contentLinks[location] || [];

        if (locationContentLinks.length > currentKeys.length) {
          const removedLinks = locationContentLinks.filter(
            link => !currentKeys.includes(link.contentId),
          );

          if (removedLinks.length > 0) {
            removedLinks.forEach(removedLink => {
              deleteContentLink.mutate({
                brand: offer.brand,
                contentKey,
                id: removedLink.id,
                location,
              });
            });
          }
        }

        currentKeys.forEach((currentKey, currentKeyIndex) => {
          const contentItem = transferDataSource.find(
            item => item.key === currentKey,
          );
          const contentLinkItem = locationContentLinks.find(
            item => item.contentId === currentKey,
          );

          if (contentItem && contentLinkItem) {
            patchContentLink.mutate({
              brand: offer.brand,
              contentId: contentItem.id,
              id: contentLinkItem.id,
              key: contentKey,
              location,
              position: currentKeyIndex + 1,
            });
          } else if (contentItem) {
            createContentLink.mutate({
              brand: offer.brand,
              contentId: contentItem.id,
              key: contentKey,
              location,
              position: currentKeyIndex + 1,
            });
          }
        });
      });
    } else {
      setIsLoading(false);
    }
    return null;
  };

  const clearError = event => {
    const spreadedErrors = {
      ...errors,
    };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const onChangeDatePicker = (key, date) => {
    setOffer(prevState => ({
      ...prevState,
      [key]: date,
    }));
  };

  const handleItemsChange = (index, key, value) => {
    const newOffer = {
      ...offer,
    };
    const newItems = newOffer.items;
    newItems[index][key] = value;
    setOffer(newOffer);
  };

  const handleItemsChangeOrder = (draggedIndex, droppedIndex) => {
    const newOffer = { ...offer };
    const draggedItem = newOffer.items.splice(draggedIndex, 1);
    newOffer.items.splice(droppedIndex, 0, draggedItem[0]);
    /* eslint-disable no-return-assign */
    newOffer.items.map((item, index) => (item.sequenceIndex = index));
    setOffer({ ...offer, items: newOffer.items });
  };

  const onBlur = event => {
    event.persist();
    setOffer(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const preview = () => {
    window.open(
      `https://${API_PREFIX}aboshop.${
        BRAND_SITE_URLS[offer?.brand?.toUpperCase()]
      }/${offer?.slug}`,
      '_blank',
    );
  };

  const handleOfferGroupCreate = group => {
    const groups = [...(offer.groups || []), group];
    const groupssWithIndex = updateSequenceIndex(groups);
    setOffer({
      ...offer,
      groups: groupssWithIndex,
    });
    setIsCreatingOfferGroup(false);
  };

  const onDeleteGroup = group => {
    const filteredGroups = offer.groups.filter(item => item !== group);
    const filteredGroupsWithIndex = updateSequenceIndex(filteredGroups);
    setOffer({
      ...offer,
      groups: filteredGroupsWithIndex,
    });
  };

  const onChangeGroup = (value, id) => {
    const newOffer = {
      ...offer,
    };
    const newGroups = newOffer.groups;
    const filteredGroupIndex = offer.groups.findIndex(item => item.id === id);
    newGroups[filteredGroupIndex] = value;
    setOffer(newOffer);
  };

  return (
    <Flex justifyContent="center">
      <Box width="800px">
        <Box>
          <Paper>
            <Box p={7}>
              <Heading fontFamily="primary" level={4}>
                General info
              </Heading>
              <Box mt={4}>
                <TextField
                  id="reference"
                  label="Reference (internal)"
                  name="reference"
                  error={errors.reference}
                  message={errors.reference}
                  data-testid="reference-input"
                  defaultValue={offer.reference}
                  key={offer.reference}
                  onBlur={onBlur}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <TextField
                  id="slug"
                  label="Slug"
                  name="slug"
                  error={errors.slug}
                  message={errors.slug}
                  data-testid="slug-input"
                  defaultValue={offer.slug}
                  key={offer.slug}
                  onBlur={onBlur}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <TextFieldWithMarkDown
                  id="title"
                  name="title"
                  label="Title"
                  error={errors.title}
                  message={errors.title}
                  datatestid="title-input"
                  defaultValue={offer.title}
                  key={offer.title}
                  onBlur={onBlur}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <TextFieldWithMarkDown
                  id="description"
                  name="description"
                  label="Description"
                  error={errors.description}
                  message={errors.description}
                  datatestid="description-input"
                  defaultValue={offer.description}
                  key={offer.description}
                  onBlur={onBlur}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <TextFieldWithMarkDown
                  id="extra"
                  name="extra"
                  label="Extra"
                  datatestid="extra-input"
                  defaultValue={offer.extra}
                  key={offer.extra}
                  onBlur={onBlur}
                />
              </Box>
              <Box mt={4}>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  label="Start date"
                  data-testid="startDate-input"
                  error={errors.startDate}
                  message={errors.startDate}
                  value={offer.startDate}
                  onChange={date => onChangeDatePicker('startDate', date)}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <DatePicker
                  id="endDate"
                  name="endDate"
                  label="End date"
                  error={errors.endDate}
                  message={errors.endDate}
                  data-testid="endDate-input"
                  value={offer.endDate}
                  onChange={date => onChangeDatePicker('endDate', date)}
                  onFocus={clearError}
                />
              </Box>
              <Box mt={4}>
                <TextField
                  disabled
                  id="type"
                  name="type"
                  label="Type"
                  data-testid="type-input"
                  value={offer.type}
                />
              </Box>
            </Box>
            <Box p={7} pt={0}>
              <Heading level={5} fontFamily="primary" mb={5}>
                Meta tags
              </Heading>
              <Box mt={2}>
                <TextField
                  id="metaTitle"
                  name="metaTitle"
                  label="Meta title"
                  data-testid="metaTitle-input"
                  defaultValue={offer.metaTitle}
                  key={offer.metaTitle}
                  onBlur={onBlur}
                  maxLength={255}
                />
              </Box>
              <Box mt={4}>
                <TextField
                  id="metaDescription"
                  name="metaDescription"
                  label="Meta description"
                  data-testid="metaDescription-input"
                  defaultValue={offer.metaDescription}
                  key={offer.metaDescription}
                  onBlur={onBlur}
                  maxLength={255}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box mt={5} mb={5}>
          <Paper>
            <Box p={7}>
              <HeaderWrapper mb={5}>
                <Heading fontFamily="primary" level={4}>
                  Products
                </Heading>
                <Button
                  size="small"
                  data-testid="save-close-product-to-formula-button"
                  appearance="secondary"
                  onClick={toggleShowProductTable}
                >
                  {isAddingProducts ? 'Close' : 'Add'}
                </Button>
              </HeaderWrapper>
              {(isAddingProducts || offer.items) && <Box mt={1} />}
              {isAddingProducts && (
                <ProductsTable
                  brand={offer.brand}
                  items={offer.items}
                  handleAddProduct={handleAddProduct}
                />
              )}
              {offer.items && (
                <Flex flexWrap="wrap">
                  <DndProvider backend={HTML5Backend}>
                    {offer.items &&
                      offer.items.map((product, index) => (
                        <ProductCardDragOrder
                          key={`${product.id}-${product.type}`}
                          product={product}
                          index={index}
                          onDelete={onDeleteProduct}
                          onDrop={handleItemsChangeOrder}
                          handleItemsChange={handleItemsChange}
                        />
                      ))}
                  </DndProvider>
                </Flex>
              )}
            </Box>
          </Paper>
        </Box>

        <Box mt={5} mb={5}>
          <Paper>
            <Box p={7} mb={2}>
              <Flex alignItems="baseline" justifyContent="space-between">
                <Heading fontFamily="primary" level={4}>
                  Groups
                </Heading>
                <Button
                  appearance="secondary"
                  data-testid="add-button"
                  disabled={isCreatingOfferGroup}
                  size="small"
                  onClick={() => setIsCreatingOfferGroup(true)}
                >
                  Add
                </Button>
              </Flex>

              {isCreatingOfferGroup && (
                <OfferGroupCreate
                  offer={offer}
                  onCancel={() => setIsCreatingOfferGroup(false)}
                  onSubmit={handleOfferGroupCreate}
                />
              )}

              <OfferGroups
                isEditable={true}
                offer={offer}
                onChange={onChangeGroup}
                onDelete={onDeleteGroup}
              />
            </Box>
          </Paper>
        </Box>

        {MH_BRAND === BRANDS.HUB && (
          <Box mt={5} mb={5}>
            <Paper>
              <Box p={7} mb={2}>
                <Heading fontFamily="primary" level={4}>
                  Teaser Description
                </Heading>
                <Box>
                  <TextFieldWithMarkDown
                    id="teaserDescription"
                    name="teaserDescription"
                    datatestid="teaser-description-input"
                    label="Description"
                    defaultValue={offer.teaserDescription}
                    key={offer.teaserDescription}
                    onBlur={onBlur}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
        <Box mt={5} mb={5}>
          <Paper>
            <Box p={7} mb={2} data-testid="html-content-block">
              {contentKey && transferDataSource && (
                <HtmlContentTransfer
                  brand={offer.brand}
                  contentKey={contentKey}
                  dataSource={transferDataSource}
                  locationOptions={[
                    {
                      label: 'Top',
                      value: 'top',
                    },
                    {
                      label: 'Bottom',
                      value: 'bottom',
                    },
                  ]}
                  targetKeys={htmlContentKeys}
                  title="HTML Content"
                  onChange={setHtmlContentKeys}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
      <Sidebar>
        <StickyTop>
          <LanguageSelect
            options={POSSIBLE_LANGUAGES[offer.brand]}
            isEditing={editing}
            brand={offer?.brand}
          />
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Id</strong>: {initialValues.id}
            </Text>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Brand</strong>: {initialValues.brand}
            </Text>
            <Text size="Caption2">
              <StatusCircle variant={offer.active ? 'success' : 'error'} />
              <Text
                size="Caption2"
                fontWeight="bold"
                style={{
                  marginLeft: '8px',
                }}
              >
                {offer.active ? 'Active' : 'Inactive'}
              </Text>
            </Text>
            <Box mt={3}>
              <Switch
                checked={offer.published}
                name="active"
                data-testid="offerform-toggle-active"
                size="small"
                label={offer.published ? 'Published' : 'Unpublished'}
                labelPlacement="right"
                labelProps={{
                  size: 'Caption2',
                  fontWeight: 'bold',
                  color: 'black',
                }}
                onChange={handleTogglePublished}
              />
            </Box>
          </Paper>
          <FormButtons
            deleteMethod={deleteMethod}
            duplicateMethod={duplicateMethod}
            editing={editing}
            isDeletable
            isDuplicable={duplicateMethod}
            isLoading={isLoading}
            isPreviewable={true}
            preview={preview}
            onSave={onSubmit}
          />
        </StickyTop>
        <StickyBottom>
          <FormConfig />
        </StickyBottom>
      </Sidebar>
    </Flex>
  );
};

export default OfferForm;
