import { Flex, Icon, Text } from '@mediahuis/chameleon-react';
import React from 'react';

const RequirementsCollapseHeader = ({ icon, text }) => {
  return (
    <Flex alignItems="center" style={{ gap: '0.5rem' }}>
      <Icon as={icon} />
      <Text>{text}</Text>
    </Flex>
  );
};

export default RequirementsCollapseHeader;
