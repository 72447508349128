import { Box, Flex, Select } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutAddressAreaValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../../components';
import StateDropdown from '../StateDropdown';
import ValidationIcon from '../ValidationIcon';
import AddressArea from './AddressArea';
import PickupLocation from './PickupLocation';

const DeliveryAddress = ({ validation }) => {
  const [isPickup, setIsPickup] = useState(
    !!(validation.data && validation.data.shopId),
  );
  const [state, setState] = useState(validation.state);

  const pickupLocationOptions = [
    { key: 'Home delivery', value: false },
    { key: 'Pickup point', value: true },
  ];

  const addressAreaValidationMutation = usePutAddressAreaValidation();

  useEffect(() => {
    if (addressAreaValidationMutation.isError) {
      notifyError({
        status:
          addressAreaValidationMutation.error.response?.request?.status &&
          addressAreaValidationMutation.error.response?.request?.status,
        message: addressAreaValidationMutation.error.response?.data?.errors
          ? addressAreaValidationMutation.error.response?.data?.errors[0]
          : addressAreaValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressAreaValidationMutation.isError]);

  useEffect(() => {
    if (addressAreaValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the address',
      });
    }
  }, [addressAreaValidationMutation.isSuccess]);

  function handleSubmit(deliveryAddress) {
    addressAreaValidationMutation.mutate({
      ...validation,
      data: {
        ...deliveryAddress,
      },
      state,
    });
  }

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> AddressArea
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        {MH_BRAND !== 'nl' && (
          <Box>
            <Select
              id="isPickup"
              value={isPickup}
              placeholder="isPickup"
              onChange={() => setIsPickup(!isPickup)}
              label="Pickup location"
            >
              {pickupLocationOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              ))}
            </Select>
          </Box>
        )}

        {isPickup ? (
          <PickupLocation validation={validation} handleSubmit={handleSubmit} />
        ) : (
          <AddressArea validation={validation} handleSubmit={handleSubmit} />
        )}
      </Box>
    </ExpansionPanel>
  );
};

export default DeliveryAddress;
