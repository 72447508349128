import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENT_LINKS_KEY } from './useContentLinks';

const useCreateContentLink = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      ContentService.postContentLink(body).then(response => response.data),
    {
      onSuccess: mutationData => {
        queryClient.setQueryData(
          [CONTENT_LINKS_KEY, mutationData.brand, mutationData.key],
          cacheData => ({
            ...cacheData,
            contentLinks: {
              ...cacheData.contentLinks,
              [mutationData.location]: [
                ...(cacheData.contentLinks[mutationData.location] || []),
                mutationData,
              ],
            },
          }),
        );
      },
    },
  );
};

export default useCreateContentLink;
