import React from 'react';
import {
  Flex,
  Select,
  Box,
  TextField,
  Heading,
  Paper,
  Button,
} from '@mediahuis/chameleon-react';
import { colorPrimary10 } from '@mediahuis/chameleon-theme-wl';
import styled, { keyframes } from 'styled-components';
import { BrandSelect, TextFieldWithMarkDown } from '~/components/';
import { usePreconditionContext } from '~/components/context/precondition';
import constants from '../constants';
import CriteriaParameterInput from './CriteriaParameterInput';

const highlight = keyframes`
  from {
    background: ${colorPrimary10};
  }
  to {
    background: initial;
  }
`;

const Criteria = styled(Paper)`
  animation: ${highlight} 1s;
`;

const PreconditionForm = () => {
  const {
    precondition,
    setPreconditionState,
    defaultCriteria,
    errors,
    clearError,
  } = usePreconditionContext();
  const { criteria, errorMessage } = precondition;

  const onChangeSelect = (event, id) => {
    const newCriteria = [...precondition.criteria];
    const index = newCriteria.findIndex(c => c.id === id);

    const targetId = event.target.id;
    const value = event.target.value;

    newCriteria[index][targetId] = value;

    if (targetId === 'parameter') {
      newCriteria[index].parameter = [value];
    } else if (
      (targetId === 'operation' && (value === 'In' || value === 'NotIn')) ||
      (targetId === 'propertyName' &&
        (newCriteria[index].operation === 'In' ||
          newCriteria[index].operation === 'NotIn'))
    ) {
      newCriteria[index].parameter =
        newCriteria[index].propertyName === 'Formula'
          ? []
          : constants.criteria[newCriteria[index].propertyName].parameters;
    } else if (
      (targetId === 'operation' && (value !== 'In' || value !== 'NotIn')) ||
      (targetId === 'propertyName' &&
        (newCriteria[index].operation !== 'In' ||
          newCriteria[index].operation !== 'NotIn'))
    ) {
      newCriteria[index].parameter = [
        newCriteria[index].propertyName === 'Formula'
          ? ''
          : constants.criteria[newCriteria[index].propertyName].parameters?.[0],
      ];
    }
    // Reset operation value when switching to propertyName which has different operations
    if (
      targetId === 'propertyName' &&
      !constants.criteria[newCriteria[index].propertyName].operations?.find(
        el => el === newCriteria[index].operation,
      )
    ) {
      newCriteria[index].operation =
        constants.criteria[newCriteria[index].propertyName].operations?.[0];
    }
    setPreconditionState('criteria', newCriteria);
  };

  const onChangeTextfieldInput = (event, id) => {
    const newCriteria = [...precondition.criteria];
    const index = newCriteria.findIndex(c => c.id === id);
    const value = event.target.value;

    newCriteria[index].parameter = [value];

    setPreconditionState('criteria', newCriteria);
  };

  const onChangeMultiSelect = (value, id) => {
    const newCriteria = [...precondition.criteria];
    const index = newCriteria.findIndex(c => c.id === id);

    newCriteria[index].parameter = value;

    setPreconditionState('criteria', newCriteria);
  };

  const onBlur = event => {
    event.persist();
    setPreconditionState(event.target.name, event.target.value);
  };

  const onRemove = id => {
    const newCriteria = [...precondition.criteria];
    const index = newCriteria.findIndex(c => c.id === id);
    newCriteria.splice(index, 1);
    setPreconditionState('criteria', newCriteria);
  };

  const onCreate = () => {
    const newCriteria = [...precondition.criteria];
    newCriteria.unshift({ ...defaultCriteria[0] });
    setPreconditionState('criteria', newCriteria);
  };

  return (
    <>
      <Flex mb={5}>
        <Box width="100%">
          <TextField
            id="name"
            datatest-id="name-input"
            label="Name"
            name="name"
            onBlur={onBlur}
            onFocus={clearError}
            error={errors.name}
            message={errors.name}
            defaultValue={precondition.name}
          />
        </Box>
      </Flex>
      <Flex mb={5}>
        <Box width="50%" mr={4}>
          <BrandSelect
            value={precondition.brand}
            onChange={event =>
              setPreconditionState('brand', event.target.value)
            }
          />
        </Box>
        <Box width="50%">
          <TextField
            id="fallbackOfferId"
            datatest-id="fallbackOfferId-input"
            label="Fallback Offer Id"
            name="fallbackOfferId"
            onBlur={onBlur}
            defaultValue={precondition.fallbackOfferId}
          />
        </Box>
      </Flex>
      <Box mb={8}>
        <TextFieldWithMarkDown
          datatestid="errorMessage-input"
          defaultValue={errorMessage}
          error={errors.errorMessage}
          id="errorMessage"
          label="Error message"
          message={errors.errorMessage}
          name="errorMessage"
          rows={3}
          onBlur={onBlur}
          onFocus={clearError}
        />
      </Box>
      <Flex alignItems="center" justifyContent="space-between" mb={5}>
        <Heading fontFamily="primary" level={5}>
          Criteria
        </Heading>
        <Button
          data-testid="button-create"
          appearance="primary"
          size="small"
          ml={3}
          onClick={onCreate}
        >
          Add criteria
        </Button>
      </Flex>
      {criteria?.map(criterium => (
        <Criteria p={5} mb={5} key={criterium.id}>
          <Flex alignItems="center" justifyContent="flex-end" mb={5}>
            <Button
              data-testid="button-remove"
              appearance="secondary"
              size="small"
              ml={3}
              onClick={() => onRemove(criterium.id)}
            >
              Remove
            </Button>
          </Flex>
          <Flex mb={4}>
            <Box width="50%" mr={4}>
              <Select
                id="propertyName"
                data-testid="propertyName-input"
                name="propertyName"
                label="Property Name"
                onChange={event => onChangeSelect(event, criterium.id)}
                value={criterium.propertyName}
              >
                {Object.keys(constants.criteria).map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width="50%">
              <Select
                id="operation"
                data-testid="operation-input"
                name="operation"
                label="Operation"
                onChange={event => onChangeSelect(event, criterium.id)}
                value={criterium.operation}
              >
                {constants.criteria[criterium.propertyName].operations.map(
                  option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ),
                )}
              </Select>
            </Box>
          </Flex>
          <CriteriaParameterInput
            criterium={criterium}
            onChangeMultiSelect={onChangeMultiSelect}
            onChangeSelect={onChangeSelect}
            onChangeTextfieldInput={onChangeTextfieldInput}
          />
        </Criteria>
      ))}
    </>
  );
};

export default PreconditionForm;
