import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [params, setParams] = useState(queryString.parse(search));

  useEffect(() => {
    setParams(queryString.parse(search));
  }, [search]);

  function updateParams(newParams) {
    history.replace(`${pathname}?${queryString.stringify(newParams)}`);
  }

  return [params, updateParams];
};

export default useQueryParams;
