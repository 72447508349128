import {
  Button,
  Flex,
  Heading,
  Icon,
  Select,
  Textarea,
  TextField,
} from '@mediahuis/chameleon-react';
import { Save } from '@mediahuis/chameleon-theme-wl/icons';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import useAboshopContext from '~/components/context/useContext';
import { notifyError, notifySuccess } from '~/components/Toasts';
import { DetailLayout } from '~/layouts/DetailLayout';
import { useCreateContent } from '~/services/content';

const ContentsCreateFormSchema = object().shape({
  brand: string().required('This is a required field'),
  content: string().required('This is a required field'),
  name: string().required('This is a required field'),
});

const ContentsCreate = () => {
  const { brands } = useAboshopContext();
  const history = useHistory();

  const htmlContentCreate = useCreateContent();

  const formRef = useRef(null);

  useEffect(() => {
    if (htmlContentCreate.isError) {
      notifyError({
        status: htmlContentCreate.error?.response?.status,
        message: htmlContentCreate.error?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentCreate.isError]);

  useEffect(() => {
    if (htmlContentCreate.isSuccess) {
      notifySuccess({
        message: 'Successfully created HTML content',
      });
      history.push('/contents');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentCreate.isSuccess]);

  function handleFormSubmit(formValues) {
    htmlContentCreate.mutate({
      brand: formValues.brand,
      name: formValues.name,
      value: formValues.content,
    });
  }

  return (
    <DetailLayout
      aside={
        <Button
          iconLeft={() => <Icon as={Save} mr={3} size="small" />}
          loading={htmlContentCreate.isLoading}
          width="full"
          data-testid="button-save"
          onClick={() => formRef?.current?.submitForm()}
        >
          Save
        </Button>
      }
      main={
        <Formik
          innerRef={formRef}
          initialValues={{
            brand: '',
            content: '',
            name: '',
          }}
          validationSchema={ContentsCreateFormSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched }) => (
            <Flex flexDirection="column" style={{ gap: '1rem' }}>
              <Heading level={4}>Create HTML Content</Heading>

              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <Field name="brand">
                  {({ field }) => (
                    <Select
                      datatestid="htmlContent-brand"
                      error={!!touched.brand && !!errors.brand}
                      id="BrandField"
                      label="Brand"
                      message={
                        touched.brand && errors.brand ? errors.brand : undefined
                      }
                      name={field.name}
                      placeholder="Brand"
                      value={field.value || ''}
                      onChange={field.onChange}
                    >
                      {brands.map(brand => (
                        <option key={brand.code} value={brand.code}>
                          {brand.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <TextField
                      datatestid="htmlContent-name"
                      error={!!touched.name && !!errors.name}
                      id="NameField"
                      label="Name"
                      maxLength={50}
                      message={
                        touched.name && errors.name ? errors.name : undefined
                      }
                      name={field.name}
                      value={field.value || ''}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  )}
                </Field>

                <Field name="content">
                  {({ field }) => (
                    <Textarea
                      datatestid="htmlContent-value"
                      error={!!touched.content && !!errors.content}
                      id="content"
                      label="Content"
                      message={
                        touched.content && errors.content
                          ? errors.content
                          : undefined
                      }
                      multiline="true"
                      name={field.name}
                      rows={20}
                      value={field.value || ''}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  )}
                </Field>
              </Form>
            </Flex>
          )}
        </Formik>
      }
    />
  );
};

export default ContentsCreate;
