import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Flex,
} from '@mediahuis/chameleon-react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { usePutDateValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const toDate = dateStr => {
  const [day, month, year] = dateStr.split('/');
  return new Date(year, month - 1, day);
};

const Dates = ({ validation }) => {
  const [date, setDate] = useState({
    checked: !(validation.data && validation.data.value),
    // Transform data object to make it work with datepicker.
    value:
      validation.data && validation.data.value && toDate(validation.data.value),
  });
  const [state, setState] = useState(validation.state);

  const handleDateChange = (value, options) => {
    setDate({ ...date, value, formattedDate: options.formattedDate });
  };

  const dateTimeFrameStart = dayjs(validation.timeFrameStart).toDate();
  const dateTimeFrameEnd = dayjs(validation.timeFrameEnd).toDate();

  const dateValidationMutation = usePutDateValidation();

  useEffect(() => {
    if (dateValidationMutation.isError) {
      notifyError({
        status:
          dateValidationMutation.error.response?.request?.status &&
          dateValidationMutation.error.response?.request?.status,
        message: dateValidationMutation.error.response?.data?.errors
          ? dateValidationMutation.error.response?.data?.errors[0]
          : dateValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValidationMutation.isError]);

  useEffect(() => {
    if (dateValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the date',
      });
    }
  }, [dateValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Date
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box>
          <React.Fragment>
            <Box mb={5}>
              <Checkbox
                data-testid="checkbox-date-validation"
                id={`${validation.id}`}
                checked={date.checked}
                onChange={() =>
                  setDate({
                    checked: !date.checked,
                    value: dateTimeFrameStart,
                    formattedDate:
                      dayjs(dateTimeFrameStart).format('DD/MM/YYYY'),
                  })
                }
                label="Mijn abonnement start zo snel mogelijk"
              />
            </Box>
            {!date.checked && (
              <Box mb={5}>
                <DatePicker
                  id={validation.id}
                  label={validation.description}
                  value={date.value}
                  onChange={handleDateChange}
                  disabledDays={{
                    before: dateTimeFrameStart,
                    after: dateTimeFrameEnd,
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        </Box>

        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              dateValidationMutation.mutate({
                ...validation,
                data: {
                  value: date.checked ? null : date.formattedDate,
                },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Dates;
