const getNameValidation = name => {
  let errors = {};
  if (name && name.length > 50) {
    errors = {
      ...errors,
      name: { message: 'name must be at most 50 characters' },
    };
  }
  if (!name) {
    errors = { ...errors, name: { message: 'name is a required field' } };
  }
  return errors;
};

export default getNameValidation;
