import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  TextField,
} from '@mediahuis/chameleon-react';
import {
  ChevronDown,
  ChevronForward,
  Refresh,
} from '@mediahuis/chameleon-theme-wl/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { useAboshopContext } from '~/components';
import { useQueryParams } from '~/hooks';
import { VOUCHER_STATE } from '~/services/voucher';
import { checkQueryParam } from '~/utils';

const VouchersFilters = ({ onReset }) => {
  const { brands: mhBrands } = useAboshopContext();
  const [params, setParams] = useQueryParams();

  const [activeKeys, setActiveKeys] = useState();

  const idFormRef = useRef();
  const searchFormRef = useRef();

  const {
    brand,
    code,
    generatedDateStart,
    generatedDateEnd,
    usedDateStart,
    usedDateEnd,
    expirationDateStart,
    expirationDateEnd,
    purchaseFormulaId,
    redeemFormulaId,
    purchaseOrderId,
    redeemOrderId,
    IdFrom,
    IdUntil,
    state,
  } = params;

  useEffect(() => {
    if (!activeKeys) {
      const activeParams = Object.keys(params);
      const defaultKeys = [];

      if (
        activeParams.includes('generatedDateStart') ||
        activeParams.includes('generatedDateEnd')
      ) {
        defaultKeys.push('generatedDate');
      }
      if (
        activeParams.includes('usedDateStart') ||
        activeParams.includes('usedDateEnd')
      ) {
        defaultKeys.push('usedDate');
      }
      if (
        activeParams.includes('expirationDateStart') ||
        activeParams.includes('expirationDateEnd')
      ) {
        defaultKeys.push('expirationDate');
      }
      if (activeParams.includes('brand')) {
        defaultKeys.push('brand');
      }
      if (activeParams.includes('state')) {
        defaultKeys.push('state');
      }
      if (
        activeParams.includes('code') ||
        activeParams.includes('purchaseFormulaId') ||
        activeParams.includes('redeemFormulaId') ||
        activeParams.includes('purchaseOrderId') ||
        activeParams.includes('redeemOrderId')
      ) {
        defaultKeys.push('search');
      }
      if (activeParams.includes('IdFrom') || activeParams.includes('IdUntil')) {
        defaultKeys.push('id');
      }

      setActiveKeys(defaultKeys);
    }
  }, [activeKeys, params]);

  function handleCheckboxChange(event, paramsKey) {
    const param = params[paramsKey];

    const isArray = Array.isArray(param);
    let paramUpdate;

    if (event.target.checked) {
      if (isArray) {
        paramUpdate = [...param, event.target.value];
      } else {
        paramUpdate = param ? [param, event.target.value] : event.target.value;
      }
    } else {
      paramUpdate = isArray
        ? param.filter(t => t !== event.target.value)
        : undefined;
    }

    setParams({
      ...params,
      offset: 0,
      [paramsKey]: paramUpdate,
    });
  }

  function resetFilters() {
    idFormRef.current?.resetForm();
    searchFormRef.current?.resetForm();
    onReset();
  }

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }} width="300px">
      <Flex>
        <Button
          appearance="secondary"
          data-testid="filters-reset-btn"
          iconLeft={Refresh}
          size="small"
          width="full"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-expand-btn"
          iconLeft={ChevronDown}
          size="small"
          width="full"
          onClick={() =>
            setActiveKeys([
              'generatedDate',
              'usedDate',
              'expirationDate',
              'brand',
              'state',
              'search',
              'id',
            ])
          }
        >
          Expand
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-collapse-btn"
          iconLeft={ChevronForward}
          size="small"
          width="full"
          onClick={() => setActiveKeys([])}
        >
          Collapse
        </Button>
      </Flex>

      <Collapse activeKey={activeKeys} onChange={keys => setActiveKeys(keys)}>
        <Collapse.Panel header="Brand" key="brand">
          <Flex flexDirection="column">
            {mhBrands.map(mhBrand => (
              <Checkbox
                checked={brand ? checkQueryParam(brand, mhBrand.code) : false}
                data-testid={`brand-${mhBrand.code}-check`}
                id={`brand-${mhBrand.code}`}
                key={mhBrand.code}
                label={mhBrand.name}
                value={mhBrand.code}
                onChange={event => handleCheckboxChange(event, 'brand')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="State" key="state">
          <Flex flexDirection="column">
            {Object.values(VOUCHER_STATE).map(voucherState => (
              <Checkbox
                checked={state ? checkQueryParam(state, voucherState) : false}
                data-testid={`state-${voucherState}-check`}
                id={`state-${voucherState}`}
                key={voucherState}
                label={voucherState}
                value={voucherState}
                onChange={event => handleCheckboxChange(event, 'state')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Generated Date" key="generatedDate">
          <DatePicker
            data-testid="generatedDate-start-input"
            id="generatedDateStart"
            label="Start"
            readOnly
            value={
              generatedDateStart
                ? dayjs(generatedDateStart).toDate()
                : undefined
            }
            onChange={date =>
              setParams({
                ...params,
                generatedDateStart: date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : undefined,
                offset: 0,
              })
            }
            mb={3}
          />
          <DatePicker
            data-testid="generatedDate-end-input"
            id="generatedDateEnd"
            label="End"
            value={dayjs(generatedDateEnd).toDate()}
            onChange={date =>
              setParams({
                ...params,
                generatedDateEnd: dayjs(date).format('YYYY-MM-DD'),
                offset: 0,
              })
            }
          />
        </Collapse.Panel>

        <Collapse.Panel header="Used Date" key="usedDate">
          <DatePicker
            data-testid="usedDate-start-input"
            id="usedDateStart"
            label="Start"
            readOnly
            value={usedDateStart ? dayjs(usedDateStart).toDate() : undefined}
            onChange={date =>
              setParams({
                ...params,
                offset: 0,
                usedDateStart: date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : undefined,
              })
            }
            mb={3}
          />
          <DatePicker
            data-testid="usedDate-end-input"
            id="usedDateEnd"
            label="End"
            value={dayjs(usedDateEnd).toDate()}
            onChange={date =>
              setParams({
                ...params,
                offset: 0,
                usedDateEnd: dayjs(date).format('YYYY-MM-DD'),
              })
            }
          />
        </Collapse.Panel>

        <Collapse.Panel header="Expiration Date" key="expirationDate">
          <DatePicker
            data-testid="expirationDate-start-input"
            id="expirationDateStart"
            label="Start"
            readOnly
            value={
              expirationDateStart
                ? dayjs(expirationDateStart).toDate()
                : undefined
            }
            onChange={date =>
              setParams({
                ...params,
                expirationDateStart: date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : undefined,
                offset: 0,
              })
            }
            mb={3}
          />
          <DatePicker
            data-testid="expirationDate-end-input"
            id="expirationDateEnd"
            label="End"
            value={dayjs(expirationDateEnd).toDate()}
            onChange={date =>
              setParams({
                ...params,
                expirationDateEnd: dayjs(date).format('YYYY-MM-DD'),
                offset: 0,
              })
            }
          />
        </Collapse.Panel>

        <Collapse.Panel header="Search" key="search">
          <Formik
            initialValues={{
              code,
              purchaseFormulaId,
              redeemFormulaId,
              purchaseOrderId,
              redeemOrderId,
            }}
            innerRef={searchFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                code: values.code ? values.code : undefined,
                offset: 0,
                purchaseFormulaId: values.purchaseFormulaId
                  ? values.purchaseFormulaId
                  : undefined,
                purchaseOrderId: values.purchaseOrderId
                  ? values.purchaseOrderId
                  : undefined,
                redeemFormulaId: values.redeemFormulaId
                  ? values.redeemFormulaId
                  : undefined,
                redeemOrderId: values.redeemOrderId
                  ? values.redeemOrderId
                  : undefined,
              });
            }}
          >
            <Form>
              <Field name="code">
                {({ field }) => (
                  <TextField
                    data-testid="search-code-input"
                    id="code"
                    label="Code"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="purchaseOrderId">
                {({ field }) => (
                  <TextField
                    data-testid="search-purchaseOrderId-input"
                    id="purchaseOrderId"
                    label="Purchase Order ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>
              <Field name="purchaseFormulaId">
                {({ field }) => (
                  <TextField
                    data-testid="search-purchaseFormulaId-input"
                    id="purchaseFormulaId"
                    label="Purchase Formula ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>
              <Field name="redeemOrderId">
                {({ field }) => (
                  <TextField
                    data-testid="search-redeemOrderId-input"
                    id="redeemOrderId"
                    label="Redeem Order ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>
              <Field name="redeemFormulaId">
                {({ field }) => (
                  <TextField
                    data-testid="search-redeemFormulaId-input"
                    id="redeemFormulaId"
                    label="Redeem Formula ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>
              <Button
                data-testid="search-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>

        <Collapse.Panel header="ID" key="id">
          <Formik
            initialValues={{
              IdFrom,
              IdUntil,
            }}
            innerRef={idFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                IdFrom: values.IdFrom ? values.IdFrom : undefined,
                IdUntil: values.IdUntil ? values.IdUntil : undefined,
                offset: 0,
              });
            }}
          >
            <Form>
              <Field name="IdFrom">
                {({ field }) => (
                  <TextField
                    data-testid="id-IdFrom-btn"
                    id="IdFrom"
                    label="ID From"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>
              <Field name="IdUntil">
                {({ field }) => (
                  <TextField
                    data-testid="id-MaxId-btn"
                    id="MaxId"
                    label="ID Until"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    type="number"
                    min="1"
                    step="1"
                  />
                )}
              </Field>

              <Button
                data-testid="id-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>
      </Collapse>
    </Flex>
  );
};

export default VouchersFilters;
