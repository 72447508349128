import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { MyContext } from './components';
import SignIn from './components/SignIn';
import { notifyInfo } from './components/Toasts';
import './i18n';
import { PageLayout } from './layouts/PageLayout';
import routes from './routes';
import { GlobalStyle, theme } from './theme';
import {
  getFromLocalStorage,
  getInitialTables,
  setLocalStorage,
} from './utils';

const queryClient = new QueryClient();

toast.configure({ autoClose: 10000, draggable: false, hideProgressBar: false });

const getInitialState = () => {
  let subscriptionService = getFromLocalStorage('subscriptionService');

  if (subscriptionService === null || subscriptionService === undefined) {
    subscriptionService = MH_BRAND === 'hub';
  }

  return {
    brands: [],
    clipBoard: null,
    markDown: false,
    darkMode: false,
    tables: getInitialTables(),
    subscriptionService,
  };
};

const App = () => {
  const [globalState, setGlobalState] = useState(getInitialState);
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hubConnection, setHubConnection] = useState(null);

  const getToken = () =>
    Auth.currentSession()
      .then(session => session)
      .catch(err => err);

  useEffect(() => {
    if (
      getFromLocalStorage('experimentalMode') === null ||
      getFromLocalStorage('experimentalMode') === undefined
    ) {
      setLocalStorage('experimentalMode', true);
      setGlobalState(prev => ({
        ...prev,
        experimentalMode: true,
      }));
    } else {
      setGlobalState(prev => ({
        ...prev,
        experimentalMode: getFromLocalStorage('experimentalMode'),
      }));
    }

    if (hubConnection === null) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${API_NOTIFICATION_SERVICE}/notify`)
        .configureLogging(LogLevel.Information)
        .build();

      setHubConnection(connect);
    }
  }, []);

  useEffect(() => {
    if (hubConnection && getFromLocalStorage('experimentalMode')) {
      hubConnection.on('NotifyOnConfirmedOrder', orderMessage => {
        notifyInfo({
          message: orderMessage,
        });
      });

      hubConnection.start();
    }

    return () => {
      if (hubConnection) {
        hubConnection.stop();
      }
    };
  }, [hubConnection, getFromLocalStorage('experimentalMode')]);

  useEffect(() => {
    if (hubConnection && !getFromLocalStorage('experimentalMode')) {
      hubConnection.stop();
    }
  }, [hubConnection, getFromLocalStorage('experimentalMode')]);

  useEffect(() => {
    getToken()
      .then(userToken => {
        setIsLoading(false);
        return setAuthToken(userToken.idToken.jwtToken);
      })
      .catch(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return null;
  }

  return authToken ? (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <MyContext values={{ ...globalState, setGlobalState }}>
            <GlobalStyle />
            <PageLayout>
              <Switch>
                {routes.map(({ exact, path, Component, hasWizard = false }) =>
                  hasWizard ? (
                    <Route
                      render={({ history, match: { url } }) => (
                        <Component history={history} url={url} />
                      )}
                      key={path}
                      exact={exact}
                      path={path}
                    />
                  ) : (
                    <Route key={path} exact={exact} path={path}>
                      {Component}
                    </Route>
                  ),
                )}
              </Switch>
            </PageLayout>
          </MyContext>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  ) : (
    <SignIn />
  );
};

export default App;
