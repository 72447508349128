import React from 'react';
import styled from 'styled-components';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import useAboshopContext from '../context/useContext';

const MarkDownContainer = styled.div`
  padding: ${props => props.theme.spacing.half};
  background-color: #fafafa;
`;

export const MarkDownBlock = ({ source, isFocused }) => {
  const { markDown } = useAboshopContext();
  const hasMarkDown = Boolean((markDown || isFocused) && source);

  return (
    hasMarkDown && (
      <MarkDownContainer hasMarkDown={hasMarkDown}>
        <MarkdownRender source={source} />
      </MarkDownContainer>
    )
  );
};
