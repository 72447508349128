import { Box, Heading, Flex } from '@mediahuis/chameleon-react';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { notifyError, notifySuccess } from '~/components';
import { DetailLayout } from '~/layouts/DetailLayout';
import { useContent, usePatchContent } from '~/services/content';

import ContentsDetailForm from './ContentsDetailForm';
import ContentsDetailLinks from './ContentsDetailLinks';
import ContentsDetailMenu from './ContentsDetailMenu';

const ContentsDetail = () => {
  const { id } = useParams();

  const contentQuery = useContent(id);
  const contentPatch = usePatchContent();

  const contentsDetailFormRef = useRef();

  useEffect(() => {
    if (contentQuery.isError) {
      notifyError({
        message:
          'An error was encountered while fetching the HTML content. Please Try again later.',
      });
    }
  }, [contentQuery.isError]);

  useEffect(() => {
    if (contentPatch.isError) {
      notifyError({
        message:
          'An error was encountered while updating the HTML content. Please Try again later.',
      });
    }
  }, [contentPatch.isError]);

  useEffect(() => {
    if (contentPatch.isSuccess) {
      notifySuccess({
        message: 'Successfully updated the HTML content.',
      });
    }
  }, [contentPatch.isSuccess]);

  if (contentQuery.isError) {
    return null;
  }

  if (contentQuery.isSuccess) {
    return (
      <DetailLayout
        aside={
          <ContentsDetailMenu
            brand={contentQuery.data.brand}
            id={id}
            isLoading={contentPatch.isLoading}
            onSave={() => contentsDetailFormRef.current?.submitForm()}
          />
        }
        main={
          <Flex flexDirection="column" style={{ gap: '2rem' }}>
            <Box>
              <Heading fontFamily="primary" level={4}>
                HTML Content
              </Heading>

              <ContentsDetailForm
                content={contentQuery.data}
                formRef={contentsDetailFormRef}
                onSubmit={values =>
                  contentPatch.mutate({
                    brand: contentQuery.data.brand,
                    contentLinks: contentQuery.data.contentLinks,
                    id: contentQuery.data.id,
                    name: values.name,
                    value: values.value,
                  })
                }
              />
            </Box>

            <Box>
              <Heading fontFamily="primary" level={4}>
                Links
              </Heading>

              <ContentsDetailLinks
                contentLinks={contentQuery.data.contentLinks}
              />
            </Box>
          </Flex>
        }
      />
    );
  }

  return <p>Loading content..</p>;
};

export default ContentsDetail;
