/* eslint-disable */
import { Folder, Tag, List } from '@mediahuis/chameleon-theme-wl/icons';
import { Divider, Heading, Flex, Icon, Box, Text, Paper } from '@mediahuis/chameleon-react';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { LiveChart, useAboshopContext } from '~/components';
import { useOrders } from '~/services/order';
import { theme } from '~/theme';

import { MetricBlocks } from '../../components/MetricBlocks';
import { useHistory } from 'react-router-dom';
import homeButtons from './buttons/homeButtons';

dayjs.extend(dayjsPluginUTC);

const Home = () => {
  const context = useAboshopContext();
  const history = useHistory();
  const showStatistics = context.experimentalMode === undefined ? true : context.experimentalMode;

  const ordersTodayQuery = useOrders({
    'api-version': 2,
    amount: 100,
    endDate: dayjs().format('YYYY-MM-DDT23:59:59'),
    includeStatistics: true,
    startDate: dayjs().format('YYYY-MM-DDT00:00:00'),
    statisticsIntervalSeconds: 3600,
    statisticsTopFormulasAmount: 5,
    statisticsTopTypesAmount: 5,
  });
  const ordersLastWeekQuery = useOrders({
    'api-version': 2,
    amount: 100,
    endDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    includeStatistics: true,
    startDate: dayjs()
      .subtract(1, 'week')
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
    statisticsIntervalSeconds: 3600,
    statisticsTopFormulasAmount: 5,
    statisticsTopTypesAmount: 5,
  });

  const calculateTotalOrdersBrand = (dataPoints, brand) => {
    if (dataPoints && dataPoints[brand]) {
      return Object.values(dataPoints[brand]).reduce(
        (total, brandDataPoint) => {
          return total + brandDataPoint.orderCount;
        },
        0,
      );
    }
    return 0;
  };

  const pieGeneralData = [];
  const legendLabels = [];
  if (ordersTodayQuery.isSuccess && ordersTodayQuery.data?.dataPoints !== null) {
    Object.keys(ordersTodayQuery.data.dataPoints).map((key, index) => {
      const graphColor = theme.colors[key]?.graph === undefined ? '#000000' : theme.colors[key].graph;
      pieGeneralData.push({ 
        color: graphColor, 
        name: key, 
        value: calculateTotalOrdersBrand(ordersTodayQuery.data.dataPoints, key)
      });

      legendLabels.push(
        { color: graphColor, value: key.toUpperCase() }
      );
    });
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  let allPages = [
    {
      link: 'subscriptionformula',
      title: 'Products',
      description: 'Technical configuration',
      icon: Tag,
    },
    {
      link: 'offers',
      title: 'Offers',
      description: 'Offers overview',
      icon: Folder,
    },
    {
      link: 'orders',
      title: 'Orders',
      description: 'Orders overview',
      icon: List,
    },
  ];

  allPages = allPages.filter(x => homeButtons.includes(x.link));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      {ordersTodayQuery.isSuccess && ordersLastWeekQuery.isSuccess && showStatistics && (
        <MetricBlocks
          dataToday={ordersTodayQuery.data}
          dataLastWeek={ordersLastWeekQuery.data}
        />
      )}
      
      {showStatistics &&
      <div style={{ display: 'flex', flexDirection: 'column', gap: '3rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <Heading level={4}>Live orders (last 24 hours)</Heading>
          <Divider />
        </div>

        {ordersTodayQuery.isSuccess && (
          <LiveChart dataPoints={ordersTodayQuery.data.dataPoints} />
        )}
      </div> }

      {showStatistics &&
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <Heading level={4}>Sales per brand</Heading>
          <Divider />
        </div>

        <ResponsiveContainer width="100%" height={500}>
          <PieChart height={500} width={500}>
            <Legend
              payload={legendLabels}
            />
            <Tooltip />

            <Pie data={pieGeneralData} dataKey="value" cx="50%" cy="50%" labelLine={false} label={renderCustomizedLabel}>
              {pieGeneralData.map(entry => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div> }

      {!showStatistics &&
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Box mb={5} mt={5}>
          <Box mb={5} textAlign="center">
            <Text size="Heading1">
              Welcome to Aboshop Admin, <br /> what would you like to do?
            </Text>
          </Box>
        </Box>
        <Flex>
          {allPages.map(page => (
            <Paper
              data-testid={page.title}
              key={page.link}
              width="full"
              mx={5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => history.push(page.link)}
              hoverable
            >
              <Box textAlign="center" p={5}>
                <Box mb={3}>
                  <Icon as={page.icon} size="xLarge" />
                </Box>
                <Box mb={1}>
                  <Text size="Heading4">{page.title}</Text>
                </Box>
                <Box mb={2}>
                  <Text>{page.description}</Text>
                </Box>
              </Box>
            </Paper>
          ))}
        </Flex>
      </Flex>
      }
    </div>
  );
};

export default Home;
