import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Box } from '@mediahuis/chameleon-react';
import OrderedOptionList from './OrderedOptionList';
import ItemTypes from './ItemTypes';

const Grid = styled(Box)`
  background-color: ${props => props.theme.colors.white};
  margin-top: 5px;
  height: 200px;
  color: ${props => props.theme.palette.grey.text};
  padding: 7px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.palette.grey[700]};
  overflow: auto;
`;

const LeftSide = ({
  availableOptions,
  setAvailableOptions,
  chosenOptions,
  setChosenOptions,
}) => {
  // This component is made of drop type Box for dropping items into an area.
  // Inside there is a drop type of Card to make ordering of options possible
  // within the Box dropping area.
  // Box docs: https://react-dnd.github.io/react-dnd/examples/dustbin/single-target
  // Card docs: https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const { BOX, LEFT_SIDE } = ItemTypes;
  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop({
    accept: BOX,
    drop: () => ({ name: LEFT_SIDE }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <Grid ref={drop}>
      <OrderedOptionList
        chosenOptions={chosenOptions}
        availableOptions={availableOptions}
        setAvailableOptions={setAvailableOptions}
        setChosenOptions={setChosenOptions}
      />
    </Grid>
  );
};
export default LeftSide;
