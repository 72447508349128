import {
  Box,
  Button,
  Flex,
  TextField,
  Textarea,
} from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutPreconditionsValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Precondition = ({ validation }) => {
  const [state, setState] = useState(validation.state);

  const preconditionsValidationMutation = usePutPreconditionsValidation();

  useEffect(() => {
    if (preconditionsValidationMutation.isError) {
      notifyError({
        status:
          preconditionsValidationMutation.error.response?.request?.status &&
          preconditionsValidationMutation.error.response?.request?.status,
        message: preconditionsValidationMutation.error.response?.data?.errors
          ? preconditionsValidationMutation.error.response?.data?.errors[0]
          : preconditionsValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preconditionsValidationMutation.isError]);

  useEffect(() => {
    if (preconditionsValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the preconditions',
      });
    }
  }, [preconditionsValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Precondition
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box mb={5}>
          <TextField
            label="Name"
            id="ruleName"
            name="ruleName"
            data-testid="input-ruleName"
            defaultValue={validation.data?.ruleName}
            disabled
          />
        </Box>
        <Box>
          <Textarea
            data-testid="input-precondition"
            disabled
            id="precondition"
            label="Precondition message"
            name="precondition"
            readOnly
            rows={4}
            value={validation.data?.errorMessage}
          />
        </Box>

        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              preconditionsValidationMutation.mutate({ ...validation, state })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Precondition;
