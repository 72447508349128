import {
  Banner,
  Box,
  Flex,
  Heading,
  Switch,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { PageLoader, useAboshopContext } from '~/components';

import { setLocalStorage } from '../../utils';
import useConfiguration from './hooks/useConfiguration';

const Configuration = () => {
  const context = useAboshopContext();
  const configuration = useConfiguration();

  const [checked, setChecked] = useState({
    darkMode: context.darkMode,
    subscriptionService: context.subscriptionService,
  });
  const [isConfigAdmin, setIsConfigAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (context && isLoading) {
      Auth.currentSession()
        .then(session => {
          const cognitoGroups =
            session.getAccessToken().payload['cognito:groups'];

          if (cognitoGroups) {
            setIsConfigAdmin(cognitoGroups.includes('Aboshop.Config.Admin'));
          }
          setIsLoading(false);

          return undefined;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [isLoading, context]);

  if (isLoading || configuration.isLoading) {
    return <PageLoader />;
  }

  const toggleChecked = e => {
    const name = e.target.name;
    setChecked({
      ...checked,
      [name]: !checked[name],
    });
    setLocalStorage(name, !checked[name]);
    context.setGlobalState(prev => ({
      ...prev,
      [name]: !checked[name],
    }));
  };

  const toggleMaintenance = brandKey => {
    const { data, update } = configuration;

    const currentBrandConfig = data[brandKey];

    if (currentBrandConfig) {
      update({
        ...data,
        [brandKey]: {
          ...currentBrandConfig,
          maintenance: !currentBrandConfig.maintenance,
        },
      });
    }
  };

  const toggleIsChatbotRobbie = brandKey => {
    const { data, update } = configuration;

    const currentBrandConfig = data[brandKey];

    if (currentBrandConfig) {
      update({
        ...data,
        [brandKey]: {
          ...currentBrandConfig,
          isChatbotRobbie: !currentBrandConfig.isChatbotRobbie,
        },
      });
    }
  };

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }}>
      <Banner appearance="error" show={configuration.isError}>
        {configuration.error}
      </Banner>

      <Box
        display="grid"
        width="full"
        style={{
          gap: '4rem',
          gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
        }}
      >
        <Box>
          <Heading level={3}>Maintenance Mode</Heading>
          {configuration.isSuccess && (
            <WideList>
              {Object.entries(configuration.data)
                .filter(([, entry]) => entry.group === MH_BRAND)
                .map(([key, entry]) => (
                  <WideListItem
                    action={
                      <Switch
                        checked={entry.maintenance}
                        disabled={!isConfigAdmin}
                        value={key}
                        onChange={
                          isConfigAdmin
                            ? () => toggleMaintenance(key)
                            : undefined
                        }
                      />
                    }
                    key={key}
                    title={key}
                  />
                ))}
            </WideList>
          )}
        </Box>

        <Box>
          <Heading level={3}>Chatbot Robbie</Heading>
          {configuration.isSuccess && (
            <WideList>
              {Object.entries(configuration.data)
                .filter(([, entry]) => entry.group === MH_BRAND)
                .map(([key, entry]) => (
                  <WideListItem
                    action={
                      <Switch
                        checked={entry.isChatbotRobbie}
                        disabled={!isConfigAdmin}
                        value={key}
                        onChange={
                          isConfigAdmin
                            ? () => toggleIsChatbotRobbie(key)
                            : undefined
                        }
                      />
                    }
                    key={key}
                    title={key}
                  />
                ))}
            </WideList>
          )}
        </Box>

        <Box>
          <Heading level={3}>Subscription Service</Heading>
          <WideList>
            <WideListItem
              action={
                <Switch
                  checked={checked.subscriptionService}
                  data-testid="subscriptionService-toggle"
                  name="subscriptionService"
                  onChange={toggleChecked}
                />
              }
              title="Active"
            />
          </WideList>
        </Box>
      </Box>
    </Flex>
  );

  /*
   * Darkmode should remain commented until Chameleon supports it.
   */
  /* <FormControlLabel
      control={
        <Switch
          checked={checked.darkMode}
          name="darkMode"
          onChange={toggleChecked}
          data-testid="darkMode-toggle"
        />
      }
      label="Dark mode"
    />
  */
};

export default Configuration;
