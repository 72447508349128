import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Box as CHBox } from '@mediahuis/chameleon-react';
import Box from './Box';
import ItemTypes from './ItemTypes';

const Grid = styled(CHBox)`
  background-color: ${props => props.theme.colors.white};
  margin-top: 5px;
  height: 200px;
  color: ${props => props.theme.palette.grey.text};
  padding: 7px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.palette.grey[700]};
  overflow: auto;
`;

const RightSide = ({
  availableOptions,
  setAvailableOptions,
  chosenOptions,
  setChosenOptions,
}) => {
  const { CARD, RIGHT_SIDE } = ItemTypes;
  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop({
    accept: CARD,
    drop: () => ({ name: RIGHT_SIDE }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Grid ref={drop}>
      {availableOptions.map(availablePaymentOption => (
        <Box
          key={availablePaymentOption.id}
          availablePaymentOption={availablePaymentOption}
          availableOptions={availableOptions}
          setAvailableOptions={setAvailableOptions}
          chosenOptions={chosenOptions}
          setChosenOptions={setChosenOptions}
        />
      ))}
    </Grid>
  );
};
export default RightSide;
