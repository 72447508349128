import React, { useState } from 'react';
import {
  DatePicker,
  TextField,
  Text,
  Flex,
  Box,
  Paper,
  Heading,
  Button,
} from '@mediahuis/chameleon-react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Save } from '@mediahuis/chameleon-theme-wl/icons';
import {
  ButtonWrapper,
  HeaderWrapper,
  Sidebar,
  StickyTop,
} from '../../../components';
import { textValidation } from '../validation/textValidation';
import { dateValidation } from '../validation/dateValidation';
import ProposalVariants from './ProposalVariants';
import validateOnSubmit from '../validation/validateOnSubmit';
import { submitProposal } from '../utils/submitProposal';

const ProposalForm = ({ initialValues, editing = false }) => {
  const history = useHistory();
  const { variants, start, end } = initialValues;

  const [isLoading, setIsLoading] = useState(false);
  const [proposalErrors, setProposalErrors] = useState({});
  const [variantsErrors, setVariantsErrors] = useState({});
  const [proposalData, setProposalData] = useState({
    name: '',
    segment: '',
    priority: 0,
    period: 0,
    ...initialValues,
    brand: initialValues?.brand.toLowerCase() || '',
    start: start ? new Date(start) : new Date(),
    end: end ? new Date(end) : new Date(),
  });
  const [variantsData, setVariantsData] = useState(
    variants ? variants : [{ id: 1, name: '', path: '', newVariant: true }],
  );

  const onInputChange = (e, type) => {
    const targetId = e.target.id;
    const targetValue =
      type === 'number' ? Number(e.target.value) : e.target.value;
    setProposalData(prevState => ({ ...prevState, [targetId]: targetValue }));
  };

  const onDateChange = ({ value, meta, id }) => {
    const localErrors = { ...proposalErrors };

    // Remove end date error, if it exists, when start date is changed.
    if (id === 'start' && proposalErrors.end) {
      delete localErrors.end;
    }

    const newProposalErrors = dateValidation({
      value,
      meta,
      id,
      proposalErrors: localErrors,
    });
    setProposalErrors(newProposalErrors);

    // Change end date if new start date is after current endDate.
    if (id === 'start' && dayjs(value) > dayjs(proposalData.end)) {
      setProposalData(prevState => ({
        ...prevState,
        [id]: value,
        end: dayjs(value)
          .add(1, 'day')
          .toDate(),
      }));
    } else {
      setProposalData(prevState => ({ ...prevState, [id]: value }));
    }
  };

  const validateInput = ({ name, segment, priority, period }) => {
    const textErrors = textValidation({ name, segment, priority, period });
    setProposalErrors(prevState => ({ ...prevState, ...textErrors }));
  };

  const clearError = e => {
    const currentErrors = { ...proposalErrors };
    delete currentErrors[e.target.id];
    setProposalErrors(currentErrors);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const onSubmitErrors = validateOnSubmit({
      proposalData,
      variantsData,
      variantsErrors,
    });
    const { newVariantsErrors, newProposalErrors } = onSubmitErrors;

    // Set errors if there are any and break of onSubmit flow.
    if (
      Object.keys({
        ...proposalErrors,
        ...newVariantsErrors,
        ...newProposalErrors,
      }).length > 0
    ) {
      setIsLoading(false);
      setProposalErrors(prevState => ({ ...prevState, ...newProposalErrors }));
      setVariantsErrors(newVariantsErrors);
      return;
    }

    submitProposal({
      initialValues,
      editing,
      data: { ...proposalData, variants: variantsData },
      setIsLoading,
      history,
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box>
        <Box>
          <Paper>
            <Box p={7} width={600}>
              <HeaderWrapper>
                <Box fontSize={16} mb={4}>
                  <b>Proposal</b>
                </Box>
              </HeaderWrapper>
              <Flex mt={4}>
                <TextField
                  id="name"
                  label="Name"
                  placeholder=""
                  mr={3}
                  width="60%"
                  value={proposalData.name}
                  onChange={onInputChange}
                  error={proposalErrors.name}
                  message={proposalErrors.name}
                  onBlur={() => validateInput({ name: proposalData.name })}
                  onFocus={clearError}
                />
                <Box width="40%">
                  <DatePicker
                    id="start"
                    label="Start Date"
                    value={proposalData.start}
                    mr={3}
                    onChange={(value, meta) => {
                      onDateChange({ value, meta, id: 'start' });
                    }}
                    error={proposalErrors.start}
                    message={proposalErrors.start}
                  />
                </Box>
                <TextField
                  id="priority"
                  label="Priority"
                  placeholder=""
                  type="number"
                  width="20%"
                  value={proposalData.priority}
                  onChange={e => onInputChange(e, 'number')}
                  error={proposalErrors.priority}
                  message={proposalErrors.priority}
                  onBlur={() =>
                    validateInput({ priority: proposalData.priority })
                  }
                  onFocus={clearError}
                />
              </Flex>
              <Flex mt={4}>
                <TextField
                  id="segment"
                  label="Segment"
                  placeholder=""
                  mr={3}
                  width="60%"
                  value={proposalData.segment}
                  onChange={onInputChange}
                  error={proposalErrors.segment}
                  message={proposalErrors.segment}
                  onBlur={() =>
                    validateInput({ segment: proposalData.segment })
                  }
                  onFocus={clearError}
                />
                <Box width="40%">
                  <DatePicker
                    id="end"
                    label="End Date"
                    value={proposalData.end}
                    mr={3}
                    onChange={(value, meta) =>
                      onDateChange({ value, meta, id: 'end' })
                    }
                    disabledDays={[
                      {
                        before: dayjs(proposalData.start).toDate(),
                      },
                    ]}
                    error={proposalErrors.end}
                    message={proposalErrors.end}
                  />
                </Box>
                <TextField
                  id="period"
                  label="Period"
                  placeholder=""
                  type="number"
                  width="20%"
                  value={proposalData.period}
                  onChange={e => onInputChange(e, 'number')}
                  error={proposalErrors.period}
                  message={proposalErrors.period}
                  onBlur={() => validateInput({ period: proposalData.period })}
                  onFocus={clearError}
                />
              </Flex>
            </Box>
          </Paper>
        </Box>
        <Box mt={4} mb={4}>
          <ProposalVariants
            data={variantsData}
            setData={setVariantsData}
            errors={variantsErrors}
            setErrors={setVariantsErrors}
          />
        </Box>
      </Box>
      <Sidebar>
        <StickyTop>
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Brand</strong>: {proposalData.brand}
            </Text>
          </Paper>
          <ButtonWrapper>
            <Button
              datatestid="save-offer-button"
              iconLeft={Save}
              onClick={onSubmit}
              type="submit"
              width="full"
              loading={isLoading}
            >
              Save
            </Button>
          </ButtonWrapper>
        </StickyTop>
      </Sidebar>
    </div>
  );
};

export default ProposalForm;
