import { useQuery } from 'react-query';

import { OrderService } from '~/services/order';

export const ORDER_KEY = 'order';

export const useOrder = id => {
  const orderId = parseInt(id, 10);

  return useQuery(
    [ORDER_KEY, orderId],
    () => OrderService.getOrder(orderId).then(response => response.data),
    {
      enabled: Boolean(orderId),
    },
  );
};
