import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PageLoader, notifyError, notifySuccess } from '~/components';
import { removeDeprecatedRequirementKeys, secureApiCall } from '~/utils';

import FormulaForm from './components/FormulaForm';

const EditFormula = () => {
  const [initialValues, setInitialValues] = useState();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const history = useHistory();
  const apiUrl = `${API_OFFER_SERVICE}/SubscriptionFormulas/${id}`;

  const deleteMethod = () => {
    secureApiCall(apiUrl, {
      method: 'DELETE',
      params: {
        'api-version': '2',
      },
      onSuccess: () => {
        history.push('/subscriptionformula');
        notifySuccess({
          message: 'Successfully deleted a formula',
        });
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const duplicateMethod = () => {
    history.push('/subscriptionformula/create/type', { initialValues });
  };

  useEffect(() => {
    secureApiCall(apiUrl, {
      method: 'GET',
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        setInitialValues(removeDeprecatedRequirementKeys(response.data));
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message: errorMessage?.response?.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        history.push('/subscriptionformula');
      },
    });
  }, [apiUrl, history, i18n.language]);

  if (!initialValues) {
    return <PageLoader />;
  }

  return (
    <FormulaForm
      editing
      apiUrl={apiUrl}
      initialValues={initialValues}
      deleteMethod={deleteMethod}
      duplicateMethod={duplicateMethod}
    />
  );
};

export default EditFormula;
