import {
  Box,
  Caption,
  Flex,
  Heading,
  IconButton,
  Paper,
  Text,
} from '@mediahuis/chameleon-react';
import {
  ChevronDown,
  ChevronUp,
  Delete,
  Edit,
} from '@mediahuis/chameleon-theme-wl/icons';
import React, { useState } from 'react';

import { Link } from '../../../components';
import ProductCardFields from './ProductCardFields';

const ProductCard = ({ index, product, onDelete, handleItemsChange }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = e => {
    e.preventDefault();
    setExpanded(prev => !prev);
  };

  return (
    <Paper
      display="flex"
      flexDirection="column"
      height="full"
      px={5}
      py={4}
      style={{ gap: '0.5rem' }}
    >
      <Box>
        <Heading fontWeight="regular" level={5}>
          {product.title}
        </Heading>
        <Caption>{product.name}</Caption>
      </Box>

      <Text flexGrow={1}>{product.description}</Text>

      <ProductCardFields
        expanded={expanded}
        index={index}
        product={product}
        handleItemsChange={handleItemsChange}
      />

      <Flex>
        <Link to={`/${product.type}/edit/${product.id}`} target="_blank">
          <IconButton
            data-testid="edit-product-button"
            aria-label="edit"
            size="small"
            icon={Edit}
          />
        </Link>
        <IconButton
          data-testid="delete-product-button"
          onClick={() => onDelete(product)}
          aria-label="delete"
          icon={Delete}
          color="colorRedBase"
          size="small"
        />

        <IconButton
          data-testid="expand-product-button"
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="small"
          style={{ marginLeft: 'auto' }}
          icon={expanded ? ChevronUp : ChevronDown}
        />
      </Flex>
    </Paper>
  );
};

export default ProductCard;
