import {
  Checkbox,
  Flex,
  Textarea,
  TextField,
} from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { array, boolean, object, string } from 'yup';

import { OfferGroupItemsSelect } from './OfferGroupItemsSelect';

const OfferGroupsFormSchema = object().shape({
  defaultSelected: boolean(),
  description: string(),
  items: array(),
  label: string(),
  title: string().required(),
});

const OfferGroupForm = ({
  disabled,
  formRef,
  initialValues,
  offer,
  onSubmit,
}) => {
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={OfferGroupsFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="title">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="TitleInput"
                label="Title"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="label">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="LabelInput"
                label="Label"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="items">
            {({ field, form }) => (
              <OfferGroupItemsSelect
                disabled={disabled}
                label="Products"
                options={offer.items || []}
                selectedOptions={field.value || []}
                onChange={selectedOptions =>
                  form.setFieldValue('items', selectedOptions)
                }
              />
            )}
          </Field>

          <Field name="defaultSelected">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="defaultSelectedCheck"
                label="Default Selected"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>
        </Flex>
      </Form>
    </Formik>
  );
};

export default OfferGroupForm;
