import React from 'react';

import { useSubscriptionFormulas } from '~/services/offer';

const SubscriptionFormulasQuery = ({ filters, render }) => {
  const subscriptionFormulasQuery = useSubscriptionFormulas(filters);

  if (subscriptionFormulasQuery.isError) {
    return <p>Error while loading formulas</p>;
  }

  if (subscriptionFormulasQuery.isSuccess) {
    return render(
      subscriptionFormulasQuery.data,
      subscriptionFormulasQuery.isFetching,
    );
  }

  return <p>Loading formulas..</p>;
};

export default SubscriptionFormulasQuery;
