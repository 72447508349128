import { Box, Paper, Flex } from '@mediahuis/chameleon-react';
import React, { useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus/lib';

import { BrandWizard, FeatureForm, useAboshopContext } from '../../components';

const AddFeature = ({ history, url }) => {
  const { brands } = useAboshopContext();

  const [initialValues, setInitialValues] = useState({
    brand: '',
    name: '',
    description: '',
    negativeDescription: '',
  });

  if (brands.length === 1) {
    initialValues.brand = brands[0].code;
  }

  const handleSelectBrand = ({ brandCode, next }) => {
    setInitialValues({
      ...initialValues,
      name: '',
      description: '',
      negativeDescription: '',
      brand: brandCode,
    });

    next();
  };

  return (
    <Wizard history={history} basename={url}>
      <Steps>
        <Step
          id="brand"
          render={({ next }) => (
            <BrandWizard
              handleSelectBrand={brandCode =>
                handleSelectBrand({ brandCode, next })
              }
            />
          )}
        />
        <Step
          id="feature"
          render={() => (
            <Flex justifyContent="center">
              <Paper>
                <Box p={7} width="600px">
                  <FeatureForm
                    initialValues={initialValues}
                    apiUrl={`${API_OFFER_SERVICE}/Features`}
                  />
                </Box>
              </Paper>
            </Flex>
          )}
        />
      </Steps>
    </Wizard>
  );
};

export default AddFeature;
