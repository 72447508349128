import { secureApiCall } from '~/utils';
import dayjs from 'dayjs';
import { notifySuccess, notifyError } from '~/components';

export const submitProposal = ({
  initialValues,
  editing,
  data,
  setIsLoading,
  history,
}) =>
  secureApiCall(
    `${ABO_PROPOSAL_SERVICE}/proposals${
      initialValues.id ? `/${initialValues.id}` : ''
    }?api-version=2.0`,
    {
      method: editing ? 'PUT' : 'POST',
      data: JSON.stringify({
        ...data,
        variants: data.variants.map(variant => {
          if (variant.newVariant) {
            // eslint-disable-next-line no-unused-vars
            const { id, newVariant, ...updatedVariant } = variant;
            return updatedVariant;
          }
          return variant;
        }),
        start: dayjs(data.start).format('YYYY-MM-DD'),
        end: dayjs(data.end).format('YYYY-MM-DD'),
      }),
      onSuccess: response => {
        setIsLoading(false);
        if (!editing) {
          history.push(`/proposals/edit/${response.data.id}`);
        }
        notifySuccess({
          message: `Successfully ${
            editing ? 'edited' : 'created '
          } a proposal.`,
        });
        return response;
      },
      onError: error => {
        setIsLoading(false);
        notifyError({
          status: error?.response?.request?.status,
          message: error.response.data.errors
            ? error.response.data.errors[0]
            : error.message,
        });
      },
    },
  );
