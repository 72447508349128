import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';

import { notifyError } from '~/components';
import { OrderService } from '~/services/order';

const useOrdersCsv = () => {
  return useMutation(
    filters => OrderService.getOrdersCsv(filters).then(response => response),
    {
      onSuccess: response => {
        let fileName = '';

        if (response.headers['content-disposition']) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(
            response.headers['content-disposition'],
          );

          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        fileDownload(response.data, fileName || 'Aboshop_order_export.csv');
      },
      onError: error => {
        notifyError({
          status:
            error.response?.request?.status && error.response.request.status,
          message: error.response?.data?.errors
            ? error.response.data.errors[0]
            : error.message,
        });
      },
    },
  );
};

export default useOrdersCsv;
