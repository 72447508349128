import { Textarea, TextField } from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';

const ContentsDetailForm = ({ content, formRef, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        name: content.name,
        value: content.value,
      }}
      innerRef={formRef}
      onSubmit={onSubmit}
    >
      <Form style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Field name="name">
          {({ field }) => (
            <TextField
              id="NameInput"
              data-testid="content-name-input"
              label="Name"
              name={field.name}
              required={false}
              value={field.value || ''}
              onBlur={field.onBlur}
              onChange={field.onChange}
            />
          )}
        </Field>
        <Field name="value">
          {({ field }) => (
            <Textarea
              datatestid="content-value-input"
              id="ValueInput"
              label="Content"
              name={field.name}
              rows={20}
              value={field.value || ''}
              onBlur={field.onBlur}
              onChange={field.onChange}
            />
          )}
        </Field>
      </Form>
    </Formik>
  );
};

export default ContentsDetailForm;
