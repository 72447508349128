import { notifyError } from '~/components';
import secureApiCall from './secureApiCall';

const getFormulas = ({ brand, filters }) =>
  new Promise(resolve => {
    secureApiCall(`${API_OFFER_SERVICE}/SubscriptionFormulas`, {
      method: 'GET',
      params: { 'api-version': '2', brands: brand, ...filters },
      onSuccess: response => {
        const results = response.data.results.map(formula => ({
          ...formula,
          type: 'SubscriptionFormula',
        }));
        resolve(results);
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        resolve(false);
      },
    });
  });

const getTypes = ({ brand, filters }) =>
  new Promise(resolve => {
    secureApiCall(`${API_OFFER_SERVICE}/SubscriptionTypes`, {
      method: 'GET',
      params: { 'api-version': '2', brands: brand, ...filters },
      onSuccess: response => {
        const results = response.data.results.map(type => ({
          ...type,
          type: 'SubscriptionType',
        }));
        resolve(results);
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        resolve(false);
      },
    });
  });

const getProducts = ({ brand, filters = {} }) =>
  Promise.all([
    getFormulas({ brand, filters }),
    getTypes({ brand, filters }),
  ]).then(([formulas, types]) => {
    const products = [...formulas, ...types];
    return products;
  });

export default getProducts;
