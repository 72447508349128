export const durationTypeOptions = [
  { id: 'days', value: 'Days', label: 'Days' },
  { id: 'weeks', value: 'Weeks', label: 'Weeks' },
  { id: 'months', value: 'Months', label: 'Months' },
  { id: 'years', value: 'Years', label: 'Years' },
];

export const durationTypeSelectDefaultOption = 'Months';

export const priceTypeOptions = [
  { id: 'regular', value: 'Regular', label: 'Regular' },
  { id: 'free', value: 'Free', label: 'Free' },
  { id: 'incentive', value: 'Incentive', label: 'Incentive' },
  { id: 'linkedSale', value: 'LinkedSale', label: 'LinkedSale' },
  { id: 'promo', value: 'Promo', label: 'Promo' },
  { id: 'stunt', value: 'Stunt', label: 'Stunt' },
  { id: 'voucherPurchase', value: 'VoucherPurchase', label: 'VoucherPurchase' },
  {
    id: 'voucherRedemption',
    value: 'VoucherRedemption',
    label: 'VoucherRedemption',
  },
];

export const PRICE_TYPE = {
  FREE: 'Free',
  INCENTIVE: 'Incentive',
  LINKED_SALE: 'LinkedSale',
  PROMO: 'Promo',
  REGULAR: 'Regular',
  STUNT: 'Stunt',
  VOUCHER_PURCHASE: 'VoucherPurchase',
  VOUCHER_REDEMPTION: 'VoucherRedemption',
};

export const INCENTIVE_PRICE_TYPES = [
  PRICE_TYPE.INCENTIVE,
  PRICE_TYPE.LINKED_SALE,
];
