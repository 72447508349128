/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Flex, Icon, Text } from '@mediahuis/chameleon-react';
import { ArrowDown, ArrowUp } from '@mediahuis/chameleon-theme-wl/icons';
import dayjs from 'dayjs';

import './MetricBlocks.css';

export const calculateTotalOrders = (dataPoints, date) => {
  return Object.values(dataPoints).reduce((total, brandDataPoints) => {
    return (
      total +
      brandDataPoints.reduce((brandTotal, dataPoint) => {
        if (date) {
          return dayjs(date).get('hour') > dayjs(dataPoint.from).get('hour')
            ? brandTotal + dataPoint.orderCount
            : brandTotal;
        }
        return brandTotal + dataPoint.orderCount;
      }, 0)
    );
  }, 0);
};

export const calculateTotalRevenue = (dataPoints, date) => {
  return Object.values(dataPoints).reduce((total, brandDataPoints) => {
    return (
      total +
      brandDataPoints.reduce((brandTotal, dataPoint) => {
        if (date) {
          return dayjs(date).get('hour') > dayjs(dataPoint.from).get('hour')
            ? brandTotal + dataPoint.revenue
            : brandTotal;
        }
        return brandTotal + dataPoint.revenue;
      }, 0)
    );
  }, 0);
};

const MetricBlocks = ({ dataToday, dataLastWeek }) => {
  const [data, setData] = useState(dataToday);
  const [prevData, setPrevData] = useState(dataLastWeek);

  useEffect(() => {
    setData(dataToday);
    setPrevData(dataLastWeek);
  }, [dataToday, dataLastWeek]);

  if (!data.dataPoints || !prevData.dataPoints) {
    return 'No data';
  }

  const totalOrders = calculateTotalOrders(data.dataPoints);
  const totalPrevOrders = calculateTotalOrders(prevData.dataPoints, dayjs());

  const totalRevenue = calculateTotalRevenue(data.dataPoints);
  const totalPrevRevenue = calculateTotalRevenue(prevData.dataPoints, dayjs());

  const unitsTrend =
    totalPrevOrders === 0
      ? null
      : ((totalOrders - totalPrevOrders) / totalPrevOrders) * 100;
  const revenueTrend =
    totalPrevRevenue === 0
      ? null
      : ((totalRevenue - totalPrevRevenue) / totalPrevRevenue) * 100;


  const firstDataPoint = Object.values(prevData.dataPoints).flatMap(
    brandDataPoints => brandDataPoints,
  )[0];

  const prevDate = firstDataPoint
    ? dayjs(firstDataPoint.from).add(1, 'day').format('DD-MM')
    : dayjs().subtract(7, 'day').format('DD-MM');

  return (
    <Flex mb={8} flexWrap="wrap" style={{ gap: '2rem' }}>
      <div className="metric-block">
        <div className="metric-block__top">
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ gap: '1rem' }}
          >
            <span>{totalOrders}</span> orders
          </Flex>
          <Flex flexDirection="column">
            <Text
              size="Caption2"
              as="div"
              style={{ color: 'var(--color-grey-50)' }}
            >
              PREV
            </Text>
            <Text size="Caption2" as="div">
              {totalPrevOrders}
            </Text>
          </Flex>
        </div>

        <div className="metric-block__bottom">
          {unitsTrend && (
            <div>
              <Icon
                as={unitsTrend < 0 ? ArrowDown : ArrowUp}
                size="small"
                color={unitsTrend < 0 ? 'colorRedBase' : 'colorGreenBase'}
              />
              <strong
                style={{
                  color:
                    unitsTrend < 0
                      ? 'var(--color-red-base)'
                      : 'var(--color-green-base)',
                }}
              >
                {unitsTrend.toFixed(2)}%
              </strong>
            </div>
          )}
          <span>compared to {prevDate}</span>
        </div>
      </div>

      <div className="metric-block">
        <div className="metric-block__top">
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ gap: '1rem' }}
          >
            <span>€{totalRevenue.toLocaleString('nl', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </Flex>
          <Flex flexDirection="column">
            <Text
              size="Caption2"
              as="div"
              style={{ color: 'var(--color-grey-50)' }}
            >
              PREV
            </Text>
            <Text size="Caption2" as="div">
              €{totalPrevRevenue.toLocaleString('nl', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Text>
          </Flex>
        </div>

        <div className="metric-block__bottom">
          {revenueTrend && (
            <div>
              <Icon
                as={revenueTrend < 0 ? ArrowDown : ArrowUp}
                size="small"
                color={revenueTrend < 0 ? 'colorRedBase' : 'colorGreenBase'}
              />
              <strong
                style={{
                  color:
                    revenueTrend < 0
                      ? 'var(--color-red-base)'
                      : 'var(--color-green-base)',
                }}
              >
                {revenueTrend.toFixed(2)}%
              </strong>
            </div>
          )}
          <span>compared to {prevDate}</span>
        </div>
      </div>
    </Flex>
  );
};

export default MetricBlocks;