import React from 'react';

import { useProposals } from '~/services/proposal';

const ProposalsQuery = ({ filters, render }) => {
  const proposalsQuery = useProposals(filters);

  if (proposalsQuery.isError) {
    return <p>Error while loading proposals</p>;
  }

  if (proposalsQuery.isSuccess) {
    return render(proposalsQuery.data, proposalsQuery.isFetching);
  }

  return <p>Loading proposals..</p>;
};

export default ProposalsQuery;
