import {
  Box,
  Flex,
  IconButton,
  Select,
  Text,
} from '@mediahuis/chameleon-react';
import { ArrowDown, ArrowUp, Close } from '@mediahuis/chameleon-theme-wl/icons';
import React from 'react';

const PaymentOptionsSelect = ({
  disabled,
  label,
  options,
  selectedOptions,
  onChange,
}) => {
  function handleSelectChange(event) {
    event.preventDefault();
    onChange([...selectedOptions, event.target.value]);
    event.target.selectedIndex = 0;
  }

  function movePaymentOption(from, to) {
    const item = selectedOptions[from];

    selectedOptions.splice(from, 1);
    selectedOptions.splice(to, 0, item);

    onChange(selectedOptions);
  }

  return (
    <Flex flexDirection="column" style={{ gap: '0.25rem' }}>
      <Select
        disabled={disabled}
        id="PaymentOptionSelect"
        label={label}
        placeholder="Add payment option"
        onChange={handleSelectChange}
      >
        {options
          .filter(option => !selectedOptions.includes(option))
          .map(option => (
            <option key={option}>{option}</option>
          ))}
      </Select>

      <Box
        borderColor="colorGrey40"
        borderRadius={2}
        borderStyle="solid"
        borderWidth={1}
        display="flex"
        flexBasis="50%"
        flexDirection="column"
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((selectedOption, selectedOptionIndex) => (
            <Flex
              alignItems="center"
              key={selectedOption}
              justifyContent="space-between"
              pl={5}
              pr={2}
              py={2}
            >
              <Text>{selectedOption}</Text>

              <Flex style={{ gap: '0.1rem' }}>
                <IconButton
                  disabled={disabled}
                  icon={ArrowUp}
                  size="small"
                  onClick={event => {
                    event.preventDefault();
                    movePaymentOption(
                      selectedOptionIndex,
                      selectedOptionIndex - 1,
                    );
                  }}
                />
                <IconButton
                  disabled={disabled}
                  icon={ArrowDown}
                  size="small"
                  onClick={event => {
                    event.preventDefault();
                    movePaymentOption(
                      selectedOptionIndex,
                      selectedOptionIndex + 1,
                    );
                  }}
                />
                <IconButton
                  color="colorRed30"
                  disabled={disabled}
                  icon={Close}
                  size="small"
                  onClick={event => {
                    event.preventDefault();
                    onChange(
                      selectedOptions.filter(
                        option => option !== selectedOption,
                      ),
                    );
                  }}
                />
              </Flex>
            </Flex>
          ))
        ) : (
          <Flex alignItems="center" height={8} pl={5} pr={2} py={2}>
            <Text fontStyle="italic">No Payment options selected</Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default PaymentOptionsSelect;
