import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Box, Flex, IconButton, Text } from '@mediahuis/chameleon-react';
import {
  ChevronDoubleBack,
  ChevronDoubleForward,
} from '@mediahuis/chameleon-theme-wl/icons';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import ItemTypes from './ItemTypes';

const DraggableTransferList = ({
  options = [],
  titleLeft,
  titleRight,
  interval,
  parentHandler,
  editableItemOptions,
}) => {
  // BASED on https://react-dnd.github.io/react-dnd/docs/overview
  const [availableOptions, setAvailableOptions] = useState([]);
  const [chosenOptions, setChosenOptions] = useState([]);
  const [localInterval, setLocalInterval] = useState(interval);

  const { BOX, CARD } = ItemTypes;

  // Transform available options to have extra attributes
  useEffect(() => {
    const transformedOptions = options.map((option, index) => ({
      ...option,
      optionId: option.optionId,
      id: index,
      text: option.name,
      type: BOX,
    }));
    setAvailableOptions(transformedOptions);
  }, [options, BOX]);

  // Transform chosen options, to have extra attributes
  useEffect(() => {
    if (editableItemOptions) {
      const transformedOptions = editableItemOptions.map(
        (defaultChosenOption, index) => ({
          ...defaultChosenOption,
          optionId: defaultChosenOption.optionId,
          id: index + options.length,
          text: defaultChosenOption.name,
          type: CARD,
        }),
      );
      setChosenOptions(transformedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableItemOptions]);

  // Logic if interval changes
  useEffect(() => {
    if (interval && interval !== localInterval) {
      if (chosenOptions.length !== 0) {
        setChosenOptions([]);
      }
      setLocalInterval(interval);
    }
  }, [chosenOptions.length, interval, localInterval]);

  // Parent callback function
  useEffect(() => {
    parentHandler(chosenOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenOptions]);

  const handleAllRight = () => {
    setChosenOptions([]);
    setAvailableOptions([...availableOptions, ...chosenOptions]);
  };

  const handleAllLeft = () => {
    setAvailableOptions([]);
    setChosenOptions([...chosenOptions, ...availableOptions]);
  };

  return (
    <Flex justifyContent="space-between">
      <DndProvider backend={Backend}>
        <Box width="40%">
          <Text>{titleLeft}</Text>
          <LeftSide
            chosenOptions={chosenOptions}
            availableOptions={availableOptions}
            setAvailableOptions={setAvailableOptions}
            setChosenOptions={setChosenOptions}
          />
        </Box>
        <Flex
          width="15%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={2}>
            <IconButton
              onClick={handleAllRight}
              disabled={chosenOptions.length === 0}
              aria-label="move all right"
              icon={ChevronDoubleForward}
            />
          </Box>
          <Box>
            <IconButton
              onClick={handleAllLeft}
              disabled={availableOptions.length === 0}
              aria-label="move all left"
              icon={ChevronDoubleBack}
            />
          </Box>
        </Flex>
        <Box width="40%">
          <Text>{titleRight}</Text>
          <RightSide
            availableOptions={availableOptions}
            setAvailableOptions={setAvailableOptions}
            chosenOptions={chosenOptions}
            setChosenOptions={setChosenOptions}
          />
        </Box>
      </DndProvider>
    </Flex>
  );
};

export default DraggableTransferList;
