import { Heading } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureForm, notifyError, PageLoader } from '~/components';
import { secureApiCall } from '../../utils/';

const EditFeature = ({ featureId, onCancel, onSuccess }) => {
  const { i18n } = useTranslation();

  const [initialValues, setInitialValues] = useState();

  const apiUrl = `${API_OFFER_SERVICE}/Features/${featureId}`;

  useEffect(() => {
    secureApiCall(apiUrl, {
      method: 'GET',
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        setInitialValues(response.data);
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  }, [apiUrl, i18n.language]);

  if (!initialValues) {
    return <PageLoader />;
  }

  return (
    <>
      <Heading fontFamily="primary" level={4}>
        Edit feature
      </Heading>
      <FeatureForm
        editing
        apiUrl={apiUrl}
        initialValues={initialValues}
        onCancel={onCancel}
        onEditSuccess={onSuccess}
      />
    </>
  );
};

export default EditFeature;
