import { Button, Flex, Heading } from '@mediahuis/chameleon-react';
import React, { useState } from 'react';

import { HeaderWrapper } from '~/components';

import { REQUIREMENT_LABEL, REQUIREMENT_TYPE } from './enums';
import {
  getSubscriptionFormulaRequirements,
  getSubscriptionTypeRequirements,
} from './utils';

import { RequirementCreate } from './RequirementCreate';
import { RequirementsCollapse } from './RequirementsCollapse';

const getRequirementTypeOptions = (formulaRequirements, typeRequirements) => {
  return Object.values(REQUIREMENT_TYPE).map(requirementTypeValue => {
    const activeFormulaRequirement =
      formulaRequirements && formulaRequirements[requirementTypeValue];
    const activeTypeRequirement =
      typeRequirements && typeRequirements[requirementTypeValue];

    return {
      disabled: Array.isArray(activeFormulaRequirement || activeTypeRequirement)
        ? false
        : Boolean(activeFormulaRequirement || activeTypeRequirement),
      label: REQUIREMENT_LABEL[requirementTypeValue],
      value: requirementTypeValue,
    };
  });
};

const FormulaRequirements = ({ subscriptionFormula, onChange }) => {
  const [isCreating, setIsCreating] = useState(false);

  const subscriptionFormulaRequirements =
    getSubscriptionFormulaRequirements(subscriptionFormula);
  const subscriptionTypeRequirements =
    subscriptionFormula.subscriptionType &&
    getSubscriptionTypeRequirements(subscriptionFormula.subscriptionType);

  function handleCollapseChange(requirements) {
    onChange(
      Object.entries(requirements).reduce(
        (acc, [requirementKey, requirementValue]) => {
          acc[requirementKey] = requirementValue ? requirementValue : null;

          return acc;
        },
        {},
      ),
    );
  }

  function handleRequirementCreate(values, type) {
    const currentRequirement = subscriptionFormula[type];
    const valuesWithBrand = { brand: subscriptionFormula.brand, ...values };

    onChange({
      ...subscriptionFormula,
      [type]: Array.isArray(currentRequirement)
        ? [...currentRequirement, valuesWithBrand]
        : valuesWithBrand,
    });
    setIsCreating(false);
  }

  return (
    <Flex flexDirection="column" style={{ gap: '2rem' }}>
      <HeaderWrapper>
        <Heading fontFamily="primary" level={4}>
          Requirements
        </Heading>
      </HeaderWrapper>

      {subscriptionTypeRequirements && (
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Heading level={6}>Type</Heading>

          <RequirementsCollapse
            isEditable={false}
            requirements={subscriptionTypeRequirements}
            onChange={handleCollapseChange}
          />
        </Flex>
      )}

      <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
        <Flex alignItems="baseline" justifyContent="space-between">
          <Heading level={6}>Formula</Heading>

          <Button
            appearance="secondary"
            data-testid="add-button"
            disabled={isCreating}
            size="small"
            onClick={() => setIsCreating(true)}
          >
            Add
          </Button>
        </Flex>

        {isCreating && (
          <RequirementCreate
            requirementTypeOptions={getRequirementTypeOptions(
              subscriptionFormulaRequirements,
              subscriptionTypeRequirements,
            )}
            onCancel={() => setIsCreating(false)}
            onSubmit={handleRequirementCreate}
          />
        )}

        <RequirementsCollapse
          isEditable={true}
          requirements={subscriptionFormulaRequirements}
          onChange={handleCollapseChange}
        />
      </Flex>
    </Flex>
  );
};

export default FormulaRequirements;
