import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENT_KEY } from './useContent';

const usePatchContent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      ContentService.patchContent(body.id, body).then(
        response => response.data,
      ),
    {
      onSuccess: data => queryClient.invalidateQueries([CONTENT_KEY, data.id]),
    },
  );
};

export default usePatchContent;
