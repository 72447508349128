import React from 'react';
import { Flex, DatePicker } from '@mediahuis/chameleon-react';
import { noop } from '~/utils';
import { Search } from './index';

const TableFilters = ({ updateFilter = noop, filters = {} }) => (
  <Flex alignItems="flex-end" id="filters">
    {filters.dateFrom !== undefined && (
      <DatePicker
        mr={3}
        id="dateFrom"
        label="Date from"
        onChange={date => updateFilter('dateFrom', date)}
        value={filters.dateFrom}
        datatestid="startDate-input"
      />
    )}
    {filters.dateTo !== undefined && (
      <DatePicker
        mr={3}
        id="dateTo"
        label="Date to"
        onChange={date => updateFilter('dateTo', date)}
        value={filters.dateTo}
        datatestid="endDate-input"
      />
    )}
    {filters.search !== undefined && (
      <Search updateFilter={updateFilter} value={filters.search} />
    )}
  </Flex>
);

export default TableFilters;
