import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENT_LINKS_KEY } from './useContentLinks';

const usePatchContentLink = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      ContentService.patchContentLink(body.id, body).then(
        response => response.data,
      ),
    {
      onSuccess: mutationData => {
        queryClient.setQueryData(
          [CONTENT_LINKS_KEY, mutationData.brand, mutationData.key],
          cacheData => ({
            ...cacheData,
            contentLinks: {
              ...cacheData.contentLinks,
              [mutationData.location]: cacheData.contentLinks[
                mutationData.location
              ].map(cacheItem => {
                if (cacheItem.id === mutationData.id) {
                  return {
                    ...cacheItem,
                    ...mutationData,
                  };
                }

                return cacheItem;
              }),
            },
          }),
        );
      },
    },
  );
};

export default usePatchContentLink;
