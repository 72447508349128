import { getI18n } from 'react-i18next';
import secureApiCall from './secureApiCall';

const getCountryCodes = () => {
  const { language } = getI18n();

  return new Promise((resolve, reject) => {
    secureApiCall(`${API_ADDRESS_SERVICE}/Countries/Language/${language}`, {
      method: 'GET',
      onSuccess: response => resolve(response.data),
      onError: () => reject(),
    });
  });
};

export default getCountryCodes;
