import { Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const columns = [
  { dataIndex: 'id', title: 'ID' },
  {
    dataIndex: 'key',
    title: 'Key',
    render: key => {
      if (
        typeof key === 'string' &&
        key.indexOf(HTML_CONTENT_LOCATIONS.OFFER) !== -1
      ) {
        const offerId = key.replace(`${HTML_CONTENT_LOCATIONS.OFFER}_`, '');

        return <Link to={`/offers/edit/${offerId}`}>{key}</Link>;
      }

      return key;
    },
  },
  { dataIndex: 'location', title: 'Location' },
  { dataIndex: 'position', title: 'Position' },
];

const ContentsDetailLinks = ({ contentLinks }) => {
  return (
    <Table
      columns={columns}
      dataSource={contentLinks}
      rowKey={record => record.id}
    />
  );
};

export default ContentsDetailLinks;
