const getImageSrc = image => {
  if (typeof image === 'string') {
    if (image.startsWith('http') || image.startsWith('data:image')) {
      return image;
    }
    return `${CDN_PATH}/${image.replace(/\\/g, '/')}`;
  }
  return '';
};

export default getImageSrc;
