import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import { TextField } from '@mediahuis/chameleon-react';
import ErrorMessage from '../ErrorMessage';
import { MarkDownBlock } from './MarkDownPreview';

const MarkDownTextField = (props, ref) => {
  const {
    id,
    name,
    label,
    value,
    errors,
    onChange,
    datatestid,
    type = 'text',
    required = false,
    disabled = false,
    multiline = true,
    defaultValue = '',
    optionalLabel = null,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [source, setSource] = useState(defaultValue);

  const handleChange = e => {
    setSource(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setSource(defaultValue);
  }, [defaultValue]);

  return (
    <Fragment>
      <TextField
        id={id}
        type={type}
        name={name}
        label={label}
        value={value}
        inputRef={ref}
        required={required}
        disabled={disabled}
        multiline={multiline}
        onChange={handleChange}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'data-testid': datatestid }}
        optionalLabel={optionalLabel}
      />
      <MarkDownBlock source={source} isFocused={isFocused} />
      {errors && errors[name] && (
        <ErrorMessage>{errors[name].message}</ErrorMessage>
      )}
    </Fragment>
  );
};

export default forwardRef(MarkDownTextField);
