import { Flex } from '@mediahuis/chameleon-react';
import React, { useRef, useState } from 'react';

import OfferGroupForm from './OfferGroupForm';
import OfferGroupsCollapseControls from './OfferGroupsCollapseControls';

const OfferGroupsCollapsePanel = ({
  isEditable,
  group,
  offer,
  onDelete,
  onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const formRef = useRef(null);

  return (
    <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
      <OfferGroupForm
        disabled={!isEditing}
        formRef={formRef}
        initialValues={{
          defaultSelected: group?.defaultSelected || false,
          description: group?.description || '',
          items: group?.items || [],
          label: group?.label || '',
          title: group?.title || '',
        }}
        offer={offer}
        onSubmit={newGroup => {
          setIsEditing(false);
          onSubmit(newGroup, group.id);
        }}
      />

      {isEditable && (
        <OfferGroupsCollapseControls
          isEditing={isEditing}
          onDelete={onDelete}
          onEdit={() => setIsEditing(true)}
          onSave={() => formRef.current.submitForm()}
        />
      )}
    </Flex>
  );
};

export default OfferGroupsCollapsePanel;
