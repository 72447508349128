import React, { useState } from 'react';

import { DetailList } from '~/components';

import AddFeature from './AddFeature';
import EditFeature from './EditFeature';
import FeaturesOverview from './FeaturesOverview';

const Features = ({ brand, features, onDelete, onSelect }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [editFeature, setEditFeature] = useState();

  if (isCreating) {
    return (
      <AddFeature
        selectedFeatures={features}
        brand={brand}
        showMultiSelect={() => setIsCreating(false)}
        handleSelectFeature={onSelect}
      />
    );
  }

  if (editFeature) {
    return (
      <EditFeature
        featureId={editFeature.id}
        onCancel={() => setEditFeature(undefined)}
        onSuccess={updatedFeature => {
          setEditFeature(undefined);
          onSelect(
            features.map(feature =>
              feature.id === updatedFeature.id ? updatedFeature : feature,
            ),
          );
        }}
      />
    );
  }

  return (
    <>
      <FeaturesOverview
        brand={brand}
        selectedFeatures={features}
        showInputForm={() => setIsCreating(true)}
        handleSelectFeature={onSelect}
      />
      <DetailList
        initialValues={features}
        type="features"
        handleDelete={onDelete}
        handleEdit={item => setEditFeature(item)}
      />
    </>
  );
};

export default Features;
