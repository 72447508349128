import { Table } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { formatBrand } from '~/utils/columns';

const columns = [
  { dataIndex: 'brand', title: 'Brand', render: formatBrand },
  {
    id: 'subscriptionType',
    key: 'subscriptionType',
    title: 'SubscriptionType',
    dataIndex: 'subscriptionType',
    width: '45%',
  },

  {
    id: 'scopes',
    key: 'scopes',
    title: 'Criteria',
    dataIndex: 'scopes',
    render: scopes => scopes.join(', '),
    width: '45%',
  },
];

const SubscriptionScopesTable = ({ isLoading, subscriptionScopes }) => {
  const history = useHistory();

  const tableData = subscriptionScopes.results;

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(
            `${history.location.pathname}/edit/${record.subscriptionType}/${record.brand}`,
          ),
      })}
    />
  );
};

export default SubscriptionScopesTable;
