import { Button, Flex, Text } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import LanguageDropdown from '~/components/LanguageDropdown';
import { useQueryParams } from '~/hooks';
import { FeaturesQuery } from '~/services/offer';

import FeaturesFilters from './FeaturesFilters';
import FeaturesTable from './FeaturesTable';

const defaultOffset = 0;
const defaultOffsetSize = 20;

const FeaturesPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [params, setParams] = useQueryParams();

  function resetFilters() {
    setParams({
      offset: defaultOffset,
    });
  }

  if (!params.offset) {
    return <Redirect to={`${pathname}?offset=${defaultOffset}`} />;
  }

  return (
    <FeaturesQuery
      filters={params}
      render={(features, isFetchingFeatures) => (
        <Flex
          justifyContent="space-between"
          px={5}
          style={{ gap: '2.5rem' }}
          width="full"
        >
          <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Total: {features.totalCount}</Text>

              <Pagination
                current={params.offset / defaultOffsetSize + 1}
                pageSize={defaultOffsetSize}
                showSizeChanger={false}
                total={features.totalCount}
                onChange={page =>
                  setParams({
                    ...params,
                    offset: (page - 1) * defaultOffsetSize,
                  })
                }
              />

              <Flex alignItems="center" style={{ gap: '5px' }}>
                <Button
                  appearance="primary"
                  data-testid="features-create-btn"
                  onClick={() =>
                    history.push(`${history.location.pathname}/create`)
                  }
                  size="small"
                >
                  Create
                </Button>
                <LanguageDropdown
                  onChange={() => queryClient.invalidateQueries()}
                />
              </Flex>
            </Flex>

            <FeaturesTable isLoading={isFetchingFeatures} features={features} />
          </Flex>

          <FeaturesFilters onReset={resetFilters} />
        </Flex>
      )}
    />
  );
};

export default FeaturesPage;
