import {
  Banner,
  Box,
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Heading,
  Icon,
  LinkText,
  Paper,
  Select,
  Text,
  TextField,
} from '@mediahuis/chameleon-react';
import { ExternalLink } from '@mediahuis/chameleon-theme-wl/icons';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  FormButtons,
  FormConfig,
  Link,
  notifyError,
  notifySuccess,
  notifyWarning,
  Sidebar,
  StickyBottom,
  StickyTop,
  TextFieldWithMarkDown,
  useAboshopContext,
} from '~/components';
import { HtmlContentTransfer } from '~/components/HtmlContentTransfer';
import { FormulaRequirements } from '~/components/Requirements';
import {
  CONTENT_LINKS_KEY,
  useAllContents,
  useCreateContentLink,
  useDeleteContentLink,
  usePatchContentLink,
} from '~/services/content';
import {
  getInProgressMessage,
  getPastedValues,
  removeDeprecatedRequirementKeys,
  secureApiCall,
} from '~/utils';
import { CLIPBOARD } from '~/utils/getClipBoard';

import LanguageSelect from '../../../components/LanguageSelect';
import {
  durationTypeOptions,
  durationTypeSelectDefaultOption,
  INCENTIVE_PRICE_TYPES,
  PRICE_TYPE,
  priceTypeOptions,
} from '../data/options';
import validateFormula from '../validation/validateFormula';
import VouchersDialog from './VouchersDialog';

const FormulaForm = ({
  apiUrl,
  editing = false,
  initialValues,
  deleteMethod,
  duplicateMethod,
}) => {
  const { clipBoard, setGlobalState, subscriptionService } =
    useAboshopContext();
  const history = useHistory();
  const queryClient = useQueryClient();

  const defaultValues = {
    durationType: durationTypeSelectDefaultOption,
    priceType: priceTypeOptions[0].value,
    duration: 0,
    features: [],
    lumpSum: null,
    isFixedTerm: false,
    isMonthlyCancellable: false,
    incentiveDescription: '',
    costCenter: '',
    projectedLifetimeValue: 0,
    ...initialValues,
    startDate: initialValues.startDate
      ? new Date(initialValues.startDate)
      : new Date(),
    endDate: initialValues.endDate
      ? new Date(initialValues.endDate)
      : new Date('12/31/2999'),
  };
  const hasType = initialValues?.subscriptionType;

  const [errors, setErrors] = useState({});
  const [formula, setFormula] = useState(defaultValues);
  const [htmlContentKeys, setHtmlContentKeys] = useState({});
  const [inProgress, setInProgress] = useState([]);
  const [isLoadingButtons, setIsLoadingButtons] = useState(false);
  const [showBanner, setShowBanner] = useState(!hasType);
  const [showVouchersDialog, setShowVouchersDialog] = useState(false);

  const allContentsQuery = useAllContents(
    {
      brand: formula.brand,
      excludedKeys: HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE,
    },
    !!formula.id,
  );

  const createContentLink = useCreateContentLink();
  const deleteContentLink = useDeleteContentLink();
  const patchContentLink = usePatchContentLink();

  const contentKey =
    formula.id && `${HTML_CONTENT_LOCATIONS.FORMULA}_${formula.id}`;
  const transferDataSource = allContentsQuery.isSuccess
    ? allContentsQuery.data.list.map(htmlContent => ({
      brand: htmlContent.brand,
      id: htmlContent.id,
      key: htmlContent.id,
      name: htmlContent.name,
    }))
    : [];

  // Overwrite state if parent gives new data.
  // E.g. when fetching data in new language
  useEffect(() => {
    setFormula(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // In case user tries to go second step in creating formula wizard
  // without having chosen a subscription type.
  useEffect(() => {
    if (!editing && !hasType) {
      notifyError({
        status: '',
        message: 'Selected product has no type associated with it.',
      });
      history.push('/subscriptionformula');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      createContentLink.isError ||
      deleteContentLink.isError ||
      patchContentLink.isError
    ) {
      notifyError({
        message:
          'An error was encountered while updating the locations. Please refresh the page to see the current state and try again.',
      });
    }
  }, [
    createContentLink.isError,
    deleteContentLink.isError,
    patchContentLink.isError,
  ]);

  const clearError = event => {
    const spreadedErrors = { ...errors };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const copyMethod = () => {
    localStorage.setItem(
      CLIPBOARD,
      JSON.stringify({ id: formula.id, type: 'formula' }),
    );
    setGlobalState(prev => ({
      ...prev,
      clipBoard: { ...formula, type: 'formula' },
    }));
    history.push('/subscriptionformula');
  };

  const pasteMethod = () => {
    const pastedValues = getPastedValues(clipBoard, initialValues);
    const pastedValuesWithOriginalExternalReference = {
      ...pastedValues,
      externalReference: formula.externalReference,
    };
    setFormula(pastedValuesWithOriginalExternalReference);
  };

  const handlePriceTypeSelect = event => {
    const newFormula = { ...formula };
    const newPriceType = event.target.value;

    // if true, remove incentive-related properties
    if (!INCENTIVE_PRICE_TYPES.includes(newPriceType)) {
      delete newFormula.lumpSum;
      delete newFormula.isFixedTerm;
      delete newFormula.incentiveDescription;
    }

    setFormula({ ...newFormula, priceType: newPriceType });
  };

  const updateHtmlContent = () => {
    const queryData = queryClient.getQueryData([
      CONTENT_LINKS_KEY,
      formula.brand,
      `${HTML_CONTENT_LOCATIONS.FORMULA}_${formula.id}`,
    ]);
    const contentLinks = queryData.contentLinks || {};

    Object.keys(htmlContentKeys).forEach(location => {
      const currentKeys = htmlContentKeys[location];
      const locationContentLinks = contentLinks[location] || [];

      if (locationContentLinks.length > currentKeys.length) {
        const removedLinks = locationContentLinks.filter(
          link => !currentKeys.includes(link.contentId),
        );

        if (removedLinks.length > 0) {
          removedLinks.forEach(removedLink => {
            deleteContentLink.mutate({
              brand: formula.brand,
              contentKey,
              id: removedLink.id,
              location,
            });
          });
        }
      }

      currentKeys.forEach((currentKey, currentKeyIndex) => {
        const contentItem = transferDataSource.find(
          item => item.key === currentKey,
        );
        const contentLinkItem = locationContentLinks.find(
          item => item.contentId === currentKey,
        );

        if (contentItem && contentLinkItem) {
          patchContentLink.mutate({
            brand: formula.brand,
            contentId: contentItem.id,
            id: contentLinkItem.id,
            key: contentKey,
            location,
            position: currentKeyIndex + 1,
          });
        } else if (contentItem) {
          createContentLink.mutate({
            brand: formula.brand,
            contentId: contentItem.id,
            key: contentKey,
            location,
            position: currentKeyIndex + 1,
          });
        }
      });
    });
  };

  const onBlur = event => {
    event.persist();
    setFormula(prevState => ({
      ...prevState,
      [event.target.name]:
        event.target.type === 'number'
          ? Number(event.target.value)
          : event.target.value,
    }));
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    if (!isEmpty(inProgress)) {
      return notifyWarning({ message: getInProgressMessage(inProgress) });
    }

    setInProgress([]);

    const formulaErrors = validateFormula({ ...formula });
    if (!isEmpty(formulaErrors)) return setErrors(formulaErrors);

    setErrors({});
    setIsLoadingButtons(true);

    const formData = { ...formula };

    if (
      formula.priceType === PRICE_TYPE.VOUCHER_REDEMPTION &&
      !formula.voucherRequirement
    ) {
      formData.voucherRequirement = {
        name: 'Voucher',
        description: 'Gelieve je vouchercode in te vullen.',
      };
      notifyWarning({ message: 'Voucher requirement was added' });
    }

    if (
      INCENTIVE_PRICE_TYPES.includes(formData.priceType) &&
      formData.isFixedTerm
    ) {
      formData.lumpSum =
        formData.lumpSum ?? parseFloat(formData.lumpSum?.replace(',', '.'));
    }

    if (editing) {
      updateHtmlContent();
    }

    secureApiCall(apiUrl, {
      method: editing ? 'PUT' : 'POST',
      data: JSON.stringify({
        ...formData,
        endDate: formula.endDate || new Date('12/31/2999'),
      }),
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        const responseData = removeDeprecatedRequirementKeys(response.data);

        setIsLoadingButtons(false);
        if (!editing) {
          history.push(`/subscriptionformula/edit/${responseData.id}`);
        }

        /**
         * Save response data to local state because ID's of these objects needs to be saved in case of new items
         */
        setFormula({
          ...responseData,
          endDate: responseData.endDate
            ? new Date(responseData.endDate)
            : defaultValues.endDate,
          startDate: responseData.startDate
            ? new Date(responseData.startDate)
            : defaultValues.startDate,
        });

        notifySuccess({
          message: `Successfully ${editing ? 'edited' : 'created '} a formula`,
        });
      },
      onError: errorMessage => {
        setIsLoadingButtons(false);
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const preview = () => {
    window.open(
      `https://${API_PREFIX}aboshop.${BRAND_SITE_URLS[formula?.brand?.toUpperCase()]
      }/checkout?formula_id=${formula?.id}`,
      '_blank',
    );
  };

  return (
    <Flex justifyContent="center">
      <Box width="600px">
        <Paper p={6} mb={8}>
          <Banner mb={6} show={showBanner} onClose={() => setShowBanner(false)}>
            This product does not have a type.
          </Banner>
          <Heading fontFamily="primary" level={4}>
            Product
          </Heading>
          <Flex mb={4}>
            <TextField
              id="reference"
              datatest-id="reference-input"
              label="Reference (internal)"
              name="reference"
              error={!!errors.reference}
              message={errors.reference}
              onBlur={onBlur}
              defaultValue={formula.reference}
              width="full"
              mr={4}
              onFocus={clearError}
              key={formula.reference}
            />
            <TextField
              disabled={
                !(
                  subscriptionService ||
                  MH_BRAND === BRANDS.LUX ||
                  MH_BRAND === BRANDS.NL
                )
              }
              id="externalReference"
              data-testid="externalReference-input"
              label="Reference (external)"
              name="externalReference"
              onBlur={onBlur}
              defaultValue={formula.externalReference}
              width="full"
              key={formula.externalReference}
            />
          </Flex>
          <Box mb={4}>
            <TextFieldWithMarkDown
              id="title"
              label="Title"
              name="title"
              error={!!errors.title}
              message={errors.title}
              datatestid="title-input"
              onBlur={onBlur}
              onFocus={clearError}
              defaultValue={formula.title}
              key={formula.title}
            />
          </Box>
          <Box mb={4}>
            <TextFieldWithMarkDown
              id="description"
              datatestid="description-input"
              label="Description"
              name="description"
              error={!!errors.description}
              message={errors.description}
              onBlur={onBlur}
              onFocus={clearError}
              defaultValue={formula.description}
              key={formula.description}
            />
          </Box>
          <Box mb={4}>
            <TextFieldWithMarkDown
              id="extra"
              datatestid="extra-input"
              label="Extra"
              name="extra"
              error={!!errors.extra}
              message={errors.extra}
              onBlur={onBlur}
              onFocus={clearError}
              defaultValue={formula.extra}
              key={formula.extra}
            />
          </Box>
          <Flex mb={4}>
            <Box width="full">
              <DatePicker
                id="startdate"
                data-testid="startDate-input"
                label="Start Date"
                name="startDate"
                error={!!errors.startDate}
                message={errors.startDate}
                value={formula.startDate}
                onChange={date =>
                  setFormula(prevState => ({ ...prevState, startDate: date }))
                }
                onFocus={clearError}
                mr={4}
              />
            </Box>
            <Box width="full">
              <DatePicker
                id="enddate"
                data-testid="endDate-input"
                label="End Date"
                name="endDate"
                error={!!errors.endDate}
                message={errors.endDate}
                onChange={date =>
                  setFormula(prevState => ({ ...prevState, endDate: date }))
                }
                onFocus={clearError}
                value={formula.endDate}
              />
            </Box>
          </Flex>
          <Box mb={4}>
            <TextFieldWithMarkDown
              id="priceSentence"
              datatestid="priceSentence-input"
              label="Price Description"
              name="priceSentence"
              error={!!errors.priceSentence}
              message={errors.priceSentence}
              onBlur={onBlur}
              onFocus={clearError}
              defaultValue={formula.priceSentence}
              key={formula.priceSentence}
            />
          </Box>
          <Box mb={4}>
            <TextFieldWithMarkDown
              id="priceExtra"
              datatestid="priceExtra-input"
              label="Price Extra"
              name="priceExtra"
              error={!!errors.priceExtra}
              message={errors.priceExtra}
              onBlur={onBlur}
              onFocus={clearError}
              defaultValue={formula.priceExtra}
              key={formula.priceExtra}
            />
          </Box>
          <Flex mb={4}>
            <Box width="50%" mr={4}>
              <Select
                id="priceType"
                data-testid="priceType-input"
                name="priceType"
                label="Price Type"
                onChange={handlePriceTypeSelect}
                value={formula.priceType}
              >
                {priceTypeOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
            {INCENTIVE_PRICE_TYPES.includes(formula.priceType) && (
              <Box width="50%">
                <TextField
                  id="incentiveDescription"
                  data-testid="incentiveDescription-input"
                  label="Incentive"
                  name="incentiveDescription"
                  error={!!errors.incentiveDescription}
                  message={errors.incentiveDescription}
                  onBlur={onBlur}
                  onFocus={clearError}
                  defaultValue={formula.incentiveDescription}
                  key={formula.incentiveDescription}
                />
              </Box>
            )}
            {formula.priceType === PRICE_TYPE.FREE && (
              <Box width="50%">
                <TextField
                  id="costCenter"
                  data-testid="costCenter-input"
                  label="Cost center"
                  name="costCenter"
                  error={!!errors.costCenter}
                  message={errors.costCenter}
                  onBlur={onBlur}
                  onFocus={clearError}
                  defaultValue={formula.costCenter}
                  key={formula.costCenter}
                />
              </Box>
            )}
            {formula.priceType === PRICE_TYPE.VOUCHER_PURCHASE && editing && (
              <Box width="50%">
                <TextField
                  action={
                    formula.voucherFormulaId && (
                      <Link
                        to={`/subscriptionformula/edit/${formula.voucherFormulaId}`}
                      >
                        <Icon as={ExternalLink} size="small" />
                      </Link>
                    )
                  }
                  data-testid="voucherFormulaId-input"
                  defaultValue={formula.voucherFormulaId}
                  disabled={!!formula.voucherFormulaId}
                  error={!!errors.voucherFormulaId}
                  id="voucherFormulaId"
                  key={formula.voucherFormulaId}
                  label="Redemption Id"
                  message={errors.voucherFormulaId}
                  name="voucherFormulaId"
                  type="number"
                  onBlur={onBlur}
                  onFocus={clearError}
                />
              </Box>
            )}
            {formula.priceType === PRICE_TYPE.VOUCHER_REDEMPTION && editing && (
              <Box display="flex" width="50%" alignItems="flex-end">
                <Button
                  alignSelf="flex-end"
                  appearance="secondary"
                  data-testid="generateVouchers-button"
                  width="full"
                  onClick={() => setShowVouchersDialog(true)}
                >
                  Generate vouchers
                </Button>

                <VouchersDialog
                  formula={formula}
                  show={showVouchersDialog}
                  onClose={() => setShowVouchersDialog(false)}
                  onCreate={() => setShowVouchersDialog(false)}
                />
              </Box>
            )}
          </Flex>
          {INCENTIVE_PRICE_TYPES.includes(formula.priceType) && (
            <Flex mb={4}>
              <Box width="50%" mr={4}>
                <Checkbox
                  id="isFixedTerm"
                  data-testid="isFixedTerm-checkbox"
                  name="isFixedTerm"
                  label="Fixed Term"
                  onChange={event => {
                    const checked = event.target.checked;
                    setFormula(prevState => ({ ...prevState, isFixedTerm: checked }))
                  }}
                  checked={formula.isFixedTerm}
                />
              </Box>
              {formula.isFixedTerm && (
                <TextField
                  id="lumpSum"
                  datatestid="lumpSum-input"
                  label="Lump Sum"
                  name="lumpSum"
                  defaultValue={formula.lumpSum}
                  key={formula.lumpSum}
                  error={!!errors.lumpSum}
                  message={errors.lumpSum}
                  onBlur={onBlur}
                  onFocus={clearError}
                  width="50%"
                />
              )}
            </Flex>
          )}
          <Box mb={4}>
            <TextField
              datatestid="projectedLifetimeValue-input"
              defaultValue={formula.projectedLifetimeValue}
              error={!!errors.projectedLifetimeValue}
              id="projectedLifetimeValue"
              key={formula.projectedLifetimeValue}
              label="Projected Lifetime Value"
              message={errors.projectedLifetimeValue}
              name="projectedLifetimeValue"
              type="number"
              onBlur={onBlur}
              onFocus={clearError}
            />
          </Box>
          <Flex mb={4}>
            <TextField
              data-testid="duration-input"
              defaultValue={formula.duration}
              error={!!errors.duration}
              id="duration"
              key={formula.duration}
              label="Duration"
              message={errors.duration}
              name="duration"
              type="number"
              width="full"
              onBlur={onBlur}
              onFocus={clearError}
            />

            <Box width="full" ml={4}>
              <Select
                id="durationType"
                data-testid="duration-input"
                name="durationType"
                onChange={onBlur}
                value={formula.durationType}
                label="Duration Type"
              >
                {durationTypeOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Box>
            <Checkbox
              id="isMonthlyCancellable"
              data-testid="isMonthlyCancellable-checkbox"
              name="isMonthlyCancellable"
              label="Can be canceled monthly"
              onChange={event => {
                const checked = event.target.checked;
                setFormula(prevState => ({ ...prevState, isMonthlyCancellable: checked }))
              }}
              checked={formula.isMonthlyCancellable}
            />
          </Box>
        </Paper>
        <Paper p={6} mb={8}>
          <FormulaRequirements
            subscriptionFormula={formula}
            onChange={requirements => {
              setFormula(prevFormula => ({
                ...prevFormula,
                ...requirements,
              }));
            }}
          />
        </Paper>
        {contentKey && transferDataSource && (
          <Box mt={5} mb={5}>
            <Paper>
              <Box p={7} mb={2} data-testid="html-content-block">
                <HtmlContentTransfer
                  brand={formula.brand}
                  contentKey={contentKey}
                  dataSource={transferDataSource}
                  locationOptions={[
                    {
                      label: 'Bottom',
                      value: 'bottom',
                    },
                  ]}
                  targetKeys={htmlContentKeys}
                  title="HTML Content"
                  onChange={setHtmlContentKeys}
                />
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      <Sidebar>
        <StickyTop>
          <LanguageSelect
            options={POSSIBLE_LANGUAGES[formula.brand]}
            isEditing={editing}
            brand={formula?.brand}
          />
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Id</strong>: {initialValues.id}
            </Text>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Brand</strong>: {initialValues.brand}
            </Text>
            {hasType && (
              <Text size="Caption2" as="p">
                <strong>Type Id:</strong>{' '}
                <LinkText
                  href={`/subscriptiontype/edit/${initialValues.subscriptionType.id}`}
                  target="_blank"
                >
                  {initialValues.subscriptionType.id}
                </LinkText>
              </Text>
            )}
            {formula.voucherFormulaId &&
              formula.priceType === PRICE_TYPE.VOUCHER_PURCHASE && (
                <Text size="Caption2" as="p">
                  <strong>Voucher Redeem Id:</strong>{' '}
                  <LinkText
                    href={`/subscriptionformula/edit/${formula.voucherFormulaId}`}
                    target="_blank"
                  >
                    {formula.voucherFormulaId}
                  </LinkText>
                </Text>
              )}
            {formula.purchasingFormulaId &&
              formula.priceType === PRICE_TYPE.VOUCHER_REDEMPTION && (
                <Text size="Caption2" as="p">
                  <strong>Voucher Purchase Id:</strong>{' '}
                  <LinkText
                    href={`/subscriptionformula/edit/${formula.purchasingFormulaId}`}
                    target="_blank"
                  >
                    {formula.purchasingFormulaId}
                  </LinkText>
                </Text>
              )}
          </Paper>
          <FormButtons
            editing={editing}
            isLoading={isLoadingButtons}
            copyMethod={copyMethod}
            pasteMethod={pasteMethod}
            deleteMethod={deleteMethod}
            duplicateMethod={duplicateMethod}
            isDeletable={deleteMethod && !subscriptionService}
            isPastable={clipBoard && clipBoard.type === 'formula'}
            isPreviewable={true}
            isDuplicable={duplicateMethod && !subscriptionService}
            preview={preview}
            onSave={onSubmit}
          />
        </StickyTop>
        <StickyBottom>
          <FormConfig />
        </StickyBottom>
      </Sidebar>
    </Flex>
  );
};

export default FormulaForm;
