import { Box, Button, Flex, Select } from '@mediahuis/chameleon-react';
import { ArrowBack } from '@mediahuis/chameleon-theme-wl/icons';
import { Table } from 'antd';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { TableFilters, useAboshopContext } from '~/components';
import { TABLE_STATES } from '~/constants';
import { useTableState } from '~/hooks';
import { exportOrders, getPaginatedItems } from '~/utils';
import { mapColumns } from '~/utils/columns';

const Wrapper = styled(Box)`
  max-width: 1200px;
  isolation: isolate;
  ${props =>
    props.isReady &&
    `
    & .ant-table-pagination {
      position: sticky;
      z-index: 3;
      top: 166px;
      margin: 0;
      padding: 16px 0;
      background: #ffffff;
    }
  `}
  ${props =>
    !props.showFilters &&
    `
    & .ant-table-pagination {
      top: 124px;
    }
  `}
`;

const OverviewTable = ({
  id = '',
  url = '',
  columns = [],
  mapData,
  onRowClick,
  showFilters = true,
  showBackButton = false,
  showCreateButton = true,
  showExportButton = false,
  brandParam = false,
  showLangToggle = true,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { brands, subscriptionService } = useAboshopContext();
  const { i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const DEFAULT_STATE = TABLE_STATES[id];
  const [table, setTable] = useTableState(id);
  const { filters, pagination } = table;

  const mappedColumns = useMemo(
    () => mapColumns({ brands, columns, filters }),
    [brands, columns, filters],
  );

  const changeLanguage = e => {
    const lang = e.target.value;
    i18n?.changeLanguage(lang);
  };

  const getPaginatedTableItems = ({
    newFilters = filters,
    newPagination = pagination,
  } = {}) => {
    setData([]);
    setIsLoading(true);
    getPaginatedItems({ url, filters: newFilters, pagination: newPagination })
      .then(response => {
        let newData = response;
        if (response.results) {
          newData = mapData ? mapData(response) : response.results;
        }
        if (newData && !(newData instanceof Array)) {
          newData = { results: [newData] };
        }
        setData(newData);
        setTable({
          filters: newFilters,
          pagination: { ...newPagination, total: response.totalCount },
        });
        setIsLoading(false);

        return response;
      })
      .catch(() => setIsLoading(false));
  };

  const getPaginatedItemsWithDebounce = useRef(
    debounce(newValue => {
      getPaginatedTableItems(newValue);
    }, 500),
  ).current;

  const updateFilter = (name, filter) => {
    const newState = {
      filters: { ...filters, [name]: filter },
      pagination: DEFAULT_STATE.pagination,
    };
    setTable(newState);
    getPaginatedItemsWithDebounce({
      newFilters: newState.filters,
      newPagination: newState.pagination,
    });
  };

  const resetFilters = () => {
    setTable(DEFAULT_STATE);
    getPaginatedTableItems({
      newFilters: DEFAULT_STATE.filters,
      newPagination: DEFAULT_STATE.pagination,
    });
  };

  const handleRowChange = record => ({
    onClick: () => {
      if (onRowClick) {
        onRowClick(record);
      } else {
        history.push(
          `${location.pathname}/edit/${
            record.id || encodeURIComponent(record.subscriptionType)
          }${brandParam ? `/${record.brand}` : ''}`,
        );
      }
    },
  });

  const handleTableChange = (newPagination, newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    const updatedPagination = { ...pagination, ...newPagination };

    setTable({ filters: updatedFilters, pagination: updatedPagination });

    getPaginatedTableItems({
      newFilters: updatedFilters,
      newPagination: updatedPagination,
    });
  };

  useEffect(() => {
    getPaginatedTableItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Wrapper isReady={!isLoading} showFilters={showFilters}>
      <Flex
        py={5}
        alignItems="flex-end"
        justifyContent="space-between"
        style={{
          background: '#ffffff',
          position: 'sticky',
          zIndex: 4,
          top: '64px',
        }}
      >
        {showFilters && (
          <TableFilters updateFilter={updateFilter} filters={filters} />
        )}
        {showLangToggle && (
          <Box ml={4}>
            <Select
              id="language"
              value={i18n?.languages[0]}
              onChange={changeLanguage}
              label="Language"
            >
              {ALL_POSSIBLE_LANGUAGES.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
        )}
        <Flex
          flexDirection="row"
          alignItems="flex-end"
          style={{
            whiteSpace: 'nowrap',
            flexGrow: 1,
            flexFlow: 'column',
          }}
          ml={3}
        >
          <Box width="full" />
          {showFilters && (
            <Button
              mb={3}
              size="small"
              appearance="plain"
              onClick={resetFilters}
              data-testid="reset-filters-button"
            >
              Reset filters
            </Button>
          )}
          {showBackButton && (
            <Button
              ml={2}
              size="small"
              appearance="outline"
              iconLeft={ArrowBack}
              data-testid="back-button"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          )}
          {showCreateButton && !subscriptionService && (
            <Button
              size="small"
              appearance="primary"
              data-testid="create-item-button"
              onClick={() => history.push(`${location.pathname}/create`)}
            >
              Create
            </Button>
          )}
          {showExportButton && (
            <Button
              ml={2}
              size="small"
              appearance="primary"
              data-testid="download-orders-button"
              onClick={() => exportOrders({ filters })}
            >
              Download
            </Button>
          )}
        </Flex>
      </Flex>
      <Table
        columns={mappedColumns}
        dataSource={data}
        loading={isLoading}
        pagination={pagination}
        onRow={handleRowChange}
        onChange={handleTableChange}
        rowKey={record => record?.id}
        className="overview-table"
        rowClassName={() => 'pointer'}
        data-testid="overview-table"
        sticky={{ offsetHeader: showFilters ? 229 : 182 }}
      />
    </Wrapper>
  );
};

export default OverviewTable;
