import { Box, Flex, Icon, Text } from '@mediahuis/chameleon-react';
import { ChevronBack, ChevronDown } from '@mediahuis/chameleon-theme-wl/icons';
import React, { useState } from 'react';

const NavigationCollapse = ({ active, children, testId, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(!active);

  return (
    <Box>
      <Flex
        alignItems="center"
        bgColor={active ? 'colorGrey10' : undefined}
        className="navigation-collapse"
        data-testid={testId}
        justifyContent="space-between"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Text size="Caption1" style={{ margin: '1rem 2rem' }}>
          {title}
        </Text>
        <Icon
          as={isCollapsed ? ChevronBack : ChevronDown}
          size="small"
          style={{ margin: '0 1rem' }}
        />
      </Flex>

      {!isCollapsed && children}
    </Box>
  );
};

export default NavigationCollapse;
