import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENT_LINKS_KEY } from './useContentLinks';

const useDeleteContentLink = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }) =>
      ContentService.deleteContentLink(id).then(response => response.data),
    {
      onSuccess: (_mutationData, { brand, contentKey, id, location }) => {
        queryClient.setQueryData(
          [CONTENT_LINKS_KEY, brand, contentKey],
          cacheData => ({
            ...cacheData,
            contentLinks: {
              ...cacheData.contentLinks,
              [location]: cacheData.contentLinks[location].filter(
                cacheItem => cacheItem.id !== id,
              ),
            },
          }),
        );
      },
    },
  );
};

export default useDeleteContentLink;
