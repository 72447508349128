import { Button, Checkbox, Flex, TextField } from '@mediahuis/chameleon-react';
import {
  ChevronDown,
  ChevronForward,
  Refresh,
} from '@mediahuis/chameleon-theme-wl/icons';
import { Collapse } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { useAboshopContext } from '~/components';
import { useQueryParams } from '~/hooks';
import { checkQueryParam } from '~/utils';

const SubscriptionScopesFilters = ({ onReset }) => {
  const { brands: mhBrands } = useAboshopContext();
  const [params, setParams] = useQueryParams();

  const [activeKeys, setActiveKeys] = useState();

  const searchFormRef = useRef();

  const { brand, name } = params;

  useEffect(() => {
    if (!activeKeys) {
      const activeParams = Object.keys(params);
      const defaultKeys = [];

      if (activeParams.includes('brand')) {
        defaultKeys.push('brand');
      }
      if (activeParams.includes('name')) {
        defaultKeys.push('search');
      }

      setActiveKeys(defaultKeys);
    }
  }, [activeKeys, params]);

  function handleCheckboxChange(event, paramsKey) {
    const param = params[paramsKey];

    const isArray = Array.isArray(param);
    let paramUpdate;

    if (event.target.checked) {
      if (isArray) {
        paramUpdate = [...param, event.target.value];
      } else {
        paramUpdate = param ? [param, event.target.value] : event.target.value;
      }
    } else {
      paramUpdate = isArray
        ? param.filter(t => t !== event.target.value)
        : undefined;
    }

    setParams({
      ...params,
      offset: 0,
      [paramsKey]: paramUpdate,
    });
  }

  function resetFilters() {
    searchFormRef.current?.resetForm();
    onReset();
  }

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }} width="300px">
      <Flex>
        <Button
          appearance="secondary"
          data-testid="filters-reset-btn"
          iconLeft={Refresh}
          size="small"
          width="full"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-expand-btn"
          iconLeft={ChevronDown}
          size="small"
          width="full"
          onClick={() => setActiveKeys(['brand', 'search'])}
        >
          Expand
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-collapse-btn"
          iconLeft={ChevronForward}
          size="small"
          width="full"
          onClick={() => setActiveKeys([])}
        >
          Collapse
        </Button>
      </Flex>

      <Collapse activeKey={activeKeys} onChange={keys => setActiveKeys(keys)}>
        <Collapse.Panel header="Brand" key="brand">
          <Flex flexDirection="column">
            {mhBrands.map(mhBrand => (
              <Checkbox
                checked={brand ? checkQueryParam(brand, mhBrand.code) : false}
                data-testid={`brand-${mhBrand.code}-check`}
                id={`brand-${mhBrand.code}`}
                key={mhBrand.code}
                label={mhBrand.name}
                value={mhBrand.code}
                onChange={event => handleCheckboxChange(event, 'brand')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Search" key="search">
          <Formik
            initialValues={{
              name,
            }}
            innerRef={searchFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                name: values.name ? values.name : undefined,
                offset: 0,
              });
            }}
          >
            <Form>
              <Field name="name">
                {({ field }) => (
                  <TextField
                    data-testid="search-name-input"
                    id="name"
                    label="Name"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>

              <Button
                data-testid="search-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>
      </Collapse>
    </Flex>
  );
};

export default SubscriptionScopesFilters;
