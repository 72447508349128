import React from 'react';
import { format } from 'date-fns';
import { states } from '~/constants';
import { StatusCircle } from '~/components';

export const formatState = (state = '') => (
  <StatusCircle variant={states[state]} />
);

export const formatBrand = (brand = '') => brand?.toUpperCase();

export const formatDate = (date = '') => format(new Date(date), 'dd/MM/yyyy');

export const formatDateTime = (date = '') =>
  format(new Date(date), 'dd/MM/yyyy - HH:mm');

export const formatType = (type = '') =>
  type ? type[0].toUpperCase() + type.slice(1) : type;

export const formatCode = (code = '') =>
  code.replace(/(.{4})(.{4})(.{4})/, '$1-$2-$3');

export const mapColumns = ({ brands = [], columns = [], filters = {} }) => {
  const newColumns = [...columns];
  const filterKeys = Object.keys(filters);

  filterKeys.forEach(key => {
    const index = newColumns.findIndex(c => c.id === key);

    if (index > -1) {
      switch (key) {
        case 'brand': {
          if (brands.length > 1) {
            newColumns[index] = {
              ...newColumns[index],
              filterMultiple: false,
              filteredValue: filters[key],
              filters: brands.map(b => ({ text: b.name, value: b.code })),
            };
          }
          break;
        }
        case 'state': {
          newColumns[index] = {
            ...newColumns[index],
            filterMultiple: false,
            filteredValue: filters[key],
            filters: [
              { text: 'Pending', value: 'Pending' },
              { text: 'Confirmed', value: 'Confirmed' },
              { text: 'Revoked', value: 'Revoked' },
            ],
          };
          break;
        }
        case 'stateVouchers': {
          newColumns[index] = {
            ...newColumns[index],
            filterMultiple: false,
            filteredValue: filters[key],
            filters: [
              { text: 'New', value: 'New' },
              { text: 'Sold', value: 'Sold' },
              { text: 'Used', value: 'Used' },
              { text: 'Revoked', value: 'Revoked' },
            ],
          };
          break;
        }
        case 'paymentState': {
          newColumns[index] = {
            ...newColumns[index],
            filterMultiple: false,
            filteredValue: filters[key],
            filters: [
              { text: 'Pending', value: 'Pending' },
              { text: 'Compliant', value: 'Compliant' },
              { text: 'Not Valid', value: 'NotValid' },
            ],
          };
          break;
        }
        case 'type': {
          newColumns[index] = {
            ...newColumns[index],
            filterMultiple: false,
            filteredValue: filters[key],
            filters: [
              { text: 'Renewal', value: 'renewal' },
              { text: 'Winback', value: 'winback' },
              { text: 'Settlement', value: 'settlement' },
              { text: 'Acquisition', value: 'acquisition' },
            ],
          };
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return newColumns;
};
