import React from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import { Box as BoxGrid } from '@mediahuis/chameleon-react';
import ItemTypes from './ItemTypes';

const Grid = styled(BoxGrid)`
  margin-bottom: 10px;
  cursor: move;
  width: 100%;
  text-align: left;
`;

const Box = ({
  availablePaymentOption,
  availableOptions,
  setAvailableOptions,
  chosenOptions,
  setChosenOptions,
}) => {
  // Based on: https://react-dnd.github.io/react-dnd/examples/dustbin/single-target
  const { CARD, BOX, LEFT_SIDE } = ItemTypes;
  const [{ isDragging }, drag] = useDrag({
    item: {
      ...availablePaymentOption,
      id: availablePaymentOption.id,
      text: availablePaymentOption.text,
      type: BOX,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      // Custom logic for setting local state of left and right side.
      if (dropResult && dropResult.name === LEFT_SIDE) {
        setChosenOptions([...chosenOptions, { ...item, type: CARD }]);
        const optionIds = availableOptions.map(
          availableOption => availableOption.id,
        );
        const index = optionIds.indexOf(item.id);
        const spreadedArray = [...availableOptions];
        if (index > -1) {
          spreadedArray.splice(index, 1);
        }
        setAvailableOptions(spreadedArray);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <Grid ref={drag} style={{ opacity }}>
      {availablePaymentOption.text}
    </Grid>
  );
};
export default Box;
