import { language } from './language';

const getInProgressMessage = (inProgress = []) => {
  if (inProgress.length === 1) {
    return `${language.workInProgress} ${inProgress[0]}. ${language.pleaseConclude}.`;
  }

  let workInProgress = language.workInProgress;

  for (let index = 0; index < inProgress.length; index++) {
    if (index === 0) {
      workInProgress += ` ${inProgress[index]}`;
    } else if (index === inProgress.length - 1) {
      workInProgress += ` and ${inProgress[index]}.`;
    } else {
      workInProgress += `, ${inProgress[index]}`;
    }
  }

  return `${workInProgress} ${language.pleaseConclude}.`;
};

export default getInProgressMessage;
