import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  TextField,
} from '@mediahuis/chameleon-react';
import {
  ChevronDown,
  ChevronForward,
  Refresh,
} from '@mediahuis/chameleon-theme-wl/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { useAboshopContext } from '~/components';
import { useQueryParams } from '~/hooks';
import {
  ORDER_PAYMENT_STATE,
  ORDER_PRECONDITION_STATE,
  ORDER_STATE,
  ORDER_TYPE,
} from '~/services/order';
import { checkQueryParam } from '~/utils';

dayjs.extend(dayjsPluginUTC);

const OrdersFilters = ({ onReset }) => {
  const { brands: mhBrands } = useAboshopContext();
  const [params, setParams] = useQueryParams();

  const [activeKeys, setActiveKeys] = useState();

  const addressFormRef = useRef();
  const idFormRef = useRef();
  const userFormRef = useRef();

  const {
    accountId,
    brands,
    busNumber,
    email,
    endDate,
    firstName,
    formula,
    formulaOfferId,
    houseNumber,
    lastName,
    orderId,
    paymentState,
    postalCode,
    preconditionState,
    startDate,
    state,
    street,
    type,
  } = params;

  useEffect(() => {
    if (!activeKeys) {
      const activeParams = Object.keys(params);
      const defaultKeys = [];

      if (
        activeParams.includes('endDate') ||
        activeParams.includes('startDate')
      ) {
        defaultKeys.push('date');
      }
      if (activeParams.includes('brands')) {
        defaultKeys.push('brand');
      }
      if (activeParams.includes('type')) {
        defaultKeys.push('type');
      }
      if (activeParams.includes('state')) {
        defaultKeys.push('state');
      }
      if (activeParams.includes('paymentState')) {
        defaultKeys.push('paymentState');
      }
      if (activeParams.includes('preconditionState')) {
        defaultKeys.push('preconditionState');
      }
      if (
        activeParams.includes('accountId') ||
        activeParams.includes('formula') ||
        activeParams.includes('formulaOfferId') ||
        activeParams.includes('orderId')
      ) {
        defaultKeys.push('id');
      }
      if (
        activeParams.includes('email') ||
        activeParams.includes('firstName') ||
        activeParams.includes('lastName')
      ) {
        defaultKeys.push('user');
      }
      if (
        activeParams.includes('busNumber') ||
        activeParams.includes('houseNumber') ||
        activeParams.includes('postalCode') ||
        activeParams.includes('street')
      ) {
        defaultKeys.push('address');
      }

      setActiveKeys(defaultKeys);
    }
  }, [activeKeys, params]);

  function handleCheckboxChange(event, paramsKey) {
    const param = params[paramsKey];

    const isArray = Array.isArray(param);
    let paramUpdate;

    if (event.target.checked) {
      if (isArray) {
        paramUpdate = [...param, event.target.value];
      } else {
        paramUpdate = param ? [param, event.target.value] : event.target.value;
      }
    } else {
      paramUpdate = isArray
        ? param.filter(t => t !== event.target.value)
        : undefined;
    }

    setParams({
      ...params,
      offset: 0,
      [paramsKey]: paramUpdate,
    });
  }

  function resetFilters() {
    addressFormRef.current?.resetForm();
    idFormRef.current?.resetForm();
    userFormRef.current?.resetForm();
    onReset();
  }

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }} width="300px">
      <Flex>
        <Button
          appearance="secondary"
          data-testid="filters-reset-btn"
          iconLeft={Refresh}
          size="small"
          width="full"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-expand-btn"
          iconLeft={ChevronDown}
          size="small"
          width="full"
          onClick={() =>
            setActiveKeys([
              'date',
              'brand',
              'type',
              'state',
              'paymentState',
              'preconditionState',
              'id',
              'user',
              'address',
            ])
          }
        >
          Expand
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-collapse-btn"
          iconLeft={ChevronForward}
          size="small"
          width="full"
          onClick={() => setActiveKeys([])}
        >
          Collapse
        </Button>
      </Flex>

      <Collapse activeKey={activeKeys} onChange={keys => setActiveKeys(keys)}>
        <Collapse.Panel header="Date" key="date">
          <DatePicker
            data-testid="date-start-input"
            id="startDate"
            label="Start"
            readOnly
            value={
              startDate ? new Date(dayjs.utc(startDate).toDate()) : undefined
            }
            onChange={date =>
              setParams({
                ...params,
                offset: 0,
                startDate: date
                  ? dayjs(date)
                      .set('hour', 0)
                      .set('minute', 0)
                      .set('second', 0)
                      .format('YYYY-MM-DDTHH:mm:ssZ')
                  : undefined,
              })
            }
            mb={3}
          />
          <DatePicker
            data-testid="date-end-input"
            id="endDate"
            label="End"
            value={new Date(dayjs.utc(endDate).format('YYYY-MM-DD'))}
            onChange={date =>
              setParams({
                ...params,
                endDate: dayjs(date)
                  .set('hour', 23)
                  .set('minute', 59)
                  .set('second', 59)
                  .format('YYYY-MM-DDTHH:mm:ssZ'),
                offset: 0,
              })
            }
            mb={3}
          />
        </Collapse.Panel>

        <Collapse.Panel header="Brand" key="brand">
          <Flex flexDirection="column">
            {mhBrands.map(mhBrand => (
              <Checkbox
                checked={brands ? checkQueryParam(brands, mhBrand.code) : false}
                data-testid={`brand-${mhBrand.code}-check`}
                id={`brands-${mhBrand.code}`}
                key={mhBrand.code}
                label={mhBrand.name}
                value={mhBrand.code}
                onChange={event => handleCheckboxChange(event, 'brands')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Type" key="type">
          <Flex flexDirection="column">
            {Object.values(ORDER_TYPE).map(orderType => (
              <Checkbox
                checked={type ? checkQueryParam(type, orderType) : false}
                data-testid={`type-${orderType}-check`}
                id={`type-${orderType}`}
                key={orderType}
                label={orderType}
                value={orderType}
                onChange={event => handleCheckboxChange(event, 'type')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="State" key="state">
          <Flex flexDirection="column">
            {Object.values(ORDER_STATE).map(orderState => (
              <Checkbox
                checked={state ? checkQueryParam(state, orderState) : false}
                data-testid={`state-${orderState}-check`}
                id={`state-${orderState}`}
                key={orderState}
                label={orderState}
                value={orderState}
                onChange={event => handleCheckboxChange(event, 'state')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Payment State" key="paymentState">
          <Flex flexDirection="column">
            {Object.values(ORDER_PAYMENT_STATE).map(orderPaymentState => (
              <Checkbox
                checked={
                  paymentState
                    ? checkQueryParam(paymentState, orderPaymentState)
                    : false
                }
                data-testid={`paymentState-${orderPaymentState}-check`}
                id={`paymentState-${orderPaymentState}`}
                key={orderPaymentState}
                label={orderPaymentState}
                value={orderPaymentState}
                onChange={event => handleCheckboxChange(event, 'paymentState')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Precondition State" key="preconditionState">
          <Flex flexDirection="column">
            {Object.values(ORDER_PRECONDITION_STATE).map(
              orderPreconditionState => (
                <Checkbox
                  checked={
                    preconditionState
                      ? preconditionState === orderPreconditionState ||
                        checkQueryParam(
                          preconditionState,
                          orderPreconditionState,
                        )
                      : false
                  }
                  data-testid={`preconditionState-${orderPreconditionState}-check`}
                  id={`preconditionState-${orderPreconditionState}`}
                  key={orderPreconditionState}
                  label={orderPreconditionState}
                  value={orderPreconditionState}
                  onChange={event =>
                    handleCheckboxChange(event, 'preconditionState')
                  }
                  mb={3}
                />
              ),
            )}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="ID" key="id">
          <Formik
            initialValues={{
              accountId,
              formula,
              formulaOfferId,
              orderId,
            }}
            innerRef={idFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                accountId: values.accountId ? values.accountId : undefined,
                formula: values.formula ? values.formula : undefined,
                formulaOfferId: values.formulaOfferId
                  ? values.formulaOfferId
                  : undefined,
                offset: 0,
                orderId: values.orderId ? values.orderId : undefined,
              });
            }}
          >
            <Form>
              <Field name="accountId">
                {({ field }) => (
                  <TextField
                    data-testid="id-account-input"
                    id="accountId"
                    label="Account ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="formula">
                {({ field }) => (
                  <TextField
                    data-testid="id-formula-input"
                    id="formula"
                    label="Formula"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="formulaOfferId">
                {({ field }) => (
                  <TextField
                    data-testid="id-formulaOfferId-input"
                    id="formulaOfferId"
                    label="Formula Offer"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="orderId">
                {({ field }) => (
                  <TextField
                    data-testid="id-order-input"
                    id="orderId"
                    label="Order ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Button
                data-testid="id-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>

        <Collapse.Panel header="User" key="user">
          <Formik
            initialValues={{
              email,
              firstName,
              lastName,
            }}
            innerRef={userFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                email: values.email ? values.email : undefined,
                firstName: values.firstName ? values.firstName : undefined,
                lastName: values.lastName ? values.lastName : undefined,
                offset: 0,
              });
            }}
          >
            <Form>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    data-testid="user-email-btn"
                    id="email"
                    label="Email"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="firstName">
                {({ field }) => (
                  <TextField
                    data-testid="user-firstName-btn"
                    id="firstName"
                    label="First Name"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="lastName">
                {({ field }) => (
                  <TextField
                    data-testid="user-lastName-btn"
                    id="lastName"
                    label="Last Name"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Button
                data-testid="user-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>

        <Collapse.Panel header="Address" key="address">
          <Formik
            initialValues={{
              boxNumber: busNumber,
              houseNumber,
              postalCode,
              street,
            }}
            innerRef={addressFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                busNumber: values.boxNumber ? values.boxNumber : undefined,
                houseNumber: values.houseNumber
                  ? values.houseNumber
                  : undefined,
                offset: 0,
                postalCode: values.postalCode ? values.postalCode : undefined,
                street: values.street ? values.street : undefined,
              });
            }}
          >
            <Form>
              <Field name="street">
                {({ field }) => (
                  <TextField
                    data-testid="address-street-btn"
                    id="street"
                    label="Street"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="houseNumber">
                {({ field }) => (
                  <TextField
                    data-testid="address-houseNumber-btn"
                    id="houseNumber"
                    label="House Number"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="boxNumber">
                {({ field }) => (
                  <TextField
                    data-testid="address-boxNumber-btn"
                    id="boxNumber"
                    label="Box"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="postalCode">
                {({ field }) => (
                  <TextField
                    data-testid="address-postalCode-btn"
                    id="postalCode"
                    label="Postal Code"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Button
                data-testid="address-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>
      </Collapse>
    </Flex>
  );
};

export default OrdersFilters;
