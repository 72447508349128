import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  TextField,
} from '@mediahuis/chameleon-react';
import {
  ChevronDown,
  ChevronForward,
  Refresh,
} from '@mediahuis/chameleon-theme-wl/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { useAboshopContext } from '~/components';
import { useQueryParams } from '~/hooks';
import { OFFER_TYPE } from '~/services/offer';
import { checkQueryParam } from '~/utils';

const OffersFilters = ({ onReset }) => {
  const { brands: mhBrands } = useAboshopContext();
  const [params, setParams] = useQueryParams();

  const [activeKeys, setActiveKeys] = useState();

  const searchFormRef = useRef();

  const {
    active,
    brand,
    id,
    name,
    slug,
    startDate,
    endDate,
    type,
    subscriptionFormulaId,
    subscriptionTypeId,
  } = params;

  useEffect(() => {
    if (!activeKeys) {
      const activeParams = Object.keys(params);
      const defaultKeys = [];

      if (activeParams.includes('brand')) {
        defaultKeys.push('brand');
      }
      if (
        activeParams.includes('name') ||
        activeParams.includes('slug') ||
        activeParams.includes('externalReference') ||
        activeParams.includes('subscriptionFormulaId') ||
        activeParams.includes('subscriptionTypeId')
      ) {
        defaultKeys.push('search');
      }
      if (
        activeParams.includes('startDate') ||
        activeParams.includes('endDate')
      ) {
        defaultKeys.push('date');
      }
      if (activeParams.includes('type')) {
        defaultKeys.push('type');
      }
      if (activeParams.includes('active')) {
        defaultKeys.push('active');
      }

      setActiveKeys(defaultKeys);
    }
  }, [activeKeys, params]);

  function handleCheckboxChange(event, paramsKey) {
    const param = params[paramsKey];

    const isArray = Array.isArray(param);
    let paramUpdate;

    if (event.target.checked) {
      if (isArray) {
        paramUpdate = [...param, event.target.value];
      } else {
        paramUpdate = param ? [param, event.target.value] : event.target.value;
      }
    } else {
      paramUpdate = isArray
        ? param.filter(t => t !== event.target.value)
        : undefined;
    }

    setParams({
      ...params,
      offset: 0,
      [paramsKey]: paramUpdate,
    });
  }

  function resetFilters() {
    searchFormRef.current?.resetForm();
    onReset();
  }

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }} width="300px">
      <Flex>
        <Button
          appearance="secondary"
          data-testid="filters-reset-btn"
          iconLeft={Refresh}
          size="small"
          width="full"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-expand-btn"
          iconLeft={ChevronDown}
          size="small"
          width="full"
          onClick={() => setActiveKeys(['brand', 'search', 'date', 'type'])}
        >
          Expand
        </Button>
        <Button
          appearance="secondary"
          data-testid="filters-collapse-btn"
          iconLeft={ChevronForward}
          size="small"
          width="full"
          onClick={() => setActiveKeys([])}
        >
          Collapse
        </Button>
      </Flex>

      <Collapse activeKey={activeKeys} onChange={keys => setActiveKeys(keys)}>
        <Collapse.Panel header="Brand" key="brand">
          <Flex flexDirection="column">
            {mhBrands.map(mhBrand => (
              <Checkbox
                checked={brand ? checkQueryParam(brand, mhBrand.code) : false}
                data-testid={`brand-${mhBrand.code}-check`}
                id={`brand-${mhBrand.code}`}
                key={mhBrand.code}
                label={mhBrand.name}
                value={mhBrand.code}
                onChange={event => handleCheckboxChange(event, 'brand')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="Search" key="search">
          <Formik
            initialValues={{
              id,
              name,
              slug,
              subscriptionFormulaId,
              subscriptionTypeId,
            }}
            innerRef={searchFormRef}
            onSubmit={values => {
              setParams({
                ...params,
                id: values.id ? values.id : undefined,
                name: values.name ? values.name : undefined,
                offset: 0,
                slug: values.slug ? values.slug : undefined,
                subscriptionFormulaId: values.subscriptionFormulaId
                  ? values.subscriptionFormulaId
                  : undefined,
                subscriptionTypeId: values.subscriptionTypeId
                  ? values.subscriptionTypeId
                  : undefined,
              });
            }}
          >
            <Form>
              <Field name="id">
                {({ field }) => (
                  <TextField
                    data-testid="search-id-input"
                    id="id"
                    label="ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="name">
                {({ field }) => (
                  <TextField
                    data-testid="search-reference-input"
                    id="name"
                    label="Reference"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="slug">
                {({ field }) => (
                  <TextField
                    data-testid="search-slug-input"
                    id="slug"
                    label="Slug"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="subscriptionFormulaId">
                {({ field }) => (
                  <TextField
                    data-testid="search-subscriptionFormulaId-input"
                    id="subscriptionFormulaId"
                    label="Contains Formula ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Field name="subscriptionTypeId">
                {({ field }) => (
                  <TextField
                    data-testid="search-subscriptionTypeId-input"
                    id="subscriptionTypeId"
                    label="Contains Type ID"
                    name={field.name}
                    required={false}
                    value={field.value || ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    mb={3}
                  />
                )}
              </Field>
              <Button
                data-testid="search-submit-btn"
                mt={3}
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Collapse.Panel>

        <Collapse.Panel header="Date" key="date">
          <DatePicker
            data-testid="date-start-input"
            id="startDate"
            label="Start Date"
            readOnly
            value={startDate ? dayjs(startDate).toDate() : undefined}
            onChange={date =>
              setParams({
                ...params,
                offset: 0,
                startDate: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
              })
            }
            mb={3}
          />
          <DatePicker
            data-testid="date-end-input"
            id="endDate"
            label="End Date"
            value={dayjs(endDate).toDate()}
            onChange={date =>
              setParams({
                ...params,
                endDate: dayjs(date).format('YYYY-MM-DD'),
                offset: 0,
              })
            }
          />
        </Collapse.Panel>

        <Collapse.Panel header="Type" key="type">
          <Flex flexDirection="column">
            {Object.values(OFFER_TYPE).map(offerType => (
              <Checkbox
                checked={type ? checkQueryParam(type, offerType) : false}
                data-testid={`type-${offerType}-check`}
                id={`type-${offerType}`}
                key={offerType}
                label={offerType}
                value={offerType}
                onChange={event => handleCheckboxChange(event, 'type')}
                mb={3}
              />
            ))}
          </Flex>
        </Collapse.Panel>

        <Collapse.Panel header="State" key="active">
          <Flex flexDirection="column">
            <Checkbox
              checked={active ? checkQueryParam(active, 'false') : false}
              data-testid="active-check-inactive"
              id="inactive"
              key="inactive"
              label="Inactive"
              value="false"
              onChange={event => handleCheckboxChange(event, 'active')}
              mb={3}
            />
            <Checkbox
              checked={active ? checkQueryParam(active, 'true') : false}
              data-testid="active-check-active"
              id="active"
              key="active"
              label="Active"
              value="true"
              onChange={event => handleCheckboxChange(event, 'active')}
              mb={3}
            />
          </Flex>
        </Collapse.Panel>
      </Collapse>
    </Flex>
  );
};

export default OffersFilters;
