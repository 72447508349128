import { Box, Button, Flex } from '@mediahuis/chameleon-react';
import { colorRedBase } from '@mediahuis/chameleon-theme-wl';
import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  PageLoader,
  Sidebar,
  StickyTop,
  notifyError,
  notifySuccess,
} from '~/components';
import { ORDER_TYPES } from '~/constants';
import { useOrder } from '~/services/order';
import { noop, secureApiCall } from '~/utils';

import Booleans from './components/Booleans';
import Choices from './components/Choices';
import Dates from './components/Dates';
import DeliveryAddress from './components/DeliveryAddress';
import Email from './components/Email';
import File from './components/File';
import Invoice from './components/Invoice';
import Login from './components/Login';
import Payment from './components/Payment';
import Precondition from './components/Precondition';
import Settlements from './components/Settlements';
import Summary from './components/Summary';
import TextField from './components/TextField';
import Voucher from './components/Voucher';

const OrderDetail = () => {
  const history = useHistory();
  const { id } = useParams();

  const [voucherId, setVoucherId] = useState(null);
  const [voucherCode, setVoucherCode] = useState(null);
  const [formula, setFormula] = useState({});
  const [isRevoking, setIsRevoking] = useState(false);

  const orderQuery = useOrder(id);

  useEffect(() => {
    if (orderQuery.isSuccess) {
      if (orderQuery.data.voucherValidation) {
        setVoucherCode(orderQuery.data.voucherValidation.data?.voucherCode);
      } else if (orderQuery.data.type === ORDER_TYPES.VOUCHERPURCHASE) {
        secureApiCall(
          `${API_VOUCHER_SERVICE}/vouchers?PurchaseOrderId=${orderQuery.data.id}`,
          {
            method: 'GET',
            onSuccess: response => {
              setVoucherCode(response.data?.results[0]?.code);
              setVoucherId(response.data?.results[0]?.id);
            },
            onError: errorMessage => {
              notifyError({
                status: errorMessage?.response?.request?.status,
                message: errorMessage?.response?.data?.errors
                  ? errorMessage.response.data.errors[0]
                  : errorMessage.message,
              });
              history.push('/orders');
            },
          },
        );
      }

      if (
        orderQuery.data !== ORDER_TYPES.SETTLEMENT &&
        orderQuery.data.subscriptionFormulaId
      ) {
        secureApiCall(
          `${API_OFFER_SERVICE}/SubscriptionFormulas/${orderQuery.data.subscriptionFormulaId}`,
          {
            method: 'GET',
            params: {
              'api-version': '2',
              lang: orderQuery.data.language,
            },
            onSuccess: response => {
              setFormula(response.data);
            },
            onError: errorMessage => {
              notifyError({
                status: errorMessage?.response?.request?.status,
                message: errorMessage?.response?.data?.errors
                  ? errorMessage.response.data.errors[0]
                  : errorMessage.message,
              });
              history.push('/orders');
            },
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, orderQuery.isSuccess]);

  useEffect(() => {
    if (voucherCode) {
      secureApiCall(`${API_VOUCHER_SERVICE}/vouchers/code/${voucherCode}`, {
        method: 'GET',
        onSuccess: response => {
          setVoucherId(response.data.id);
        },
        onError: errorMessage => {
          notifyError({
            status: errorMessage?.response?.request?.status,
            message: errorMessage?.response?.data?.errors
              ? errorMessage.response.data.errors[0]
              : errorMessage.message,
          });
          history.push('/orders');
        },
      });
    }
  }, [history, voucherCode]);

  const revokeOrder = () => {
    setIsRevoking(true);
    secureApiCall(`${API_ORDER_SERVICE}/orders/${id}`, {
      method: 'PATCH',
      params: {
        'api-version': '2',
      },
      data: JSON.stringify({
        state: 'Revoked',
      }),
      onSuccess: () => {
        notifySuccess({
          message: 'Successfully revoked an order',
        });
        setIsRevoking(false);
      },
      onError: errorMessage => {
        setIsRevoking(false);
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  if (orderQuery.isSuccess) {
    return (
      <Flex justifyContent="center">
        <Box width="600px">
          {orderQuery.data.emailValidations && orderQuery.data.emailValidations.map(emailValidation => (
            <Email validation={emailValidation} key={emailValidation.id} />
          ))}
          {orderQuery.data.invoiceDetailsValidation && (
            <Invoice validation={orderQuery.data.invoiceDetailsValidation} />
          )}
          {orderQuery.data.loginValidation && (
            <Login validation={orderQuery.data.loginValidation} />
          )}
          {orderQuery.data.paymentValidation && (
            <Payment validation={orderQuery.data.paymentValidation} />
          )}
          {orderQuery.data.dateValidations && orderQuery.data.dateValidations.map(dateValidation => (
            <Dates validation={dateValidation} key={dateValidation.id} />
          ))}
          {orderQuery.data.booleanValidations && orderQuery.data.booleanValidations.map(booleanValidation => (
            <Booleans validation={booleanValidation} key={booleanValidation.id} />
          ))}
          {orderQuery.data.addressAreaValidations && orderQuery.data.addressAreaValidations.map(addressAreaValidation => (
            <DeliveryAddress validation={addressAreaValidation} key={addressAreaValidation.id} />
          ))}
          {orderQuery.data.fileValidations && orderQuery.data.fileValidations.map(fileValidation => (
            <File validation={fileValidation} key={fileValidation.id} />
          ))}
          {orderQuery.data.choiceValidations && orderQuery.data.choiceValidations.map(choiceValidation => (
            <Choices validation={choiceValidation} key={choiceValidation.id} />
          ))}
          {orderQuery.data.preconditionsValidation && (
            <Precondition
              validation={orderQuery.data.preconditionsValidation}
            />
          )}
          {orderQuery.data.textfieldValidations && orderQuery.data.textfieldValidations.map(textfieldValidation => (
            <TextField validation={textfieldValidation} key={textfieldValidation.id} />
          ))}
          {orderQuery.data.voucherValidation && (
            <Voucher validation={orderQuery.data.voucherValidation} />
          )}
        </Box>

        <Sidebar>
          <StickyTop>
            <Summary
              order={orderQuery.data}
              formula={formula}
              paymentValidation={orderQuery.data.paymentValidation}
              voucherCode={voucherCode}
              voucherId={voucherId}
            />
            {orderQuery.data.type === 'settlement' && (
              <Settlements orderId={orderQuery.data.id} />
            )}

            {orderQuery.data.state !== 'Revoked' && (
              <Popconfirm
                title="Are you sure to revoke this order?"
                onConfirm={revokeOrder}
                onCancel={noop}
                okText={
                  <span data-testid="button-revoke-confirm">Confirm</span>
                }
                cancelText={
                  <span data-testid="button-revoke-cancel">Cancel</span>
                }
              >
                <Button
                  appearance="plain"
                  data-testid="button-revoke-order"
                  loading={isRevoking}
                  size="small"
                  style={{ color: colorRedBase }}
                  width="full"
                >
                  Revoke
                </Button>
              </Popconfirm>
            )}
          </StickyTop>
        </Sidebar>
      </Flex>
    );
  }

  return <PageLoader />;
};

export default OrderDetail;
