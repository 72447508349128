import { Box, Flex } from '@mediahuis/chameleon-react';
import React from 'react';

import { Navigation } from '~/components/Navigation';
import { TopBar } from '~/components/TopBar';
import { TOP_BAR_HEIGHT } from '~/constants/styles';

const PageLayout = ({ children }) => {
  return (
    <>
      <TopBar />

      <Flex height={`calc(100vh - ${TOP_BAR_HEIGHT})`} width="full">
        <Box
          borderRightColor="colorGrey20"
          borderRightStyle="solid"
          borderRightWidth={1}
          style={{
            minWidth: '15rem',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Navigation />
        </Box>

        <Box
          flexGrow={1}
          p={8}
          style={{
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Flex>
    </>
  );
};

export default PageLayout;
