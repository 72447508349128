import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Step, Steps, Wizard } from 'react-albus/lib';
import { BrandWizard } from '../../components';
import { resetRequirementIds } from '../../utils';
import TypeForm from './components/TypeForm';

const AddType = ({ history, url }) => {
  const { state = {} } = useLocation();
  const [initialValues, setInitialValues] = useState({
    addressAreaRequirements: [],
    booleanRequirements: [],
    choiceRequirements: [],
    dateRequirements: [],
    emailRequirements: [],
    fileRequirements: [],
    textfieldRequirements: [],
    features: [],
    ...state.initialValues,
    id: 0,
    brand: '',
    title: '',
    reference: '',
  });

  const handleSelectBrand = ({ brandCode, next }) => {
    if (state.initialValues && state.initialValues.brand === brandCode) {
      setInitialValues({
        ...initialValues,
        brand: brandCode,
        requirements: resetRequirementIds(state, brandCode),
      });
    } else {
      setInitialValues({
        ...initialValues,
        logo: '',
        image: '',
        features: [],
        brand: brandCode,
        requirements: state.initialValues
          ? resetRequirementIds(state, brandCode)
          : [],
      });
    }
    next();
  };

  return (
    <Wizard history={history} basename={url}>
      <Steps>
        <Step
          id="brand"
          render={({ next }) => (
            <BrandWizard
              handleSelectBrand={brandCode =>
                handleSelectBrand({ brandCode, next })
              }
            />
          )}
        />
        <Step
          id="offer"
          render={() => (
            <TypeForm
              apiUrl={`${API_OFFER_SERVICE}/SubscriptionTypes`}
              initialValues={initialValues}
            />
          )}
        />
      </Steps>
    </Wizard>
  );
};

export default AddType;
