import React from 'react';

import { useFeatures } from '~/services/offer';

const FeaturesQuery = ({ filters, render }) => {
  const featuresQuery = useFeatures(filters);

  if (featuresQuery.isError) {
    return <p>Error while loading features</p>;
  }

  if (featuresQuery.isSuccess) {
    return render(featuresQuery.data, featuresQuery.isFetching);
  }

  return <p>Loading features..</p>;
};

export default FeaturesQuery;
