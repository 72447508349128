import React, { Fragment, forwardRef } from 'react';
import { TextField as CHTextField } from '@mediahuis/chameleon-react';
import ErrorMessage from '../ErrorMessage';
import noop from '../../utils/noop';

const TextField = (props, ref) => {
  const {
    id,
    label,
    type = 'text',
    name,
    required = false,
    disabled = false,
    multiline = false,
    value,
    errors,
    InputProps,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    datatestid,
    defaultValue = '',
    maxLength = '',
    rows,
    rowsMax,
  } = props;

  /*
    The error field will show its message by the name key.
    Due to nested validation object it is possible that this key
    contains multiple object names, sperated by a '.' character.
    Make sure that this key is always the flatten value of the last object key.
  */

  const errorKey = name.substring(name.lastIndexOf('.') + 1);

  return (
    <Fragment>
      <CHTextField
        rows={rows}
        rowsMax={rowsMax}
        id={id}
        label={label}
        required={required}
        inputRef={ref}
        type={type}
        name={name}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        value={value}
        multiline={multiline}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        InputProps={InputProps}
        inputProps={{
          'data-testid': datatestid,
          maxLength,
        }}
        defaultValue={defaultValue}
      />
      {errors && errors[errorKey] && (
        <ErrorMessage>{errors[errorKey].message}</ErrorMessage>
      )}
    </Fragment>
  );
};

export default forwardRef(TextField);
