import { REQUIREMENT_TYPE } from '../enums';

const getSubscriptionTypeRequirements = subscriptionType => {
  return {
    [REQUIREMENT_TYPE.Email]: subscriptionType[REQUIREMENT_TYPE.Email],
    [REQUIREMENT_TYPE.Login]: subscriptionType[REQUIREMENT_TYPE.Login],
    [REQUIREMENT_TYPE.Date]: subscriptionType[REQUIREMENT_TYPE.Date],
    [REQUIREMENT_TYPE.Boolean]: subscriptionType[REQUIREMENT_TYPE.Boolean],
    [REQUIREMENT_TYPE.AddressArea]:
      subscriptionType[REQUIREMENT_TYPE.AddressArea],
    [REQUIREMENT_TYPE.InvoiceDetails]:
      subscriptionType[REQUIREMENT_TYPE.InvoiceDetails],
    [REQUIREMENT_TYPE.Payment]: subscriptionType[REQUIREMENT_TYPE.Payment],
    [REQUIREMENT_TYPE.File]: subscriptionType[REQUIREMENT_TYPE.File],
    [REQUIREMENT_TYPE.Textfield]: subscriptionType[REQUIREMENT_TYPE.Textfield],
    [REQUIREMENT_TYPE.Preconditions]:
      subscriptionType[REQUIREMENT_TYPE.Preconditions],
    [REQUIREMENT_TYPE.Voucher]: subscriptionType[REQUIREMENT_TYPE.Voucher],
    [REQUIREMENT_TYPE.Choice]: subscriptionType[REQUIREMENT_TYPE.Choice],
  };
};

export default getSubscriptionTypeRequirements;
