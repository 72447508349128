import { Auth } from 'aws-amplify';
import Axios from 'axios';
import queryString from 'query-string';
import { getI18n } from 'react-i18next';

const orderServiceInstance = Axios.create({
  baseURL: API_ORDER_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    'api-version': '2',
  },
});

orderServiceInstance.interceptors.request.use(
  async config => {
    const { language } = getI18n();
    const session = await Auth.currentSession();

    if (language) {
      config.params.lang = language;
    }
    if (session.idToken?.jwtToken) {
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    }

    return config;
  },
  err => Promise.reject(err),
);

class OrderService {
  static getOrder(id) {
    return orderServiceInstance.get(`/orders/${id}`);
  }

  static getOrders(filters) {
    const filterParams = queryString.stringify(filters);

    return orderServiceInstance.get(`/orders?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }

  static getOrdersCsv(filters) {
    const filterParams = queryString.stringify(filters);

    return orderServiceInstance.get(`/orders?${filterParams}`, {
      headers: {
        Accept: 'text/csv',
      },
      params: {
        amount: 10000,
      },
    });
  }

  static putAddressAreaValidation(addressAreaValidation) {
    return orderServiceInstance.put(
      `/validations/addressarea/${addressAreaValidation.id}`,
      addressAreaValidation,
    );
  }

  static putBooleanValidation(booleanValidation) {
    return orderServiceInstance.put(
      `/validations/boolean/${booleanValidation.id}`,
      booleanValidation,
    );
  }

  static putChoiceValidation(choiceValidation) {
    return orderServiceInstance.put(
      `/validations/choice/${choiceValidation.id}`,
      choiceValidation,
    );
  }

  static putDateValidation(dateValidation) {
    return orderServiceInstance.put(
      `/validations/date/${dateValidation.id}`,
      dateValidation,
    );
  }

  static putEmailValidation(emailValidation) {
    return orderServiceInstance.put(
      `/validations/email/${emailValidation.id}`,
      emailValidation,
    );
  }

  static putFileValidation(fileValidation) {
    return orderServiceInstance.put(
      `/validations/file/${fileValidation.id}`,
      fileValidation,
    );
  }

  static putInvoiceValidation(invoiceValidation) {
    return orderServiceInstance.put(
      `/validations/invoicedetails/${invoiceValidation.id}`,
      invoiceValidation,
    );
  }

  static putLoginValidation(loginValidation) {
    return orderServiceInstance.put(
      `/validations/login/${loginValidation.id}`,
      loginValidation,
    );
  }

  static putPaymentValidation(paymentValidation) {
    return orderServiceInstance.put(
      `/validations/payment/${paymentValidation.id}`,
      paymentValidation,
    );
  }

  static putPreconditionsValidation(preconditionsValidation) {
    return orderServiceInstance.put(
      `/validations/preconditions/${preconditionsValidation.id}`,
      preconditionsValidation,
    );
  }

  static putTextfieldValidation(textfieldValidation) {
    return orderServiceInstance.put(
      `/validations/textfield/${textfieldValidation.id}`,
      textfieldValidation,
    );
  }

  static putVoucherValidation(voucherValidation) {
    return orderServiceInstance.put(
      `/validations/voucher/${voucherValidation.id}`,
      voucherValidation,
    );
  }
}

export default OrderService;
