import { Flex, Button, Text } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import LanguageDropdown from '~/components/LanguageDropdown';
import { useQueryParams } from '~/hooks';
import { OffersQuery } from '~/services/offer';

import OffersFilters from './OffersFilters';
import OffersTable from './OffersTable';

const defaultOffset = 0;
const defaultOffsetSize = 20;
const defaultType = 'Acquisition';

const OffersPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [params, setParams] = useQueryParams();

  function resetFilters() {
    setParams({
      offset: defaultOffset,
      type: defaultType,
    });
  }

  if (!params.offset && !params.type) {
    return (
      <Redirect
        to={`${pathname}?offset=${defaultOffset}&type=${defaultType}`}
      />
    );
  }

  return (
    <OffersQuery
      filters={params}
      render={(offers, isFetchingOffers) => (
        <Flex
          justifyContent="space-between"
          px={5}
          style={{ gap: '2.5rem' }}
          width="full"
        >
          <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Total: {offers.totalCount}</Text>

              <Pagination
                current={params.offset / defaultOffsetSize + 1}
                pageSize={defaultOffsetSize}
                showSizeChanger={false}
                total={offers.totalCount}
                onChange={page =>
                  setParams({
                    ...params,
                    offset: (page - 1) * defaultOffsetSize,
                  })
                }
              />
              <Flex alignItems="center" style={{ gap: '5px' }}>
                <Button
                  appearance="primary"
                  data-testid="offers-create-btn"
                  onClick={() =>
                    history.push(`${history.location.pathname}/create`)
                  }
                  size="small"
                >
                  Create
                </Button>
                <LanguageDropdown
                  onChange={() => queryClient.invalidateQueries()}
                />
              </Flex>
            </Flex>

            <OffersTable isLoading={isFetchingOffers} offers={offers} />
          </Flex>

          <OffersFilters onReset={resetFilters} />
        </Flex>
      )}
    />
  );
};

export default OffersPage;
