/* eslint-disable no-unused-vars */
export const variantsValidations = ({ name, path, error, e, data, id }) => {
  let currentErrors = { ...error };

  const isDuplicate =
    data && data.find(el => el.name === name && el.id !== id) !== undefined;

  if (name != null) {
    if (!name) {
      currentErrors = {
        ...currentErrors,
        name: 'Name is a required field.',
      };
    }
    if (isDuplicate) {
      currentErrors = {
        ...currentErrors,
        name: 'You cannot have duplicate names.',
      };
    }
  }
  if (path != null && !path) {
    currentErrors = {
      ...currentErrors,
      path: 'Url is a required field.',
    };
  }

  return currentErrors;
};
