export const ORDER_PAYMENT_STATE = Object.freeze({
  COMPLIANT: 'Compliant',
  PENDING: 'Pending',
  NOT_VALID: 'NotValid',
});

export const ORDER_PRECONDITION_STATE = Object.freeze({
  COMPLIANT: 'Compliant',
  PENDING: 'Pending',
  NOT_VALID: 'NotValid',
});

export const ORDER_STATE = Object.freeze({
  CONFIRMED: 'Confirmed',
  PENDING: 'Pending',
  REVOKED: 'Revoked',
});

export const ORDER_TYPE = Object.freeze({
  ACQUISITION: 'acquisition',
  RENEWAL: 'renewal',
  WINBACK: 'winback',
  SETTLEMENT: 'settlement',
  VOUCHER_PURCHASE: 'voucherpurchase',
  VOUCHER_REDEMPTION: 'voucherredemption',
});
