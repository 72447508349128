import styled from 'styled-components';
import { Button } from './Button';

export const FullWidthButton = styled(Button)`
  && {
    display: flex;
    margin-left: 0;
    margin-bottom: ${props => props.theme.spacing.half};
    width: 100%;
  }
`;
