import { useMutation, useQueryClient } from 'react-query';

import { ContentService } from '~/services/content';

import { CONTENTS_KEY } from './useContents';

const useDeleteContent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }) =>
      ContentService.deleteContent(id).then(response => response.data),
    {
      onSuccess: () => {
        queryClient.removeQueries([CONTENTS_KEY]);
      },
    },
  );
};

export default useDeleteContent;
