import {
  Button,
  DatePicker,
  Dialog,
  Flex,
  Heading,
  TextField,
  Checkbox,
  Box,
  Select,
  Caption,
  LinkText,
} from '@mediahuis/chameleon-react';
import { useContentLinks } from '~/services/content';
import { apiCall, mergeArrays, secureApiCall } from '~/utils';
import { notifyError, notifySuccess } from '~/components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const defaultDate = dayjs().add(6, 'M').toDate();

const ToastLink = styled(LinkText)`
  color: #fff;
  margin-left: 5px;
`;

const VouchersDialog = ({ show, onClose, formula }) => {
  const [amount, setAmount] = useState(1);
  const [expirationDate, setExpirationDate] = useState(defaultDate);
  const [isGeneratePdfsChecked, setIsGeneratePdfsChecked] = useState(false);
  const [template, setTemplate] = useState();
  const [isGenerating, setIsGenerating] = useState(false);

  const contentLinksQuery = useContentLinks(
    formula.brand,
    HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE,
    !!formula.brand,
  );

  const handleDialogClose = () => {
    setAmount(1);
    setExpirationDate(defaultDate);
    setIsGeneratePdfsChecked(false);
    setIsGenerating(false);
    setTemplate(null);
    onClose();
  };

  const createVouchersAndDownloadPdfs = () => {
    setIsGenerating(true);
    secureApiCall(`${API_VOUCHER_SERVICE}/vouchers/generate?amount=${amount}`, {
      method: 'POST',
      data: JSON.stringify({
        brand: formula.brand,
        expirationDate,
        redeemFormulaId: formula.id,
      }),
      onSuccess: response => {
        const codes = response.data.map(
          voucher =>
            `${API_VOUCHER_SERVICE}/Templates/html/${template.contentId}?voucherCode=${voucher.code}`,
        );
        apiCall(`${API_PDF_SERVICE}/HtmlToPdf/zip?fileNamePrefix=voucher`, {
          method: 'POST',
          data: codes,
          responseType: 'blob',
          onSuccess: innerResponse => {
            const blob = innerResponse.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'vouchers.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
            handleDialogClose();
            return null;
          },
          onError: error => {
            handleDialogClose();
            notifyError({
              status:
                error.response?.request?.status &&
                error.response.request.status,
              message: error.response?.data?.errors
                ? error.response.data.errors[0]
                : error.message,
            });
          },
        });
      },
      onError: errorMessage => {
        handleDialogClose();
        notifyError({
          status:
            errorMessage.response?.request?.status &&
            errorMessage.response.request.status,
          message: errorMessage.response?.data?.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const createVouchersAndShowRedirectToast = () => {
    setIsGenerating(true);
    secureApiCall(`${API_VOUCHER_SERVICE}/vouchers/generate?amount=${amount}`, {
      method: 'POST',
      data: JSON.stringify({
        brand: formula.brand,
        expirationDate,
        redeemFormulaId: formula.id,
      }),
      onSuccess: response => {
        handleDialogClose();
        notifySuccess({
          message: (
            <>
              <span>Successfully created vouchers:</span>
              <ToastLink
                href={`/vouchers?offset=0&IdFrom=${
                  response.data[0].id
                }&IdUntil=${response.data[response.data.length - 1].id}`}
              >
                Click here to view
              </ToastLink>
            </>
          ),
        });
      },
      onError: errorMessage => {
        handleDialogClose();
        notifyError({
          status:
            errorMessage.response?.request?.status &&
            errorMessage.response.request.status,
          message: errorMessage.response?.data?.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const createVouchers = () => {
    if (isGeneratePdfsChecked) {
      createVouchersAndDownloadPdfs(amount, expirationDate, template);
    } else {
      createVouchersAndShowRedirectToast(amount, expirationDate);
    }
  };

  return (
    <Dialog
      data-testid="generateVouchers-dialog"
      headerHidden
      show={show}
      onClose={handleDialogClose}
      closeOnBackdropClick={!isGenerating}
      closeOnEscape={!isGenerating}
    >
      <Flex flexDirection="column" style={{ gap: '1rem' }}>
        <Heading level={3}>Create vouchers</Heading>
        <TextField
          data-testid="generateVouchers-amount-field"
          label="Amount"
          min={0}
          type="number"
          value={amount}
          onChange={e => setAmount(e.target.value)}
        />
        <DatePicker
          data-testid="generateVouchers-expirationDate-field"
          id="ExpirationDate"
          label="Expiration Date"
          value={expirationDate}
          onChange={date => setExpirationDate(date)}
        />
        <Checkbox
          data-testid="generateVouchers-generatePdfs-field"
          id="GeneratePdfs"
          label="Generate Voucher PDFs?"
          value={isGeneratePdfsChecked}
          onChange={event => setIsGeneratePdfsChecked(event.target.checked)}
        />

        {isGeneratePdfsChecked && (
          <Box>
            <Caption>
              Generating PDFs takes a while, await the download after Creating.
              Best maximum 100 at a time (takes around 1 minute)
            </Caption>
            <Select
              disabled={!isGeneratePdfsChecked}
              data-testid="generateVouchers-choose-template"
              label="Choose template"
              placeholder="Template"
              value={template ? template.contentId : ''}
              onChange={e =>
                setTemplate(
                  mergeArrays(
                    contentLinksQuery.data.contentLinks?.page,
                    contentLinksQuery.data.contentLinks?.bulk,
                  )?.find(
                    result => result.contentId.toString() === e.target.value,
                  ),
                )
              }
            >
              {contentLinksQuery.isSuccess &&
                mergeArrays(
                  contentLinksQuery.data.contentLinks?.page,
                  contentLinksQuery.data.contentLinks?.bulk,
                )?.map(result => (
                  <option key={result.contentId} value={result.contentId}>
                    Id: {result.contentId} - {result.content?.name}
                  </option>
                ))}
            </Select>
          </Box>
        )}
        <Flex style={{ gap: '0.5rem' }}>
          <Button
            appearance="secondary"
            data-testid="generateVouchers-cancel-button"
            width="full"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="generateVouchers-create-button"
            disabled={!expirationDate || (isGeneratePdfsChecked && !template)}
            width="full"
            onClick={() => createVouchers()}
            loading={isGenerating}
          >
            Create
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default VouchersDialog;
