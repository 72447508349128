import React, { useState } from 'react';
import { Tree as AntTree } from 'antd';

const Tree = props => {
  const [expandedKeys, setExpandedKeys] = useState([]);

  const handleNodeExpand = keys => setExpandedKeys(keys);

  const handleTitleClick = key => {
    setExpandedKeys(prevKeys => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter(e => e !== key);
      }

      return [...prevKeys, key];
    });
  };

  return (
    <AntTree
      onExpand={handleNodeExpand}
      expandedKeys={expandedKeys}
      titleRender={record => (
        <span
          tabIndex="0"
          role="button"
          key={record.key}
          onClick={() => handleTitleClick(record.key)}
          style={{ display: 'inline-block', width: '100%', height: '100%' }}
        >
          {record.title}
        </span>
      )}
      {...props}
    />
  );
};

export default Tree;
