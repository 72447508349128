import {
  Button,
  Flex,
  Heading,
  Icon,
  Loader,
  Select,
  TextField,
} from '@mediahuis/chameleon-react';
import { Drag } from '@mediahuis/chameleon-theme-wl/icons';
import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { notifyError } from '~/components';
import { TableTransfer } from '~/components/TableTransfer';
import {
  CONTENT_LINKS_KEY,
  useAllContents,
  useContentLinks,
  useCreateContentLink,
  useDeleteContentLink,
  usePatchContentLink,
} from '~/services/content';

const columns = [
  {
    dataIndex: 'id',
    title: 'Id',
    render: key => {
      const offerId = key;

      return <Link to={`/contents/edit/${offerId}`}>{key}</Link>;
    },
  },
  {
    dataIndex: 'brand',
    title: 'Brand',
    render: tag => <Tag>{tag}</Tag>,
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
];

const draggableColumns = [
  ...columns,
  {
    dataIndex: '',
    title: '',
    render: () => <Icon size="small" as={Drag} />,
  },
];

const ContentsLocationsTransfer = ({
  brandOptions,
  contentKey,
  locationOptions,
  previewUrl,
  title,
}) => {
  const queryClient = useQueryClient();

  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedBrandOption, setSelectedBrandOption] = useState(
    brandOptions[0].value,
  );
  const [selectedLocationOption, setSelectedLocationOption] = useState(
    locationOptions[0].value,
  );
  const [targetKeys, setTargetKeys] = useState({});

  const contentsQuery = useAllContents({
    brand: selectedBrandOption,
    excludedKeys: HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE,
  });
  const contentLinksQuery = useContentLinks(selectedBrandOption, contentKey);
  const createContentLink = useCreateContentLink();
  const deleteContentLink = useDeleteContentLink();
  const patchContentLink = usePatchContentLink();

  useEffect(() => {
    if (contentsQuery.isSuccess) {
      const transferDataSource = contentsQuery.data.list.map(htmlContent => ({
        brand: htmlContent.brand,
        id: htmlContent.id,
        key: htmlContent.id,
        name: htmlContent.name,
      }));

      setDataSource(transferDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandOption, contentsQuery.isSuccess]);

  useEffect(() => {
    if (contentLinksQuery.isSuccess) {
      if (!targetKeys[selectedBrandOption]?.[selectedLocationOption]) {
        const contentLinks =
          contentLinksQuery.data.contentLinks?.[selectedLocationOption] || [];
        const contentLinkIds = contentLinks.map(
          contentLink => contentLink.contentId,
        );

        setTargetKeys(prevTargetKeys => ({
          ...prevTargetKeys,
          [selectedBrandOption]: {
            ...prevTargetKeys[selectedBrandOption],
            [selectedLocationOption]: contentLinkIds,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBrandOption,
    selectedLocationOption,
    contentLinksQuery.isSuccess,
  ]);

  useEffect(() => {
    if (
      createContentLink.isError ||
      deleteContentLink.isError ||
      patchContentLink.isError
    ) {
      notifyError({
        message:
          'An error was encountered while updating the locations. Please refresh the page to see the current state and try again.',
      });
    }
  }, [
    createContentLink.isError,
    deleteContentLink.isError,
    patchContentLink.isError,
  ]);

  function changeTargetPosition(startIndex, endIndex) {
    const newTargetKeys = [
      ...targetKeys[selectedBrandOption][selectedLocationOption],
    ];
    const newTargetKey = newTargetKeys.splice(startIndex, 1)[0];

    newTargetKeys.splice(endIndex, 0, newTargetKey);
    setTargetKeys(prevTargetKeys => ({
      ...prevTargetKeys,
      [selectedBrandOption]: {
        ...prevTargetKeys[selectedBrandOption],
        [selectedLocationOption]: [...newTargetKeys],
      },
    }));
  }

  function transferItems(newTargetKeys) {
    setTargetKeys(prevTargetKeys => ({
      ...prevTargetKeys,
      [selectedBrandOption]: {
        ...prevTargetKeys[selectedBrandOption],
        [selectedLocationOption]: [...newTargetKeys],
      },
    }));
  }

  function updateContentLinks() {
    Object.keys(targetKeys).forEach(brand => {
      const queryData = queryClient.getQueryData([
        CONTENT_LINKS_KEY,
        brand,
        contentKey,
      ]);
      const contentLinks = queryData.contentLinks || {};

      Object.keys(targetKeys[brand]).forEach(location => {
        const currentKeys = targetKeys[brand][location];
        const locationContentLinks = contentLinks[location] || [];

        if (locationContentLinks.length > currentKeys.length) {
          const removedLinks = locationContentLinks.filter(
            link => !currentKeys.includes(link.contentId),
          );

          if (removedLinks.length > 0) {
            removedLinks.forEach(removedLink => {
              deleteContentLink.mutate({
                brand,
                contentKey,
                id: removedLink.id,
                location,
              });
            });
          }
        }

        currentKeys.forEach((currentKey, currentKeyIndex) => {
          const cacheData = queryClient.getQueryData([
            'all-contents',
            { brand, excludedKeys: HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE },
          ]);
          const contentItem = cacheData?.list.find(
            item => item.id === currentKey,
          );
          const contentLinkItem = locationContentLinks.find(
            item => item.contentId === currentKey,
          );

          if (contentItem && contentLinkItem) {
            patchContentLink.mutate({
              brand,
              contentId: contentItem.id,
              id: contentLinkItem.id,
              key: contentKey,
              location,
              position: currentKeyIndex + 1,
            });
          } else if (contentItem) {
            createContentLink.mutate({
              brand,
              contentId: contentItem.id,
              key: contentKey,
              location,
              position: currentKeyIndex + 1,
            });
          }
        });
      });
    });
  }

  return (
    <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Heading fontFamily="primary" level={6} mb={0}>
          {title}
        </Heading>

        <Flex style={{ gap: '0.5rem' }}>
          {previewUrl && (
            <Button
              data-testid={`content-locations-view-${title}`}
              size="small"
              onClick={() =>
                window.open(previewUrl(selectedBrandOption), '_blank')
              }
            >
              View
            </Button>
          )}

          <Button
            data-testid={`content-locations-save-${title}`}
            loading={
              createContentLink.isLoading ||
              deleteContentLink.isLoading ||
              patchContentLink.isLoading
            }
            size="small"
            onClick={updateContentLinks}
          >
            Save
          </Button>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" style={{ gap: '1rem' }}>
        <Select
          data-testid={`${title}-select-brand`.toLowerCase()}
          label=""
          labelHidden
          style={{ width: '50%' }}
          value={selectedBrandOption}
          onChange={e => setSelectedBrandOption(e.target.value)}
        >
          {brandOptions.map(brandOption => (
            <option key={brandOption.value} value={brandOption.value}>
              {brandOption.label}
            </option>
          ))}
        </Select>
        <Select
          data-testid={`${title}-select-location`.toLowerCase()}
          label=""
          labelHidden
          style={{ width: '50%' }}
          value={selectedLocationOption}
          onChange={e => setSelectedLocationOption(e.target.value)}
        >
          {locationOptions.map(locationOption => (
            <option key={locationOption.value} value={locationOption.value}>
              {locationOption.label}
            </option>
          ))}
        </Select>
      </Flex>

      <TextField
        label="Search"
        labelHidden
        placeholder="Search"
        onChange={event => setSearchValue(event.target.value)}
      />

      {contentsQuery.isSuccess && contentLinksQuery.isSuccess ? (
        <TableTransfer
          columns={columns}
          draggableColumns={draggableColumns}
          dataSource={dataSource.filter(item =>
            item.name.toUpperCase().includes(searchValue.toUpperCase()),
          )}
          targetKeys={
            targetKeys[selectedBrandOption]
              ? targetKeys[selectedBrandOption][selectedLocationOption]
              : []
          }
          titles={['Available', 'Active']}
          onChange={transferItems}
          onRowChange={changeTargetPosition}
        />
      ) : (
        <Flex justifyContent="center" py={8}>
          <Loader />
        </Flex>
      )}
    </Flex>
  );
};

export default ContentsLocationsTransfer;
