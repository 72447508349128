import { Flex, Banner } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useQueryParams } from '~/hooks';
import { SubscriptionTypesQuery } from '~/services/offer';

import SubscriptionTypesFilters from '../../types/SubscriptionTypesFilters';
import SubscriptionTypesTable from '../../types/SubscriptionTypesTable';

const defaultOffset = 0;
const defaultOffsetSize = 20;

const ChooseSubscriptionType = ({ onRowClick }) => {
  const { pathname } = useLocation();

  const [params, setParams] = useQueryParams();

  function resetFilters() {
    setParams({
      offset: defaultOffset,
    });
  }

  if (!params.offset) {
    return <Redirect to={`${pathname}?offset=${defaultOffset}`} />;
  }

  return (
    <SubscriptionTypesQuery
      filters={params}
      render={(subscriptionTypes, isFetchingSubscriptionTypes) => (
        <>
          <Banner appearance="info" closeHidden show mb={8} mx={5}>
            Click on a type to form the basis of your product
          </Banner>
          <Flex
            justifyContent="space-between"
            px={5}
            style={{ gap: '2.5rem' }}
            width="full"
          >
            <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
              <Flex justifyContent="space-between">
                <Pagination
                  current={params.offset / defaultOffsetSize + 1}
                  pageSize={defaultOffsetSize}
                  showSizeChanger={false}
                  total={subscriptionTypes.totalCount}
                  onChange={page =>
                    setParams({
                      ...params,
                      offset: (page - 1) * defaultOffsetSize,
                    })
                  }
                />
              </Flex>

              <SubscriptionTypesTable
                isLoading={isFetchingSubscriptionTypes}
                subscriptionTypes={subscriptionTypes}
                onRowClick={onRowClick}
              />
            </Flex>

            <SubscriptionTypesFilters onReset={resetFilters} />
          </Flex>
        </>
      )}
    />
  );
};

export default ChooseSubscriptionType;
