import React from 'react';
import styled from 'styled-components';
import {
  Button as ChameleonButton,
  Icon as IconWrapper,
  Flex,
} from '@mediahuis/chameleon-react';
import noop from '../../utils/noop';

const CHButton = styled(ChameleonButton)`
  &:disabled {
    background-color: ${props => props.theme.colors.grayLight};
    color: ${props => props.theme.colors.gray};
    border: none;
  }
  &:last-child {
    margin-left: ${props => props.theme.spacing.half};
  }
`;

export const Button = ({
  color = 'default',
  Icon,
  label = '',
  size = 'small',
  variant = 'contained',
  onClick = noop,
  className,
  isLoading = false,
  datatestid = '',
  disabled = false,
  type = 'button',
  appearance = 'primary',
  iconSize = 'small',
  p = 0,
}) => (
  <CHButton
    p={p}
    aria-label={label}
    color={color}
    size={size}
    className={className}
    variant={variant}
    onClick={onClick}
    disabled={!!(disabled || isLoading)}
    data-testid={datatestid}
    type={type}
    appearance={appearance}
    isLoading={isLoading}
  >
    {Icon && (
      <Flex flexDirection="column" justifyContent="center" mr={3}>
        <IconWrapper as={Icon} size={iconSize} />
      </Flex>
    )}
    {label}
  </CHButton>
);
