import { Box, Flex } from '@mediahuis/chameleon-react';
import React from 'react';

const DetailLayout = ({ aside, main }) => {
  return (
    <Flex justifyContent="center" style={{ gap: '5rem' }} width="full">
      <Box flexGrow={1}>{main}</Box>

      {aside && (
        <Box flexBasis="20%" height="fit-content" position="sticky" top={0}>
          {aside}
        </Box>
      )}
    </Flex>
  );
};

export default DetailLayout;
