import styled from 'styled-components';
import { Select as AntSelect } from 'antd';
import {
  colorPrimary10,
  colorPrimary50,
  colorPrimary30,
} from '@mediahuis/chameleon-theme-wl';

/* Restyle Ant Select component so it looks like a Chameleon component */
export const MultiSelect = styled(AntSelect)`
  && {
    .ant-select-selector {
      padding: 8px;
      border: 1px solid #919191;
      border-radius: 4px;
      min-height: 48px;
    }
    .ant-select-selection-item {
      background-color: ${colorPrimary10};
      padding: 0 4px;
      margin-right: 4px;
      border: 1px solid ${colorPrimary30};
      color: ${colorPrimary50};
    }
    .anticon {
      color: ${colorPrimary50};
    }
  }
`;

export const { Option } = MultiSelect;
