import { Table, Transfer } from 'antd';
import React from 'react';
import { DndProvider } from 'react-dnd';
import difference from 'lodash.difference';
import HTML5Backend from 'react-dnd-html5-backend';

import TableTransferDragRow from './TableTransferDragRow';

const TableTransfer = ({
  columns,
  draggableColumns,
  onRowChange,
  ...transferProps
}) => {
  return (
    <Transfer {...transferProps}>
      {({
        disabled: transferDisabled,
        direction,
        filteredItems,
        selectedKeys: listSelectedKeys,
        onItemSelect,
        onItemSelectAll,
      }) => {
        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: transferDisabled || item.disabled,
          }),
          onSelect: ({ key }, selected) => onItemSelect(key, selected),
          onSelectAll: (selected, selectedRows) => {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={direction === 'right' ? draggableColumns : columns}
              components={{
                body: {
                  row: direction === 'right' ? TableTransferDragRow : undefined,
                },
              }}
              dataSource={filteredItems}
              rowSelection={rowSelection}
              size="small"
              style={{
                pointerEvents: transferDisabled ? 'none' : undefined,
              }}
              onRow={({ key, disabled: itemDisabled }, index) => ({
                index,
                style: direction === 'left' ? { cursor: 'pointer' } : undefined,
                onClick: () => {
                  if (itemDisabled || transferDisabled) return;

                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
                onDrop: onRowChange,
              })}
            />
          </DndProvider>
        );
      }}
    </Transfer>
  );
};

export default TableTransfer;
