import React, { Fragment } from 'react';
import { Button } from '@mediahuis/chameleon-react';
import { Popconfirm } from 'antd';
import {
  Save,
  Copy,
  Clipboard,
  ExternalLink,
} from '@mediahuis/chameleon-theme-wl/icons';
import { colorRedBase } from '@mediahuis/chameleon-theme-wl';
import { noop } from '../../utils';

const FormButtons = ({
  editing,
  copyMethod,
  pasteMethod,
  deleteMethod,
  duplicateMethod,
  preview = noop,
  onSave = noop,
  isLoading = false,
  isPastable = false,
  isDeletable = false,
  isDuplicable = false,
  isPreviewable = false,
}) => {
  return (
    <div id="formbuttons">
      <Button
        data-testid="button-save"
        iconLeft={Save}
        loading={isLoading}
        onClick={onSave}
        width="full"
        mb={4}
      >
        Save
      </Button>
      {editing && (
        <Fragment>
          {copyMethod && (
            <Button
              data-testid="button-copy"
              iconLeft={Clipboard}
              loading={isLoading}
              onClick={copyMethod}
              appearance="secondary"
              width="full"
              size="small"
              mb={4}
            >
              Copy
            </Button>
          )}
          {isPastable && (
            <Button
              data-testid="button-paste"
              iconLeft={Clipboard}
              loading={isLoading}
              onClick={pasteMethod}
              appearance="secondary"
              width="full"
              size="small"
              mb={4}
            >
              Paste
            </Button>
          )}
          {isDuplicable && (
            <Button
              data-testid="button-duplicate"
              iconLeft={Copy}
              loading={isLoading}
              onClick={duplicateMethod}
              appearance="secondary"
              width="full"
              size="small"
              mb={4}
            >
              Duplicate
            </Button>
          )}
          {isPreviewable && (
            <Button
              data-testid="duplicate-formula-button"
              iconLeft={ExternalLink}
              loading={isLoading}
              onClick={preview}
              appearance="secondary"
              width="full"
              size="small"
              mb={4}
            >
              View
            </Button>
          )}
          {isDeletable && (
            <Popconfirm
              title="Are you sure that you want to delete?"
              onConfirm={deleteMethod}
              onCancel={noop}
              okText={<span data-testid="button-delete-confirm">Confirm</span>}
              cancelText={
                <span data-testid="button-delete-cancel">Cancel</span>
              }
            >
              <Button
                data-testid="button-delete"
                loading={isLoading}
                appearance="plain"
                size="small"
                width="full"
                style={{ color: colorRedBase }}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default FormButtons;
