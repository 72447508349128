import { Table } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (text, record) => (
      <Link
        to={location => `${location.pathname}/edit/${record.id}`}
        onClick={event => event.stopPropagation()}
      >
        {text}
      </Link>
    ),
  },
  { dataIndex: 'brand', title: 'Brand' },
  {
    dataIndex: 'name',
    title: 'Name',
  },
];

const ContentsTable = ({ contents, isLoading }) => {
  const history = useHistory();

  return (
    <Table
      columns={columns}
      dataSource={contents}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(`${history.location.pathname}/edit/${record.id}`),
      })}
    />
  );
};

export default ContentsTable;
