const getDescriptionValidation = description => {
  let errors = {};
  if (!description) {
    errors = {
      ...errors,
      description: { message: 'description is a required field' },
    };
  }
  return errors;
};

export default getDescriptionValidation;
