import { Box, Text } from '@mediahuis/chameleon-react';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const Dropzone = ({ onDrop, acceptedFiles = 'image/*', filesLimit = 1 }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptedFiles, onDrop, maxFiles: filesLimit });

  return (
    <div className="container">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <Box textAlign="center">
          <Text>Drag and drop some files here, or click to select files.</Text>
        </Box>
      </Container>
    </div>
  );
};

export default Dropzone;
