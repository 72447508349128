import React from 'react';
import styled from 'styled-components';
import {
  Select as ChSelect,
  TextField as ChTextField,
  Flex,
} from '@mediahuis/chameleon-react';
import noop from '../../utils/noop';

const Select = styled(ChSelect)`
  select {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: none;
  }
`;

const TextField = styled(ChTextField)`
  input {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    position: relative !important;
  }
`;

const Search = ({ updateFilter = noop, value }) => {
  const handleChange = newValue => {
    updateFilter('search', { ...value, ...newValue });
  };

  const setType = type => {
    switch (type) {
      case 'formula':
      case 'subscriptionTypeId':
        return 'number';
      default:
        return 'text';
    }
  };

  return (
    <Flex>
      <Select
        id="searchSelect"
        data-testid="searchSelect"
        placeholder="Search item"
        onChange={e => handleChange({ item: e.target.value, keyword: '' })}
        value={value.item}
        label="Search"
      >
        {value.searchableItems.map(state => (
          <option key={state.value} value={state.value}>
            {state.key}
          </option>
        ))}
      </Select>
      <TextField
        id="search"
        placeholder="Search"
        onChange={e => handleChange({ keyword: e.target.value })}
        value={value.keyword}
        type={setType(value.item)}
      />
    </Flex>
  );
};

export default Search;
