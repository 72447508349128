import styled from 'styled-components';
import { TOP_BAR_HEIGHT } from '../constants/styles';

export const StickyTop = styled.div`
  position: sticky;
  top: 80px;
`;

export const StickyBottom = styled.div`
  position: sticky;
  bottom: ${props => props.theme.spacing.base};
`;

export const Sidebar = styled.div`
  width: 180px;
  min-height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${props => props.theme.spacing.base};
`;

export const StickySidebar = styled.div`
  width: 180px;
  position: sticky;
  top: ${TOP_BAR_HEIGHT};
  margin-left: ${props => props.theme.spacing.base};
`;
