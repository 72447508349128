const getPastedValues = (clipBoard = {}, initialValues = {}) => {
  let pastedValues = {};

  if (clipBoard.brand === initialValues.brand) {
    pastedValues = { ...clipBoard };
  } else {
    const { brand, image, features } = initialValues;
    pastedValues = { ...clipBoard, brand, image, features };

    if (clipBoard.type === 'type') {
      pastedValues.logo = initialValues.logo;
    }

    if (clipBoard.type === 'formula') {
      pastedValues.subscriptionTypes = initialValues.subscriptionTypes;
    }
  }

  if (clipBoard.requirements) {
    pastedValues.requirements = clipBoard.requirements.map(req => {
      const newReq = {
        localId: true,
        id: Date.now(),
        name: req.name,
        type: req.type,
        brand: initialValues.brand,
        description: req.description,
      };

      if (req.parameters) {
        const newReqParams = { ...req.parameters };
        if (newReqParams.brand) {
          newReqParams.brand = initialValues.brand;
        }
        newReq.parameters = newReqParams;
      }

      return newReq;
    });
  }

  pastedValues.id = initialValues.id;
  pastedValues.reference = `${clipBoard.reference}-copy`;

  return pastedValues;
};

export default getPastedValues;
