import React from 'react';
import { Flex, Loader } from '@mediahuis/chameleon-react';

const PageLoader = () => {
  return (
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Loader />
    </Flex>
  );
};

export default PageLoader;
