import { Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = ({ onChange }) => {
  const { i18n } = useTranslation();

  const menuItems = ALL_POSSIBLE_LANGUAGES.map(({ label, value }) => ({
    key: value,
    label,
  }));

  return (
    <Dropdown
      data-testid="language-dropdown"
      overlay={
        <Menu
          items={menuItems}
          data-testid="language-dropdown-menu"
          onClick={({ key }) => {
            i18n.changeLanguage(key);
            onChange(key);
          }}
        />
      }
      trigger={['click']}
    >
      <Button data-testid="language-dropdown-btn">
        <Space>{i18n.language}</Space>
      </Button>
    </Dropdown>
  );
};

export default LanguageDropdown;
