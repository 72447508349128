import base from './base';

export const language = {
  ...base,
  getPaymentUrl: (id, brand) => {
    if (brand === 'dl') {
      return `https://www.mollie.com/dashboard/org_12462428/payments/${id}`;
    }

    return `https://plaza.buckaroo.nl/Transaction/Transactions/Details?transactionKey=${id}`;
  },
};
