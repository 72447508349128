import {
  Checkbox,
  Flex,
  Textarea,
  TextField,
} from '@mediahuis/chameleon-react';
import { Select } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, object, string } from 'yup';

import { notifyError } from '~/components';
import { getCountryCodes } from '~/utils';

const AddressAreaRequirementFormSchema = object().shape({
  countries: array().of(string()),
  description: string(),
  hasDeliveryArea: boolean(),
  isDeliveryAddress: boolean(),
  name: string().required(),
  pickUpAllowed: boolean(),
});

const AddressAreaRequirementForm = ({
  disabled,
  formRef,
  initialValues,
  onSubmit,
}) => {
  const { i18n } = useTranslation();

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountryCodes()
      .then(response => {
        setCountries(response);

        return response;
      })
      .catch(() => {
        notifyError({
          message:
            'Could not get country codes from address service. Try again later.',
        });
      });
  }, [i18n.language]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={AddressAreaRequirementFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NameInput"
                label="Name"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="countries">
            {({ field, form, meta }) => (
              <Select
                disabled={disabled}
                id="CountriesSelect"
                mode="multiple"
                name={field.name}
                optionFilterProp="label"
                options={countries.map(country => ({
                  label: country.Name,
                  value: country.IsoCode,
                }))}
                status={!!meta.touched && !!meta.error ? 'error' : undefined}
                value={field.value}
                onBlur={field.onBlur}
                onChange={values => form.setFieldValue('countries', values)}
              />
            )}
          </Field>

          <Field name="hasDeliveryArea">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="HasDeliveryAreaCheck"
                label="Delivery Area"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="isDeliveryAddress">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="IsDeliveryAddressCheck"
                label="Is Delivery Address"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="pickUpAllowed">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="PickUpAllowedCheck"
                label="Pickup Allowed"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>
        </Flex>
      </Form>
    </Formik>
  );
};

export default AddressAreaRequirementForm;
