import { useQuery } from 'react-query';

import { ContentService } from '~/services/content';

export const CONTENTS_KEY = 'contents';

const useContents = (params, enabled = true) => {
  return useQuery(
    [CONTENTS_KEY, params],
    () => ContentService.getContents(params).then(response => response.data),
    {
      enabled,
    },
  );
};

export default useContents;
