import { Flex } from '@mediahuis/chameleon-react';
import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import enabledRoutes from '~/routes';
import {
  configRoutes,
  homeRoutes,
  htmlContentsLocationsRoutes,
  htmlContentsOverviewRoutes,
  offersRoutes,
  ordersRoutes,
  preconditionRulesRoutes,
  productsRoutes,
  proposalsRoutes,
  typesRoutes,
  vouchersOverviewRoutes,
  vouchersTemplatesRoutes,
} from '~/routes/base';

import './Navigation.css';

import NavigationCollapse from './NavigationCollapse';
import NavigationItem from './NavigationItem';

const hasLocationMatch = (routes, pathName) =>
  routes.some(route =>
    matchPath(pathName, {
      exact: route.exact,
      path: route.path,
    }),
  );

const navigationItems = [
  {
    enabled: enabledRoutes.some(enabledRoute =>
      homeRoutes.map(homeRoute => homeRoute.path).includes(enabledRoute.path),
    ),
    key: 'home',
    rootPath: '/',
    routes: homeRoutes,
    testId: 'home',
    title: 'Home',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      typesRoutes
        .map(typesRoute => typesRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'subscriptiontype',
    rootPath: '/subscriptiontype',
    routes: typesRoutes,
    testId: 'subscriptiontype-menu-item',
    title: 'Types',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      productsRoutes
        .map(productsRoute => productsRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'subscriptionformula',
    rootPath: '/subscriptionformula',
    routes: productsRoutes,
    testId: 'subscriptionformula-menu-item',
    title: 'Products',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      offersRoutes
        .map(offersRoute => offersRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'offers',
    rootPath: '/offers',
    routes: offersRoutes,
    testId: 'offers-menu-item',
    title: 'Offers',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      ordersRoutes
        .map(ordersRoute => ordersRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'orders',
    rootPath: '/orders',
    routes: ordersRoutes,
    testId: 'orders-menu-item',
    title: 'Orders',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      preconditionRulesRoutes
        .map(preconditionRulesRoute => preconditionRulesRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'preconditions',
    rootPath: '/preconditions',
    routes: preconditionRulesRoutes,
    testId: 'preconditions-menu-item',
    title: 'Precondition Rules',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      proposalsRoutes
        .map(proposalsRoute => proposalsRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'proposals',
    rootPath: '/proposals',
    routes: proposalsRoutes,
    testId: 'proposals-menu-item',
    title: 'Proposals',
  },
  {
    enabled: enabledRoutes.some(
      enabledRoute =>
        vouchersOverviewRoutes
          .map(vouchersOverviewRoute => vouchersOverviewRoute.path)
          .includes(enabledRoute.path) ||
        vouchersTemplatesRoutes
          .map(vouchersTemplatesRoute => vouchersTemplatesRoute.path)
          .includes(enabledRoute.path),
    ),
    items: [
      {
        key: 'vouchers-overview',
        rootPath: '/vouchers',
        routes: vouchersOverviewRoutes,
        testId: 'vouchers-overview-menu-item',
        title: 'Overview',
      },
      {
        key: 'vouchers-templates',
        rootPath: '/vouchers/templates',
        routes: vouchersTemplatesRoutes,
        testId: 'vouchers-templates-menu-item',
        title: 'Templates',
      },
    ],
    key: 'vouchers',
    testId: 'vouchers-menu-item',
    title: 'Vouchers',
  },
  {
    enabled: enabledRoutes.some(
      enabledRoute =>
        htmlContentsOverviewRoutes
          .map(htmlContentsOverviewRoute => htmlContentsOverviewRoute.path)
          .includes(enabledRoute.path) ||
        htmlContentsLocationsRoutes
          .map(htmlContentsLocationsRoute => htmlContentsLocationsRoute.path)
          .includes(enabledRoute.path),
    ),
    items: [
      {
        key: 'contents-overview',
        rootPath: '/contents',
        routes: htmlContentsOverviewRoutes,
        testId: 'contents-overview-menu-item',
        title: 'Overview',
      },
      {
        key: 'contents-locations',
        rootPath: '/contents/locations',
        routes: htmlContentsLocationsRoutes,
        testId: 'contents-locations-menu-item',
        title: 'Locations',
      },
    ],
    key: 'contents',
    testId: 'contents-menu-item',
    title: 'HTML Content',
  },
  {
    enabled: enabledRoutes.some(enabledRoute =>
      configRoutes
        .map(configRoute => configRoute.path)
        .includes(enabledRoute.path),
    ),
    key: 'config',
    rootPath: '/config',
    routes: configRoutes,
    testId: 'config-menu-item',
    title: 'Config',
  },
];

const Navigation = () => {
  const location = useLocation();

  const activeItem = navigationItems
    .reduce((acc, navigationItem) => {
      if (navigationItem.items) {
        return acc.concat(navigationItem.items);
      }

      return acc.concat(navigationItem);
    }, [])
    .find(navigationItem => {
      if (navigationItem.routes) {
        return hasLocationMatch(navigationItem.routes, location.pathname);
      }

      return false;
    });

  return (
    <Flex flexDirection="column">
      {navigationItems.map(navigationItem => {
        if (navigationItem.enabled) {
          if (navigationItem.items) {
            return (
              <NavigationCollapse
                active={navigationItem.items.find(
                  navigationSubItem => activeItem.key === navigationSubItem.key,
                )}
                key={navigationItem.key}
                testId={navigationItem.testId}
                title={navigationItem.title}
              >
                {navigationItem.items.map(navigationSubItem => (
                  <Link
                    className="navigation-link"
                    key={navigationSubItem.key}
                    to={navigationSubItem.rootPath}
                  >
                    <NavigationItem
                      active={activeItem.key === navigationSubItem.key}
                      isChild
                      testId={navigationSubItem.testId}
                      title={navigationSubItem.title}
                    />
                  </Link>
                ))}
              </NavigationCollapse>
            );
          }

          return (
            <Link
              className="navigation-link"
              key={navigationItem.key}
              to={navigationItem.rootPath}
            >
              <NavigationItem
                active={activeItem.key === navigationItem.key}
                testId={navigationItem.testId}
                title={navigationItem.title}
              />
            </Link>
          );
        }

        return null;
      })}
    </Flex>
  );
};

export default Navigation;
