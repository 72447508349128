import React from 'react';

import { useSubscriptionScopes } from '~/services/subscriptionScope';

const SubscriptionScopesQuery = ({ filters, render }) => {
  const subscriptionScopesQuery = useSubscriptionScopes(filters);

  if (subscriptionScopesQuery.isError) {
    return <p>Error while loading subscriptionScopes</p>;
  }

  if (subscriptionScopesQuery.isSuccess) {
    return render(
      subscriptionScopesQuery.data,
      subscriptionScopesQuery.isFetching,
    );
  }

  return <p>Loading subscriptionScopes..</p>;
};

export default SubscriptionScopesQuery;
