import { Flex } from '@mediahuis/chameleon-react';
import { Table } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { StatusCircle } from '~/components';
import { states } from '~/constants';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (text, record) => (
      <Link
        to={location => `${location.pathname}/edit/${record.id}`}
        onClick={event => event.stopPropagation()}
      >
        {text}
      </Link>
    ),
  },
  { dataIndex: 'brand', title: 'Brand' },
  { dataIndex: 'user', title: 'User' },
  { dataIndex: 'creationDate', title: 'Creation Date', width: 160 },
  { dataIndex: 'updateDate', title: 'Update Date', width: 160 },
  {
    dataIndex: 'state',
    render: state => (
      <Flex alignItems="center" style={{ gap: '0.5rem' }}>
        <StatusCircle variant={states[state]} />
        {state}
      </Flex>
    ),
    title: 'State',
  },
  { dataIndex: 'type', title: 'Type' },
];

const mapOrdersResults = results => {
  return results.map(result => {
    const updatedResult = {
      ...result,
    };

    if (updatedResult.creationDate) {
      updatedResult.creationDate = format(
        new Date(updatedResult.creationDate),
        'dd/MM/yyyy HH:mm',
      )
    }
    if (updatedResult.updateDate) {
      updatedResult.updateDate = format(
        new Date(updatedResult.updateDate),
        'dd/MM/yyyy HH:mm',
      )
    }

    if (updatedResult.loginValidation && updatedResult.loginValidation.data) {
      updatedResult.user = `${updatedResult.loginValidation.data.firstName || ''
        } ${updatedResult.loginValidation.data.lastName || ''}`;
    }

    return updatedResult;
  });
};

const OrdersTable = ({ isLoading, orders }) => {
  const history = useHistory();

  const tableData = mapOrdersResults(orders.results);

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(`${history.location.pathname}/edit/${record.id}`),
      })}
    />
  );
};

export default OrdersTable;
