import { Box, Button, Flex } from '@mediahuis/chameleon-react';
import React, { useRef } from 'react';

import OfferGroupForm from './OfferGroupForm';

const OfferGroupCreate = ({ offer, onCancel, onSubmit }) => {
  const formRef = useRef(null);

  return (
    <Box
      borderColor="colorGrey20"
      borderRadius={2}
      borderStyle="solid"
      borderWidth={1}
      display="flex"
      flexDirection="column"
      p={4}
      style={{ gap: '2rem' }}
    >
      <OfferGroupForm
        formRef={formRef}
        initialValues={{ defaultSelected: false }}
        offer={offer}
        onSubmit={onSubmit}
      />

      <Flex flexDirection="column" style={{ gap: '0.25rem' }}>
        <Button
          appearance="primary"
          size="small"
          onClick={() => formRef.current.submitForm()}
        >
          Save
        </Button>
        <Button appearance="secondary" size="small" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

export default OfferGroupCreate;
