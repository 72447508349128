import React from 'react';
import { Paper, Box, Text, Flex } from '@mediahuis/chameleon-react';
import styled from 'styled-components';
import useAboshopContext from './context/useContext';
import BackButton from './buttons/BackButton';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const BrandWizard = ({ handleSelectBrand }) => {
  const { brands } = useAboshopContext();

  if (brands.length === 1) {
    handleSelectBrand(brands[0].code);
    return null;
  }

  return (
    <Flex justifyContent="center">
      <Box width="800px">
        <BackButton />
        <Grid>
          {brands.map(brand => (
            <Paper
              data-testid={`${brand.code}-brand-select`}
              onClick={() => handleSelectBrand(brand.code)}
              hoverable
              elevation={1}
              p={4}
              key={brand.code}
            >
              <Box align="center">
                <Text size="Heading5">{brand.name}</Text>
              </Box>
            </Paper>
          ))}
        </Grid>
      </Box>
    </Flex>
  );
};

export default BrandWizard;
