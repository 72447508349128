import { Auth } from 'aws-amplify';
import Axios from 'axios';
import queryString from 'query-string';
import { getI18n } from 'react-i18next';

const voucherServiceInstance = Axios.create({
  baseURL: API_VOUCHER_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
});

voucherServiceInstance.interceptors.request.use(
  async config => {
    const { language } = getI18n();
    const session = await Auth.currentSession();

    if (language) {
      config.params.lang = language;
    }
    if (session.idToken?.jwtToken) {
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    }

    return config;
  },
  err => Promise.reject(err),
);

class VoucherService {
  static getVouchers(filters) {
    const filterParams = queryString.stringify(filters);

    return voucherServiceInstance.get(`/vouchers?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }

  static getVouchersCsv(filters) {
    const filterParams = queryString.stringify(filters);

    return voucherServiceInstance.get(`/vouchers?${filterParams}`, {
      headers: {
        Accept: 'text/csv',
      },
      params: {
        amount: 10000,
      },
    });
  }
}

export default VoucherService;
