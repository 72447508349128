import { Flex, Heading } from '@mediahuis/chameleon-react';
import React from 'react';

import { useAboshopContext } from '~/components';
import { DetailLayout } from '~/layouts/DetailLayout';

import ContentsLocationsTransfer from './ContentsLocationsTransfer';
import locationsConfig from './locations-config';

const ContentsLocations = () => {
  const { brands: mhBrands } = useAboshopContext();

  return (
    <DetailLayout
      main={
        <Flex flexDirection="column" style={{ gap: '2rem' }}>
          <Heading fontFamily="primary" level={4}>
            Locations
          </Heading>

          {mhBrands.length > 0 && locationsConfig.map(configItem => (
            <ContentsLocationsTransfer
              brandOptions={mhBrands.map(mhBrand => ({
                label: mhBrand.name,
                value: mhBrand.code,
              }))}
              contentKey={configItem.key}
              key={configItem.key}
              locationOptions={configItem.locationOptions}
              previewUrl={configItem.previewUrl}
              title={configItem.name}
            />
          ))}
        </Flex>
      }
    />
  );
};

export default ContentsLocations;
