import React from 'react';

import { usePreconditions } from '~/services/precondition';

const PreconditionsQuery = ({ filters, render }) => {
  const preconditionsQuery = usePreconditions(filters);

  if (preconditionsQuery.isError) {
    return <p>Error while loading preconditions</p>;
  }

  if (preconditionsQuery.isSuccess) {
    return render(preconditionsQuery.data, preconditionsQuery.isFetching);
  }

  return <p>Loading preconditions..</p>;
};

export default PreconditionsQuery;
