import { Divider, Heading, Paper, Text } from '@mediahuis/chameleon-react';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useOrderSettlement } from '../../../services/order-settle';

const SettlementsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
`;

const SettlementsListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Settlements = ({ orderId }) => {
  const orderSettlementQuery = useOrderSettlement(orderId);

  return (
    <Paper mb={5} p={4}>
      <Heading fontFamily="primary" level={6}>
        Settlements
      </Heading>

      {orderSettlementQuery.isLoading && <Text>Loading..</Text>}

      {orderSettlementQuery.isSuccess && (
        <>
          {orderSettlementQuery.data.map(
            (orderSettlement, orderSettlementIndex) =>
              orderSettlement ? (
                <Fragment key={orderSettlement.agreementId}>
                  <SettlementsList>
                    <SettlementsListItem>
                      <Text fontWeight="bold" size="Caption2">
                        Agreement Id
                      </Text>
                      <Text size="Caption2">{orderSettlement.agreementId}</Text>
                    </SettlementsListItem>

                    <SettlementsListItem>
                      <Text fontWeight="bold" size="Caption2">
                        Payment Reference
                      </Text>
                      <Text size="Caption2">
                        {orderSettlement.paymentReference}
                      </Text>
                    </SettlementsListItem>

                    <SettlementsListItem>
                      <Text fontWeight="bold" size="Caption2">
                        Page Number
                      </Text>
                      <Text size="Caption2">{orderSettlement.pageNumber}</Text>
                    </SettlementsListItem>

                    <SettlementsListItem>
                      <Text fontWeight="bold" size="Caption2">
                        Outstanding Payment Reason
                      </Text>
                      <Text size="Caption2">
                        {orderSettlement.outstandingPaymentReason}
                      </Text>
                    </SettlementsListItem>
                  </SettlementsList>

                  {orderSettlementIndex <
                    orderSettlementQuery.data.length - 1 && (
                    <Divider style={{ marginBottom: '1em', width: '100%' }} />
                  )}
                </Fragment>
              ) : (
                <Text fontStyle="italic" key={orderSettlement} size="Caption2">
                  empty
                </Text>
              ),
          )}
        </>
      )}
    </Paper>
  );
};

export default Settlements;
