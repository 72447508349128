import React, { useEffect } from 'react';
import getBrands from '../../utils/getBrands';
import getClipBoard from '../../utils/getClipBoard';
import { notifyError } from '../Toasts';

const Context = React.createContext();
export const RequirementContext = React.createContext();

const MyContext = ({ children, values }) => {
  const { setGlobalState } = values;

  useEffect(() => {
    Promise.all([getBrands(), getClipBoard()])
      .then(([brands, clipBoard]) => {
        setGlobalState(prev => ({ ...prev, brands, clipBoard }));
        return null;
      })
      .catch(() => {
        notifyError({
          message:
            'An error occurred while getting subscription formulas/subscription types.',
        });
      });
  }, [setGlobalState]);

  return <Context.Provider value={{ ...values }}>{children}</Context.Provider>;
};

export { Context, MyContext };
