import { Box, Button, Flex, TextField } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutEmailValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Email = ({ validation }) => {
  const [state, setState] = React.useState(validation.state);
  const [email, setEmail] = useState(validation.data && validation.data.value);

  const emailValidationMutation = usePutEmailValidation();

  useEffect(() => {
    if (emailValidationMutation.isError) {
      notifyError({
        status:
          emailValidationMutation.error.response?.request?.status &&
          emailValidationMutation.error.response?.request?.status,
        message: emailValidationMutation.error.response?.data?.errors
          ? emailValidationMutation.error.response?.data?.errors[0]
          : emailValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidationMutation.isError]);

  useEffect(() => {
    if (emailValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the email',
      });
    }
  }, [emailValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Email
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box>
          <TextField
            label="Email address"
            id="email"
            name="email"
            data-testid="input-email"
            defaultValue={email}
            onChange={event => setEmail(event.target.value)}
          />
        </Box>

        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              emailValidationMutation.mutate({
                ...validation,
                data: { value: email },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Email;
