import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mediahuis/chameleon-react';
import { ArrowBack } from '@mediahuis/chameleon-theme-wl/icons';

const BackButton = ({ onClick }) => {
  const history = useHistory();

  return (
    <Box align="right" mb={5}>
      <Button
        p={5}
        appearance="outline"
        data-testid="back-button"
        onClick={() => {
          onClick ? onClick() : history.goBack();
        }}
        iconLeft={ArrowBack}
      >
        Back
      </Button>
    </Box>
  );
};

export default BackButton;
