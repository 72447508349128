import { isBefore } from 'date-fns';

import { PRICE_TYPE } from '../data/options';

const validateFormula = ({
  title,
  reference,
  startDate,
  endDate,
  priceType,
  isFixedTerm,
  lumpSum,
  incentiveDescription,
  costCenter,
  projectedLifetimeValue,
}) => {
  const errors = {};

  if (!title) {
    errors.title = 'Title is required';
  }
  if (!reference) {
    errors.reference = 'Reference is required';
  }
  if (!projectedLifetimeValue && projectedLifetimeValue !== 0) {
    errors.projectedLifetimeValue = 'Projected Lifetime Value is required';
  }
  if (!startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (
    startDate &&
    endDate &&
    isBefore(new Date(endDate), new Date(startDate))
  ) {
    errors.endDate = `End date must be later than ${new Date(
      startDate,
    ).toDateString()}`;
  }
  if (isFixedTerm && !lumpSum) {
    errors.lumpSum = 'Lump Sum is required';
  }
  if (
    (priceType === PRICE_TYPE.INCENTIVE ||
      priceType === PRICE_TYPE.LINKED_SALE) &&
    !incentiveDescription
  ) {
    errors.incentiveDescription = 'Incentive is required';
  }
  if (priceType === PRICE_TYPE.FREE && !costCenter) {
    errors.costCenter = 'Cost center is required';
  }
  return errors;
};

export default validateFormula;
