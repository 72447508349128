import base from './base';

const criteria = {
  Age: {
    operations: ['Equals', 'NotEquals', 'SmallerOrEquals', 'Smaller', 'GreaterOrEquals', 'Greater'],
    parameters: [''],
  },
  Person: {
    operations: base.allOperations,
    parameters: [
      'Prospect',
      'CurrentSubscriber',
      'FutureSubscriber',
      'FormerSubscriber',
    ],
  },
  SubscriptionKind: {
    operations: base.allOperations,
    parameters: [
      'Regular',
      'Promo',
      'Incentive',
      'Free',
      'Stunt',
      'LinkedSale',
    ],
  },
  Formula: {
    operations: ['Equals', 'NotEquals', 'In', 'NotIn'],
    parameters: [''],
  },
};

const constants = {
  criteria,
};

export default constants;
