import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notifyError, notifySuccess, PageLoader } from '~/components';
import { secureApiCall } from '../../utils';
import OfferForm from './components/OfferForm';

const EditOffer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { i18n } = useTranslation();

  const [initialValues, setInitialValues] = useState();

  const apiUrl = `${API_OFFER_SERVICE}/Offers/${id}`;

  const deleteMethod = () => {
    secureApiCall(apiUrl, {
      method: 'DELETE',
      params: {
        'api-version': '2',
      },
      onSuccess: () => {
        history.push('/offers');
        notifySuccess({
          message: 'Successfully deleted an offer',
        });
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage?.response?.request?.status &&
            errorMessage.response.request.status,
          message: errorMessage?.response?.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const duplicateMethod = () => {
    history.push('/offers/create', { initialValues });
  };

  useEffect(() => {
    secureApiCall(`${API_OFFER_SERVICE}/offers/${id}`, {
      method: 'GET',
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        if (response.data.items) {
          response.data.items = response.data.items.map(item => {
            item.key = `${item.id}-${item.type}`;
            return item;
          });
          response.data.items.sort((a, b) => a.sequenceIndex - b.sequenceIndex);
        }
        setInitialValues(response.data);
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message:
            errorMessage?.response?.data?.errors?.[0] || errorMessage.message,
        });
        history.push('/offers');
      },
    });
  }, [id, history, i18n.language]);

  return initialValues ? (
    <OfferForm
      deleteMethod={deleteMethod}
      duplicateMethod={duplicateMethod}
      editing
      initialValues={initialValues}
    />
  ) : (
    <PageLoader />
  );
};

export default EditOffer;
