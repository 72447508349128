export const dateValidation = ({ value, meta, id, errors }) => {
  const newErrors = { ...errors };

  if (!value) {
    newErrors[id] = 'This is not a valid date.';
  } else if (meta.disabled) {
    newErrors[id] = 'The chosen date is outside the allowed dates.';
  } else {
    delete newErrors[id];
  }

  return newErrors;
};
