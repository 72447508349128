import Axios from 'axios';

const paymentServiceInstance = Axios.create({
  baseURL: API_PAYMENT_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    'api-version': '2',
  },
});

class PaymentService {
  static postCallback(paymentId) {
    const formData = new FormData();
    formData.append('id', paymentId);
    return paymentServiceInstance.post('/Callback', formData);
  }
}

export default PaymentService;
