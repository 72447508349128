import { Box, Icon, Text } from '@mediahuis/chameleon-react';
import { Close } from '@mediahuis/chameleon-theme-wl/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import getImageSrc from '../../utils/getImageSrc';
import Dropzone from '../Dropzone';

const CancelButton = styled.button`
  position: absolute;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
`;

const ImageField = ({ defaultImage, file, label, onChange }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage();
    }
  }, [file]);

  if (!defaultImage && !file) {
    return (
      <Box mb={2}>
        <Text>{label}</Text>
        <Dropzone
          acceptedFiles="image/*"
          filesLimit={1}
          onDrop={files => onChange(files[0])}
        />
      </Box>
    );
  }

  const imageSrc = getImageSrc(image || defaultImage);

  return (
    <Box mb={2} style={{ position: 'relative' }}>
      <Box component="div">
        <Text>{label}</Text>
      </Box>
      {imageSrc && (
        <img src={imageSrc} alt="uploaded" style={{ maxWidth: '200px' }} />
      )}
      <CancelButton
        data-testid="image-close-button"
        role="button"
        tabIndex={0}
        type="button"
        onClick={() => onChange(undefined)}
      >
        <Icon
          as={Close}
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '3px',
          }}
          color="secondary"
        />
      </CancelButton>
    </Box>
  );
};

export default ImageField;
