import { Button, Caption, Flex, Paper } from '@mediahuis/chameleon-react';
import { Auth } from 'aws-amplify';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #328bb766;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Link = styled.a`
  color: initial;
`;

const SignIn = () => (
  <Wrapper>
    <Paper
      width="300px"
      style={{
        position: 'relative',
        textAlign: 'center',
        borderRadius: '4px',
      }}
    >
      <img
        alt="HUB"
        src="https://shared.mediahuis.be/logos/hub/v1/brand-square-main.svg"
        style={{ marginBottom: '12px' }}
        width="48px"
      />
      <Caption level={1}>Login with corporate Mediahuis account</Caption>
      <Button
        mb={5}
        appearance="primary"
        onClick={() => Auth.federatedSignIn({ provider: COGNITO_FEDERATED_ID })}
        data-testid="ad-logon"
      >
        Login
      </Button>
      <Caption level={1}>
        <Link
          href={`https://${COGNITO_DOMAIN}/login?client_id=${COGNITO_CLIENT_ID}&response_type=code&redirect_uri=${window.location.origin}`}
          data-testid="other-logon"
        >
          Login with different account
        </Link>
      </Caption>
    </Paper>
  </Wrapper>
);

export default SignIn;
