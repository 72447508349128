import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useQueryParams } from '~/hooks';
import { OverviewLayout } from '~/layouts/OverviewLayout';
import { useContents } from '~/services/content';

import ContentsOverviewFilters from './ContentsOverviewFilters';
import ContentsOverviewMenu from './ContentsOverviewMenu';
import ContentsOverviewTable from './ContentsOverviewTable';

const defaultAmount = 20;
const defaultOffset = 0;

const ContentsOverview = () => {
  const { pathname } = useLocation();
  const [params, setParams] = useQueryParams();

  const [itemCount, setItemCount] = useState(0);

  const contentsQuery = useContents(params);

  useEffect(() => {
    if (contentsQuery.isSuccess) {
      setItemCount(contentsQuery.data.realCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsQuery.isSuccess]);

  function resetFilters() {
    setParams({
      amount: defaultAmount,
      excludedKeys: [HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE],
      offset: defaultOffset,
    });
  }

  if (!params.amount || !params.excludedKeys || !params.offset) {
    return (
      <Redirect
        to={`${pathname}?amount=${defaultAmount}&excludedKeys=${HTML_CONTENT_LOCATIONS.VOUCHER_TEMPLATE}&offset=${defaultOffset}`}
      />
    );
  }

  return (
    <OverviewLayout
      filters={<ContentsOverviewFilters onReset={resetFilters} />}
      menu={
        <ContentsOverviewMenu
          itemCount={itemCount}
          offset={params.offset}
          pageSize={defaultAmount}
          onPageChange={page =>
            setParams({
              ...params,
              offset: (page - 1) * defaultAmount,
            })
          }
        />
      }
      table={
        <ContentsOverviewTable
          contents={contentsQuery.data?.list || []}
          isLoading={contentsQuery.isFetching}
        />
      }
    />
  );
};

export default ContentsOverview;
