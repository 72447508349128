export const OFFER_TYPE = Object.freeze({
  ACQUISITION: 'Acquisition',
  RENEWAL: 'Renewal',
  WINBACK: 'Winback',
});

export const PRICE_TYPE = Object.freeze({
  FREE: 'Free',
  INCENTIVE: 'Incentive',
  LINKED_SALE: 'LinkedSale',
  NOT_SPECIFIED: 'NotSpecified',
  PROMO: 'Promo',
  REGULAR: 'Regular',
  STUNT: 'Stunt',
  VOUCHER_PURCHASE: 'VoucherPurchase',
  VOUCHER_REDEMPTION: 'VoucherRedemption',
});
