import { Select } from '@mediahuis/chameleon-react';
import React from 'react';

const StateDropdown = ({ state, setState }) => {
  const options = [
    { key: 'Compliant', value: 'Compliant' },
    { key: 'Not valid', value: 'NotValid' },
    { key: 'Pending', value: 'Pending' },
  ];
  return (
    <Select
      id="state"
      value={state}
      placeholder="State"
      onChange={e => setState(e.target.value)}
      label="State"
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.key}
        </option>
      ))}
    </Select>
  );
};

export default StateDropdown;
