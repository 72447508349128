import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notifyError, PageLoader } from '~/components';
import { secureApiCall } from '../../utils';
import VoucherForm from './components/VoucherForm';

const VoucherDetail = () => {
  const [initialValues, setInitialValues] = useState();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const history = useHistory();
  const apiUrl = `${API_VOUCHER_SERVICE}/vouchers/${id}`;

  useEffect(() => {
    secureApiCall(apiUrl, {
      method: 'GET',
      onSuccess: response => {
        setInitialValues(response.data);
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message: errorMessage?.response?.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        history.push('/vouchers');
      },
    });
  }, [apiUrl, history, i18n.language]);

  if (!initialValues) {
    return <PageLoader />;
  }

  return <VoucherForm editing apiUrl={apiUrl} initialValues={initialValues} />;
};

export default VoucherDetail;
