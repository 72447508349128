const REQUIREMENT_TYPE = Object.freeze({
  AddressArea: 'addressAreaRequirements',
  Boolean: 'booleanRequirements',
  Choice: 'choiceRequirements',
  Date: 'dateRequirements',
  Email: 'emailRequirements',
  File: 'fileRequirements',
  InvoiceDetails: 'invoiceDetailsRequirement',
  Login: 'loginRequirement',
  Payment: 'paymentRequirement',
  Preconditions: 'preconditionsRequirement',
  Textfield: 'textfieldRequirements',
  Voucher: 'voucherRequirement',
});

export default REQUIREMENT_TYPE;
