import React, { useState, createContext, useContext } from 'react';
import constants from '~/pages/scopes/constants';
import useAboshopContext from './useContext';

const SubscriptionScopeContext = createContext();

const SubscriptionScopesProvider = ({ children }) => {
  const globalContext = useAboshopContext();

  const defaultValues = {
    id: Date.now(),
    brand: globalContext?.brands?.[0]?.code,
    subscriptionType: '',
    scopes: constants.scopes,
  };

  const [errors, setErrors] = useState({});

  const [subscriptionScope, setSubscriptionScope] = useState(defaultValues);

  const clearError = event => {
    const spreadedErrors = { ...errors };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const setSubscriptionScopeState = (key, value) =>
    setSubscriptionScope(prevState => ({ ...prevState, [key]: value }));

  const value = {
    subscriptionScope,
    setSubscriptionScopeState,
    setSubscriptionScope,
    defaultValues,
    errors,
    setErrors,
    clearError,
  };

  return (
    <SubscriptionScopeContext.Provider value={value}>
      {children}
    </SubscriptionScopeContext.Provider>
  );
};

const useSubscriptionScopeContext = () => {
  const context = useContext(SubscriptionScopeContext);

  if (context === undefined) {
    throw new Error(
      'useSubscriptionScopeContext must be used within a SubscriptionScopesProvider',
    );
  }

  return context;
};

export { SubscriptionScopesProvider, useSubscriptionScopeContext };
