const locationsConfig = [
  {
    key: HTML_CONTENT_LOCATIONS.GIFT,
    locationOptions: [
      {
        label: 'Top',
        value: 'top',
      },
      {
        label: 'Bottom',
        value: 'bottom',
      },
    ],
    name: 'Gift',
    previewUrl: brand =>
      `https://${MH_ENV}aboshop.${BRAND_SITE_URLS[brand.toUpperCase()]}/cadeau`,
  },
  {
    key: HTML_CONTENT_LOCATIONS.VOUCHER,
    locationOptions: [
      {
        label: 'Top',
        value: 'top',
      },
      {
        label: 'Bottom',
        value: 'bottom',
      },
    ],
    name: 'Voucher',
    previewUrl: brand =>
      `https://${MH_ENV}aboshop.${
        BRAND_SITE_URLS[brand.toUpperCase()]
      }/voucher?voucherCode=preview`,
  },
];

export default locationsConfig;
