import { Button, Flex, Text } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ContentsOverviewMenu = ({
  itemCount,
  offset,
  pageSize,
  onPageChange,
}) => {
  const history = useHistory();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text>Total: {itemCount}</Text>

      <Pagination
        current={offset / pageSize + 1}
        pageSize={pageSize}
        showSizeChanger={false}
        total={itemCount}
        onChange={onPageChange}
      />

      <Flex alignItems="center" style={{ gap: '5px' }}>
        <Button
          appearance="primary"
          data-testid="contents-create-btn"
          size="small"
          onClick={() => history.push('/contents/create')}
        >
          Create
        </Button>
      </Flex>
    </Flex>
  );
};

export default ContentsOverviewMenu;
