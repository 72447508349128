import { Table } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { formatBrand, formatDateTime } from '~/utils/columns';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (text, record) => (
      <Link
        to={location => `${location.pathname}/edit/${record.id}`}
        onClick={event => event.stopPropagation()}
      >
        {text ?? '-'}
      </Link>
    ),
  },
  { dataIndex: 'brand', title: 'Brand', render: formatBrand },
  {
    dataIndex: 'name',
    title: 'Name',
    render: text => (text ? text : '-'),
  },
  {
    dataIndex: 'start',
    title: 'Start Date',
    render: text => (text ? formatDateTime(text) : '-'),
  },
  {
    dataIndex: 'end',
    title: 'End Date',
    render: text => (text ? formatDateTime(text) : '-'),
  },
  {
    dataIndex: 'priority',
    title: 'Priority',
    render: text => (text ? text : '-'),
  },
];

const ProposalsTable = ({ isLoading, proposals }) => {
  const history = useHistory();

  const tableData = proposals.results;

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(`${history.location.pathname}/edit/${record.id}`),
      })}
    />
  );
};

export default ProposalsTable;
