import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, PageLoader } from '~/components';
import { Table, Input, Button as AButton, Space } from 'antd';
import { Box } from '@mediahuis/chameleon-react';
import { getProducts } from '../../../utils';

const ButtonNoMargin = styled(Button)`
  && {
    margin: 0;
  }
`;

const ProductsTable = ({ brand, items, handleAddProduct }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({ searchText: '', searchedColumn: '' });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    getProducts({
      brand,
      filters: {
        [dataIndex]: selectedKeys[0],
      },
    })
      .then(result => {
        result.sort((a, b) => b.id - a.id);
        setProducts(result);
        setIsLoading(false);
        return result;
      })
      .catch(() => setIsLoading(false));

    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: '' });
  };

  let searchInput;
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <AButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </AButton>
          <AButton
            onClick={() => {
              handleReset(clearFilters);
              handleSearch('', confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </AButton>
        </Space>
      </div>
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text => text,
  });

  const columns = [
    {
      id: 'type',
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      render: item => (item === 'SubscriptionFormula' ? 'Formula' : 'Type'),
      filters: [
        {
          text: 'Formula',
          value: 'SubscriptionFormula',
        },
        {
          text: 'Type',
          value: 'SubscriptionType',
        },
      ],

      onFilter: (value, record) => record.type === value,
    },
    {
      id: 'id',
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
    },
    {
      id: 'reference',
      key: 'reference',
      title: 'Reference',
      dataIndex: 'reference',
      ...getColumnSearchProps('name'),
    },
    {
      id: 'title',
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
    },
    {
      id: 'add',
      key: 'add',
      width: 80,
      align: 'right',
      // eslint-disable-next-line react/display-name
      render: rowData => (
        <ButtonNoMargin
          size="small"
          disabled={rowData.disabled}
          onClick={e => {
            e.stopPropagation();
            handleAddProduct(rowData, e);
          }}
          label="Add"
          appearance="secondary"
          datatestid="add-product-table-button"
        />
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getProducts({ brand })
      .then(result => {
        result.sort((a, b) => b.id - a.id);
        setProducts(result);
        setIsLoading(false);
        return result;
      })
      .catch(() => setIsLoading(false));
  }, [brand]);

  const data = useMemo(() => {
    const keys = items.map(item => `${item.id}-${item.type}`);
    return products.map(product => ({
      ...product,
      disabled: keys.includes(`${product.id}-${product.type}`),
    }));
  }, [items, products]);

  if (isLoading) {
    return (
      <Box mb={5}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box my={6}>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        rowClassName={() => 'pointer'}
        rowKey={record => record.id}
        onRow={record => ({
          onClick: () =>
            window.open(`/${record.type}/edit/${record.id}`, '_blank'),
        })}
      />
    </Box>
  );
};

export default ProductsTable;
