import secureApiCall from './secureApiCall';

export const CLIPBOARD = 'clipBoard';

const getClipBoard = () =>
  new Promise(resolve => {
    const clipBoard = JSON.parse(localStorage.getItem(CLIPBOARD));

    if (clipBoard) {
      const endpoint =
        clipBoard.type === 'type'
          ? 'SubscriptionTypes'
          : 'SubscriptionFormulas';
      const apiUrl = `${API_OFFER_SERVICE}/${endpoint}/${clipBoard.id}`;

      secureApiCall(apiUrl, {
        method: 'GET',
        params: {
          'api-version': '2',
        },
        onSuccess: response => {
          resolve({ ...response.data, type: clipBoard.type });
        },
        onError: () => {
          localStorage.removeItem(CLIPBOARD);
          resolve(null);
        },
      });
    } else {
      resolve(null);
    }
  });

export default getClipBoard;
