import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { Box } from '@mediahuis/chameleon-react';
import Card from './Card';

const OrderedOptionList = ({
  availableOptions,
  setAvailableOptions,
  chosenOptions,
  setChosenOptions,
}) => {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = chosenOptions[dragIndex];
      setChosenOptions(
        update(chosenOptions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [chosenOptions, setChosenOptions],
  );
  const renderCard = (card, index) => (
    <Card
      key={card.id}
      index={index}
      card={card}
      moveCard={moveCard}
      chosenOptions={chosenOptions}
      availableOptions={availableOptions}
      setAvailableOptions={setAvailableOptions}
      setChosenOptions={setChosenOptions}
    />
  );
  return <Box>{chosenOptions.map((card, i) => renderCard(card, i))}</Box>;
};

export default OrderedOptionList;
