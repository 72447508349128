import React from 'react';
import { Box, Select, Text, TextField } from '@mediahuis/chameleon-react';
import { MultiSelect, Option } from '~/components/Ant/MultiSelect';
import constants from '../constants';

const CriteriaParameterInput = ({
  criterium,
  onChangeMultiSelect,
  onChangeSelect,
  onChangeTextfieldInput,
}) => {
  if (criterium.propertyName === 'Formula') {
    if (criterium.operation === 'In' || criterium.operation === 'NotIn') {
      return (
        <>
          <Text as="p" mb={3}>
            Formula ID (press enter or tab to add)
          </Text>
          <MultiSelect
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Tags Mode"
            onChange={value => onChangeMultiSelect(value, criterium.id)}
            allowClear
            value={criterium.parameter || []}
            id="parameter"
            name="parameter"
            dropdownStyle={{ display: 'none' }}
          />
        </>
      );
    }
    return (
      <TextField
        label="Formula ID"
        id="Formula"
        name="Formula"
        data-testid="input-formulaId"
        onChange={event => onChangeTextfieldInput(event, criterium.id)}
        value={criterium.parameter || ['']}
      />
    );
  }

  if (criterium.propertyName === 'Age') {
    return (
      <TextField
        label="Age"
        id="Age"
        name="Age"
        type="number"
        data-testid="input-age"
        onChange={event => onChangeTextfieldInput(event, criterium.id)}
        value={criterium.parameter || ['']}
      />
    );
  }

  const criteriaParameters =
    constants?.criteria?.[criterium.propertyName]?.parameters;

  return (
    <Box width="full" mb={5}>
      {criterium.operation === 'In' || criterium.operation === 'NotIn' ? (
        <>
          <Text as="p" mb={3}>
            Parameters
          </Text>
          <MultiSelect
            style={{ width: '100%' }}
            allowClear
            mode="multiple"
            value={criterium.parameter || []}
            onChange={value => onChangeMultiSelect(value, criterium.id)}
            id="parameter"
            name="parameter"
          >
            {criteriaParameters.map(option => (
              <Option
                style={{ marginRight: '4px', paddingLeft: '2px' }}
                key={option}
              >
                {option}
              </Option>
            ))}
          </MultiSelect>
        </>
      ) : (
        <Box width="100%">
          <Select
            id="parameter"
            data-testid="parameter-input"
            name="parameter"
            label="Parameter"
            onChange={event => onChangeSelect(event, criterium.id)}
            value={criterium.parameter}
          >
            {criteriaParameters.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
};

export default CriteriaParameterInput;
