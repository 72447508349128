import axios from 'axios';
import { Auth } from 'aws-amplify';
import { getI18n } from 'react-i18next';

// eslint-disable-next-line space-before-function-paren
const secureApiCall = async (
  url,
  { onSuccess, onError, headers, params, ...config },
) => {
  const currentSessionData = await Auth.currentSession();

  const { language } = getI18n();

  return axios({
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentSessionData.idToken.jwtToken}`,
      ...headers,
    },
    params: {
      lang: language,
      ...params,
    },
    ...config,
  })
    .then(response => onSuccess(response))
    .catch(error => {
      if (onError) {
        onError(error);
      }
    });
};

export default secureApiCall;
