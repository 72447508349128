import dayjs from 'dayjs';
import { format } from 'date-fns';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { calculateTotalOrders, calculateTotalRevenue } from './MetricBlocks';

const defaultOneDayChartData = [
  {
    orders: 0,
    revenue: 0,
    time: '00:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '01:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '02:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '03:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '04:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '05:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '06:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '07:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '08:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '09:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '10:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '11:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '12:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '13:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '14:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '15:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '16:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '17:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '18:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '19:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '20:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '21:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '22:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '23:00',
  },
  {
    orders: 0,
    revenue: 0,
    time: '24:00',
  },
];

const mapDataPointsToOneDayChartData = dataPoints => {
  const dataPointKeys = Object.keys(dataPoints);
  const allDataPoints = [...defaultOneDayChartData];

  for (let i = 0; i < dataPointKeys.length; i++) {
    for (let j = 0; j < dataPoints[dataPointKeys[i]].length; j++) {
      const dataPoint = dataPoints[dataPointKeys[i]][j];

      const timeFrom = format(
        new Date(dataPoint.from),
        'HH:mm',
      );
      const currentTimeEntryIndex = allDataPoints.findIndex(
        entry => entry.time === timeFrom,
      );

      if (currentTimeEntryIndex !== -1) {
        allDataPoints[currentTimeEntryIndex] = {
          orders:
            allDataPoints[currentTimeEntryIndex].orders + dataPoint.orderCount,
          revenue:
            allDataPoints[currentTimeEntryIndex].revenue + dataPoint.revenue,
          time: allDataPoints[currentTimeEntryIndex].time,
        };
      }
      else {
        console.log(`No entry found for time: ${timeFrom}`);
      }
    }
  }

  return allDataPoints.sort(
    (a, b) => a.time.substring(0, 2) - b.time.substring(0, 2),
  );
};

const mapDataPointsToChartData = (dataPoints, startDate, endDate) => {
  const dataPointKeys = Object.keys(dataPoints);
  const numberOfDataPoints = dayjs(endDate).diff(dayjs(startDate), 'days') + 1;

  if (numberOfDataPoints === 1) {
    return mapDataPointsToOneDayChartData(dataPoints);
  }

  const defaultChartData = [];
  let date = dayjs(startDate);
  for (let a = 0; a < numberOfDataPoints; a++) {
    defaultChartData.push({
      orders: 0,
      revenue: 0,
      time: `${date.format('DD/MM/YY')}`,
      sortingKey: date
    });
    date = date.add(1, 'day');
  }

  const allDataPoints = [...defaultChartData];
  for (let i = 0; i < dataPointKeys.length; i++) {
    for (let j = 0; j < dataPoints[dataPointKeys[i]].length; j++) {
      const dataPoint = dataPoints[dataPointKeys[i]][j];
      const dateFrom = dayjs(dataPoint.from);
      const formattedDateFrom = `${dateFrom.format('DD/MM/YY')}`;

      const currentTimeEntryIndex = allDataPoints.findIndex(
        entry => entry.time === formattedDateFrom,
      );

      if (currentTimeEntryIndex !== -1) {
        allDataPoints[currentTimeEntryIndex] = {
          orders: allDataPoints[currentTimeEntryIndex].orders + dataPoint.orderCount,
          revenue: allDataPoints[currentTimeEntryIndex].revenue + dataPoint.revenue,
          time: allDataPoints[currentTimeEntryIndex].time,
          sortingKey: allDataPoints[currentTimeEntryIndex].sortingKey
        };
      }
    }
  }

  allDataPoints.map(dp => Math.round(dp.revenue * 100) / 100);
  return allDataPoints.sort((a, b) => a.sortingKey - b.sortingKey);
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="recharts-default-tooltip" style={{ margin: '0px', padding: '10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
        <p className="label">{label}</p>
        <p className="desc">Orders: {payload[0].value}</p>
        <p className="desc">Revenue: {payload[1].value.toLocaleString('nl', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
      </div>
    );
  }

  return null;
};

const LiveChart = ({ dataPoints = {}, startDate, endDate, showOverviewText = false }) => {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <AreaChart data={mapDataPointsToChartData(dataPoints, startDate, endDate)}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="time" />
        <YAxis dataKey="revenue" orientation="left" yAxisId={0} />
        <YAxis dataKey="orders" orientation="right" yAxisId={1} />
        <Tooltip content={<CustomTooltip />} />
        <Area
          dataKey="orders"
          fill="#498fcc"
          stackId="1"
          stroke="#498fcc"
          type="monotone"
          yAxisId={1}
        />
        <Area
          dataKey="revenue"
          fill="#22AA22"
          stackId="1"
          stroke="#22AA22"
          type="monotone"
          yAxisId={0}
        />
        {showOverviewText && <text x={120} y={50} textAnchor="left" dominantBaseline="left" style={{ fontSize: 20, fontWeight: 'bold', fill: '#000000', outline: '2px solid black', outlineOffset: '5px' }}>Orders: {calculateTotalOrders(dataPoints).toLocaleString('nl')}</text>}
        {showOverviewText && <text x={120} y={100} textAnchor="left" dominantBaseline="left" style={{ fontSize: 20, fontWeight: 'bold', fill: '#000000', outline: '2px solid black', outlineOffset: '5px' }}>Revenue: €{calculateTotalRevenue(dataPoints).toLocaleString('nl', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</text>}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LiveChart;
