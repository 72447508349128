import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PageLoader, notifyError, notifySuccess } from '~/components';
import { removeDeprecatedRequirementKeys, secureApiCall } from '~/utils';

import TypeForm from './components/TypeForm';

const EditType = () => {
  const [initialValues, setInitialValues] = useState();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const history = useHistory();
  const apiUrl = `${API_OFFER_SERVICE}/SubscriptionTypes/${id}`;

  const deleteMethod = () =>
    secureApiCall(apiUrl, {
      method: 'DELETE',
      params: {
        'api-version': '2',
      },
      onSuccess: () => {
        history.push('/subscriptiontype');
        notifySuccess({
          message: 'Successfully deleted a type',
        });
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });

  useEffect(() => {
    secureApiCall(apiUrl, {
      method: 'GET',
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        response.data.features.sort(
          (a, b) => a.sequenceIndex - b.sequenceIndex,
        );
        setInitialValues(removeDeprecatedRequirementKeys(response.data));
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        history.push('/subscriptiontype');
      },
    });
  }, [apiUrl, history, i18n.language]);

  if (!initialValues) {
    return <PageLoader />;
  }

  return (
    <TypeForm
      editing
      initialValues={initialValues}
      apiUrl={apiUrl}
      deleteMethod={deleteMethod}
    />
  );
};

export default EditType;
