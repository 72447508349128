import React from 'react';
import { TextField, Box, Flex } from '@mediahuis/chameleon-react';

export const AddressFields = ({ type, address, setAddress, disabled }) => (
  <React.Fragment>
    <Flex justifyContent="space-between" mt={5}>
      <Box width="67%" mr={5}>
        <TextField
          disabled={disabled}
          label="Street"
          id={`${type}Street`}
          name={`${type}Street`}
          data-testid={`${type}Street`}
          defaultValue={address.street}
          onChange={event =>
            setAddress({ ...address, street: event.target.value })
          }
        />
      </Box>
      <Box width="15%" mr={5}>
        <TextField
          disabled={disabled}
          label="House"
          id={`${type}House`}
          name={`${type}House`}
          data-testid={`${type}House`}
          defaultValue={address.houseNumber}
          onChange={event =>
            setAddress({ ...address, houseNumber: event.target.value })
          }
        />
      </Box>
      <Box width="15%">
        <TextField
          disabled={disabled}
          label="Bus"
          id={`${type}Bus`}
          name={`${type}Bus`}
          data-testid={`${type}Bus`}
          defaultValue={address.busNumber}
          onChange={event =>
            setAddress({ ...address, busNumber: event.target.value })
          }
        />
      </Box>
    </Flex>
    <Flex justifyContent="space-between" mt={5}>
      <Box width="32%">
        <TextField
          disabled={disabled}
          label="Postal code"
          id={`${type}PostalCode`}
          name={`${type}PostalCode`}
          data-testid={`${type}PostalCode`}
          defaultValue={address.postalCode}
          onChange={event =>
            setAddress({ ...address, postalCode: event.target.value })
          }
        />
      </Box>
      <Box width="32%">
        <TextField
          disabled={disabled}
          label="City"
          id={`${type}City`}
          name={`${type}City`}
          data-testid={`${type}City`}
          defaultValue={address.city}
          onChange={event =>
            setAddress({ ...address, city: event.target.value })
          }
        />
      </Box>
      <Box width="32%">
        <TextField
          disabled={disabled}
          label="Country"
          id={`${type}Country`}
          name={`${type}Country`}
          data-testid={`${type}Country`}
          defaultValue={address.countryCode}
          onChange={event =>
            setAddress({ ...address, countryCode: event.target.value })
          }
        />
      </Box>
    </Flex>
  </React.Fragment>
);
