const DATE_TO = new Date();
const DATE_FROM = new Date();
DATE_FROM.setDate(DATE_TO.getDate() - 30);

export const TABLES = 'tables';

export const TABLE_IDS = {
  TYPES_OVERVIEW: 'typesOverview',
  ORDERS_OVERVIEW: 'ordersOverview',
  OFFERS_OVERVIEW: 'offersOverview',
  FEATURES_OVERVIEW: 'featuresOverview',
  FORMULAS_OVERVIEW: 'formulasOverview',
  ABO_PROPOSALS_OVERVIEW: 'aboProposalsOverview',
  PRECONDITIONS: 'preconditions',
  SUBSCRIPTION_SCOPES: 'subscriptionScopes',
  VOUCHERS_OVERVIEW: 'vouchersOverview',
};

export const TABLE_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 25,
  showSizeChanger: false,
  position: ['topLeft', 'none'],
  showTotal: total => `Total: ${total} items`,
};

export const TABLE_FILTERS = {
  [TABLE_IDS.TYPES_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [{ key: 'Internal reference', value: 'name' }],
    },
  },
  [TABLE_IDS.ORDERS_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [{ key: 'Formula', value: 'formula', type: 'number' }],
    },
    state: null,
    dateFrom: DATE_FROM,
    dateTo: DATE_TO,
    paymentState: null,
    type: null,
  },
  [TABLE_IDS.OFFERS_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [
        { key: 'Internal reference', value: 'name' },
        { key: 'Slug', value: 'slug' },
        { key: 'Tags', value: 'tags' },
        { key: 'Type', value: 'type' },
      ],
    },
  },
  [TABLE_IDS.FEATURES_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [{ key: 'Internal reference', value: 'name' }],
    },
  },
  [TABLE_IDS.FORMULAS_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [
        { key: 'Subscription type (Id)', value: 'subscriptionTypeId' },
        { key: 'Internal reference', value: 'name' },
        { key: 'External reference', value: 'externalReference' },
      ],
    },
  },
  [TABLE_IDS.ABO_PROPOSALS_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [
        { key: 'Name', value: 'name' },
        { key: 'Priority', value: 'priority' },
      ],
    },
    dateFrom: new Date('01/01/2010'),
    dateTo: new Date('12/31/2099'),
  },
  [TABLE_IDS.PRECONDITIONS]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [{ key: 'Name', value: 'name' }],
    },
  },
  [TABLE_IDS.SUBSCRIPTION_SCOPES]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [{ key: 'subscriptionType', value: 'subscriptionType' }],
    },
  },
  [TABLE_IDS.VOUCHERS_OVERVIEW]: {
    brand: null,
    search: {
      item: '',
      keyword: '',
      searchableItems: [
        { key: 'Code', value: 'Code' },
        { key: 'Purchase Formula ID', value: 'PurchaseFormulaId' },
        { key: 'Redeem Formula ID', value: 'RedeemFormulaId' },
      ],
    },
    stateVouchers: null,
    dateFrom: DATE_FROM,
    dateTo: DATE_TO,
  },
};

export const TABLE_STATES = {
  [TABLE_IDS.TYPES_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.TYPES_OVERVIEW],
  },
  [TABLE_IDS.ORDERS_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.ORDERS_OVERVIEW],
  },
  [TABLE_IDS.OFFERS_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.OFFERS_OVERVIEW],
  },
  [TABLE_IDS.FEATURES_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.FEATURES_OVERVIEW],
  },
  [TABLE_IDS.FORMULAS_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.FORMULAS_OVERVIEW],
  },
  [TABLE_IDS.ABO_PROPOSALS_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.ABO_PROPOSALS_OVERVIEW],
  },
  [TABLE_IDS.PRECONDITIONS]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.PRECONDITIONS],
  },
  [TABLE_IDS.SUBSCRIPTION_SCOPES]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.SUBSCRIPTION_SCOPES],
  },
  [TABLE_IDS.VOUCHERS_OVERVIEW]: {
    pagination: TABLE_PAGINATION,
    filters: TABLE_FILTERS[TABLE_IDS.VOUCHERS_OVERVIEW],
  },
};
