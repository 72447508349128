import React from 'react';

import { useVouchers } from '~/services/voucher';

const VouchersQuery = ({ filters, render }) => {
  const vouchersQuery = useVouchers(filters);

  if (vouchersQuery.isError) {
    return <p>Error while loading vouchers</p>;
  }

  if (vouchersQuery.isSuccess) {
    return render(vouchersQuery.data, vouchersQuery.isFetching);
  }

  return <p>Loading vouchers..</p>;
};

export default VouchersQuery;
