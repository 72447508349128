import styled from 'styled-components';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.54);
  color: ${props => props.theme.palette.secondary};
  padding: 0;
  font-size: 12px;
`;

export default Label;
