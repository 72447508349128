import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Flex,
  Text,
  TextField as CHTextfield,
} from '@mediahuis/chameleon-react';

const Wrapper = styled(Box)`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const Dropdown = styled(Box)`
  display: ${props => (props.isDropDownActive ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  height: ${props => {
    if (props.filteredFeaturesLength > 5) {
      return '350px';
    } else if (
      props.filteredFeaturesLength > 3 &&
      props.filteredFeaturesLength < 5
    ) {
      return '250px';
    } else if (
      props.filteredFeaturesLength > 1 &&
      props.filteredFeaturesLength < 3
    ) {
      return '150px';
    }
    return '70px';
  }};
  overflow-y: auto;
  border: 0.1px transparent #919191;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const TextField = styled(CHTextfield)`
  input:focus & {
    display: block;
  }
  z-index: 5;
  position: relative;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  padding: 12px 20px;
  background-color: ${props => (props.selected ? '#bcbaba' : 'none')};
  &:hover {
    background-color: #d3d3d3;
  }
`;

const ClickableBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: 1;
`;

const MultiAutoComplete = ({
  options = [],
  optionField = 'name',
  handleSelect,
  value = [],
  subHeading = '',
  handleRemove,
}) => {
  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [search, setSearch] = useState('');
  const filteredOptionArray = useMemo(() => {
    const mappedActiveFeatures = value.map(el => ({ ...el, selected: true }));
    if (options.length > 0) {
      const newArray = [...mappedActiveFeatures, ...options];
      return newArray.filter(
        (option, index, self) =>
          index === self.findIndex(t => t[optionField] === option[optionField]),
      );
    }
    return [];
  }, [value, options, optionField]);

  const stringLength = 50;

  const toggleDropdown = () => {
    setIsDropDownActive(prevState => !prevState);
  };

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27 && isDropDownActive) {
        setIsDropDownActive(false);
      }
    },
    [isDropDownActive],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const filteredFeatures = filteredOptionArray.filter(feature => {
    return feature.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  return (
    <Wrapper>
      {isDropDownActive && <ClickableBackground onClick={toggleDropdown} />}
      <TextField
        placeholder="Select Features"
        onFocus={() => !isDropDownActive && toggleDropdown()}
        onChange={e => setSearch(e.target.value)}
        labelHidden={true}
      />
      <Dropdown
        placeholder="Select Features"
        isDropDownActive={isDropDownActive}
        filteredFeaturesLength={filteredFeatures?.length}
      >
        {filteredFeatures?.length > 0 ? (
          filteredFeatures?.map(option => {
            return (
              <DropdownItem
                onClick={() => {
                  if (!option?.selected) handleSelect(option);
                  if (option.selected) handleRemove(option);
                }}
                key={option[optionField]}
                selected={option?.selected}
              >
                <Box>
                  <Text size="Heading5">{option[optionField]}</Text>
                </Box>
                {option[subHeading] && (
                  <Text>
                    {option[subHeading].length > stringLength
                      ? `${option[subHeading].substring(0, stringLength)}...`
                      : option[subHeading]}
                  </Text>
                )}
              </DropdownItem>
            );
          })
        ) : (
          <Flex
            textAlign="center"
            flexDirection="column"
            justifyContent="center"
            height="full"
          >
            <Text size="Heading5">No Data Found.</Text>
          </Flex>
        )}
      </Dropdown>
    </Wrapper>
  );
};
export default MultiAutoComplete;
