import { Box } from '@mediahuis/chameleon-react';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ProductCard from './ProductCard';

const ProductCardDragOrder = ({
  handleItemsChange,
  index,
  onDelete,
  onDrop,
  product,
}) => {
  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'Box',
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
      };
    },
    drop: item => onDrop(item.index, index),
  });
  const [, drag] = useDrag({
    item: {
      index,
      type: 'Box',
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <Box
      width="33%"
      p={2}
      ref={ref}
      style={{
        cursor: 'move',
        border: `${isOver ? '2px dashed #1890ff' : ''}`,
      }}
    >
      <ProductCard
        product={product}
        index={index}
        onDelete={onDelete}
        handleItemsChange={handleItemsChange}
      />
    </Box>
  );
};

export default ProductCardDragOrder;
