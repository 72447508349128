import { Box, Button, Flex, Select } from '@mediahuis/chameleon-react';
import React, { useRef, useState } from 'react';

import { RequirementForm } from '../RequirementForm';

const RequirementCreate = ({ requirementTypeOptions, onCancel, onSubmit }) => {
  const [selectedType, setSelectedType] = useState(
    requirementTypeOptions.find(
      requirementTypeOption => !requirementTypeOption.disabled,
    )?.value,
  );

  const formRef = useRef(null);

  return (
    <Box
      borderColor="colorGrey20"
      borderRadius={2}
      borderStyle="solid"
      borderWidth={1}
      display="flex"
      flexDirection="column"
      p={4}
      style={{ gap: '2rem' }}
    >
      <Select
        id="RequirementTypeSelect"
        label="Type"
        name="requirementType"
        value={selectedType}
        onChange={event => setSelectedType(event.target.value)}
      >
        {requirementTypeOptions.map(requirementTypeOption => (
          <option
            disabled={requirementTypeOption.disabled}
            key={requirementTypeOption.value}
            value={requirementTypeOption.value}
          >
            {requirementTypeOption.label}
          </option>
        ))}
      </Select>

      <RequirementForm
        formRef={formRef}
        requirementType={selectedType}
        onSubmit={onSubmit}
      />

      <Flex flexDirection="column" style={{ gap: '0.25rem' }}>
        <Button
          appearance="primary"
          size="small"
          onClick={() => formRef.current.submitForm()}
        >
          Save
        </Button>
        <Button appearance="secondary" size="small" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

export default RequirementCreate;
