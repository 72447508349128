import { Box, Button, Flex, TextField } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutInvoiceValidation } from '~/services/order';

import {
  AddressFields,
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Invoice = ({ validation }) => {
  const [address, setAddress] = useState({
    busNumber: validation.data ? validation.data.busNumber : '',
    city: validation.data ? validation.data.city : '',
    countryCode: validation.data ? validation.data.countryCode : '',
    postalCode: validation.data ? validation.data.postalCode : '',
    street: validation.data ? validation.data.street : '',
    houseNumber: validation.data ? validation.data.houseNumber : '',
  });
  const [invoice, setInvoice] = useState({
    addressKey: validation.data ? validation.data.addressKey : '',
    addressee: validation.data ? validation.data.addressee : '',
    companyName: validation.data ? validation.data.companyName : '',
    vatNumber: validation.data ? validation.data.vatNumber : '',
  });
  const [state, setState] = React.useState(validation.state);

  const invoiceValidationMutation = usePutInvoiceValidation();

  useEffect(() => {
    if (invoiceValidationMutation.isError) {
      notifyError({
        status:
          invoiceValidationMutation.error.response?.request?.status &&
          invoiceValidationMutation.error.response?.request?.status,
        message: invoiceValidationMutation.error.response?.data?.errors
          ? invoiceValidationMutation.error.response?.data?.errors[0]
          : invoiceValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceValidationMutation.isError]);

  useEffect(() => {
    if (invoiceValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the invoice',
      });
    }
  }, [invoiceValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Invoice
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box mt={5} mb={5}>
          <TextField
            label="Addressee"
            id="invoiceAddressee"
            name="invoiceAddressee"
            data-testid="invoiceAddressee"
            defaultValue={invoice.addressee}
            onChange={event =>
              setInvoice({ ...invoice, addressee: event.target.value })
            }
          />
        </Box>

        <Box mt={5} mb={5}>
          <TextField
            label="Address Key"
            id="invoiceAddressKey"
            name="invoiceAddressKey"
            data-testid="invoiceAddressKey"
            defaultValue={invoice.addressKey}
            onChange={event =>
              setInvoice({ ...invoice, addressKey: event.target.value })
            }
          />
        </Box>

        <Box mt={5} mb={5}>
          <TextField
            label="Company name"
            id="invoiceCompanyName"
            name="invoiceCompanyName"
            data-testid="invoiceCompanyName"
            defaultValue={invoice.companyName}
            onChange={event =>
              setInvoice({ ...invoice, companyName: event.target.value })
            }
          />
        </Box>

        <Box mt={5} mb={5}>
          <TextField
            label="VAT number"
            id="invoiceVatNumber"
            name="invoiceVatNumber"
            data-testid="invoiceVatNumber"
            defaultValue={invoice.vatNumber}
            onChange={event =>
              setInvoice({ ...invoice, vatNumber: event.target.value })
            }
          />
        </Box>

        <AddressFields
          type="invoice"
          address={address}
          setAddress={setAddress}
          label="Address"
        />
      </Box>

      <Flex mt={4} justifyContent="flex-end" width="full">
        <Button
          type="primary"
          size="small"
          onClick={() =>
            invoiceValidationMutation.mutate({
              ...validation,
              data: { ...address, ...invoice },
              state,
            })
          }
        >
          Save
        </Button>
      </Flex>
    </ExpansionPanel>
  );
};

export default Invoice;
