import { Box, Button, Flex, TextField } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { PAYMENT_INTERVAL } from '~/components/Requirements/enums';
import { usePutPaymentValidation } from '~/services/order';
import { usePostCallback } from '~/services/payment';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const PAYMENT_METHODS = {
  BANCONTACT: 'bancontact',
  CREDIT_CARD: 'creditcard',
  DIRECT_DEBIT: 'DirectDebit',
  IDEAL: 'ideal',
};

const Payment = ({ validation }) => {
  const {
    method,
    state: paymentStatus,
    interval,
    accountNumber,
    accountOwner,
    cardExpiration,
    cardLabel,
    cardNumber,
    paymentId
  } = validation.data || {};

  const [state, setState] = useState(validation.state);

  const paymentValidationMutation = usePutPaymentValidation();
  const mollieCallbackMutation = usePostCallback();

  useEffect(() => {
    if (paymentValidationMutation.isError) {
      notifyError({
        status:
          paymentValidationMutation.error.response?.request?.status &&
          paymentValidationMutation.error.response?.request?.status,
        message: paymentValidationMutation.error.response?.data?.errors
          ? paymentValidationMutation.error.response?.data?.errors[0]
          : paymentValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentValidationMutation.isError]);

  useEffect(() => {
    if (paymentValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the payment',
      });
    }
  }, [paymentValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Payment
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>
        <Flex justifyContent="space-between" mt={5}>
          <Box width="15%">
            <TextField
              label="Amount"
              id="amount"
              name="amount"
              data-testid="input-amount"
              defaultValue={validation.amount}
              disabled={true}
            />
          </Box>
          <Box width="15%">
            <TextField
              label="Status"
              id="paymentStatus"
              name="paymentStatus"
              data-testid="input-paymentStatus"
              defaultValue={paymentStatus}
              disabled={true}
            />
          </Box>
          <Box width="30%">
            <TextField
              label="Method"
              id="paymentMethod"
              name="paymentMethod"
              data-testid="input-paymentMethod"
              defaultValue={method}
              disabled={true}
            />
          </Box>
          <Box width="30%">
            <TextField
              label="Interval"
              id="paymentInterval"
              name="paymentInterval"
              data-testid="input-paymentInterval"
              defaultValue={interval}
              disabled={true}
            />
          </Box>
        </Flex>
        {interval?.toLowerCase() === PAYMENT_INTERVAL.RECURRING.toLowerCase() &&
          (method?.toLowerCase() === PAYMENT_METHODS.BANCONTACT ||
            method?.toLowerCase() ===
            PAYMENT_METHODS.DIRECT_DEBIT.toLowerCase() ||
            method.toLowerCase() === PAYMENT_METHODS.IDEAL) && (
            <Flex mt={5} width="full">
              {accountOwner && (
                <TextField
                  label="Account Owner"
                  id="accountOwner"
                  name="accountOwner"
                  data-testid="input-accountOwner"
                  defaultValue={accountOwner}
                  disabled={true}
                  width="full"
                  mr={5}
                />
              )}

              {accountNumber && (
                <TextField
                  label="Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  data-testid="input-accountNumber"
                  defaultValue={accountNumber}
                  disabled={true}
                  width="full"
                />
              )}
            </Flex>
          )}
        {interval?.toLowerCase() === PAYMENT_INTERVAL.RECURRING.toLowerCase() &&
          method?.toLowerCase() ===
          PAYMENT_METHODS.CREDIT_CARD.toLowerCase() && (
            <>
              <Flex mt={5} width="full">
                <TextField
                  label="Account Owner"
                  id="accountOwner"
                  name="accountOwner"
                  data-testid="input-accountOwner"
                  defaultValue={accountOwner}
                  disabled={true}
                  width="full"
                  mr={5}
                />
                <TextField
                  label="Card Label"
                  id="cardLabel"
                  name="cardLabel"
                  data-testid="input-cardLabel"
                  defaultValue={cardLabel}
                  disabled={true}
                  width="full"
                />
              </Flex>
              <Flex width="full" mt={5}>
                <TextField
                  label="Card Expiration"
                  id="cardExpiration"
                  name="cardExpiration"
                  data-testid="input-cardExpiration"
                  defaultValue={cardExpiration}
                  disabled={true}
                  width="full"
                  mask="99/99"
                  mr={5}
                />

                <TextField
                  label="Card Number"
                  optionalLabel="4 latest numbers"
                  id="cardNumber"
                  name="cardNumber"
                  data-testid="input-cardNumber"
                  defaultValue={cardNumber}
                  disabled={true}
                  width="full"
                />
              </Flex>
              {!cardExpiration && (
                <Flex width="full" mt={5}>
                  <Button
                    alignSelf="flex-end"
                    size="small"
                    type="primary"
                    onClick={() => {
                      mollieCallbackMutation.mutate(paymentId);
                      setTimeout(() => { window.location.reload(); }, 1000)
                    }}
                  >
                    Fetch expiration date
                  </Button>
                </Flex>
              )}
            </>
          )}
        <Flex width="full" mt={5} flexDirection="column">
          <Button
            alignSelf="flex-end"
            size="small"
            type="primary"
            onClick={() =>
              paymentValidationMutation.mutate({ ...validation, state })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Payment;
