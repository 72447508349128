import { useQuery } from 'react-query';

import { ContentService } from '~/services/content';

export const CONTENT_LINKS_KEY = 'content-links';

const useContentLinks = (brand, key, enabled = true) => {
  return useQuery(
    [CONTENT_LINKS_KEY, brand, key],
    () =>
      ContentService.getContentLinks(brand, key).then(
        response => response.data,
      ),
    {
      enabled,
    },
  );
};

export default useContentLinks;
