import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Box, Button, Flex } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import { ExpansionPanel, notifyError, notifySuccess } from '~/components';
import { usePutFileValidation } from '~/services/order';
import { secureApiCall } from '~/utils';

import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const File = ({ validation }) => {
  const [state, setState] = useState(validation.state);
  const [imagePath, setImagePath] = useState('');
  const [pdfStream, setPdfStream] = useState('');

  const fileValidationMutation = usePutFileValidation();

  useEffect(() => {
    if (fileValidationMutation.isError) {
      notifyError({
        status:
          fileValidationMutation.error.response?.request?.status &&
          fileValidationMutation.error.response?.request?.status,
        message: fileValidationMutation.error.response?.data?.errors
          ? fileValidationMutation.error.response?.data?.errors[0]
          : fileValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileValidationMutation.isError]);

  useEffect(() => {
    if (fileValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the file',
      });
    }
  }, [fileValidationMutation.isSuccess]);

  useEffect(() => {
    if (validation.data) {
      secureApiCall(
        `${SUBSCRIBER_FILE_SERVICE}/aboshop-validation/${validation.data.filePath}?api-version=2`,
        {
          responseType: 'blob',
          method: 'GET',
          onSuccess: response => {
            if (/(\.pdf)$/i.exec(validation.data.filePath)) {
              setPdfStream(response.data);
            } else {
              setImagePath(URL.createObjectURL(response.data));
            }
          },
          onError: error => error,
        },
      );
    }
  }, [validation.data]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> File
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>
        <Box>
          {pdfStream && (
            <Document noData="Kon bestand niet laden." file={pdfStream}>
              <Page width="400" renderAnnotationLayer={false} pageNumber={1} />
            </Document>
          )}
          {imagePath && <img alt="file" src={imagePath} />}
        </Box>
        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              fileValidationMutation.mutate({ ...validation, state })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default File;
