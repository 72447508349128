import React from 'react';
import { toast } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import { Icon } from '@mediahuis/chameleon-react';
import {
  Error,
  Warning,
  Checkmark,
} from '@mediahuis/chameleon-theme-wl/icons';
import { theme } from '../theme';

const Wrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: ${props => props.theme.spacing.andHalf};
  margin-left: ${props => props.theme.spacing.half};
`;

const TextWrapper = styled.div``;

const StringWithSpacing = styled.div`
  font-size: 1.1em;
`;

export const notifySuccess = ({
  message,
  position = toast.POSITION.BOTTOM_RIGHT,
}) =>
  toast.success(
    <ThemeProvider theme={theme}>
      <Wrapper>
        <IconWrapper>
          <Icon as={Checkmark} />
        </IconWrapper>
        <StringWithSpacing>{message}</StringWithSpacing>
      </Wrapper>
    </ThemeProvider>,
    {
      position,
    },
  );

export const notifyError = ({
  status,
  message,
  position = toast.POSITION.BOTTOM_RIGHT,
}) =>
  toast.error(
    <ThemeProvider theme={theme}>
      <Wrapper>
        <IconWrapper>
          <Icon as={Error} />
        </IconWrapper>
        <TextWrapper>
          <StringWithSpacing>
            <strong>Error {status}</strong>
          </StringWithSpacing>
          <StringWithSpacing>{message}</StringWithSpacing>
        </TextWrapper>
      </Wrapper>
    </ThemeProvider>,
    {
      position,
    },
  );

export const notifyWarning = ({
  message,
  position = toast.POSITION.BOTTOM_RIGHT,
}) =>
  toast.warn(
    <ThemeProvider theme={theme}>
      <Wrapper>
        <IconWrapper>
          <Icon as={Warning} />
        </IconWrapper>
        <StringWithSpacing>{message}</StringWithSpacing>
      </Wrapper>
    </ThemeProvider>,
    {
      position,
    },
  );

export const notifyInfo = ({
  message,
  position = toast.POSITION.BOTTOM_RIGHT,
}) =>
  toast.info(
    <ThemeProvider theme={theme}>
      <Wrapper>
        <IconWrapper>
          <Icon as={Checkmark} />
        </IconWrapper>
        <StringWithSpacing>{message}</StringWithSpacing>
      </Wrapper>
    </ThemeProvider>,
    {
      position,
    },
  );