import React from 'react';
import { Paper, Switch } from '@mediahuis/chameleon-react';
import useAboshopContext from './context/useContext';

const FormConfig = () => {
  const { markDown, setGlobalState } = useAboshopContext();

  return (
    <Paper p={3}>
      <Switch
        id="markDown"
        data-testid="markDown-toggle"
        label="Display markdown"
        checked={markDown}
        onChange={e => {
          const checked = e.target.checked;
          setGlobalState(prev => ({ ...prev, markDown: checked }));
        }}
      />
    </Paper>
  );
};

export default FormConfig;
