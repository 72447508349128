import React from 'react';

import { useOffers } from '~/services/offer';

const OffersQuery = ({ filters, render }) => {
  const offersQuery = useOffers(filters);

  if (offersQuery.isError) {
    return <p>Error while loading offers</p>;
  }

  if (offersQuery.isSuccess) {
    return render(offersQuery.data, offersQuery.isFetching);
  }

  return <p>Loading offers..</p>;
};

export default OffersQuery;
