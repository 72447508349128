import REQUIREMENT_TYPE from './requirementType';

const REQUIREMENT_LABEL = Object.freeze({
  [REQUIREMENT_TYPE.AddressArea]: 'Address Area',
  [REQUIREMENT_TYPE.Boolean]: 'Checkbox',
  [REQUIREMENT_TYPE.Choice]: 'Choice',
  [REQUIREMENT_TYPE.Date]: 'Date',
  [REQUIREMENT_TYPE.Email]: 'Email',
  [REQUIREMENT_TYPE.File]: 'File',
  [REQUIREMENT_TYPE.InvoiceDetails]: 'Invoice Details',
  [REQUIREMENT_TYPE.Login]: 'Login',
  [REQUIREMENT_TYPE.Payment]: 'Payment',
  [REQUIREMENT_TYPE.Preconditions]: 'Preconditions',
  [REQUIREMENT_TYPE.Textfield]: 'Textfield',
  [REQUIREMENT_TYPE.Voucher]: 'Voucher',
});

export default REQUIREMENT_LABEL;
