import { TABLES, TABLE_STATES, TABLE_PAGINATION } from '~/constants';
import getFromStorage from './getFromStorage';

const mapFilters = (filters = {}) => {
  if (typeof filters.dateFrom === 'string') {
    filters.dateFrom = new Date(filters.dateFrom);
  }

  if (typeof filters.dateTo === 'string') {
    filters.dateTo = new Date(filters.dateTo);
  }

  return filters;
};

const mapPagination = (pagination = {}) => ({
  ...TABLE_PAGINATION,
  ...pagination,
});

const getInitialTables = () => {
  const tables = getFromStorage({ key: TABLES });

  if (tables) {
    const keys = Object.keys(tables);

    for (let i = 0; i < keys.length; i += 1) {
      const id = keys[i];
      tables[id].filters = mapFilters(tables[id].filters);
      tables[id].pagination = mapPagination(tables[id].pagination);
    }

    return tables;
  }

  return TABLE_STATES;
};

export default getInitialTables;
