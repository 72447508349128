import React from 'react';
import {
  Paper,
  Box,
  Flex,
  Tooltip,
  Button,
  TextField,
  IconButton,
} from '@mediahuis/chameleon-react';
import { Delete } from '@mediahuis/chameleon-theme-wl/icons';
import { HeaderWrapper } from '~/components';
import { variantsValidations } from '../validation/variantsValidation';

const ProposalVariants = ({ data, setData, errors, setErrors }) => {
  const validateVariantInput = ({ name, path, id }) => {
    const newVariantErrors = variantsValidations({
      name,
      path,
      error: errors[id],
      data,
      id,
    });
    setErrors(prevState => ({
      ...prevState,
      [id]: newVariantErrors,
    }));
  };

  const clearVariantError = ({ id, field }) => {
    const currentVariantErrors = { ...errors[id] };
    delete currentVariantErrors[field];
    setErrors(prevState => ({
      ...prevState,
      [id]: currentVariantErrors,
    }));
  };

  const onVariantChange = ({ id, variant, value }) => {
    const updatedVariants = data.map(element => {
      if (element.id === variant.id) {
        return {
          ...variant,
          [id]: value,
        };
      }
      return element;
    });
    setData(updatedVariants);
  };

  const addNewProposalVariant = () => {
    const sortedVariants = data.sort((a, b) => a.id - b.id);
    // Only add a new variant if the last created variant has values.
    if (
      sortedVariants?.slice(-1)[0]?.name !== '' ||
      sortedVariants?.slice(-1)[0]?.path !== ''
    ) {
      // Clear variant errors if exist.
      if (errors) {
        const currentErrors = { ...errors };
        delete currentErrors.general;
        setErrors(currentErrors);
      }
      setData(prevState => [
        ...prevState,
        {
          id:
            sortedVariants.length > 0
              ? sortedVariants?.slice(-1)[0]?.id + 1
              : 0,
          name: '',
          path: '',
          newVariant: true,
        },
      ]);
    } else {
      setErrors(prevState => ({
        ...prevState,
        general: 'First fill in empty variant before creating a new one.',
      }));
    }
  };

  const removeVariant = ({ id }) => {
    const filteredVariants = data.filter(variant => variant.id !== id);
    setData(filteredVariants);
  };

  return (
    <Paper>
      <Box p={7}>
        <HeaderWrapper>
          <Flex>
            <Box fontSize={16} mb={2}>
              <b>Variants</b>
            </Box>
            <Flex ml={3}>
              <Tooltip
                id="tooltip"
                label="Changes will only persist when the proposal is saved."
              />
            </Flex>
          </Flex>
          <Button
            data-testid="create-proposal-variant"
            appearance="secondary"
            onClick={addNewProposalVariant}
            size="small"
          >
            Add
          </Button>
        </HeaderWrapper>
        {data.map((variant, index) => (
          <Flex key={variant.id} mt={4}>
            <Box width="40%">
              <TextField
                id={`variant-name-${variant.id}`}
                label="Name"
                placeholder=""
                value={variant.name}
                data-testid="variant-name"
                mr={3}
                onChange={e =>
                  onVariantChange({
                    id: 'name',
                    variant,
                    value: e.target.value.toLowerCase(),
                  })
                }
                error={errors[variant.id]?.name}
                message={errors[variant.id]?.name}
                onBlur={() =>
                  validateVariantInput({
                    name: variant.name,
                    id: variant.id,
                  })
                }
                onFocus={() =>
                  clearVariantError({ id: variant.id, field: 'name' })
                }
              />
            </Box>
            <Box width="40%">
              <TextField
                id={`variant-path-${variant.id}`}
                label="Offer Slug"
                placeholder=""
                value={variant.path}
                data-testid="variant-path"
                mr={3}
                onChange={e =>
                  onVariantChange({
                    id: 'path',
                    variant,
                    value: e.target.value,
                  })
                }
                error={errors[variant.id]?.path}
                message={errors[variant.id]?.path}
                onBlur={() =>
                  validateVariantInput({
                    path: variant.path,
                    id: variant.id,
                  })
                }
                onFocus={() =>
                  clearVariantError({ id: variant.id, field: 'path' })
                }
              />
            </Box>
            {index !== 0 && (
              <Flex alignItems="flex-end">
                <Flex alignItems="center" height="48px">
                  <IconButton
                    onClick={() => removeVariant({ id: variant.id })}
                    data-testid="button-delete"
                    icon={Delete}
                    color="colorRedBase"
                    size="small"
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        ))}
        <Box mt={3}>
          <p>{errors.general}</p>
        </Box>
      </Box>
    </Paper>
  );
};

export default ProposalVariants;
