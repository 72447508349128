import { PAYMENT_INTERVAL, PAYMENT_OPTION } from '../enums';

const defaultOneShotPaymentOptions = [
  PAYMENT_OPTION.Bancontact,
  PAYMENT_OPTION.Belfius,
  PAYMENT_OPTION.CreditCard,
  PAYMENT_OPTION.DirectDebit,
  PAYMENT_OPTION.KBC,
  PAYMENT_OPTION.PayPal,
  PAYMENT_OPTION.WireTransfer,
];
const defaultRecurringPaymentOptions = [
  PAYMENT_OPTION.Bancontact,
  PAYMENT_OPTION.CreditCard,
  PAYMENT_OPTION.DirectDebit,
];

const getPaymentOptions = interval => {
  const paymentOptions = {
    [PAYMENT_INTERVAL.ONESHOT]: {
      [BRANDS.AA]: defaultOneShotPaymentOptions,
      [BRANDS.DEMO]: defaultOneShotPaymentOptions,
      [BRANDS.HUB]: defaultOneShotPaymentOptions,
      [BRANDS.LUX]: defaultOneShotPaymentOptions,
      [BRANDS.NL]: [
        PAYMENT_OPTION.CreditCard,
        PAYMENT_OPTION.DirectDebit,
        PAYMENT_OPTION.Ideal,
        PAYMENT_OPTION.PayPal,
        PAYMENT_OPTION.WireTransfer,
      ],
    },
    [PAYMENT_INTERVAL.RECURRING]: {
      [BRANDS.AA]: defaultRecurringPaymentOptions,
      [BRANDS.DEMO]: defaultRecurringPaymentOptions,
      [BRANDS.HUB]: defaultRecurringPaymentOptions,
      [BRANDS.LUX]: defaultRecurringPaymentOptions,
      [BRANDS.NL]: [
        PAYMENT_OPTION.CreditCard,
        PAYMENT_OPTION.DirectDebit,
        PAYMENT_OPTION.Ideal,
      ],
    },
  };

  return paymentOptions[interval][MH_BRAND] ?? [];
};

export default getPaymentOptions;
