import { Box, Flex, Icon, Paper, Text } from '@mediahuis/chameleon-react';
import { Clipboard, Tune } from '@mediahuis/chameleon-theme-wl/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const pages = [
  {
    link: 'configuration',
    title: 'Configuration',
    description: 'Technical configuration',
    icon: Tune,
    size: 12,
  },
  {
    link: 'subscriptionScopes',
    title: 'Subscription Scopes',
    description: 'Overview of all Subscription Scopes',
    icon: Clipboard,
  },
  {
    link: 'features',
    title: 'Features',
    description: 'Overview of all features',
    icon: Clipboard,
  },
];

const ConfigOverview = () => {
  const history = useHistory();

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Flex>
        {pages.map(page => (
          <Paper
            data-testid={`${page.link}-card`}
            key={page.link}
            width="full"
            mx={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => history.push(page.link)}
            hoverable
          >
            <Box textAlign="center" p={5}>
              <Box mb={3}>
                <Icon as={page.icon} size="xLarge" />
              </Box>
              <Box mb={1}>
                <Text size="Heading4">{page.title}</Text>
              </Box>
              <Box mb={2}>
                <Text>{page.description}</Text>
              </Box>
            </Box>
          </Paper>
        ))}
      </Flex>
    </Flex>
  );
};

export default ConfigOverview;
