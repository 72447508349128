import { Button, Flex, Heading, Switch } from '@mediahuis/chameleon-react';
import { Exit } from '@mediahuis/chameleon-theme-wl/icons';
import { Auth } from 'aws-amplify';
import React from 'react';

import { useAboshopContext } from '~/components';
import { TOP_BAR_HEIGHT } from '~/constants/styles';
import { setLocalStorage } from '../../utils';

const TopBar = () => {
  const context = useAboshopContext();

  const toggleChecked = e => {
    const checked = e.target.checked;
    const name = e.target.name;
    setLocalStorage(name, checked);
    context.setGlobalState(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <Flex
      alignItems="center"
      borderBottomColor="colorGrey20"
      borderBottomStyle="solid"
      borderBottomWidth={1}
      data-testid="top-bar"
      height={TOP_BAR_HEIGHT}
      justifyContent="space-between"
      px={7}
      width="full"
    >

      <Flex style={{ gap: '1rem' }}>
        <img
          alt="brand-square-main"
          data-testid="button-home"
          src="https://shared.mediahuis.be/logos/hub/v1/brand-square-main.svg"
          style={{ maxHeight: '32px', maxWidth: '100%' }}
        />
        <Heading fontFamily="primary" level={4} m={0}>
          Aboshopadmin
        </Heading>
      </Flex>

      <Flex alignItems="center">
        { MH_BRAND !== BRANDS.MHAA && <Switch
          checked={context.experimentalMode}
          name="experimentalMode"
          label="Experimental mode"
          labelPlacement="right"
          labelProps={{
            fontWeight: 'bold',
            color: 'black',
          }}
          onChange={toggleChecked}
        />}

        <Button
          appearance="plain"
          data-testid="button-sign-out"
          iconLeft={Exit}
          size="small"
          onClick={() => Auth.signOut()}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
};

export default TopBar;
