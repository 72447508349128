import { isBefore } from 'date-fns';

const validateOffer = ({ offer, setErrors }) => {
  const errors = {};
  if (!offer.reference) {
    errors.reference = 'Reference is required';
  }
  if (!offer.slug) {
    errors.slug = 'Slug is required';
  }
  if (offer.slug && !/^[a-zA-Z\x7f-\xff-Z0-9\-_/]*$/.test(offer.slug)) {
    errors.slug = 'Invalid characters in slug';
  }
  if (!offer.title) {
    errors.title = 'Title is required';
  }
  if (!offer.startDate) {
    errors.startDate = 'start date is required';
  }
  if (!offer.endDate) {
    errors.endDate = 'end date is required';
  }
  if (
    offer.startDate &&
    offer.endDate &&
    isBefore(new Date(offer.endDate), new Date(offer.startDate))
  ) {
    errors.endDate = `End date must be later than ${new Date(
      offer.startDate,
    ).toDateString()}`;
  }

  setErrors(errors);
  return errors;
};

export default validateOffer;
