import { Flex, Heading, Paper, Text } from '@mediahuis/chameleon-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FormButtons, notifyError, notifySuccess } from '~/components';
import { useDeleteContent } from '~/services/content';

const ContentsDetailMenu = ({ brand, id, isLoading, onSave }) => {
  const history = useHistory();

  const contentDelete = useDeleteContent();

  useEffect(() => {
    if (contentDelete.isError) {
      notifyError({
        message:
          'An error was encountered while deleting the HTML content. Please Try again later.',
      });
    }
  }, [contentDelete.isError]);

  useEffect(() => {
    if (contentDelete.isSuccess) {
      notifySuccess({
        message: 'Successfully deleted the HTML content.',
      });
      history.push('/contents');
    }
  }, [history, contentDelete.isSuccess]);

  return (
    <Flex flexDirection="column" style={{ gap: '1rem' }}>
      <Paper p={4}>
        <Heading fontFamily="primary" level={5}>
          Summary
        </Heading>
        <Text as="p" mb={3} size="Caption1">
          <strong>Id</strong>: {id}
        </Text>
        <Text as="p" mb={3} size="Caption1">
          <strong>Brand</strong>: {brand}
        </Text>
      </Paper>

      <FormButtons
        deleteMethod={() => contentDelete.mutate({ id })}
        editing
        isDeletable
        isLoading={isLoading}
        onSave={onSave}
      />
    </Flex>
  );
};

export default ContentsDetailMenu;
