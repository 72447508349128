import { Button, Flex, Text } from '@mediahuis/chameleon-react';
import { Pagination } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';

import { LiveChart, useAboshopContext } from '~/components';

import LanguageDropdown from '~/components/LanguageDropdown';
import { useQueryParams } from '~/hooks';
import { OrdersQuery, useOrdersCsv } from '~/services/order';

import OrdersFilters from './OrdersFilters';
import OrdersTable from './OrdersTable';

const defaultEndDate = dayjs()
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 59)
  .format('YYYY-MM-DDTHH:mm:ssZ');
const defaultOffset = 0;
const defaultOffsetSize = 20;
const defaultStartDate = dayjs()
  .subtract(1, 'month')
  .set('hour', 0)
  .set('minute', 0)
  .set('second', 0)
  .format('YYYY-MM-DDTHH:mm:ssZ');

const OrdersPage = () => {
  const context = useAboshopContext();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [params, setParams] = useQueryParams();
  const showStatistics = context.experimentalMode === undefined ? true : context.experimentalMode;

  const ordersCsvExport = useOrdersCsv();

  function resetFilters() {
    setParams({
      endDate: defaultEndDate,
      offset: defaultOffset,
      startDate: defaultStartDate,
    });
  }

  if (!params.endDate || !params.offset || !params.startDate) {
    return (
      <Redirect
        to={`${pathname}?endDate=${encodeURIComponent(
          defaultEndDate,
        )}&offset=${defaultOffset}&startDate=${encodeURIComponent(
          defaultStartDate,
        )}`}
      />
    );
  }

  const intervalDays = dayjs(params.endDate).diff(dayjs(params.startDate), 'days');
  return (
    <OrdersQuery
      filters={{
        includeStatistics: true,
        statisticsIntervalSeconds: intervalDays === 0 ? 3600 : 86400,
        ...params,
      }}
      render={(orders, isFetchingOrders) => (
        <Flex
          justifyContent="space-between"
          px={5}
          style={{ gap: '2.5rem' }}
          width="full"
        >
          <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
            {showStatistics && <LiveChart dataPoints={orders.dataPoints} startDate={params.startDate} endDate={params.endDate} showOverviewText='true' />}
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Total: {orders.totalCount}</Text>

              <Pagination
                current={params.offset / defaultOffsetSize + 1}
                pageSize={defaultOffsetSize}
                showSizeChanger={false}
                total={orders.totalCount}
                onChange={page =>
                  setParams({
                    ...params,
                    offset: (page - 1) * defaultOffsetSize,
                  })
                }
              />

              <Flex alignItems="center" style={{ gap: '5px' }}>
                <Button
                  appearance="primary"
                  data-testid="orders-download-btn"
                  loading={ordersCsvExport.isLoading}
                  size="small"
                  onClick={() => ordersCsvExport.mutate(params)}
                >
                  Download
                </Button>
                <LanguageDropdown
                  onChange={() => queryClient.invalidateQueries()}
                />
              </Flex>
            </Flex>

            <OrdersTable isLoading={isFetchingOrders} orders={orders} />
          </Flex>

          <OrdersFilters onReset={resetFilters} />
        </Flex>
      )}
    />
  );
};

export default OrdersPage;
