const useImageValidation = ({ logo, image }) => {
  const errors = {};
  if (!logo) {
    errors.logo = { message: 'Logo is a required field' };
  }
  if (!image) {
    errors.image = { message: 'Image is a required field' };
  }

  return errors;
};

export default useImageValidation;
