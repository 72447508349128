export const states = {
  Confirmed: 'success',
  true: 'success',
  Revoked: 'error',
  false: 'error',
  Pending: 'pending',
  Compliant: 'success',
  InValid: 'error',
  Used: 'success',
  New: 'pending',
  Sold: 'success',
};
