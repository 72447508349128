import React from 'react';
import { Flex, Box, TextField, Text } from '@mediahuis/chameleon-react';
import { BrandSelect } from '~/components/';
import { MultiSelect, Option } from '~/components/Ant/MultiSelect';
import { useSubscriptionScopeContext } from '~/components/context/subscriptionScope';
import constants from '../constants';

const SubscriptionScopeForm = ({ id }) => {
  const {
    subscriptionScope,
    setSubscriptionScopeState,
    errors,
    clearError,
  } = useSubscriptionScopeContext();
  const { subscriptionType, scopes } = subscriptionScope;

  const onBlur = event => {
    event.persist();
    setSubscriptionScopeState(event.target.name, event.target.value);
  };

  return (
    <>
      <Flex mb={5}>
        <Box width="50%">
          <TextField
            id="subscriptionType"
            datatest-id="subscriptionType-input"
            label="SubscriptionType"
            name="subscriptionType"
            onBlur={onBlur}
            onFocus={clearError}
            error={errors.subscriptionType}
            message={errors.subscriptionType}
            defaultValue={subscriptionType}
            disabled={!!id}
          />
        </Box>
        <Box width="50%" ml={4}>
          <BrandSelect
            value={subscriptionScope.brand}
            onChange={event =>
              setSubscriptionScopeState('brand', event.target.value)
            }
            disabled={!!id}
          />
        </Box>
      </Flex>
      <Box width="full" mb={5}>
        <Text as="p" mb={3}>
          Scopes
        </Text>
        <MultiSelect
          style={{ width: '100%' }}
          allowClear
          mode="multiple"
          value={scopes || []}
          onChange={value => setSubscriptionScopeState('scopes', value)}
          id="parameter"
          name="parameter"
        >
          {constants.scopes.map(option => (
            <Option
              style={{ marginRight: '4px', paddingLeft: '2px' }}
              key={option}
            >
              {option}
            </Option>
          ))}
        </MultiSelect>
      </Box>
    </>
  );
};

export default SubscriptionScopeForm;
