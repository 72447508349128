const validateType = ({ type = {}, setErrors }) => {
  const errors = {};
  if (!type.reference) {
    errors.reference = 'Reference is required';
  }
  if (!type.title) {
    errors.title = 'Title is required';
  }
  if (!type.description) {
    errors.description = 'Description is required';
  }
  setErrors(errors);
  return errors;
};

export default validateType;
