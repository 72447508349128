import { Auth } from 'aws-amplify';
import Axios from 'axios';
import queryString from 'query-string';

const contentServiceInstance = Axios.create({
  baseURL: API_CONTENT_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
});

contentServiceInstance.interceptors.request.use(
  async config => {
    const session = await Auth.currentSession();

    if (session.idToken?.jwtToken) {
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    }

    return config;
  },
  err => Promise.reject(err),
);

class ContentService {
  static deleteContent(id) {
    return contentServiceInstance.delete('/Contents', {
      params: {
        id,
      },
    });
  }

  static deleteContentLink(id) {
    return contentServiceInstance.delete('/ContentLinks', {
      params: {
        id,
      },
    });
  }

  static getContent(id) {
    return contentServiceInstance.get(`/Contents/${id}`);
  }

  static getContents(filters = {}) {
    const filterParams = queryString.stringify(filters);

    return contentServiceInstance.get(`/Contents?${filterParams}`);
  }

  static getContentLinks(brand, key) {
    return contentServiceInstance.get('/ContentLinks', {
      params: {
        brand,
        key,
      },
    });
  }

  static patchContent(id, body) {
    return contentServiceInstance.patch(`/Contents/${id}`, body);
  }

  static patchContentLink(id, body) {
    return contentServiceInstance.patch(`/ContentLinks/${id}`, body);
  }

  static postContent(body) {
    return contentServiceInstance.post('/Contents', body);
  }

  static postContentLink(body) {
    return contentServiceInstance.post('/ContentLinks', body);
  }
}

export default ContentService;
