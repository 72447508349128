import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { OrderService } from '~/services/order';

export const ORDERS_KEY = 'orders';

const useOrders = filters => {
  const queryClient = useQueryClient();

  const [placeholderData, setPlaceholderData] = useState();

  return useQuery(
    [ORDERS_KEY, filters],
    () =>
      OrderService.getOrders(filters).then(response => {
        setPlaceholderData(response.data);

        return response.data;
      }),
    {
      placeholderData,
      onSuccess: data => {
        if (data.loadMore && filters.offset) {
          const prefetchFilters = {
            ...filters,
            offset: parseInt(filters.offset, 10) + data.results.length,
          };

          queryClient.prefetchQuery([ORDERS_KEY, prefetchFilters], () =>
            OrderService.getOrders(prefetchFilters).then(
              response => response.data,
            ),
          );
        }
      },
    },
  );
};

export default useOrders;
