import { Box, Paper, Flex } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { FeatureForm, notifyError, PageLoader } from '~/components';
import { secureApiCall } from '../../utils/';

const EditFeature = () => {
  const [initialValues, setInitialValues] = useState();
  const history = useHistory();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const apiUrl = `${API_OFFER_SERVICE}/Features/${id}`;

  useEffect(() => {
    secureApiCall(apiUrl, {
      method: 'GET',
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        setInitialValues(response.data);
      },
      onError: errorMessage => {
        notifyError({
          status: errorMessage?.response?.request?.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
        history.push('/features');
      },
    });
  }, [apiUrl, history, i18n.language]);

  if (!initialValues) {
    return <PageLoader />;
  }

  return (
    <Flex justifyContent="center">
      <Paper>
        <Box p={7} width="600px">
          <FeatureForm editing apiUrl={apiUrl} initialValues={initialValues} />
        </Box>
      </Paper>
    </Flex>
  );
};

export default EditFeature;
