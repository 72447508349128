import Axios from 'axios';

const orderSettleServiceInstance = Axios.create({
  baseURL: API_ORDER_SETTLE_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    'api-version': '2',
  },
});

class OrderSettleService {
  static getOrderSettlementByOrderId(orderId) {
    return orderSettleServiceInstance.get('/OrderSettlements', {
      params: { orderId },
    });
  }
}

export default OrderSettleService;
