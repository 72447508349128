import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrandWizard } from '../../components';
import ProposalForm from './components/ProposalForm';

const AddProposal = () => {
  const { state = {} } = useLocation();

  const [initialValues, setInitialValues] = useState({
    ...state.initialValues,
    id: 0,
    brand: '',
  });

  const handleSelectBrand = brandCode => {
    if (state?.initialValues?.brand === brandCode) {
      setInitialValues({ ...initialValues, brand: brandCode });
    } else {
      setInitialValues({
        ...initialValues,
        brand: brandCode,
      });
    }
  };

  return initialValues.brand ? (
    <ProposalForm initialValues={initialValues} />
  ) : (
    <BrandWizard handleSelectBrand={handleSelectBrand} />
  );
};

export default AddProposal;
