import React, { useState, createContext, useContext } from 'react';
import constants from '~/pages/preconditions/constants/';
import useAboshopContext from './useContext';

const PreconditionContext = createContext();

const PreconditionsProvider = ({ children }) => {
  const globalContext = useAboshopContext();
  const defaultCriteriaPropertyName = Object.keys(constants.criteria)[0];

  const defaultCriteria = [
    {
      id: Date.now(),
      propertyName: defaultCriteriaPropertyName,
      operation: constants.criteria[defaultCriteriaPropertyName].operations[0],
      parameter: [
        constants.criteria[defaultCriteriaPropertyName].parameters[0],
      ],
    },
  ];

  const [errors, setErrors] = useState({});

  const [precondition, setPrecondition] = useState({
    name: '',
    errorMessage: '',
    brand: globalContext?.brands?.[0]?.code,
    fallbackOfferId: null,
    criteria: defaultCriteria,
  });

  const clearError = event => {
    const spreadedErrors = { ...errors };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const setPreconditionState = (key, value) =>
    setPrecondition(prevState => ({ ...prevState, [key]: value }));

  const value = {
    precondition,
    setPreconditionState,
    setPrecondition,
    defaultCriteria,
    errors,
    setErrors,
    clearError,
  };

  return (
    <PreconditionContext.Provider value={value}>
      {children}
    </PreconditionContext.Provider>
  );
};

const usePreconditionContext = () => {
  const context = useContext(PreconditionContext);

  if (context === undefined) {
    throw new Error(
      'usePreconditionContext must be used within a PreconditionsProvider',
    );
  }

  return context;
};

export { PreconditionsProvider, usePreconditionContext };
