import { useQuery } from 'react-query';

import { ContentService } from '~/services/content';

export const ALL_CONTENTS_KEY = 'all-contents';

const queryOffset = 200;

const useAllContents = (params, enabled = true) => {
  return useQuery(
    [ALL_CONTENTS_KEY, params],
    () =>
      ContentService.getContents({ ...params, offset: 0 }).then(
        async firstResponse => {
          const contentsData = firstResponse.data;
          const requestsNeeded = Math.ceil(
            firstResponse.data.realCount / queryOffset,
          );

          for (let i = 1; i < requestsNeeded; i++) {
            // eslint-disable-next-line no-await-in-loop
            const response = await ContentService.getContents({
              ...params,
              offset: i * queryOffset,
            });

            contentsData.list = [...contentsData.list, ...response.data.list];
          }

          return contentsData;
        },
      ),
    {
      enabled,
    },
  );
};

export default useAllContents;
