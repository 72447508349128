const scopes = [
  'PlusArticles',
  'Publication',
  'PrintWeek',
  'PrintWeekend',
  'MultiBrand',
  'RouteYou'
];

const base = {
  scopes,
};

export default base;
