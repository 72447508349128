import { Box, Flex, IconButton, Text } from '@mediahuis/chameleon-react';
import { Delete, Edit } from '@mediahuis/chameleon-theme-wl/icons';
import React from 'react';

import { noop } from '~/utils';

import Parameters from './Parameters';

const DetailList = ({
  disabled = false,
  editable = true,
  handleDelete = noop,
  handleEdit = noop,
  initialValues = [],
  type = '',
}) => (
  <Box>
    {initialValues.map(item => (
      <Box my={5} key={item.name}>
        <Flex mt={4}>
          <Flex justifyContent="space-between" width="full">
            <Box>
              <Box>
                <Text
                  size="Heading6"
                  primary={item.name}
                  secondary={item.description}
                >
                  {item.name}
                </Text>
              </Box>
              <Text
                size="paragraph"
                primary={item.name}
                secondary={item.description}
              >
                {item.description}
              </Text>
            </Box>
            <Flex>
              <Flex flexDirection="column" justifyContent="center">
                {!disabled && editable && (
                  <IconButton
                    icon={Edit}
                    disabled={disabled}
                    onClick={() => handleEdit(item)}
                    size="small"
                    data-testid={`${type}-edit-icon`}
                    aria-label="edit"
                  />
                )}
              </Flex>
              <Flex flexDirection="column" justifyContent="center">
                {!disabled && (
                  <IconButton
                    icon={Delete}
                    disabled={disabled}
                    onClick={() => handleDelete(item)}
                    size="small"
                    data-testid={`${type}-delete-icon`}
                    aria-label="delete"
                    color="colorRedBase"
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Parameters ml={7} data={item.parameters} />
      </Box>
    ))}
  </Box>
);

export default DetailList;
