import React, { useState, useEffect } from 'react';
import { secureApiCall } from '~/utils';
import { useParams, useHistory } from 'react-router-dom';
import { notifyError, PageLoader } from '~/components';
import ProposalForm from './components/ProposalForm';

const EditOffer = () => {
  const [initialValues, setInitialValues] = useState();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    secureApiCall(`${ABO_PROPOSAL_SERVICE}/proposals/${id}?api-version=2.0`, {
      method: 'GET',
      onSuccess: response => {
        setInitialValues(response.data);
        return response;
      },
      onError: error => {
        notifyError({
          status: error?.response?.request?.status,
          message: error?.response?.data?.errors
            ? error.response.data.errors[0]
            : error.message || 'Could not retrieve proposal.',
        });
        history.push('/proposals');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialValues ? (
    <ProposalForm editing initialValues={initialValues} />
  ) : (
    <PageLoader />
  );
};

export default EditOffer;
