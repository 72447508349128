import { Flex, Textarea, TextField } from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { number, object, string } from 'yup';

const DateRequirementFormSchema = object().shape({
  description: string(),
  endDay: number().required(),
  name: string().required(),
  startDay: number().required(),
});

const DateRequirementForm = ({
  disabled,
  formRef,
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={DateRequirementFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NameInput"
                label="Name"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Flex style={{ gap: '0.5rem' }}>
            <Field name="startDay">
              {({ field, meta }) => (
                <TextField
                  disabled={disabled}
                  error={!!meta.touched && !!meta.error}
                  id="StartDayInput"
                  label="Start Day"
                  message={meta.touched && meta.error ? meta.error : undefined}
                  name={field.name}
                  required
                  type="number"
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                />
              )}
            </Field>

            <Field name="endDay">
              {({ field, meta }) => (
                <TextField
                  disabled={disabled}
                  error={!!meta.touched && !!meta.error}
                  id="EndDayInput"
                  label="End Day"
                  message={meta.touched && meta.error ? meta.error : undefined}
                  name={field.name}
                  required
                  type="number"
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                />
              )}
            </Field>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  );
};

export default DateRequirementForm;
