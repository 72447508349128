const getFromLocalStorage = item => {
  const storage = localStorage.getItem(item);

  if (storage) {
    return Boolean(storage === 'on');
  }

  return null;
};

export default getFromLocalStorage;
