import axios from 'axios';
import { getI18n } from 'react-i18next';

const apiCall = (url, { onSuccess, onError, params, ...config }) => {
  const { language } = getI18n();
  return axios({
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      lang: language,
      ...params,
    },
    ...config,
  })
    .then(response => onSuccess(response))
    .catch(error => {
      if (onError) {
        onError(error);
      }
    });
};

export default apiCall;
