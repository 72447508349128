import { Box, Button, Flex, Select } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutChoiceValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Choices = ({ validation }) => {
  const options = validation.choices;

  const [state, setState] = useState(validation.state);
  const [value, setValue] = useState(validation?.data?.value);

  const choiceValidationMutation = usePutChoiceValidation();

  useEffect(() => {
    if (choiceValidationMutation.isError) {
      notifyError({
        status:
          choiceValidationMutation.error.response?.request?.status &&
          choiceValidationMutation.error.response?.request?.status,
        message: choiceValidationMutation.error.response?.data?.errors
          ? choiceValidationMutation.error.response?.data?.errors[0]
          : choiceValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceValidationMutation.isError]);

  useEffect(() => {
    if (choiceValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the choice',
      });
    }
  }, [choiceValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Choice
          {` | ${validation?.key}`}
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>
        <Box>
          <Select
            id="choice"
            data-testid="choice"
            value={value}
            onChange={e => setValue(e.target.value)}
            label={validation.description}
          >
            {options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </Box>
        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              choiceValidationMutation.mutate({
                ...validation,
                data: { value },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Choices;
