import { useMutation, useQueryClient } from 'react-query';

import { OrderService, ORDER_KEY } from '~/services/order';

export const usePutVoucherValidation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      OrderService.putVoucherValidation(body).then(response => response.data),
    {
      onSuccess: responseData => {
        queryClient.setQueryData(
          [ORDER_KEY, responseData.orderId],
          currentOrder => ({
            ...currentOrder,
            voucherValidation: responseData,
          }),
        );
      },
    },
  );
};
