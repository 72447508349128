import { notifyError } from '~/components';
import secureApiCall from './secureApiCall';

const uploadImage = (file, brand, fallback) => {
  if (file) {
    const formDataImage = new FormData();
    formDataImage.append('file', file);

    return secureApiCall(`${SUBSCRIBER_FILE_SERVICE}/aboshop-public/${brand}?api-version=2`, {
      method: 'POST',
      data: formDataImage,
      onSuccess: response => ({
        ...response.data,
        id: response.data.filePath
      }),
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  }
  if (fallback) {
    return Promise.resolve({ id: fallback });
  }
  return Promise.resolve({ id: null });
};

export default uploadImage;
