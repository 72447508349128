import { useMutation, useQueryClient } from 'react-query';

import { OrderService, ORDER_KEY } from '~/services/order';

export const usePutTextfieldValidation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      OrderService.putTextfieldValidation(body).then(response => response.data),
    {
      onSuccess: responseData => {
        queryClient.setQueryData(
          [ORDER_KEY, responseData.orderId],
          currentOrder => ({
            ...currentOrder,
            textfieldValidations: currentOrder.textfieldValidations.map(
              textfieldValidation => {
                if (textfieldValidation.id === responseData.id) {
                  return responseData;
                }

                return textfieldValidation;
              },
            ),
          }),
        );
      },
    },
  );
};
