import React from 'react';
import styled from 'styled-components';
import {
  Accordion as CHAccordion,
  AccordionItem as CHAccordionItem,
  Paper,
  Box,
} from '@mediahuis/chameleon-react';

const AccordionItem = styled(CHAccordionItem)`
  #headerText {
    color: #000000;
    text-decoration: none;
  }
`;

const Accordion = styled(CHAccordion)`
  #expansionPanel-0-section {
    padding: 20px !important;
  }
`;

const ExpansionPanel = ({ headerText, defaultExpanded = false, children }) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleChange = () => (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Box mb={2} p={2} width="full" textAlign="left">
      <Paper>
        <Accordion
          expanded={expanded}
          onChange={handleChange()}
          collapsible={true}
          id="expansionPanel"
        >
          <AccordionItem title={<div id="headerText">{headerText}</div>}>
            {children}
          </AccordionItem>
        </Accordion>
      </Paper>
    </Box>
  );
};

export default ExpansionPanel;
