import React from 'react';

import { PreconditionsProvider } from '../components/context/precondition';
import { SubscriptionScopesProvider } from '../components/context/subscriptionScope';
import ConfigOverview from '../pages/config/ConfigOverview';
import Configuration from '../pages/config/Configuration';
import {
  ContentsCreate,
  ContentsDetail,
  ContentsLocations,
  ContentsOverview,
} from '../pages/contents';
import AddFeature from '../pages/features/AddFeature';
import EditFeature from '../pages/features/EditFeature';
import FeaturesPage from '../pages/features/FeaturesPage';
import AddFormula from '../pages/formulas/AddFormula';
import EditFormula from '../pages/formulas/EditFormula';
import SubscriptionFormulasPage from '../pages/formulas/SubscriptionFormulasPage';
import Home from '../pages/home/';
import NotFound from '../pages/notFound';
import AddOffer from '../pages/offers/AddOffer';
import EditOffer from '../pages/offers/EditOffer';
import OffersPage from '../pages/offers/OffersPage';
import OrderDetail from '../pages/orders/OrderDetail';
import OrdersPage from '../pages/orders/OrdersPage';
import PreconditionDetail from '../pages/preconditions/PreconditionDetail';
import PreconditionsPage from '../pages/preconditions/PreconditionsPage';
import AddProposal from '../pages/proposals/AddProposal';
import EditProposal from '../pages/proposals/EditProposal';
import ProposalsPage from '../pages/proposals/ProposalsPage';
import SubscriptionScopesDetail from '../pages/scopes/SubscriptionScopesDetail';
import SubscriptionScopesPage from '../pages/scopes/SubscriptionScopesPage';
import AddType from '../pages/types/AddType';
import EditType from '../pages/types/EditType';
import SubscriptionTypesPage from '../pages/types/SubscriptionTypesPage';
import VoucherDetail from '../pages/vouchers/VoucherDetail';
import VouchersPage from '../pages/vouchers/VouchersPage';
import VoucherTemplates from '../pages/vouchers/VoucherTemplates';

export const homeRoutes = [
  {
    title: 'Home',
    path: '/',
    exact: true,
    Component: <Home />,
  },
];

export const typesRoutes = [
  {
    title: 'Types',
    path: '/subscriptiontype',
    exact: true,
    Component: <SubscriptionTypesPage />,
  },
  {
    title: 'Types - Create',
    path: '/subscriptiontype/create',
    exact: false,
    history: true,
    hasWizard: true,
    Component: AddType,
  },
  {
    title: 'Type - Edit',
    path: '/subscriptiontype/edit/:id',
    exact: true,
    Component: <EditType />,
  },
];

export const productsRoutes = [
  {
    title: 'Products',
    path: '/subscriptionformula',
    exact: true,
    Component: <SubscriptionFormulasPage />,
  },
  {
    title: 'Products - Create',
    path: '/subscriptionformula/create',
    exact: false,
    history: true,
    hasWizard: true,
    Component: AddFormula,
  },
  {
    title: 'Products - Edit',
    path: '/subscriptionformula/edit/:id',
    exact: true,
    Component: <EditFormula />,
  },
];

export const offersRoutes = [
  {
    title: 'Offers',
    path: '/offers',
    exact: true,
    Component: <OffersPage />,
  },
  {
    title: 'Offers - Edit',
    path: '/offers/edit/:id',
    exact: true,
    Component: <EditOffer />,
  },
  {
    title: 'Offers - Create',
    path: '/offers/create',
    exact: false,
    hasWizard: true,
    history: true,
    Component: AddOffer,
  },
];

export const ordersRoutes = [
  {
    title: 'Orders',
    path: '/orders',
    exact: true,
    Component: <OrdersPage />,
  },
  {
    title: 'Orders - Detail',
    path: '/orders/edit/:id',
    exact: true,
    Component: <OrderDetail />,
  },
  {
    title: 'Orders - Detail',
    path: '/orders/all',
    exact: true,
    Component: <OrderDetail />,
  },
];

export const preconditionRulesRoutes = [
  {
    title: 'Precondition Rules',
    path: '/preconditions',
    exact: true,
    Component: <PreconditionsPage />,
  },
  {
    title: 'Precondition Rules - Edit',
    path: '/preconditions/edit/:id',
    exact: true,
    Component: (
      <PreconditionsProvider>
        <PreconditionDetail />
      </PreconditionsProvider>
    ),
  },
  {
    title: 'Precondition Rules - Create',
    path: '/preconditions/create',
    exact: true,
    Component: (
      <PreconditionsProvider>
        <PreconditionDetail />
      </PreconditionsProvider>
    ),
  },
];

export const proposalsRoutes = [
  {
    title: 'Proposals',
    path: '/proposals',
    exact: true,
    Component: <ProposalsPage />,
  },
  {
    title: 'Proposals - Edit',
    path: '/proposals/edit/:id',
    exact: true,
    Component: <EditProposal />,
  },
  {
    title: 'Proposals - Create',
    path: '/proposals/create',
    exact: true,
    Component: <AddProposal />,
  },
];

export const vouchersOverviewRoutes = [
  {
    title: 'Vouchers',
    path: '/vouchers',
    exact: true,
    Component: <VouchersPage />,
  },
  {
    title: 'Vouchers - Detail',
    path: '/vouchers/edit/:id',
    exact: true,
    Component: <VoucherDetail />,
  },
];

export const vouchersTemplatesRoutes = [
  {
    title: 'Vouchers - Templates',
    path: '/vouchers/templates',
    exact: true,
    Component: <VoucherTemplates />,
  },
];

export const htmlContentsOverviewRoutes = [
  {
    title: 'Contents - Overview',
    path: '/contents',
    exact: true,
    Component: <ContentsOverview />,
  },
  {
    title: 'Contents - Create',
    path: '/contents/create',
    exact: true,
    Component: <ContentsCreate />,
  },
  {
    title: 'Contents - Edit',
    path: '/contents/edit/:id',
    exact: true,
    Component: <ContentsDetail />,
  },
];

export const htmlContentsLocationsRoutes = [
  {
    title: 'Contents - Locations',
    path: '/contents/locations',
    exact: true,
    Component: <ContentsLocations />,
  },
];

export const configRoutes = [
  {
    title: 'Config',
    path: '/config',
    exact: true,
    Component: <ConfigOverview />,
  },
  {
    title: 'Configuration',
    path: '/configuration',
    exact: true,
    Component: <Configuration />,
  },
  {
    title: 'Subscription Scopes',
    path: '/subscriptionScopes',
    exact: true,
    Component: <SubscriptionScopesPage />,
  },
  {
    title: 'Subscription Scopes - Edit',
    path: '/subscriptionScopes/edit/:id/:brand',
    exact: true,
    Component: (
      <SubscriptionScopesProvider>
        <SubscriptionScopesDetail />
      </SubscriptionScopesProvider>
    ),
  },
  {
    title: 'Subscription Scopes - Create',
    path: '/subscriptionScopes/create',
    exact: true,
    Component: (
      <SubscriptionScopesProvider>
        <SubscriptionScopesDetail />
      </SubscriptionScopesProvider>
    ),
  },
  {
    title: 'Features',
    path: '/features',
    exact: true,
    Component: <FeaturesPage />,
  },
  {
    title: 'Features - Create ',
    path: '/features/create',
    exact: false,
    history: true,
    hasWizard: true,
    Component: AddFeature,
  },
  {
    title: 'Features - Edit',
    path: '/features/edit/:id',
    exact: true,
    Component: <EditFeature />,
  },
];

export const errorRoutes = [
  {
    title: 'Not Found',
    path: '',
    exact: false,
    Component: <NotFound />,
  },
];
