import React from 'react';
import { Box } from '@mediahuis/chameleon-react';
import Tree from './Tree';

const getTreeTitle = value => {
  if (value !== undefined || value !== null) {
    return value.toString();
  }

  return 'null;';
};

const getTreeChildren = ({ parentKey, childValue }) => {
  if (Array.isArray(childValue)) {
    if (childValue.some(v => Array.isArray(v))) {
      return childValue.map((v, i) => ({
        title: v[0],
        key: `${parentKey}-${i}`,
        children: getTreeChildren({
          parentKey: `${parentKey}-${i}`,
          childValue: v[1],
        }),
      }));
    }

    return childValue.map((v, i) => ({
      title: getTreeTitle(v),
      key: `${parentKey}-${i}`,
    }));
  }

  if (typeof childValue === 'object') {
    return getTreeChildren({
      parentKey,
      childValue: Object.entries(childValue),
    });
  }

  return [{ key: `${parentKey}-0`, title: getTreeTitle(childValue) }];
};

const ROOT_KEY = '0';

const Parameters = ({ data = {}, ...props }) => {
  const hasKeys = Object.keys(data).length > 0;

  if (!hasKeys) return null;

  const treeData = [
    {
      key: ROOT_KEY,
      title: 'Parameters',
      children: getTreeChildren({
        childValue: data,
        parentKey: ROOT_KEY,
      }),
    },
  ];

  return (
    <Box {...props}>
      <Tree treeData={treeData} />
    </Box>
  );
};

export default Parameters;
