import React from 'react';

import { useSubscriptionTypes } from '~/services/offer';

const SubscriptionTypesQuery = ({ filters, render }) => {
  const subscriptionTypesQuery = useSubscriptionTypes(filters);

  if (subscriptionTypesQuery.isError) {
    return <p>Error while loading subscriptionTypes</p>;
  }

  if (subscriptionTypesQuery.isSuccess) {
    return render(
      subscriptionTypesQuery.data,
      subscriptionTypesQuery.isFetching,
    );
  }

  return <p>Loading subscriptionTypes..</p>;
};

export default SubscriptionTypesQuery;
