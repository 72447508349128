import {
  Box,
  Flex,
  Heading,
  Paper,
  Text,
  TextField,
} from '@mediahuis/chameleon-react';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Features,
  FormButtons,
  FormConfig,
  ImageField,
  Sidebar,
  StickyBottom,
  StickyTop,
  TextFieldWithMarkDown,
  notifyError,
  notifySuccess,
  notifyWarning,
  useAboshopContext,
} from '~/components';
import { TypeRequirements } from '~/components/Requirements';
import {
  getInProgressMessage,
  getPastedValues,
  removeDeprecatedRequirementKeys,
  secureApiCall,
  updateSequenceIndex,
  uploadImage,
} from '~/utils';
import { CLIPBOARD } from '~/utils/getClipBoard';

import LanguageSelect from '../../../components/LanguageSelect';
import validateType from '../validation/validateType';

const TypeForm = ({ apiUrl, editing = false, initialValues, deleteMethod }) => {
  const [type, setType] = useState(initialValues);
  const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [inProgress, setInProgress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { clipBoard, subscriptionService, setGlobalState } =
    useAboshopContext();

  const history = useHistory();

  // In case user tries to go second step in creating offer wizard
  // without having chosen a brand.
  useEffect(() => {
    if (!type?.brand) {
      history.push('/subscriptiontype');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Overwrite state if parent gives new data.
  // E.g. when fetching data in new language
  useEffect(() => {
    setFiles({});
    setType(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const copyMethod = () => {
    localStorage.setItem(
      CLIPBOARD,
      JSON.stringify({
        id: type.id,
        type: 'type',
      }),
    );
    setGlobalState(prev => ({
      ...prev,
      clipBoard: {
        ...type,
        type: 'type',
      },
    }));
    history.push('/subscriptiontype');
  };

  const pasteMethod = () => {
    const pastedValues = getPastedValues(clipBoard, initialValues);
    setType(pastedValues);
  };

  const duplicateMethod = () => {
    history.push('/subscriptiontype/create', {
      initialValues: type,
    });
  };

  const handleUpload = (name, file) => {
    setFiles({
      ...files,
      [name]: file,
    });
    setType({
      ...type,
      [name]: undefined,
    });
  };

  const handleSelectFeature = selectedFeatures => {
    const featuresWithIndex = updateSequenceIndex(selectedFeatures);
    setType({
      ...type,
      features: featuresWithIndex,
    });
  };

  const handleDeleteFeature = deletingFeature => {
    const filteredFeatures = type.features.filter(
      feature =>
        feature.id !== deletingFeature.id &&
        feature.name !== deletingFeature.name,
    );
    const filteredFeaturesWithIndex = updateSequenceIndex(filteredFeatures);
    setType({
      ...type,
      features: filteredFeaturesWithIndex,
    });
  };

  const clearError = event => {
    const spreadedErrors = {
      ...errors,
    };
    delete spreadedErrors[event.target.name];
    setErrors(spreadedErrors);
  };

  const onBlur = event => {
    event.persist();
    setType(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = () => {
    if (!isEmpty(inProgress)) {
      return notifyWarning({
        message: getInProgressMessage(inProgress),
      });
    }

    setInProgress([]);
    setIsLoading(true);

    if (
      isEmpty(
        validateType({
          type,
          setErrors,
        }),
      )
    ) {
      Promise.all([
        uploadImage(files.logo, initialValues.brand, type.logo),
        uploadImage(files.image, initialValues.brand, type.image),
      ])
        .then(values => {
          secureApiCall(apiUrl, {
            method: editing ? 'PUT' : 'POST',
            data: JSON.stringify({
              brand: initialValues.brand,
              ...type,
              title: type.title,
              logo: values[0].id,
              image: values[1].id,
            }),
            params: {
              'api-version': '2',
            },
            onSuccess: response => {
              const responseData = removeDeprecatedRequirementKeys(
                response.data,
              );

              setIsLoading(false);
              /**
               * Save response data to local state because ID's of these objects needs to be saved in case of new items
               */
              setType(responseData);

              if (!editing) {
                history.push(`/subscriptiontype/edit/${responseData.id}`);
              }
              notifySuccess({
                message: `Successfully ${
                  editing ? 'edited' : 'created '
                } a type.`,
              });
            },
            onError: errorMessage => {
              setIsLoading(false);
              notifyError({
                status:
                  errorMessage.response.request.status &&
                  errorMessage.response.request.status,
                message: errorMessage.response.data.errors
                  ? errorMessage.response.data.errors[0]
                  : errorMessage.message,
              });
            },
          });
          return values;
        })
        .catch(errorMessage =>
          notifyError({
            status:
              errorMessage.response.request.status &&
              errorMessage.response.request.status,
            message: errorMessage.response.data.errors
              ? errorMessage.response.data.errors[0]
              : errorMessage.message,
          }),
        );
    } else {
      setIsLoading(false);
    }
    return null;
  };

  if (!type?.brand) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div>
        <Box mb={5}>
          <Paper>
            <Box p={7} mb={2} width="600px">
              <Heading fontFamily="primary" level={4}>
                Type
              </Heading>
              <Flex mb={4}>
                <TextField
                  data-testid="reference-input"
                  defaultValue={type.reference}
                  error={!!errors.reference}
                  id="reference"
                  key={type.reference}
                  label="Reference (internal)"
                  mb={5}
                  message={errors.reference}
                  mr={4}
                  name="reference"
                  width="full"
                  onBlur={onBlur}
                  onFocus={clearError}
                />
                <TextField
                  data-testid="externalReference-input"
                  defaultValue={type.externalReference}
                  disabled={
                    !(
                      subscriptionService ||
                      MH_BRAND === BRANDS.LUX ||
                      MH_BRAND === BRANDS.NL
                    )
                  }
                  id="externalReference"
                  key={type.externalReference}
                  label="Reference (external)"
                  name="externalReference"
                  width="full"
                  onBlur={onBlur}
                />
              </Flex>
              <Box mb={4}>
                <TextFieldWithMarkDown
                  id="title"
                  name="title"
                  label="Title"
                  datatestid="title-input"
                  error={!!errors.title}
                  message={errors.title}
                  onBlur={onBlur}
                  onFocus={clearError}
                  defaultValue={type.title}
                  key={type.title}
                />
              </Box>
              <Box mb={4}>
                <TextFieldWithMarkDown
                  id="description"
                  name="description"
                  label="Description"
                  datatestid="description-input"
                  error={!!errors.description}
                  message={errors.description}
                  onBlur={onBlur}
                  onFocus={clearError}
                  defaultValue={type.description}
                  key={type.description}
                />
              </Box>
              <Box mb={4}>
                <TextFieldWithMarkDown
                  id="extra"
                  name="extra"
                  label="Extra"
                  datatestid="extra-input"
                  onBlur={onBlur}
                  defaultValue={type.extra}
                  key={type.extra}
                />
              </Box>
              <Box mb={4}>
                <TextFieldWithMarkDown
                  id="priceExtra"
                  name="priceExtra"
                  label="Price Extra"
                  datatestid="priceExtra-input"
                  onBlur={onBlur}
                  defaultValue={type.priceExtra}
                  key={type.priceExtra}
                />
              </Box>
              <TextFieldWithMarkDown
                id="priceSentence"
                name="priceSentence"
                label="Price Description"
                datatestid="priceSentence-input"
                onBlur={onBlur}
                defaultValue={type.priceSentence}
                key={type.priceSentence}
              />
            </Box>
          </Paper>
        </Box>
        <Box mb={5}>
          <Paper>
            <Box p={7} mb={2} width="600px">
              <Features
                brand={type.brand}
                features={type.features}
                onDelete={handleDeleteFeature}
                onSelect={handleSelectFeature}
              />
            </Box>
          </Paper>
        </Box>
        <Box mb={5}>
          <Paper>
            <Box p={7} width="600px">
              <TypeRequirements
                subscriptionType={type}
                onChange={requirements => {
                  setType(prevType => ({ ...prevType, ...requirements }));
                }}
              />
            </Box>
          </Paper>
        </Box>
        <Box mb={5}>
          <Paper>
            <Box p={7} mb={2} width="600px">
              <Heading fontFamily="primary" level={4}>
                Images
              </Heading>
              <Flex justifyContent="space-between" mt={5}>
                <Box width="48%">
                  <ImageField
                    defaultImage={type.logo}
                    file={files.logo}
                    label="Logo"
                    onChange={file => handleUpload('logo', file)}
                  />
                </Box>
                <Box width="48%">
                  <ImageField
                    defaultImage={type.image}
                    file={files.image}
                    label="Featured image"
                    onChange={file => handleUpload('image', file)}
                  />
                </Box>
              </Flex>
            </Box>
          </Paper>
        </Box>
      </div>
      <Sidebar>
        <StickyTop>
          <LanguageSelect
            options={POSSIBLE_LANGUAGES[type.brand]}
            isEditing={editing}
            brand={type?.brand}
          />
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Id</strong>: {initialValues.id}
            </Text>
            <Text size="Caption2" as="p" mb={2}>
              <strong>Brand</strong>: {type.brand}
            </Text>
          </Paper>
          <FormButtons
            onSave={onSubmit}
            editing={editing}
            isLoading={isLoading}
            copyMethod={copyMethod}
            pasteMethod={pasteMethod}
            deleteMethod={deleteMethod}
            duplicateMethod={duplicateMethod}
            isDeletable={deleteMethod && !subscriptionService}
            isPastable={clipBoard && clipBoard.type === 'type'}
            isDuplicable={duplicateMethod && !subscriptionService}
          />
        </StickyTop>
        <StickyBottom>
          <FormConfig />
        </StickyBottom>
      </Sidebar>
    </div>
  );
};

export default TypeForm;
