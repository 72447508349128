import React from 'react';
import { Collapse } from 'antd';
import { Flex, Icon, Text } from '@mediahuis/chameleon-react';
import { Tag } from '@mediahuis/chameleon-theme-wl/icons';
import OfferGroupsCollapsePanel from './OfferGroupsCollapsePanel';

const OfferGroups = ({ isEditable, offer, onChange, onDelete }) => {
  function handleOfferGroupChange(group, id) {
    onChange(group, id);
  }

  function handleOfferGroupDelete(group) {
    onDelete(group);
  }

  return offer.groups ? (
    <Collapse>
      {offer.groups.map(group => {
        return (
          <Collapse.Panel
            header={
              <Flex alignItems="center" style={{ gap: '0.5rem' }}>
                <Icon as={Tag} />
                <Text>{group.title}</Text>
              </Flex>
            }
            key={`${group.sequenceIndex}-${group.title}`}
          >
            <OfferGroupsCollapsePanel
              isEditable={isEditable}
              offer={offer}
              group={group}
              onDelete={() => handleOfferGroupDelete(group)}
              onSubmit={handleOfferGroupChange}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  ) : null;
};

export default OfferGroups;
