import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import './TableTransferDragRow.css';

const componentType = 'TableTransferDragRow';

const TableTransferDragRow = ({
  className,
  index,
  style,
  onDrop,
  ...rowProps
}) => {
  const ref = useRef(null);

  const [{ dropClassName, isOver }, drop] = useDrop({
    accept: componentType,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => onDrop(item.index, index),
  });
  const [, drag] = useDrag({
    item: {
      index,
      type: componentType,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      className={`${className} ${isOver ? dropClassName : ''}`}
      ref={ref}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...rowProps}
    />
  );
};

export default TableTransferDragRow;
