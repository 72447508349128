import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { SubscriptionScopeService } from '~/services/subscriptionScope';

export const SUBSCRIPTION_SCOPES_KEY = 'subscriptionScopes';

const useSubscriptionScopes = filters => {
  const queryClient = useQueryClient();

  const [placeholderData, setPlaceholderData] = useState();

  return useQuery(
    [SUBSCRIPTION_SCOPES_KEY, filters],
    () =>
      SubscriptionScopeService.getSubscriptionScopes(filters).then(response => {
        setPlaceholderData(response.data);

        return response.data;
      }),
    {
      placeholderData,
      onSuccess: data => {
        if (data.loadMore && filters.offset) {
          const prefetchFilters = {
            ...filters,
            offset: parseInt(filters.offset, 10) + data.results.length,
          };

          queryClient.prefetchQuery(
            [SUBSCRIPTION_SCOPES_KEY, prefetchFilters],
            () =>
              SubscriptionScopeService.getSubscriptionScopes(
                prefetchFilters,
              ).then(response => response.data),
          );
        }
      },
    },
  );
};

export default useSubscriptionScopes;
