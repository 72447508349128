import React from 'react';

import { useOrders } from '~/services/order';

const OrdersQuery = ({ filters, render }) => {
  const ordersQuery = useOrders(filters);

  if (ordersQuery.isError) {
    return <p>Error while loading orders</p>;
  }

  if (ordersQuery.isSuccess) {
    return render(ordersQuery.data, ordersQuery.isFetching);
  }

  return <p>Loading orders..</p>;
};

export default OrdersQuery;
