const deprecatedRequirementKeys = [
  'addressAreaRequirement',
  'booleanRequirement',
  'dateRequirement',
  'emailRequirement',
  'fileRequirement',
];

const removeDeprecatedRequirementKeys = objectWithRequirements => {
  return Object.keys(objectWithRequirements).reduce((acc, key) => {
    if (!deprecatedRequirementKeys.includes(key)) {
      acc[key] = objectWithRequirements[key];
    }

    return acc;
  }, {});
};

export default removeDeprecatedRequirementKeys;
