import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { OfferService } from '~/services/offer';

export const FEATURES_KEY = 'features';

const useFeatures = filters => {
  const queryClient = useQueryClient();

  const [placeholderData, setPlaceholderData] = useState();

  return useQuery(
    [FEATURES_KEY, filters],
    () =>
      OfferService.getFeatures(filters).then(response => {
        setPlaceholderData(response.data);

        return response.data;
      }),
    {
      placeholderData,
      onSuccess: data => {
        if (data.loadMore && filters.offset) {
          const prefetchFilters = {
            ...filters,
            offset: parseInt(filters.offset, 10) + data.results.length,
          };

          queryClient.prefetchQuery([FEATURES_KEY, prefetchFilters], () =>
            OfferService.getFeatures(prefetchFilters).then(
              response => response.data,
            ),
          );
        }
      },
    },
  );
};

export default useFeatures;
