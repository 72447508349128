const validatePrecondition = ({ name, errorMessage }) => {
  const errors = {};
  if (!name) {
    errors.name = 'Name is required';
  }
  if (!errorMessage) {
    errors.errorMessage = 'Error Message is required';
  }
  return errors;
};

export default validatePrecondition;
