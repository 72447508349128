import React from 'react';
import isEmpty from 'lodash.isempty';
import { useHistory, useParams } from 'react-router-dom';
import { Heading, Paper, Flex, Caption } from '@mediahuis/chameleon-react';
import { secureApiCall } from '~/utils';
import {
  PageLoader,
  FormButtons,
  Sidebar,
  StickyTop,
  notifySuccess,
  notifyError,
} from '~/components';
import PreconditionForm from './components/PreconditionForm';
import { usePreconditionContext } from '../../components/context/precondition';
import validatePrecondition from './validation/index';

const PreconditionDetail = () => {
  const { precondition, setPrecondition, setErrors } = usePreconditionContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(false);

  const { id } = useParams();
  const history = useHistory();
  const url = `${API_PRECONDITION_RULES}/${id ?? ''}`;

  React.useEffect(() => {
    const fetchData = async () => {
      await secureApiCall(url, {
        method: 'GET',
        onSuccess: response => setPrecondition(response?.data),
        onError: error => {
          notifyError({
            status: error?.response?.request?.status,
            message: error?.response?.data?.errors
              ? error.response.data.errors?.[0]
              : error?.message,
          });
          history.push('/preconditions');
        },
      });
      setIsLoading(false);
    };
    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async () => {
    const preconditionErrors = validatePrecondition(precondition);
    if (!isEmpty(preconditionErrors)) {
      return setErrors(preconditionErrors);
    }
    setIsFetching(true);
    const newCriteria = [...precondition.criteria];
    newCriteria.forEach(criteria => {
      // if length of id is equal to 13 reset to 0 so API will generate unique id
      if (criteria.id.toString().length === 13) {
        criteria.id = 0;
      }
      return criteria;
    });

    const updatedPrecondition = { ...precondition, criteria: newCriteria };

    await secureApiCall(url, {
      method: id ? 'PUT' : 'POST',
      data: JSON.stringify({
        ...updatedPrecondition,
        // eslint-disable-next-line radix
        fallbackOfferId: parseInt(precondition.fallbackOfferId),
      }),
      onSuccess: response => {
        if (!id) {
          history.push(`/preconditions/edit/${response.data.id}`);
        }
        notifySuccess({
          message: `Successfully ${
            id ? 'updated' : 'created'
          } precondition rule`,
        });
      },
      onError: error => {
        notifyError({
          status:
            error.response.request.status && error.response.request.status,
          message: error.response.data.errors
            ? error.response.data.errors[0]
            : error.message,
        });
      },
    });
    return setIsFetching(false);
  };

  const onDelete = async () => {
    setIsFetching(true);
    await secureApiCall(url, {
      method: 'DELETE',
      onSuccess: () => {
        history.push('/preconditions');
        notifySuccess({
          message: `Successfully deleted precondition rule with id: ${id}`,
        });
      },
      onError: error => error,
    });
    setIsFetching(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Flex alignItems="flex-start" justifyContent="center">
      <Paper p={6} mb={8} mr={1} width="600px">
        <Heading fontFamily="primary" level={4} mb={5}>
          {id ? 'Edit ' : 'Create'} Precondition Rule
        </Heading>
        <PreconditionForm />
      </Paper>
      <Sidebar>
        <StickyTop>
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Caption level={2} mb={2}>
              <strong>Brand</strong>: {precondition.brand}
            </Caption>
          </Paper>
          <FormButtons
            isLoading={isFetching}
            onSave={onSubmit}
            isDeletable={true}
            editing={id}
            deleteMethod={onDelete}
          />
        </StickyTop>
      </Sidebar>
    </Flex>
  );
};

export default PreconditionDetail;
