import { useQuery } from 'react-query';

import { OrderSettleService } from '~/services/order-settle';

export const ORDER_SETTLEMENT_KEY = 'order-settlement';

export const useOrderSettlement = orderId => {
  return useQuery(
    [ORDER_SETTLEMENT_KEY, orderId],
    () =>
      OrderSettleService.getOrderSettlementByOrderId(orderId).then(
        response => response.data,
      ),
    {
      enabled: Boolean(orderId),
    },
  );
};
