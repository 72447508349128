import { Flex } from '@mediahuis/chameleon-react';
import React, { useRef, useState } from 'react';

import { RequirementForm } from '../RequirementForm';

import RequirementsCollapseControls from './RequirementsCollapseControls';

const RequirementsCollapsePanel = ({
  isEditable,
  requirement,
  requirementType,
  onDelete,
  onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const formRef = useRef(null);

  return (
    <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
      <RequirementForm
        disabled={!isEditing}
        formRef={formRef}
        requirement={requirement}
        requirementType={requirementType}
        onSubmit={(req, reqType) => {
          setIsEditing(false);
          onSubmit(req, reqType);
        }}
      />

      {isEditable && (
        <RequirementsCollapseControls
          isEditing={isEditing}
          onDelete={onDelete}
          onEdit={() => setIsEditing(true)}
          onSave={() => formRef.current.submitForm()}
        />
      )}
    </Flex>
  );
};

export default RequirementsCollapsePanel;
