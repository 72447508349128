import React from 'react';
import styled from 'styled-components';
import { Text, Box } from '@mediahuis/chameleon-react';
import { Link } from '../../components';

const FlexWrapper = styled.div`
  flex-direction: column;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkWithUnderline = styled(Link)`
  text-decoration: underline;
`;

const NotFound = () => {
  return (
    <FlexWrapper>
      <Box mb={10} mt={5}>
        <Text size="Heading1" align="center">
          WOOPS - PAGE NOT FOUND
        </Text>
      </Box>
      <Box mb={5}>
        <Text size="Heading4" align="center">
          <LinkWithUnderline to="/" data-testid="go-back-home-404">
            GO HOME
          </LinkWithUnderline>
        </Text>
      </Box>
    </FlexWrapper>
  );
};

export default NotFound;
