import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { OfferService } from '~/services/offer';

export const SUBSCRIPTION_TYPES_KEY = 'subscriptionTypes';

const useSubscriptionTypes = filters => {
  const queryClient = useQueryClient();

  const [placeholderData, setPlaceholderData] = useState();

  return useQuery(
    [SUBSCRIPTION_TYPES_KEY, filters],
    () =>
      OfferService.getSubscriptionTypes(filters).then(response => {
        setPlaceholderData(response.data);

        return response.data;
      }),
    {
      placeholderData,
      onSuccess: data => {
        if (data.loadMore && filters.offset) {
          const prefetchFilters = {
            ...filters,
            offset: parseInt(filters.offset, 10) + data.results.length,
          };

          queryClient.prefetchQuery(
            [SUBSCRIPTION_TYPES_KEY, prefetchFilters],
            () =>
              OfferService.getSubscriptionTypes(prefetchFilters).then(
                response => response.data,
              ),
          );
        }
      },
    },
  );
};

export default useSubscriptionTypes;
