import { Box, Button, Checkbox, Flex } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutBooleanValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Booleans = ({ validation }) => {
  const [state, setState] = useState(validation.state);
  const [value, setValue] = useState(validation?.data?.value);

  const booleanValidationMutation = usePutBooleanValidation();

  useEffect(() => {
    if (booleanValidationMutation.isError) {
      notifyError({
        status:
          booleanValidationMutation.error.response?.request?.status &&
          booleanValidationMutation.error.response?.request?.status,
        message: booleanValidationMutation.error.response?.data?.errors
          ? booleanValidationMutation.error.response?.data?.errors[0]
          : booleanValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booleanValidationMutation.isError]);

  useEffect(() => {
    if (booleanValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the boolean',
      });
    }
  }, [booleanValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Checkbox
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>

        <Box>
          <Checkbox
            checked={value}
            data-testid={`input-checkbox-description-${validation.id}`}
            id={`input-checkbox-description-${validation.id}`}
            label={validation.description}
            onChange={e => setValue(e.target.checked)}
          />
        </Box>

        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            onClick={() =>
              booleanValidationMutation.mutate({
                ...validation,
                data: { value },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Booleans;
