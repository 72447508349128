import { Collapse } from 'antd';
import React from 'react';

import { getRequirementIcon } from '../utils';

import RequirementsCollapseHeader from './RequirementsCollapseHeader';
import RequirementsCollapsePanel from './RequirementsCollapsePanel';

const RequirementsCollapse = ({ isEditable, requirements, onChange }) => {
  function handleRequirementChange(requirement, requirementType) {
    const currentRequirement = requirements[requirementType];

    if (currentRequirement) {
      if (Array.isArray(currentRequirement)) {
        requirements[requirementType] = currentRequirement.map(r => {
          if (r.id === requirement.id) {
            return requirement;
          }

          return r;
        });
      } else {
        requirements[requirementType] = requirement;
      }
    }

    onChange(requirements);
  }

  function handleRequirementDelete(requirement, requirementType) {
    const currentRequirement = requirements[requirementType];

    if (currentRequirement) {
      if (Array.isArray(currentRequirement)) {
        requirements[requirementType] = currentRequirement.filter(
          r => r.id !== requirement.id,
        );
      } else {
        requirements[requirementType] = undefined;
      }
    }

    onChange(requirements);
  }

  return (
    <Collapse>
      {Object.entries(requirements)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, requirementValue]) => Boolean(requirementValue))
        .map(([requirementKey, requirementValue]) => {
          if (Array.isArray(requirementValue)) {
            return requirementValue.map(requirement => (
              <Collapse.Panel
                header={
                  <RequirementsCollapseHeader
                    icon={getRequirementIcon(requirementKey)}
                    text={requirement.name}
                  />
                }
                key={`${requirementKey}-${requirement.id}`}
              >
                <RequirementsCollapsePanel
                  isEditable={isEditable}
                  requirement={requirement}
                  requirementType={requirementKey}
                  onDelete={() =>
                    handleRequirementDelete(requirement, requirementKey)
                  }
                  onSubmit={handleRequirementChange}
                />
              </Collapse.Panel>
            ));
          }

          return (
            <Collapse.Panel
              header={
                <RequirementsCollapseHeader
                  icon={getRequirementIcon(requirementKey)}
                  text={requirementValue.name}
                />
              }
              key={`${requirementKey}-${requirementValue.id}`}
            >
              <RequirementsCollapsePanel
                isEditable={isEditable}
                requirement={requirementValue}
                requirementType={requirementKey}
                onDelete={() =>
                  handleRequirementDelete(requirementValue, requirementKey)
                }
                onSubmit={handleRequirementChange}
              />
            </Collapse.Panel>
          );
        })}
    </Collapse>
  );
};

export default RequirementsCollapse;
