import React, { useState } from 'react';
import { TextField, Flex, Button, Box } from '@mediahuis/chameleon-react';
import { AddressFields } from '../../../../components';

const AddressArea = ({ validation, handleSubmit }) => {
  const [addressArea, setAddressArea] = useState({
    addressKey: validation.data ? validation.data.addressKey : '',
    addressee: validation.data ? validation.data.addressee : '',
    busNumber: validation.data ? validation.data.busNumber : '',
    city: validation.data ? validation.data.city : '',
    countryCode: validation.data ? validation.data.countryCode : '',
    houseNumber: validation.data ? validation.data.houseNumber : '',
    postalCode: validation.data ? validation.data.postalCode : '',
    street: validation.data ? validation.data.street : '',
  });

  return (
    <Box width="full">
      <Box mb={5} mt={5}>
        <TextField
          label="Addressee"
          id="AddressAreaAddressee"
          name="AddressAreaAddressee"
          data-testid="AddressAreaAddressee"
          defaultValue={addressArea.addressee}
          onChange={event =>
            setAddressArea({
              ...addressArea,
              addressee: event.target.value,
            })
          }
        />
      </Box>

      <Box mb={5} mt={5}>
        <TextField
          label="Address Key"
          id="AddressAreaaddressKey"
          name="AddressAreaaddressKey"
          data-testid="AddressAreaaddressKey"
          defaultValue={addressArea.addressKey}
          onChange={event =>
            setAddressArea({
              ...addressArea,
              addressKey: event.target.value,
            })
          }
        />
      </Box>

      <AddressFields
        type="AddressArea"
        address={addressArea}
        setAddress={setAddressArea}
        label="Address"
      />

      <Flex mt={4} justifyContent="flex-end" width="full">
        <Button
          type="primary"
          size="small"
          onClick={() => handleSubmit(addressArea)}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default AddressArea;
