import { useQuery } from 'react-query';

import { ContentService } from '~/services/content';

export const CONTENT_KEY = 'content';

const useContent = id => {
  return useQuery(
    [CONTENT_KEY, id],
    () => ContentService.getContent(id).then(response => response.data),
    {
      enabled: !!id,
    },
  );
};

export default useContent;
