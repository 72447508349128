import { Flex } from '@mediahuis/chameleon-react';
import { Table } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { StatusCircle } from '~/components';
import { states } from '~/constants';
import { formatBrand, formatDateTime } from '~/utils/columns';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (text, record) => (
      <Link
        to={location => `${location.pathname}/edit/${record.id}`}
        onClick={event => event.stopPropagation()}
      >
        {text}
      </Link>
    ),
  },
  { dataIndex: 'brand', title: 'Brand', render: formatBrand },
  { dataIndex: 'code', title: 'Code' },
  {
    dataIndex: 'state',
    render: state => (
      <Flex alignItems="center" style={{ gap: '0.5rem' }}>
        <StatusCircle variant={states[state]} />
        {state}
      </Flex>
    ),
    title: 'State',
  },
  {
    dataIndex: 'generatedDate',
    title: 'Date Generated',
    render: text => (text ? formatDateTime(text) : '-'),
  },
  {
    dataIndex: 'usedDate',
    title: 'Date Used',
    render: text => (text ? formatDateTime(text) : '-'),
  },
  {
    dataIndex: 'purchaseFormulaId',
    title: 'Purchase Formula ID',
    render: text => (text ? text : '-'),
  },
  {
    dataIndex: 'redeemFormulaId',
    title: 'Redeem Formula ID',
    render: text => (text ? text : '-'),
  },
];

const VouchersTable = ({ isLoading, vouchers }) => {
  const history = useHistory();

  const tableData = vouchers.results;

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(`${history.location.pathname}/edit/${record.id}`),
      })}
    />
  );
};

export default VouchersTable;
