import { Table } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { formatBrand } from '~/utils/columns';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    render: (text, record) => (
      <Link
        to={location => `${location.pathname}/edit/${record.id}`}
        onClick={event => event.stopPropagation()}
      >
        {text}
      </Link>
    ),
  },
  {
    dataIndex: 'name',
    title: 'Name',
    render: text => (text ? text : '-'),
  },
  { dataIndex: 'brand', title: 'Brand', render: formatBrand },
  {
    dataIndex: 'criteria',
    title: 'Criteria',
    render: criteria =>
      criteria.map(criterium => (
        <div key={criterium.id}>
          {criterium.propertyName} {criterium.operation}{' '}
          {criterium.parameter?.join(',')}
        </div>
      )),
  },
  {
    dataIndex: 'errorMessage',
    title: 'Error Message',
    render: text => (text ? text : '-'),
  },
];

const PreconditionsTable = ({ isLoading, preconditions }) => {
  const history = useHistory();

  const tableData = preconditions.results;

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      rowClassName={() => 'pointer'}
      rowKey={record => record.id}
      onRow={record => ({
        onClick: () =>
          history.push(`${history.location.pathname}/edit/${record.id}`),
      })}
    />
  );
};

export default PreconditionsTable;
