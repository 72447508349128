/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  List,
  ListItem,
  Select,
  Text,
  Textarea,
  TextField,
} from '@mediahuis/chameleon-react';
import { Add, Close, Delete, Save } from '@mediahuis/chameleon-theme-wl/icons';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { object, string } from 'yup';

import useAboshopContext from '~/components/context/useContext';
import { notifyError, notifySuccess } from '~/components/Toasts';
import {
  useContentLinks,
  useCreateContent,
  useCreateContentLink,
  useDeleteContent,
  useDeleteContentLink,
  usePatchContent,
  usePatchContentLink,
} from '~/services/content';
import { mergeArrays } from '~/utils';

const VoucherTemplatesFormSchema = object().shape({
  content: string().required('This is a required field'),
  name: string().required('This is a required field'),
});

const VoucherTemplates = () => {
  const { brands } = useAboshopContext();

  const [isCreating, setIsCreating] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const formRef = useRef(null);

  const contentLinksQuery = useContentLinks(
    selectedBrand,
    'voucherTemplate',
    !!selectedBrand,
  );

  const htmlContentCreate = useCreateContent();
  const createContentLink = useCreateContentLink();
  const htmlContentDelete = useDeleteContent();
  const deleteContentLink = useDeleteContentLink();
  const htmlContentUpdate = usePatchContent();
  const htmlContentLinkUpdate = usePatchContentLink();

  useEffect(() => {
    if (selectedBrand) {
      setSelectedTemplate(undefined);
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (htmlContentDelete.isError) {
      notifyError({
        status: htmlContentDelete.error?.response?.status,
        message: htmlContentDelete.error?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentDelete.isError]);

  useEffect(() => {
    if (htmlContentCreate.isError) {
      notifyError({
        status: htmlContentCreate.error?.response?.status,
        message: htmlContentCreate.error?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentCreate.isError]);

  useEffect(() => {
    if (htmlContentUpdate.isError) {
      notifyError({
        status: htmlContentUpdate.error?.response?.status,
        message: htmlContentUpdate.error?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentUpdate.isError]);

  useEffect(() => {
    if (htmlContentDelete.isSuccess) {
      notifySuccess({
        message: 'Successfully deleted the template',
      });
      setSelectedBrand(undefined);
      setSelectedTemplate(undefined);
    }
  }, [htmlContentDelete.isSuccess]);

  useEffect(() => {
    if (htmlContentCreate.isSuccess) {
      notifySuccess({
        message: `Successfully created the template ${htmlContentCreate.data.name}`,
      });
      setIsCreating(false);
      setSelectedBrand(undefined);
      setSelectedTemplate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContentCreate.isSuccess]);

  useEffect(() => {
    if (htmlContentUpdate.isSuccess) {
      notifySuccess({
        message: 'Successfully updated the template',
      });
      setSelectedBrand(undefined);
      setSelectedTemplate(undefined);
    }
  }, [htmlContentUpdate.isSuccess]);

  function handleFormSubmit(formValues) {
    if (isCreating && selectedBrand) {
      htmlContentCreate.mutate(
        {
          brand: selectedBrand,
          name: formValues.name,
          value: formValues.content,
        },
        {
          onSuccess: data =>
            createContentLink.mutate({
              brand: selectedBrand,
              contentId: data.id,
              key: 'voucherTemplate',
              location: formValues.isBulk ? 'bulk' : 'page',
              position: 0,
            }),
        },
      );
    }

    if (selectedBrand && selectedTemplate) {
      htmlContentUpdate.mutate(
        {
          brand: selectedBrand,
          value: formValues.content,
          id: selectedTemplate.contentId,
          name: formValues.name,
        },
        {
          onSuccess: data =>
            htmlContentLinkUpdate.mutate({
              location: formValues.isBulk ? 'bulk' : 'page',
              id: selectedTemplate.id,
            }),
        },
      );
    }
  }

  const handleDelete = () => {
    deleteContentLink.mutate({ id: selectedTemplate.id });
    htmlContentDelete.mutate({ id: selectedTemplate.contentId });
    setSelectedTemplate(undefined);
  };

  return (
    <Flex justifyContent="center">
      <Flex flexDirection="column" pb={10} style={{ gap: '3rem' }} width="80%">
        <Flex
          alignItems="flex-end"
          justifyContent="space-between"
          style={{ gap: '1rem' }}
          width="full"
        >
          <Box flexGrow={1} style={{ minWidth: '250px' }}>
            <Select
              data-testid="voucher-template-choose-brand"
              label="Choose brand"
              placeholder="Brand"
              value={selectedBrand ? selectedBrand : ''}
              onChange={e => setSelectedBrand(e.target.value)}
            >
              {brands.map(brand => (
                <option key={brand.code} value={brand.code}>
                  {brand.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box flexGrow={4}>
            <Select
              data-testid="voucher-template-choose-template"
              disabled={isCreating || !selectedBrand}
              label="Choose template"
              placeholder="Template"
              value={selectedTemplate ? selectedTemplate.contentId : ''}
              onChange={e =>
                setSelectedTemplate(
                  mergeArrays(
                    contentLinksQuery.data.contentLinks?.page,
                    contentLinksQuery.data.contentLinks?.bulk,
                  )?.find(
                    result => result.contentId.toString() === e.target.value,
                  ),
                )
              }
            >
              {contentLinksQuery.isSuccess &&
                mergeArrays(
                  contentLinksQuery.data.contentLinks?.page,
                  contentLinksQuery.data.contentLinks?.bulk,
                )?.map(result => (
                  <option key={result.contentId} value={result.contentId}>
                    Id: {result.contentId} - {result.content?.name}
                  </option>
                ))}
            </Select>
          </Box>
          <Button
            data-testid="voucher-template-delete"
            disabled={!selectedTemplate}
            iconLeft={Delete}
            style={{ minWidth: '175px' }}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            data-testid="voucher-template-create"
            disabled={isCreating || !selectedBrand}
            iconLeft={Add}
            style={{ minWidth: '175px' }}
            onClick={() => {
              setIsCreating(true);
              setSelectedTemplate(undefined);
            }}
          >
            Create new
          </Button>
        </Flex>

        <Box>
          <Flex flexDirection="column" style={{ gap: '1rem' }}>
            <Heading level={4}>Template</Heading>

            <Text>
              {
                'You can add voucher parameters in the template by surrounding them with double brackets. So for example, if you want to display the name of the redemeer you can use {{REDEEMER_NAME}} in the template.'
              }
            </Text>
            <Text fontWeight="bold">Available parameters:</Text>
            <List>
              <ListItem>REDEEMER_NAME</ListItem>
              <ListItem>PERSONALIZED_MESSAGE</ListItem>
              <ListItem>VOUCHER_CODE</ListItem>
              <ListItem>PURCHASER_NAME</ListItem>
              <ListItem>GENERATED_DATE</ListItem>
              <ListItem>EXPIRY_DATE</ListItem>
              <ListItem>IMAGE</ListItem>
              <ListItem>PRODUCT_COPY</ListItem>
            </List>
          </Flex>

          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={{
              content: isCreating ? '' : selectedTemplate?.content.value,
              name: isCreating ? '' : selectedTemplate?.content.name,
              isBulk: isCreating
                ? false
                : selectedTemplate?.location === 'bulk',
            }}
            validationSchema={VoucherTemplatesFormSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Flex flexDirection="column" style={{ gap: '1rem' }}>
                  <Field name="name">
                    {({ field }) => (
                      <TextField
                        data-testid="voucher-template-name"
                        disabled={!isCreating && !selectedTemplate}
                        error={touched.name && errors.name}
                        id="name"
                        label="Name"
                        message={
                          touched.name && errors.name ? errors.name : undefined
                        }
                        name={field.name}
                        value={field.value || ''}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                      />
                    )}
                  </Field>

                  <Field name="content">
                    {({ field }) => (
                      <Textarea
                        data-testid="voucher-template-content"
                        disabled={!isCreating && !selectedTemplate}
                        error={touched.content && errors.content}
                        id="content"
                        label="Content"
                        message={
                          touched.content && errors.content
                            ? errors.content
                            : undefined
                        }
                        multiline
                        name={field.name}
                        rows={20}
                        value={field.value || ''}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                      />
                    )}
                  </Field>
                  <Field name="isBulk">
                    {({ field }) => (
                      <Checkbox
                        data-testid="voucher-template-isBulk"
                        disabled={!isCreating && !selectedTemplate}
                        error={touched.isBulk && errors.isBulk}
                        id="isBulk"
                        label="Template is only available for bulk PDF generation, not visible for end users"
                        message={
                          touched.isBulk && errors.isBulk
                            ? errors.isBulk
                            : undefined
                        }
                        name={field.name}
                        rows={20}
                        checked={field.value ?? false}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                      />
                    )}
                  </Field>
                </Flex>
              </Form>
            )}
          </Formik>

          <Flex justifyContent="flex-end" style={{ gap: '0.5rem' }}>
            {isCreating && (
              <Button
                appearance="secondary"
                data-testid="voucher-template-cancel"
                iconLeft={Close}
                loading={
                  htmlContentCreate.isLoading || htmlContentUpdate.isLoading
                }
                style={{ width: '10rem' }}
                onClick={() => setIsCreating(false)}
              >
                Cancel
              </Button>
            )}
            <Button
              data-testid="voucher-template-save"
              disabled={!isCreating && !selectedTemplate}
              iconLeft={Save}
              loading={
                htmlContentCreate.isLoading || htmlContentUpdate.isLoading
              }
              style={{ width: '10rem' }}
              onClick={() => formRef?.current?.submitForm()}
            >
              Save
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default VoucherTemplates;
