import { Button, Flex } from '@mediahuis/chameleon-react';
import React from 'react';

const OfferGroupsCollapseControls = ({
  isEditing,
  onDelete,
  onEdit,
  onSave,
}) => {
  return (
    <Flex pt={4} style={{ gap: '0.5rem' }}>
      {isEditing ? (
        <Button appearance="primary" size="small" onClick={onSave}>
          Save
        </Button>
      ) : (
        <Button appearance="secondary" size="small" onClick={onEdit}>
          Edit
        </Button>
      )}

      <Button appearance="plain" size="small" onClick={onDelete}>
        Delete
      </Button>
    </Flex>
  );
};

export default OfferGroupsCollapseControls;
