import React from 'react';
import isEmpty from 'lodash.isempty';
import { useHistory, useParams } from 'react-router-dom';
import { Heading, Paper, Flex, Caption } from '@mediahuis/chameleon-react';
import { secureApiCall } from '~/utils';
import {
  PageLoader,
  FormButtons,
  Sidebar,
  StickyTop,
  notifySuccess,
  notifyError,
} from '~/components';
import { useSubscriptionScopeContext } from '~/components/context/subscriptionScope';
import validateSubscriptionScope from './validation/index';
import SubscriptionScopeForm from './components/SubscriptionScopeForm';

const SubscriptionScopesDetail = () => {
  const {
    subscriptionScope,
    setSubscriptionScope,
    setErrors,
  } = useSubscriptionScopeContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(false);

  const { id, brand } = useParams();

  const history = useHistory();
  const url = `${API_SUBSCRIPTION_SCOPES}/${id ? decodeURIComponent(id) : ''}${
    brand ? `?brand=${brand}` : ''
  }`;

  React.useEffect(() => {
    const fetchData = async () => {
      await secureApiCall(url, {
        method: 'GET',
        onSuccess: response => setSubscriptionScope(response?.data),
        onError: error => {
          notifyError({
            status: error?.response?.request?.status,
            message: error?.response?.data?.errors
              ? error.response.data.errors?.[0]
              : error?.message,
          });
          history.push('/scopes');
        },
      });
      setIsLoading(false);
    };
    if (id && brand) {
      fetchData();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, brand]);

  const onSubmit = async () => {
    const subscriptionScopeErrors = validateSubscriptionScope(
      subscriptionScope,
    );
    if (!isEmpty(subscriptionScopeErrors)) {
      return setErrors(subscriptionScopeErrors);
    }
    setIsFetching(true);

    await secureApiCall(url, {
      method: id ? 'PATCH' : 'POST',
      data: JSON.stringify({
        ...subscriptionScope,
      }),
      onSuccess: response => {
        if (!id) {
          history.push(
            `/subscriptionScopes/edit/${response.data.subscriptionType}/${response.data.brand}`,
          );
        }
        notifySuccess({
          message: `${id ? 'Update' : 'Create'} success!`,
        });
      },
      onError: error => {
        notifyError({
          status:
            error.response.request.status && error.response.request.status,
          message: error.response.data.errors
            ? error.response.data.errors[0]
            : error.message,
        });
      },
    });
    return setIsFetching(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Flex alignItems="flex-start" justifyContent="center">
      <Paper p={6} mb={8} mr={1} width="600px">
        <Heading fontFamily="primary" level={4} mb={5}>
          {id ? 'Edit ' : 'Create'} Subscription Scope
        </Heading>
        <SubscriptionScopeForm id={id} />
      </Paper>
      <Sidebar>
        <StickyTop>
          <Paper p={4} mb={5}>
            <Heading fontFamily="primary" level={6}>
              Summary
            </Heading>
            <Caption level={2} mb={2}>
              <strong>Brand</strong>: {subscriptionScope.brand}
            </Caption>
          </Paper>
          <FormButtons isLoading={isFetching} onSave={onSubmit} editing={id} />
        </StickyTop>
      </Sidebar>
    </Flex>
  );
};

export default SubscriptionScopesDetail;
