import React from 'react';
import { Select } from '@mediahuis/chameleon-react';
import useAboshopContext from '../../components/context/useContext';
import noop from '../../utils/noop';

const BrandSelect = ({
  onChange = noop,
  value,
  disabled = false,
  allBrandsSelectable = false,
}) => {
  const context = useAboshopContext();
  return (
    <Select
      id="brandSelect"
      data-testid="brandsSelect"
      placeholder="Select brand"
      onChange={onChange}
      disabled={disabled}
      value={value}
      label="Brand"
    >
      {allBrandsSelectable && (
        <option key="all-brands" value="">
          All brands
        </option>
      )}
      {context.brands.map(brand => (
        <option key={brand.code} value={brand.code.toLowerCase()}>
          {brand.name}
        </option>
      ))}
    </Select>
  );
};

export default BrandSelect;
