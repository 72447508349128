import {
  configRoutes,
  errorRoutes,
  homeRoutes,
  htmlContentsLocationsRoutes,
  htmlContentsOverviewRoutes,
  offersRoutes,
  ordersRoutes,
  preconditionRulesRoutes,
  productsRoutes,
  typesRoutes,
  vouchersOverviewRoutes,
  vouchersTemplatesRoutes,
} from './base';

// Important that errorRoutes is spread as last in the routes array
// else it will overwrite other paths because its path matching is not exact.
const routes = [
  ...homeRoutes,
  ...productsRoutes,
  ...offersRoutes,
  ...ordersRoutes,
  ...preconditionRulesRoutes,
  ...typesRoutes,
  ...vouchersOverviewRoutes,
  ...vouchersTemplatesRoutes,
  ...htmlContentsOverviewRoutes,
  ...htmlContentsLocationsRoutes,
  ...configRoutes,
  ...errorRoutes,
];

export default routes;
