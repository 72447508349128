import styled from 'styled-components';

export const List = styled.ul`
  margin: 0 0 ${props => props.theme.spacing.half};
  list-style-type: none;
  padding-left: 0;
  &:last-child {
    margin: 0;
  }
`;
