import { Box, Button, Flex, TextField } from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutVoucherValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const Voucher = ({ validation }) => {
  const [state, setState] = useState(validation.state);
  const [voucherCode, setVoucherCode] = useState(
    validation.data && validation.data.voucherCode,
  );

  const voucherValidationMutation = usePutVoucherValidation();

  useEffect(() => {
    if (voucherValidationMutation.isError) {
      notifyError({
        status:
          voucherValidationMutation.error.response?.request?.status &&
          voucherValidationMutation.error.response?.request?.status,
        message: voucherValidationMutation.error.response?.data?.errors
          ? voucherValidationMutation.error.response?.data?.errors[0]
          : voucherValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherValidationMutation.isError]);

  useEffect(() => {
    if (voucherValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the voucher',
      });
    }
  }, [voucherValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> Voucher
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>
        <Box>
          <TextField
            data-testid="input-voucher-code"
            defaultValue={voucherCode}
            id="VoucherCode"
            label="Voucher code"
            name="voucher_code"
            onChange={event => setVoucherCode(event.target.value)}
          />
        </Box>
        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              voucherValidationMutation.mutate({
                ...validation,
                data: { voucherCode },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default Voucher;
