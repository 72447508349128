import { REQUIREMENT_TYPE } from '../enums';

const getSubscriptionFormulaRequirements = subscriptionFormula => {
  return {
    [REQUIREMENT_TYPE.Email]: subscriptionFormula[REQUIREMENT_TYPE.Email],
    [REQUIREMENT_TYPE.Login]: subscriptionFormula[REQUIREMENT_TYPE.Login],
    [REQUIREMENT_TYPE.Date]: subscriptionFormula[REQUIREMENT_TYPE.Date],
    [REQUIREMENT_TYPE.Boolean]: subscriptionFormula[REQUIREMENT_TYPE.Boolean],
    [REQUIREMENT_TYPE.AddressArea]:
      subscriptionFormula[REQUIREMENT_TYPE.AddressArea],
    [REQUIREMENT_TYPE.InvoiceDetails]:
      subscriptionFormula[REQUIREMENT_TYPE.InvoiceDetails],
    [REQUIREMENT_TYPE.Payment]: subscriptionFormula[REQUIREMENT_TYPE.Payment],
    [REQUIREMENT_TYPE.File]: subscriptionFormula[REQUIREMENT_TYPE.File],
    [REQUIREMENT_TYPE.Textfield]:
      subscriptionFormula[REQUIREMENT_TYPE.Textfield],
    [REQUIREMENT_TYPE.Preconditions]:
      subscriptionFormula[REQUIREMENT_TYPE.Preconditions],
    [REQUIREMENT_TYPE.Voucher]: subscriptionFormula[REQUIREMENT_TYPE.Voucher],
    [REQUIREMENT_TYPE.Choice]: subscriptionFormula[REQUIREMENT_TYPE.Choice],
  };
};

export default getSubscriptionFormulaRequirements;
