import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Heading, Button } from '@mediahuis/chameleon-react';
import { useTranslation } from 'react-i18next';
import secureApiCall from '../../utils/secureApiCall';
import { notifyError } from '../Toasts';
import MultiAutoComplete from '../inputs/MultiAutoComplete';

const HeaderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.half};
`;

const FeaturesOverview = ({
  showInputForm,
  selectedFeatures,
  handleSelectFeature,
  brand,
}) => {
  const { i18n } = useTranslation();
  const [features, setFeatures] = useState([]);

  const handleSelect = feature => {
    handleSelectFeature([...selectedFeatures, feature]);
  };

  const handleRemove = feature => {
    const filteredFeatures = selectedFeatures.filter(
      el => el.id !== feature.id,
    );
    handleSelectFeature(filteredFeatures);
  };

  useEffect(() => {
    secureApiCall(`${API_OFFER_SERVICE}/features`, {
      method: 'GET',
      params: { 'api-version': '2', brands: brand, categories: 'Formula' },
      onSuccess: response => {
        setFeatures(response.data.results);
      },
      onError: errorMessage => {
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  }, [brand, i18n.language]);

  return (
    <div>
      <HeaderButtonWrapper>
        <Heading fontFamily="primary" level={4}>
          Features
        </Heading>
        <Button
          data-testid="add-features-button"
          appearance="secondary"
          size="small"
          ml={3}
          onClick={showInputForm}
        >
          Add
        </Button>
      </HeaderButtonWrapper>
      <MultiAutoComplete
        options={features}
        optionField="name"
        handleSelect={handleSelect}
        handleRemove={handleRemove}
        value={selectedFeatures}
        subHeading="description"
      />
    </div>
  );
};

export default FeaturesOverview;
