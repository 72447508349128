export const STATUS_PENDING = 'Pending';
export const STATUS_CONFIRMED = 'Compliant';
export const STATUS_REVOKED = 'NotValid';

export const VALIDATION_TYPES = Object.freeze({
  ADDRESS_AREA: 'AddressArea',
  BOOL: 'Boolean',
  CHOICE: 'Choice',
  DATE: 'Date',
  FILE: 'File',
  INVOICE: 'InvoiceDetails',
  LOGIN: 'Login',
  MAIL: 'Email',
  PAYMENT: 'Payment',
  PRECONDITION: 'Preconditions',
  TEXT_FIELD: 'Textfield',
  VOUCHER: 'Voucher',
});
