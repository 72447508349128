import { Flex } from '@mediahuis/chameleon-react';
import React from 'react';

const OverviewLayout = ({ filters, menu, table }) => {
  return (
    <Flex justifyContent="space-between" style={{ gap: '2.5rem' }} width="full">
      <Flex flexDirection="column" flexGrow={1} style={{ gap: '1rem' }}>
        {menu}

        {table}
      </Flex>

      {filters}
    </Flex>
  );
};

export default OverviewLayout;
