import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading, Button, Box } from '@mediahuis/chameleon-react';
import TextField from '../../components/inputs/TextField';
import MarkDownTextField from '../../components/markdown/MarkDownTextField';
import { brandField } from '../../data/fields/brand';
import secureApiCall from '../../utils/secureApiCall';
import { notifyError, notifySuccess } from '../Toasts';
import getNameValidation from './validation/getNameValidation';
import getDescriptionValidation from './validation/getDescriptionValidation';

const HeaderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.half};
`;

const AddFeature = ({
  showMultiSelect,
  brand,
  handleSelectFeature,
  selectedFeatures = [],
}) => {
  const [feature, setFeature] = useState({
    brand: brandField.defaultSelect,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [isValidated, setIsValidated] = useState(false);

  const handleFeatureChange = event => {
    if (isValidated) {
      if (event.target.name === 'name') {
        const nameErrors = getNameValidation(event.target.value);
        if (!nameErrors.name) {
          const spreadedCustomErrors = { ...customErrors };
          delete spreadedCustomErrors[event.target.name];
          setCustomErrors(spreadedCustomErrors);
        } else {
          setCustomErrors({ ...customErrors, ...nameErrors });
        }
      }
      if (event.target.name === 'description') {
        const descriptionErrors = getDescriptionValidation(event.target.value);
        if (!descriptionErrors.description) {
          const spreadedCustomErrors = { ...customErrors };
          delete spreadedCustomErrors[event.target.name];
          setCustomErrors(spreadedCustomErrors);
        } else {
          setCustomErrors({ ...customErrors, ...descriptionErrors });
        }
      }
    }
    setFeature({ ...feature, [event.target.name]: event.target.value });
  };

  const onClickSave = () => {
    setIsLoading(true);
    secureApiCall(`${API_OFFER_SERVICE}/Features`, {
      method: 'POST',
      data: JSON.stringify({
        ...feature,
        brand,
      }),
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        handleSelectFeature([...selectedFeatures, response.data]);
        setIsLoading(false);
        showMultiSelect();
        notifySuccess({
          message: 'Successfully created a feature.',
        });
      },
      onError: errorMessage => {
        setIsLoading(false);
        notifyError({
          status:
            errorMessage.response.request.status &&
            errorMessage.response.request.status,
          message: errorMessage.response.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  const handleValidations = e => {
    e.preventDefault();

    const nameErrors = getNameValidation(feature.name);
    const descriptionErrors = getDescriptionValidation(feature.description);

    const combinedErrors = { ...nameErrors, ...descriptionErrors };

    setIsValidated(true);
    if (Object.entries(combinedErrors).length !== 0) {
      setCustomErrors(combinedErrors);
      return;
    }
    onClickSave();
  };

  return (
    <div>
      <HeaderButtonWrapper>
        <Heading fontFamily="primary" level={4}>
          Features
        </Heading>
        <div>
          <Button
            data-testid="cancel-feature-button"
            appearance="secondary"
            size="small"
            onClick={showMultiSelect}
            mr={3}
          >
            Cancel
          </Button>
          <Button
            data-testid="save-feature-button"
            appearance="primary"
            size="small"
            onClick={handleValidations}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </HeaderButtonWrapper>
      <Box>
        <TextField
          id="name"
          label="Name"
          name="name"
          onChange={handleFeatureChange}
          datatestid="feature-name-input"
          errors={customErrors}
        />
      </Box>
      <Box mt={5}>
        <MarkDownTextField
          id="description"
          label="Description"
          name="description"
          onChange={handleFeatureChange}
          datatestid="feature-description-input"
          errors={customErrors}
          optionalLabel={MH_BRAND === BRANDS.LUX && 'Translatable'}
        />
      </Box>
      <Box mt={5}>
        <MarkDownTextField
          id="negativeDescription"
          label="Negative Description"
          name="negativeDescription"
          onChange={handleFeatureChange}
          datatestid="feature-negative-description-input"
          optionalLabel={MH_BRAND === BRANDS.LUX && 'Translatable'}
        />
      </Box>
    </div>
  );
};

export default AddFeature;
