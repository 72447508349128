import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Textarea } from '@mediahuis/chameleon-react';
import { MarkDownBlock } from './MarkDownPreview';
import { noop } from '../../utils';

const TextareaContained = styled(Textarea)`
  textarea {
    resize: vertical;
    overflow-y: hidden;
    width: 100%;
    margin-top: 1px;
  }
`;

const TextFieldWithMarkDown = ({
  datatestid,
  disabled = false,
  error,
  id,
  label,
  name,
  message,
  onBlur = noop,
  required = false,
  defaultValue,
  onFocus = noop,
  optionalLabel = null,
  rows = 1,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localValue, setLocalValue] = useState(defaultValue);

  return (
    <Fragment>
      <TextareaContained
        data-testid={datatestid}
        disabled={disabled}
        error={error}
        name={name}
        id={id}
        label={label}
        message={message}
        required={required}
        maxLength={500}
        value={localValue}
        optionalLabel={optionalLabel}
        rows={rows}
        onFocus={event => {
          onFocus(event);
          setIsFocused(true);
        }}
        onBlur={event => {
          onBlur(event);
          setIsFocused(false);
        }}
        onChange={event => setLocalValue(event.target.value)}
      />
      <MarkDownBlock source={localValue} isFocused={isFocused} />
    </Fragment>
  );
};

export default TextFieldWithMarkDown;
