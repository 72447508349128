import { Flex, Textarea, TextField } from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { object, string } from 'yup';

const EmailRequirementFormSchema = object().shape({
  description: string(),
  name: string().required(),
});

const EmailRequirementForm = ({
  disabled,
  formRef,
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={EmailRequirementFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NameInput"
                label="Name"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>
        </Flex>
      </Form>
    </Formik>
  );
};

export default EmailRequirementForm;
