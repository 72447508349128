import {
  Box,
  Button,
  Flex,
  TextField as TextFieldCh,
} from '@mediahuis/chameleon-react';
import React, { useEffect, useState } from 'react';

import { usePutTextfieldValidation } from '~/services/order';

import {
  ExpansionPanel,
  notifyError,
  notifySuccess,
} from '../../../components';
import StateDropdown from './StateDropdown';
import ValidationIcon from './ValidationIcon';

const TextField = ({ validation }) => {
  const [state, setState] = useState(validation.state);
  const [value, setValue] = useState(validation.data?.value);

  const textfieldValidationMutation = usePutTextfieldValidation();

  useEffect(() => {
    if (textfieldValidationMutation.isError) {
      notifyError({
        status:
          textfieldValidationMutation.error.response?.request?.status &&
          textfieldValidationMutation.error.response?.request?.status,
        message: textfieldValidationMutation.error.response?.data?.errors
          ? textfieldValidationMutation.error.response?.data?.errors[0]
          : textfieldValidationMutation.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textfieldValidationMutation.isError]);

  useEffect(() => {
    if (textfieldValidationMutation.isSuccess) {
      notifySuccess({
        message: 'Successfully edited the textfield',
      });
    }
  }, [textfieldValidationMutation.isSuccess]);

  return (
    <ExpansionPanel
      headerText={
        <Flex>
          <ValidationIcon status={state} /> TextField
          {` | ${validation?.key}`}
        </Flex>
      }
    >
      <Box width="full" p={1}>
        <Box mb={5}>
          <StateDropdown state={state} setState={setState} />
        </Box>
        <Box>
          <TextFieldCh
            label="TextField value"
            id="textField"
            name="textField"
            data-testid="input-textField"
            defaultValue={value}
            onChange={event => setValue(event.target.value)}
          />
        </Box>
        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              textfieldValidationMutation.mutate({
                ...validation,
                data: { value },
                state,
              })
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
    </ExpansionPanel>
  );
};

export default TextField;
