export default (state, brandCode) => {
  if (state.initialValues.requirements.length > 0) {
    return state.initialValues.requirements.map(requirement => ({
      ...requirement,
      brand: brandCode,
      subscriptionFormulaId: null,
      subscriptionTypeId: null,
      id: null,
    }));
  }
  return [];
};
