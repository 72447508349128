import { Auth } from 'aws-amplify';
import Axios from 'axios';
import queryString from 'query-string';
import { getI18n } from 'react-i18next';

const offerServiceInstance = Axios.create({
  baseURL: API_OFFER_SERVICE,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    'api-version': '2',
  },
});

offerServiceInstance.interceptors.request.use(
  async config => {
    const { language } = getI18n();
    const session = await Auth.currentSession();

    if (language) {
      config.params = {
        ...config.params,
        lang: language,
      };
    }
    if (session.idToken?.jwtToken) {
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    }

    return config;
  },
  err => Promise.reject(err),
);

class OfferService {
  static deleteHtmlContent(id) {
    return offerServiceInstance.delete(`/HtmlContents/${id}`);
  }

  static getHtmlContents(params) {
    return offerServiceInstance.get('/HtmlContents', { params });
  }

  static getOffers(filters) {
    const filterParams = queryString.stringify(filters);

    return offerServiceInstance.get(`/Offers?${filterParams}`, {
      params: {
        amount: filters.amount || 20
      },
    });
  }

  static getSubscriptionFormulas(filters) {
    const filterParams = queryString.stringify(filters);

    return offerServiceInstance.get(`/SubscriptionFormulas?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }

  static getSubscriptionTypes(filters) {
    const filterParams = queryString.stringify(filters);

    return offerServiceInstance.get(`/SubscriptionTypes?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }

  static getFeatures(filters) {
    const filterParams = queryString.stringify(filters);

    return offerServiceInstance.get(`/Features?${filterParams}`, {
      params: {
        amount: filters.amount || 20,
      },
    });
  }

  static postHtmlContents(body) {
    return offerServiceInstance.post('/HtmlContents', body);
  }

  static putHtmlContents(body) {
    return offerServiceInstance.put(`/HtmlContents/${body.id}`, body);
  }
}

export default OfferService;
