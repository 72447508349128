import {
  Box,
  Flex,
  IconButton,
  Select,
  Text,
} from '@mediahuis/chameleon-react';
import { Close } from '@mediahuis/chameleon-theme-wl/icons';
import React from 'react';

const OfferGroupItemsSelect = ({
  disabled,
  label,
  options,
  selectedOptions,
  onChange,
}) => {
  function handleSelectChange(event) {
    event.preventDefault();
    onChange([
      ...selectedOptions,
      {
        id: Number(event.target.value),
        type: options
          .filter(item => item.id === Number(event.target.value))
          .map(item => item.type)[0],
      },
    ]);
    event.target.selectedIndex = 0;
  }

  return (
    <Flex flexDirection="column" style={{ gap: '0.25rem' }}>
      <Select
        disabled={disabled}
        id="OfferGroupItemsSelect"
        label={label}
        placeholder="Select products"
        onChange={handleSelectChange}
      >
        {options
          .filter(option => {
            if (!selectedOptions.length > 0) return option;

            return !selectedOptions.some(
              selectedOption => selectedOption.id === option.id,
            );
          })
          .map(option => (
            <option key={`${option.id}-${option.title}`} value={option.id}>
              {option.id} - {option.title}
            </option>
          ))}
      </Select>

      <Box
        borderColor="colorGrey40"
        borderRadius={2}
        borderStyle="solid"
        borderWidth={1}
        display="flex"
        flexBasis="50%"
        flexDirection="column"
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map(selectedOption => (
            <Flex
              alignItems="center"
              key={selectedOption.id}
              justifyContent="space-between"
              pl={5}
              pr={2}
              py={2}
            >
              <Text>
                {options
                  .filter(option => option.id === selectedOption.id)
                  .map(option => `${option.id} - ${option.title}`)}
              </Text>

              <Flex style={{ gap: '0.1rem' }}>
                <IconButton
                  color="colorRed30"
                  disabled={disabled}
                  icon={Close}
                  size="small"
                  onClick={event => {
                    event.preventDefault();
                    onChange(
                      selectedOptions.filter(
                        option => option !== selectedOption,
                      ),
                    );
                  }}
                />
              </Flex>
            </Flex>
          ))
        ) : (
          <Flex alignItems="center" height={8} pl={5} pr={2} py={2}>
            <Text fontStyle="italic">No products selected</Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default OfferGroupItemsSelect;
