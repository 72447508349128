import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  TextField,
} from '@mediahuis/chameleon-react';
import { Drag } from '@mediahuis/chameleon-theme-wl/icons';
import { Segmented } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableTransfer } from '~/components/TableTransfer';
import { useContentLinks } from '~/services/content';

const columns = [
  {
    dataIndex: 'id',
    title: 'Id',
    render: id => <Link to={`/contents/edit/${id}`}>{id}</Link>,
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
];

const draggableColumns = [
  ...columns,
  {
    dataIndex: '',
    title: '',
    render: () => <Icon size="small" as={Drag} />,
  },
];

const HtmlContentTransfer = ({
  brand,
  contentKey,
  dataSource,
  locationOptions,
  targetKeys,
  title,
  onChange,
}) => {
  const [selectedLocationOption, setSelectedLocationOption] = useState(
    locationOptions[0].value,
  );
  const [searchValue, setSearchValue] = useState('');

  const contentLinksQuery = useContentLinks(brand, contentKey);

  useEffect(() => {
    if (contentLinksQuery.isSuccess) {
      if (!targetKeys[selectedLocationOption]) {
        const contentLinks =
          contentLinksQuery.data.contentLinks?.[selectedLocationOption] || [];
        const contentLinkIds = contentLinks.map(
          contentLink => contentLink.contentId,
        );

        onChange(prevTargetKeys => ({
          ...prevTargetKeys,
          [selectedLocationOption]: contentLinkIds,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationOption, contentLinksQuery.isSuccess]);

  function changeTargetPosition(startIndex, endIndex) {
    const newTargetKeys = [...targetKeys[selectedLocationOption]];
    const newTargetKey = newTargetKeys.splice(startIndex, 1)[0];

    newTargetKeys.splice(endIndex, 0, newTargetKey);
    onChange(prevTargetKeys => ({
      ...prevTargetKeys,
      [selectedLocationOption]: [...newTargetKeys],
    }));
  }

  function transferItems(newTargetKeys) {
    onChange(prevTargetKeys => ({
      ...prevTargetKeys,
      [selectedLocationOption]: [...newTargetKeys],
    }));
  }

  return (
    <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
      <Heading fontFamily="primary" level={4}>
        {title}
      </Heading>

      <Segmented
        block
        options={locationOptions.map(locationOption => ({
          label: (
            <Box key={locationOption.value} p={2}>
              <Text>{locationOption.label}</Text>
            </Box>
          ),
          value: locationOption.value,
        }))}
        value={selectedLocationOption}
        onChange={setSelectedLocationOption}
      />

      <TextField
        label="Search"
        labelHidden
        placeholder="Search"
        onChange={event => setSearchValue(event.target.value)}
      />

      <TableTransfer
        columns={columns}
        draggableColumns={draggableColumns}
        dataSource={dataSource.filter(item =>
          item.name.toUpperCase().includes(searchValue.toUpperCase()),
        )}
        disabled={!contentLinksQuery.isSuccess}
        targetKeys={targetKeys[selectedLocationOption] || []}
        titles={['Available', 'Active']}
        onChange={transferItems}
        onRowChange={changeTargetPosition}
      />
    </Flex>
  );
};

export default HtmlContentTransfer;
