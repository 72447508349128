import React, { useCallback, useState } from 'react';
import {
  Button,
  Flex,
  Paragraph,
  TextField,
  Box,
} from '@mediahuis/chameleon-react';
import { getPickupLocationByPostalCode } from '../../../../utils';
import PickupCard from './PickupCard';

const PickupLocation = ({ validation, handleSubmit }) => {
  const [pickupLocations, setPickupLocations] = useState([]);
  const [pickupLocation, setPickupLocation] = useState({
    addressee: validation.data ? validation.data.addressee : '',
    busNumber: validation.data ? validation.data.busNumber : '',
    city: validation.data ? validation.data.city : '',
    countryCode: validation.data ? validation.data.countryCode : '',
    deliveryStore: validation.data ? validation.data.deliveryStore : '',
    houseNumber: validation.data ? validation.data.houseNumber : '',
    postalCode: validation.data ? validation.data.postalCode : '',
    shopId: validation.data ? validation.data.shopId : '',
    street: validation.data ? validation.data.street : '',
  });

  const loadPickupLocations = useCallback(
    code => {
      getPickupLocationByPostalCode({
        postalCode: code,
      })
        .then(response => {
          setPickupLocations(response.data);
          return response;
        })
        .catch(error => error);
    },
    [setPickupLocations],
  );

  const handlePickupLocationSelect = location => {
    setPickupLocation({ box: '', ...location });
  };

  if (pickupLocation.shopId) {
    return (
      <React.Fragment>
        <Box>
          <PickupCard
            handlePickupLocationSelect={handlePickupLocationSelect}
            hoverable={false}
            location={pickupLocation}
            isSelected={true}
          />
        </Box>
        <Flex mt={4} justifyContent="flex-end" width="full">
          <Button type="primary" onClick={() => handleSubmit(pickupLocation)}>
            Save
          </Button>
        </Flex>
      </React.Fragment>
    );
  }

  return (
    <Box width="full" p={1}>
      <Box mb={5}>
        <TextField
          label="PostalCode"
          id="PostalCode"
          name="PostalCode"
          data-testid="PostalCode"
          onChange={event => {
            setPickupLocation({ postalCode: event.target.value });
            loadPickupLocations(event.target.value);
          }}
        />
      </Box>
      <Box>
        {pickupLocation.postalCode &&
          (pickupLocations.length < 1 ? (
            <Paragraph mb={5} px={5}>
              Geen krantenwinkels beschikbaar voor deze postcode
            </Paragraph>
          ) : (
            <Box container spacing={2}>
              {pickupLocations.map(location => (
                <Box key={location.shopId}>
                  <PickupCard
                    handlePickupLocationSelect={handlePickupLocationSelect}
                    hoverable={false}
                    location={location}
                    isSelected={false}
                  />
                </Box>
              ))}
            </Box>
          ))}
      </Box>
      <Flex mt={4} justifyContent="flex-end" width="full">
        <Button
          type="primary"
          disabled
          onClick={() => handleSubmit(pickupLocation)}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default PickupLocation;
