import secureApiCall from './secureApiCall';

const getBrands = () =>
  secureApiCall(`${API_OFFER_SERVICE}/brands`, {
    method: 'GET',
    params: {
      'api-version': '2',
    },
    onSuccess: response => response.data,
    onError: () => [],
  });

export default getBrands;
