import { Flex } from '@mediahuis/chameleon-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Step, Steps, Wizard } from 'react-albus/lib';
import { notifyError } from '~/components';
import { secureApiCall } from '~/utils';
import FormulaForm from './components/FormulaForm';
import ChooseSubscriptionType from './components/ChooseSubscriptionType';

const AddFormula = ({ history, url }) => {
  const { state = {} } = useLocation();
  const [initialValues, setInitialValues] = useState({
    addressAreaRequirements: [],
    booleanRequirements: [],
    choiceRequirements: [],
    dateRequirements: [],
    emailRequirements: [],
    fileRequirements: [],
    textfieldRequirements: [],
    features: [],
    id: 0,
    brand: '',
    reference: '',
  });

  const handleRowClick = (row, next) => {
    if (state.initialValues) {
      // Duplication flow
      secureApiCall(
        `${API_OFFER_SERVICE}/SubscriptionFormulas/${state.initialValues.id}/duplicate`,
        {
          method: 'POST',
          data: JSON.stringify({ subscriptionTypeId: row.id }),
          params: {
            'api-version': '2',
          },
          onSuccess: response => {
            history.push(`/subscriptionformula/edit/${response.data.id}`);
          },
          onError: error => {
            notifyError({
              message: error.message,
            });
          },
        },
      );
    } else {
      // Standard flow
      secureApiCall(`${API_OFFER_SERVICE}/SubscriptionTypes/${row.id}`, {
        method: 'GET',
        params: {
          'api-version': '2',
        },
        onSuccess: response => {
          setInitialValues({
            ...initialValues,
            image: '',
            features: [],
            brand: row.brand,
            subscriptionType: response.data,
          });
          next();
        },
        onError: error => {
          notifyError({
            message: error.message,
          });
        },
      });
    }
  };

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Wizard history={history} basename={url}>
        <Steps>
          <Step
            id="type"
            render={({ next }) => (
              <ChooseSubscriptionType
                onRowClick={row => handleRowClick(row, next)}
              />
            )}
          />
          <Step
            id="formula"
            render={() => (
              <Flex flexGrow={0} justifyContent="space-around">
                <FormulaForm
                  initialValues={initialValues}
                  apiUrl={`${API_OFFER_SERVICE}/SubscriptionFormulas`}
                />
              </Flex>
            )}
          />
        </Steps>
      </Wizard>
    </Flex>
  );
};

export default AddFormula;
