const allOperations = [
  'Equals',
  'NotEquals',
  'SmallerOrEquals',
  'Smaller',
  'GreaterOrEquals',
  'Greater',
  'In',
  'NotIn',
];

const subscriptionKinds = [
  'Free',
  'Action',
  'Promo',
  'FullPrice',
  'LinkedSale',
  'Incentive',
  'Student',
  'Teacher',
  'FreeMonth',
  'Senior',
];

const base = {
  allOperations,
  subscriptionKinds,
};

export default base;
